import { io } from 'socket.io-client';
import { SWITCH_MESSENGER_TOGGLE, GET_CHAT_ROOMS_SUCCESS, GET_CHAT_ROOMS_FAILED, RECEIVED_ROOM_JOINED, GET_ROOM_CHATS_SUCCESS, GET_ROOM_CHATS_FAILED, RECEIVED_MESSAGE, DELETE_CHAT, RECEIVED_READ_STATUS, UNREAD_MESSAGE_COUNT } from './types';
import API from '../../helpers/api';
import { isAuth } from '../../helpers/auth';
import { get } from 'lodash';

const currentUser = isAuth();
export const socket = io(process.env.REACT_APP_API_HOST, { 
    path: '/socket.io',
    transports: ['websocket'],
    reconnection: true,  // Enable reconnection
    reconnectionDelay: 1000,  // Initial delay before attempting reconnection (in milliseconds)
    reconnectionAttempts: 10,  // Number of reconnection attempts before giving up
});

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

export const getChatRooms = (query) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGetByKey('chat', `/rooms`, query);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_CHAT_ROOMS_SUCCESS, payload: response.data});
        } else {
            await dispatch({ type: GET_CHAT_ROOMS_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_CHAT_ROOMS_FAILED);
      }
    }
}

export const getRoomChats = (chatId, query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGetByKey('chat', `/${chatId}`, query);

          /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: GET_ROOM_CHATS_SUCCESS, payload: response.data});
            } else {
                await dispatch({ type: GET_ROOM_CHATS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_ROOM_CHATS_FAILED);
        }
      }
}


export const switchMessengerToggle = (value) => {
    return async (dispatch) => {
        dispatch({ type: SWITCH_MESSENGER_TOGGLE, payload: value });
    } 
}

/** --------- Used to store socket Id with reference of userId ----------- */
export const registerUserSocket = (userId) => {
    socket.emit('registerSocket', userId);
    requestUnreadMessageCount(userId);
}

export const socketConnection = (room) => {
    socket.on('connect', () => {
        if(room){
            socket.emit('joinRoom', room);
        }
    });      
}

export const socketDisconnected = () => {
    socket.on('disconnect', () => {
        console.log('Disconnected from server');
    });
}


export const socketReconnect = () => {
  socket.on('reconnect', (attemptNumber) => {
    console.log(`Reconnected to server (attempt ${attemptNumber})`);
  });
}

export const socketReconnectFailed = () => {
    socket.on('reconnect_failed', () => {
        console.log('Failed to reconnect to server');
    });
}

export const createAndJoinRoom = (data) => {
    socket.emit('createRoom', data);
}

// Function to send a message to the server
export const sendMessage = (data) => {
    socket.emit('message', data);
};

export const readMessage = (roomId, messageId, side=false) => {
    socket.emit('messageRead', {roomId, messageId, side});
}


export const deleteMessage = (data) => {
    socket.emit('deleteMessage', data);
}

export const requestUnreadMessageCount = (userId) => {
    socket.emit('requestUnreadMessageCount', userId);
}

export const receivedRoomJoined = () => {
    return async (dispatch) => {
        socket.on('roomJoined', async  (data) => {
            if(data){
                dispatch({ type: RECEIVED_ROOM_JOINED, payload:data });
            }
        })
    } 
}


export const receivedMessage = () => {
    return async (dispatch) => {
        socket.on('message', async (data) => {
            if(data){
                dispatch({ type: RECEIVED_MESSAGE, payload: data});
                requestUnreadMessageCount(currentUser.aud)
            }
        } )
    }
}

export const receiveReadStatus = () => {
    return async (dispatch) => {
        socket.on('messageRead', async ({messageId, roomId}) => {
            if(messageId){
                dispatch({ type: RECEIVED_READ_STATUS, payload: {messageId, roomId} });
                requestUnreadMessageCount(currentUser.aud)
            }
        })
    }
}

export const receivedDeletedMessage = () => {
    return async (dispatch) => {
        socket.on('deleteMessage', async (data) => {
            if(data){
                dispatch({ type: DELETE_CHAT, payload: data });
                requestUnreadMessageCount(currentUser.aud)
            }
        });
    }
} 



export const unreadMessageCount = () => {
    return async (dispatch) => {
        socket.on('unreadMessageCount', async (data) => {
            if(data){
                dispatch({ type: UNREAD_MESSAGE_COUNT, payload: data });
            }
        });
    }
} 
