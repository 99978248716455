import { 
    CANCEL_PLAN_FAILED,
    CANCEL_PLAN_SUCCESS,
    CLEAR_SUBSCRIPTION_STATE,
    GET_SUBSCRIPTION_PLANS_FAILED, 
    GET_SUBSCRIPTION_PLANS_SUCCESS, 
    PURCHASE_PLAN_FAILED, 
    PURCHASE_PLAN_SUCCESS,
    UPGRADE_PLAN_FAILED,
    UPGRADE_PLAN_SUCCESS
} from "../actions/types";
const initialState = {
    error : null,
    plans: null,
    purchase: null,
}

const subscriptionReducer = ( state = initialState, action ) => {
    switch(action.type){
        case GET_SUBSCRIPTION_PLANS_SUCCESS:
            return {
                plans: action.payload,
                error: null
            }
        case GET_SUBSCRIPTION_PLANS_FAILED:
            return {
                error: action.payload,
            }
        case PURCHASE_PLAN_SUCCESS:
            return {
                purchase: action.payload,
                error: null
            }
        case PURCHASE_PLAN_FAILED:
            return {
                error: action.payload
            }
        case UPGRADE_PLAN_SUCCESS:
            return {
                upgrade: action.payload,
                error: null
            }
        case UPGRADE_PLAN_FAILED:
            return {
                error: action.payload
            }
        case CANCEL_PLAN_SUCCESS:
            return {
                cancel: action.payload,
                error: null
            }
        case CANCEL_PLAN_FAILED:
            return {
                error: action.payload
            }
        case CLEAR_SUBSCRIPTION_STATE:
            return {
                resetState: null
            }
        default: return state
    }
}

export default subscriptionReducer;