import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate, } from 'react-router-dom';
import { isAuth } from '../../helpers/auth';
// import { switchAppView } from '../../redux/actions/meta.action';
// import { useDispatch } from 'react-redux';

const CustomCongratulationsModal = ({ show, onHide }) => {
    const loggedUser = isAuth();
    const navigate = useNavigate();
    // const dispatch = useDispatch();

    const handleSwitchToClientView = () =>  {
        localStorage.removeItem('sp_id');
        localStorage.removeItem('specialty');
        // dispatch(switchAppView('client'))
        // setTimeout(() => {
            // navigate(`/business/${loggedUser.user}`);
            navigate(`/dashboard`);
        // }, 0)
    }


    return (
        <Modal 
            show={show}
            onHide={onHide}
            backdrop='static'
            size='xl'
        >
            <Modal.Body>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Congratulations!
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                        You’ve successfully completed your profile setup. Thank you for taking the time to share your information and join the BirthBridge community.
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                        Your profile is now live, and families can start discovering your services. We’re excited to support you as you grow your practice and connect with the families who need you most.
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '20px', fontWeight: 'bold' }}>
                        Share Your Profile:
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                        Help more families find you by sharing your profile! Use the link below to spread the word through social media, email, or text.
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigator.clipboard.writeText(`${window.location.origin}/business/${loggedUser?.user}`)}
                        >
                            Copy Profile Link
                        </Button>
                    </Box>
                    <Typography variant="body1" align="center" style={{ marginTop: '20px', fontWeight: 'bold' }}>
                        Earn Rewards Through Referrals:
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                        Invite fellow birth professionals to join BirthBridge and earn rewards for each successful referral. Your referral credits can be applied toward your future BirthBridge payments.
                    </Typography>
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ marginTop: '10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate('/invite')}
                        >
                            Invite a Friend
                        </Button>
                    </Box>
                    <Typography variant="body1" align="center" style={{ marginTop: '20px', fontWeight: 'bold' }}>
                        Manage Your Payments:
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '10px' }}>
                        BirthBridge makes it simple to manage payments for your services. Visit the "Wallet" section to track payments, update your payment details, and manage invoices with ease.
                    </Typography>
                    <Typography variant="body1" align="center" style={{ marginTop: '20px' }}>
                        We’re here to support you every step of the way. Welcome to the BirthBridge community!
                    </Typography>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-center pt-2 pb-2'>
                    <Button
                        variant="contained"
                        color="primary"
                        // onClick={() => window.location.replace('/profile')}
                        onClick={() => handleSwitchToClientView()}
                        style={{
                            backgroundColor:  '#5b6e74',
                            width: 200,
                            height: 45,
                            cursor: `${'pointer'}`
                          }}
                    >
                        Finish
                    </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CustomCongratulationsModal;