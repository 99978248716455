import {
    GET_ALL_BUSINESS_SUCCESS,
    GET_ALL_BUSINESS_FAILED,
    GET_SINGLE_BUSINESS_SUCCESS,
    GET_SINGLE_BUSINESS_FAILED,
    GET_TOPFAVOURITE_BUISNESS_SUCCESS,
    GET_TOPFAVOURITE_BUISNESS_FAILED,
    GET_TOPRATED_BUISNESS_SUCCESS,
    GET_TOPRATED_BUISNESS_FAILED,
    GET_NEAR_BY_BUSINESS_SUCCESS,
    GET_NEAR_BY_BUSINESS_FAILED,
    GET_ALL_BIRTH_PROFESSIONALS_SUCCESS,
    GET_ALL_BIRTH_PROFESSIONALS_FAILED,
} from "../actions/types";

const initialState = {
  businessAll: null,
  errorBusiness:null,
  businessSingle: null,
  errorBusiSingle:null,
  favourite:null,
  topRated: null,
  error: null
};

const businessReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_ALL_BUSINESS_SUCCESS :
            return {
                // ...state,
                businessAll : action.payload,
                errorBusiness: null
            }
        case GET_ALL_BUSINESS_FAILED : 
            return {
                // ...state,
                errorBusiness : action.payload
            }
        case GET_SINGLE_BUSINESS_SUCCESS :
            return {
                // ...state,
                businessSingle : action.payload,
                errorBusiSingle: null
            }
        case GET_SINGLE_BUSINESS_FAILED : 
            return {
                // ...state,
                errorBusiSingle : action.payload
            }
        case GET_TOPFAVOURITE_BUISNESS_SUCCESS:
            return {
                favourite: action.payload,
                error: null
            }
        case GET_TOPFAVOURITE_BUISNESS_FAILED:
            return {
                error: action.payload
            }
        case GET_TOPRATED_BUISNESS_SUCCESS:
            return {
                topRated: action.payload,
                error: null
            }
        case GET_TOPRATED_BUISNESS_FAILED:
            return {
                error: action.payload
            }
        case GET_NEAR_BY_BUSINESS_SUCCESS:
            return {
                nearByBusiness: action.payload,
                error: null
            }
        case GET_NEAR_BY_BUSINESS_FAILED:
            return {
                error: action.payload
            }
        case GET_ALL_BIRTH_PROFESSIONALS_SUCCESS:
            return {
                professionals: action.payload,
                error: null
            }
        case GET_ALL_BIRTH_PROFESSIONALS_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};

export default businessReducer;