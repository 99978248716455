import API from '../helpers/api';
import axios from 'axios';

export const communityService = {
    getAllPosts: async (page = 1, limit = 5) => {
        try {
            const response = await API.apiGet('community', `/posts?page=${page}&limit=${limit}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    getPostById: async (postId) => {
        try {
            const response = await API.apiGet('community', `/posts/${postId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    createPost: async (postData) => {
        try {
            const response = await API.apiPostUrl('community', `/posts`, postData);
            return response;
        } catch (error) {
            throw error;
        }
    },

    updatePost: async (postId, postData) => {
        try {
            const response = await API.apiPutUrl('community', `/posts/${postId}`, postData);
            return response;
        } catch (error) {
            console.error('Error updating post:', error);
            throw error;
        }
    },



    addComment: async (postId, commentData) => {
        try {
          

            const response = await API.apiPostUrl('community', `/posts/${postId}/comments`, {
                content: commentData.content,
                parentId: commentData.parentId
            });

            
            return response;
        } catch (error) {
            console.error('Error in addComment service:', error);
            console.error('Error details:', {
                status: error.response?.status,
                message: error.response?.data?.message
            });
            throw error;
        }
    },

    updateComment: async (postId, commentId, commentData) => {
        try {
            
            const response = await API.apiPutUrl(
                'community',
                `/posts/${postId}/comments/${commentId}`,
                commentData
            );

            return response;
        } catch (error) {
            console.error('Error updating comment:', error);
            throw error;
        }
    },

    deletePost: async (postId) => {
        try {
            const response = await API.apiDelete('community', `/posts/${postId}`);
            return response;
        } catch (error) {
            throw error;
        }
    },


    editPost: async (postId, data) => {

        try {
            const response = await API.apiPutUrl('community', `/posts/${postId}`, data);
            return response;
        } catch (error) {
            throw error;
        }
    },

    editComment: async (postId, commentId, content, isReplyDeleted = false) => {
        try {
         

            const response = await API.apiPutUrl('community', `/posts/${postId}/comments/${commentId}`, {
                content,  // Just send the content string directly,
                isReplyDeleted,
            });

            return response;
        } catch (error) {
            console.error('[FE] Edit comment error:', JSON.stringify(error.response?.data || error, null, 2));
            throw error;
        }
    },

    editReply: async (postId, commentId, replyId, content, isReplyDeleted = false) => {
        try {
           

            const response = await API.apiPutUrl('community', `/posts/${postId}/comments/${commentId}/replies/${replyId}`, {
                content,  // Just send the content string directly
                isReplyDeleted,
            });

            return response;
        } catch (error) {
            console.error('[FE] Edit reply error:', JSON.stringify(error.response?.data || error, null, 2));
            throw error;
        }
    },

    upvotePost: async (postId) => {
        try {
            const response = await API.apiPostUrl('community', `/posts/${postId}/upvote`);
            return response;
        } catch (error) {
            throw error;
        }
    },

    upvoteComment: async (postId, commentId) => {
        try {
          
            const response = await API.apiPostUrl(
                'community',
                `/posts/${postId}/comments/${commentId}/upvote`
            );

            return response;
        } catch (error) {
            console.error('Service error:', error);
            throw error;
        }
    },

    getTopPosts: async () => {
        try {
            const response = await API.apiGet('community', '/posts/top');
            return response;
        } catch (error) {
            throw error;
        }
    }
}; 