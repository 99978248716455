import React from 'react';
import { Card, Button } from 'react-bootstrap';
import RatingReadOnly from '../../components/RatingReadOnly';
import { 
    displayAddress, 
    showOverview 
} from '../../utils/validations';
import { FaMapMarkerAlt } from "react-icons/fa";
import Tooltip from '../Tooltip';
import { RiMapPinRangeFill } from 'react-icons/ri';
import LoadingView from '../../components/LoadingView';
import { MdDelete } from 'react-icons/md';
import { optimizedCloudinaryImage } from '../../utils/fileCompression';

const defaultPlaceholder = require('../../assets/images/default__placeholder.png');

const CustomBookmarkCard = ({ loading, item, navigate, key, handleRemove, bookmarkId }) => {

    const isStaff = item.associatedWithSeller && Array.isArray(item.associatedWithSeller) && item.associatedWithSeller.length
        ?
        (typeof item.associatedWithSeller[0] === 'object')
            ? item.associatedWithSeller[0]._id
            : item.associatedWithSeller[0]

        : null
    let services = (item.services && item.services.length) ? item.services.map((services) => services.category.name) : []

    return (
        <Card 
            key={key}
        >
            <div className='card--media'>
                <Card.Link className='cursor-pointer' style={{ backgroundColor: 'transparent' }}>
                    <Card.Img
                        variant="top"
                        src={item?.profilePic
                            ? optimizedCloudinaryImage(item?.profilePic?.url)
                            : defaultPlaceholder
                        }
                        alt={`${item.name}`}
                    />
                </Card.Link>
                <Card.Title>
                    <Card.Link>
                        <p className="text-capitalize" style={{ marginBottom:'5px', color:'#000'}}>
                            {item.name}
                        </p>
                        {services &&
                            <p className='mb-1' style={{ marginBottom:'0px', color:'#000'}}>
                                {services.map(service => service).splice(0, 2).join(', ')} 
                                {services.length > 2 ? '... more' : ''}
                            </p>
                        }
                        <RatingReadOnly 
                            value={item.averageRating ? item.averageRating : 0} 
                            reviewsCount={true} 
                            reviews={Array.from({ length: item.reviewCount }, () => [])}
                        />
                    </Card.Link>
                </Card.Title> 

            </div>
            <Card.Body>
                <Card.Title>
                    <Card.Link style={{ cursor:'default'}}>
                        {(item && item.language && item.language.length) && <small>{item.language.map(item => item.label).join(', ')}</small>}
                        {item.address && 
                            <span className='d-flex mt-2'> 
                                <small><FaMapMarkerAlt /> {displayAddress(item.address)}</small> 
                                <small className='ml-1'><RiMapPinRangeFill /> {item.seller_serve_distance} Miles</small>
                             </span>
                        }
                        <small 
                            className='mb-2 mt-2 mr-0 d-block card--desc text-capitalise'
                        >
                            {showOverview(item.seller_business_desc)}
                        </small>
                        <div className='d-flex'>
                            <Button 
                                variant="primary"
                                className='w-100'
                                onClick={() => isStaff
                                    ? navigate(`/business/${isStaff}?tab=staff`)
                                    : navigate(`/business/${item._id}`)
                                } 
                                // disabled
                            >
                                View Profile
                            </Button>
                            <Tooltip
                                    placement={'auto'}
                                    // message={`Reach out to ${item.name.charAt(0).toUpperCase() + item.name.slice(1)}`}

                                >
                                    <Button 
                                        variant={loading ? 'transparent' : 'secondary'}
                                        className='ml-2'
                                        onClick={() => loading ? null : handleRemove(bookmarkId)}
                                        disabled={loading}
                                    >
                                        {loading ? <LoadingView size={12} height={24} color={'white'}/> : <MdDelete size={24}/>}
                                    </Button>
                                </Tooltip>
                        </div>                      
                    </Card.Link>
                </Card.Title>
            </Card.Body>
        </Card>
    )
}

export default CustomBookmarkCard;