import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Modal, Form, Button, Col, Row, Dropdown, InputGroup } from 'react-bootstrap';
import { Calendar, Views, DateLocalizer, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MdAdd } from "react-icons/md";
import BookedUsersModal from '../Schedules/bookedUsers';
import { createSchedule, deleteSchedule, getSchedules, updateSchedule } from '../../../redux/actions/schedule.action';
import DateCalender from 'react-calendar';
import { formatPrice, generateTimeOptions, isOverlapping, momentFormat, validateNumber } from '../../../utils/validations';
import { toast } from 'react-toastify';
import EventWrapper from './eventWrapper';
import { calculateClassPrice, calculateHourlyPrice } from '../../../utils/scheduleHelper';
import LoadingView from '../../LoadingView';

import { useSearchParams, useLocation } from "react-router-dom";

import 'react-calendar/dist/Calendar.css'; // DateCalender CSS 
import 'react-big-calendar/lib/css/react-big-calendar.css'
import './style.css';
import Swal from 'sweetalert2';
import { Alert, Snackbar } from '@mui/material';
import './EventModal.css';
import API from '../../../helpers/api';
import { getMetaUser } from '../../../redux/actions/user.action';

const localizer = momentLocalizer(moment) // or globalizeLocalizer 

const Scheduler = (props) => {
  const customOption = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }
  const eventParams = {
    title: '',
    service: '',
    startDate: '',
    endDate: '',
    startTime: '',
    date: '',
    endTime: '',
    allDay: true,
    schedule: 'no_repeat',
    days: -1,
    scheduleId: '',
    description: ''
  }

  const scheduleOptions = [
    { value: 'no_repeat', label: 'Do Not Repeat' },
    { value: 'daily', label: 'Daily Repeat' },
    { value: 'custom', label: 'Custom' },
  ]

  /** ----- Start / JEnd Date Picker ----  */
  const endDatePickerRef = useRef(null);
  const startDatePickerRef = useRef(null);
  const minimumDate = new Date();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const searchQueryValue = searchParams.get("redirect");

  const location = useLocation();
  const { redirect } = location.state || {};

  const [loader, setLoader] = useState(true);
  const [paymentMode, setPaymentMode] = useState(null);
  const [isStripeConnected, setConnected] = useState(false);
  /** ----- Start Calendar ----- */
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  /** ----- End Calendar ----- */
  const [showEndCalendar, setShowEndCalendar] = useState(false);

  /** ----- Past Schedules Not Editable ----- */
  const [isPastSchedule, setPastSchedule] = useState(false);

  const [classHourPrice, setClassHourPrice] = useState(0);
  const [serviceAmountType, setServiceAmountType] = useState('');

  /** ---- Show Event Modal ----- */
  const [showModal, setShowModal] = useState({ event: false, bookedUsers: false });
  /** ---- Show Custom Option Modal ---- */
  const [showCustomOptions, setShowCustomOptions] = useState(false);

  const [copiedService, setCopiedService] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  /** ----- All Events ----- */
  const [events, setEvents] = useState([]);

  /** ----- Single Event to Create ----- */
  const [event, setEvent] = useState({ ...eventParams });

  /** ---- Time Options ---- */
  const [showTime, setShowTime] = useState(false);

  /** ----- Array Of Times for Start----- */
  const [startTimeOptions, setStartTimeOptions] = useState([]);

  /** ----- Array of Time for End  */
  const [endTimeOptions, setEndTimeOptions] = useState([]);

  /** ----- Repeat Period ----- */
  const [repeatPeriod, setRepeatPeriod] = useState(null);

  /** ---- Is Used for Save Event Button ---- */
  const [isDisable, setIsDisable] = useState(false);

  /** ---- IS Editable ---- */
  const [isEdit, setIsEdit] = useState(false);

  /** ------------------------------[ Redux State ]------------------------------- */
  const scheduleState = useSelector(state => state.schedule);


  /** -------------------------------------------------------------------------- */

  useEffect(() => {
    if (props && props.paymentMode) {
      setPaymentMode(props.paymentMode)
    }

    if (props && props.isStripeConnected) {
      setConnected(props.isStripeConnected)
    }
  }, [
    props?.paymentMode,
    props?.isStripeConnected
  ])

  const handleGetSchedules = () => {
    dispatch(getSchedules());
  }

  const handleUpdateFirstVisit = async () => {
    try {
        const response = await API.apiPostUrl('seller', '/schedule-visit');
        if( response.data && response.data.success){
          dispatch(getMetaUser())
        }
    } catch (error) {
        console.error(error);
    }
  }

  const handleSelectSlot = useCallback(
    ({ start, end }) => {

      /** ----- Blocks to select past date from bookintg ---- */
      if (moment().isAfter(start, 'day')) {
        return;
      }

      if (moment(end).isSame(moment(start).add(1, 'days').startOf('day'), 'minute') || moment(end).isSame(moment(start).add(1, 'days').endOf('day'), 'minute')) {
        end = moment(end).subtract(1, 'days').endOf('day').toDate(); // Set end time to the last moment of the previous day
      } else {
        // Subtract one second to make sure it stays within the selected range
        end = moment(end).subtract(1, 'second').toDate();
      }

      let offlineMode = (paymentMode !== 'online' || !isStripeConnected);

      const data = {
        startDate: start,
        endDate: end,
        // endDate: momentFormat.correctDate(start, end),
        // days: length,
        schedule: 'no_repeat',
        allowed_payment: offlineMode ? 'offline' : 'online'
      };

      setEvent(data);
      openEventModal();
    },
    [event] // Dependency added to ensure the correct events array is used
  );

  useEffect(() => {
    handleGetSchedules();
  }, []);

  useEffect(() => {
    if (scheduleState.error) {
      toast.error(scheduleState.error.message);
      setLoader(false);
    }

    if (!scheduleState.error) {
      if (scheduleState.post && scheduleState.post.success) {
        if (scheduleState.post && scheduleState.post.showSetupServicePopup) {

          const firstSchedule = scheduleState.post.schedule || null

          Swal.fire({
            title: 'Success! Your Schedule is Ready.',
            text: `Share your availability link on your website or social media to let clients find you easily.`,
            icon: 'success',
            confirmButtonText: 'Copy Link',
            showCancelButton: true,
            cancelButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            preConfirm: () => {
              return new Promise((resolve) => {
                if (firstSchedule) {
                  handleCopyLink(`${window.location.host}/book-appointments/${firstSchedule.service._id}`, firstSchedule.title);
                  handleOnSkipSchedule() // stay on same page and change the URL
                } else {
                  navigate('/invite')
                }
                resolve();
              });
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
              handleOnSkipSchedule(); // stay on same page and change the URL
            }
          });
        }
        handleGetSchedules();
      }

      if (scheduleState.get && scheduleState.get.success) {
        setEvents([...scheduleState.get.schedules]);
        setTimeout(() => {
          setLoader(false);
        }, 1500)
      }

      if (scheduleState.delete && scheduleState.delete.success) {
        handleGetSchedules();
      }

      if (scheduleState.put && scheduleState.put.success) {
        toast.success(scheduleState.put.message)
        handleGetSchedules();
      }
    }
  }, [scheduleState]);

  /** -----------------------------------------------  Flow on Initial Signup Steps for Schedule Availability ------------------------ */

  useEffect(() => {
    if(searchQueryValue === 'profile' && redirect === 'profile'){
      handleUpdateFirstVisit();

      Swal.fire({
        title: 'Great Job! ',
        text: `Congratulations Please Schedule your specialty`,
        icon: 'success',
        confirmButtonText: 'Schedule Your Specialty',
        showCancelButton: true,
        cancelButtonText: 'Skip & Go to Profile',
        customClass: {
            confirmButton: 'btn btn-primary',
        },
        preConfirm: () => {
            return new Promise((resolve) => {
                handleOnClickSchedule() // Open Schedule Modal to create and redirect after creating
                resolve();
            });
        }
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          handleOnSkipSchedule(); // Call your skip button action here
        }
      });
      
    }

    if(searchQueryValue && !redirect){ 
      navigate('/scheduler')
    }
  }, [searchQueryValue, redirect])

  const handleOnSkipSchedule = () => {
    navigate('/profile', { state: null });
  }



  const handleCopyLink = (link, serviceName) => {
    navigator.clipboard.writeText(link);
    setCopiedService(serviceName);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  /** ------ Used to get scheduled events details to update / view  ----- */
  const handleSelectEvent = useCallback(
    (e) => {
      if (moment().isAfter(moment(e.start), 'day')) {
        // Dates after maxDate are not selectable
        setPastSchedule(true);
        // return;
      }

      setIsEdit(true)
      let data = {}
      // data.date = moment(e.start).startOf('day').toDate()
      data.startDate = moment(e.start).startOf('day').toDate() //momentFormat.formatReactCalendarDate() 
      data.endDate = moment(e.end).endOf('day').toDate()//momentFormat.formatReactCalendarDate(e.end)
      data.schedule = e.schedule
      data.amount = e.amount
      data.service_type = e.service.service_type;

      setShowTime(e.service.service_type === 'class' ? true : false);

      if (e.service.service_type === 'class') {
        data.is_virtual = e.is_virtual

        if (e.is_virtual) {
          data.class_information = e.class_information
        }

        data.required_approval = e.required_approval
        data.startTime = momentFormat.formatReactCalendarTime(e.start)
        data.endTime = momentFormat.formatReactCalendarTime(e.end)
        let amt = calculateHourlyPrice(data.startDate, data.startTime, data.endDate, data.endTime, e.amount, e.service.amount_type)
        setClassHourPrice(amt);
        setServiceAmountType(e.service.amount_type);
      }

      setEvent({
        ...e,
        ...data
      });
      openEventModal();
    },
    []
  )


  /** ----- Update Disable ----- */
  useEffect(() => {
    const hasStartTime = showTime && !event.startTime;
    const hasEndTime = showTime && !event.endTime;
    const hasNoService = !event.service;
    const date1 = moment(event.startDate).isAfter(event.endDate)
    const date2 = moment(event.endDate).isBefore(event.startDate)
    setIsDisable(hasStartTime || hasEndTime || hasNoService || date1 || date2);
  }, [event, showTime]);

  /** ------ Handle Close Modal ------ */
  // const closeEventModal = () => {
  //   setShowModal({ event: false, bookedUsers: false })
  //   setShowTime(false);
  //   setShowStartCalendar(false);
  //   setShowEndCalendar(false);

  //   // setTimeOptions([])

  //   setStartTimeOptions([]);
  //   setEndTimeOptions([]);

  //   setIsDisable(false);
  //   setIsEdit(false);

  //   setShowCustomOptions(false);
  //   setPastSchedule(false);
  //   setEvent({ ...eventParams })
  // };

  /** ----- Open Event Modal ----- */
  const openEventModal = () => setShowModal({ event: true });

  /** --- To Hide Calendar --- */
  const handleHideCalendar = () => {
    setShowStartCalendar(false);
    setShowEndCalendar(false);
  }
  /** ----- Handle Date Label Click ------- */
  const handleDateLabelClick = (dateField) => {
    if (dateField === 'startDate') {
      setShowStartCalendar(!showStartCalendar);
      setShowEndCalendar(false);
    }

    if (dateField === 'endDate') {
      setShowEndCalendar(!showEndCalendar);
      setShowStartCalendar(false);
    }
  };

  /** ------ Handle Date Picker ----- */
  const handleCalendarDate = (date, field) => {
    let _event = event;

    if(field === 'startDate'){
      const _st = moment(date);
      const _et = moment(_event.endDate);
      if(_et.isBefore(_st)){
        _event.endDate = date
      }
    }

    _event[field] = date;

    setIsDisable(false)
    setEvent({..._event})
    handleHideCalendar();
  }

  useEffect(() => {
    if (showTime) {
      handleGenerateTimeOptions()
    }
  }, [event.startDate, event.endDate, event.service, event.startTime])

  /** ----- Updating the Amount for class based on hourly payment defined in service ---- */
  useEffect(() => {
    if (showTime && event.startTime && event.endTime) {
      setEvent({
        ...event,
        amount: calculateClassPrice(event.startDate, event.startTime, event.endDate, event.endTime, classHourPrice, serviceAmountType)
        // amount: calculateClassPrice(event.startTime, event.endTime, classHourPrice, serviceAmountType)
      })
    }
  }, [event.startTime, event.endTime, event.startDate, event.endDate]);


  const handleGenerateTimeOptions = () => {
    setStartTimeOptions(generateTimeOptions(events, event.startDate));
    if (event.startTime) {
      setEndTimeOptions(generateTimeOptions(events, event.endDate, event.startTime, event.startDate));
    }
  }

  const renderStartDatePicker = () => {
    return (
      <DateCalender
        ref={(ref) => startDatePickerRef.current = ref}
        onChange={(date) => handleCalendarDate(date, 'startDate')}
        minDate={minimumDate}

      />
    )
  }

  /** ---- Handle Date Picker ---- */
  const renderEndDatePicker = () => {
    return (
      <DateCalender
        ref={(ref) => endDatePickerRef.current = ref}
        onChange={(date) => handleCalendarDate(date, 'endDate')}
        minDate={event.startDate || minimumDate}
      />
    )
  }

  /** ---- Handle Fields Changes ---- */
  const handleModalInput = (e) => {
    let { name, value, checked } = e.target;

    let data = {};

    if (name === 'max_capacity') {
      if (value.trim() !== '') {
        value = validateNumber(value);
        // if( value > 20) return ;
      }
    }
    /** ---- If Custom Options otherwise ----- */
    if (Object.keys(customOption).includes(name)) {
      data.customOption = { ...event.customOption }
      data.customOption[name] = checked
    }

    if (name === "service") {
      const selectedOption = e.target.selectedOptions[0];
      const serviceType = selectedOption.getAttribute('data-service-type');
      const amountType = selectedOption.getAttribute('data-amount-type');

      const serviceTitle = selectedOption.getAttribute('data-service-title');
      const category = selectedOption.getAttribute('data-service-tags');

      const serviceDescription = selectedOption.getAttribute('data-service-description');
      var amount = selectedOption.getAttribute('data-service-amount');

      data.amount = amount
      data.service_type = serviceType
      if (serviceType === 'class') {
        data.max_capacity = selectedOption.getAttribute('data-service-max-capacity');
        data.is_virtual = JSON.parse(selectedOption.getAttribute('data-service-is-virtual'));
        data.required_approval = false
        setServiceAmountType(amountType);
        setClassHourPrice(amount);


      } else {
        delete data.max_capacity
      }

      data.title = category ? category.concat('/' + serviceTitle) : serviceTitle;
      // data.tags = serviceTags
      data.description = serviceDescription
      // data.allDay = serviceType === 'class' ? false : true;

      setShowTime(serviceType === 'class');
    }

    if (name === 'schedule') {
      if (value !== 'custom') {
        // if (event.days >= 0) {
        //   data[name] = 'daily'
        // }
        setShowCustomOptions(false);
        data.customOption = { ...customOption }
      }

      if (value === 'custom') {
        setShowCustomOptions(true);
        data.customOption = { ...customOption }
      }
    }

    /** ------------- Checking For Overlapping ---------------- */ 
      
    if (name === 'startTime') {
      data.endTime = ''
    }

    if (name === 'allowed_payment') {
      if (value === 'online' && (paymentMode !== 'online' || !isStripeConnected)) {
        value = 'offline'
        toast.info(`Please enable online payments in the 'Wallet' section to proceed`)
      }

    }

    if (name === 'required_approval') {
      value = JSON.parse(value);
    }

    if (name === 'is_virtual') {
      if (!['true', true].includes(value)) {
        delete data.class_information
      }
      value = ['true', true].includes(value) ? true : false
    }
    data[name] = value
    setEvent({ ...event, ...data });
  }


  const handleProcessSubmitEvent = () => {
    var scheduleId = Date.now();
    var weekdays = null;
    var weekDaysToRepeat = [];
    var totalDays = 0;
    const hourDifference = moment(event.endDate).diff(moment(event.startDate), 'hours');

    if (event.schedule === 'no_repeat') {
      totalDays = 1
    }

    if (['daily', 'custom'].includes(event.schedule)) {
      // const lastDate = moment(event.startDate).add(1, 'years').toDate();
      const lastDate = moment(repeatPeriod).endOf("day").toDate();
      totalDays = Math.ceil(moment(lastDate).diff(moment(event.startDate).startOf("day"), 'days', true));
      if (event.schedule === 'custom') {
        weekdays = event.customOption;
        for (const day in weekdays) {
          if (weekdays[day]) {
            const dayOfWeek = moment().day(day).day();
            weekDaysToRepeat.push(dayOfWeek);
          }
        }
      } else {
        weekDaysToRepeat = [0, 1, 2, 3, 4, 5, 6]
      }
    } else {
      weekDaysToRepeat = [0, 1, 2, 3, 4, 5, 6]
    }

    const _evt = [];
    for (let i = 0; i < totalDays; i++) {
      const currentDate = moment(event.startDate).add(i, 'days');
      const dayOfWeek = currentDate.day();

      if (weekDaysToRepeat.includes(dayOfWeek)) {
        const endDate = moment(currentDate).add(hourDifference, 'hours').toDate();
        endDate.setHours(23)
        endDate.setMinutes(59)
        endDate.setSeconds(59)

        const newEvent = {
          ...event,
          associatedWithUser: props?.loggedUser.user,
          service: event.service,
          title: event.title,
          schedule: event.schedule,
          scheduleId: scheduleId,
          // allDay: event.allDay,
          date: currentDate.startOf('d').toDate(),
          start: momentFormat.combineDateTime(currentDate.toDate(), event.startTime),
          end: momentFormat.combineDateTime(endDate, event.endTime),
        };

        _evt.push(newEvent);
      }
    }

    setEvents((prevEvents) => [...prevEvents, ..._evt]);
    dispatch(createSchedule({ schedules: _evt }));
    closeEventModal();
  }

  const handleSubmitEvent = async (e) => {

    if(event.startDate && event.endDate){
      const result = isOverlapping(event.startDate, event.endDate, events, event); 
        if(result){
          Swal.fire({
            title: 'Oops Time Conflict! ',
            text: `You already have ${result.title} scheduled, are you sure you want to add this too?`,
            icon: 'warning',
            confirmButtonText: 'Allow',
            showCancelButton: true,
            cancelButtonText: 'Deny',
            customClass: {
                confirmButton: 'btn btn-primary',
            },
            preConfirm: () => {
                return new Promise((resolve) => {
                    handleProcessSubmitEvent() // Open Schedule Modal to create and redirect after creating
                    resolve();
                });
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel) {
              closeEventModal(); // Call your skip button action here
            }
          });
        }else {
          handleProcessSubmitEvent()
        }
    }
  };

  /** ------ When Updating a Schedules ------ */

  const updatingSchedules = (type) => {
    var data = {
      service: typeof event.service === 'object' ? event.service._id : event.service,
      title: event.title,
      description: event.description,
      // allDay: event.allDay,
      service_type: event.service_type,
      max_capacity: event.max_capacity,
      allowed_payment: event.allowed_payment
    }

    /** ------ These Fields gets updated when updating the class ------- */
    if (showTime) {
      data.amount = event.amount
      data.is_virtual = event.is_virtual
      if (event.is_virtual) {
        data.class_information = event.class_information
      }
      data.required_approval = event.required_approval

      data.start = momentFormat.combineDateTime(event.startDate, event.startTime)
      data.end = momentFormat.combineDateTime(event.endDate, event.endTime)
    }

    // also add fields in the update api in backend 
    dispatch(updateSchedule(type === 'current' ? event._id : event.scheduleId, data, type));
    if (type === 'all') {
      toast.info('Update is in progress')
    }
    closeEventModal()
  }

  const handleDelete = () => {
    dispatch(deleteSchedule({ serviceSchedulerId: event._id }))
    closeEventModal()
  }

  const handleDeleteMultiPle = (field) => {
    if (field === 'all') {
      dispatch(deleteSchedule({ scheduleId: event.scheduleId }))
    } else {
      dispatch(deleteSchedule({ serviceSchedulerId: event._id }))
    }
    closeEventModal()
  }

  const [currentStep, setCurrentStep] = useState(1);


  const closeEventModal = () => {
    setShowModal({ event: false, bookedUsers: false })
    setShowTime(false);
    setShowStartCalendar(false);
    setShowEndCalendar(false);

    // setTimeOptions([])

    setStartTimeOptions([]);
    setEndTimeOptions([]);

    setIsDisable(false);
    setIsEdit(false);
    setCurrentStep(1);
    setShowCustomOptions(false);
    setPastSchedule(false);
    setEvent({ ...eventParams })

    // if(searchQueryValue === 'profile' && redirect === 'profile'){
    //   navigate('/profile', { currentStep:6, currentQuestionIndex:-1, redirect:'scheduler'})
    // }
  };

  const eventModal = () => {


    const handleNextStep = () => {
      let nextStep = currentStep + 1;
     
      while (
        (nextStep === 4 && !showTime) ||
        (nextStep === 6 && !showTime) ||
        (nextStep === 7 && !showTime)
      ) {
        nextStep++;
      }
      if (nextStep <= 8) setCurrentStep(nextStep);
    };

    const handlePreviousStep = () => {
      let prevStep = currentStep - 1;
      while (
        (prevStep === 4 && !showTime) ||
        (prevStep === 6 && !showTime) ||
        (prevStep === 7 && !showTime)
      ) {
        prevStep--;
      }
      if (prevStep >= 1) setCurrentStep(prevStep);
    };



    const renderStepContent = () => {
      switch (currentStep) {
        case 1: // Step 1: Specialty
          return (
            <div className="step-content">
              <Form.Group controlId="formSpecialty">
                <Form.Label className="step-label">Specialty</Form.Label>
                <Form.Control
                  as="select"
                  name="service"
                  value={
                    event.service
                      ? typeof event.service === 'object'
                        ? event.service._id
                        : event.service
                      : ''
                  }
                  onChange={(e) => handleModalInput(e)}
                >
                  <option value="">Choose Specialty</option>
                  {props &&
                    props.services.map((service, serviceIndex) => (
                      <option
                        value={service._id}
                        key={serviceIndex}
                        data-service-type={service.bbservice.service_type}
                        data-amount-type={service.amount_type}
                        data-service-title={service.bbservice.name}
                        data-service-tags={
                          typeof service.category === 'object'
                            ? service.category.name
                            : null
                        }
                        data-service-max-capacity={service.max_capacity}
                        data-service-is-virtual={service.is_virtual}
                        data-service-description={
                          service.desc || service.bbservice.description
                        }
                        data-service-amount={service.amount}
                      >
                        {typeof service.category === 'object'
                          ? service.category.name
                          : service.category}{' '}
                        | <strong>{`$${service.amount}${service.amount_type === 'hour' ? '/hour' : ' Flat'}`}</strong>
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            </div>
          );

        case 2: // Step 2: Date / Repeated
          return (
            <div className="step-content">
              <Row>
                <Col sm={6}>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Group aria-label='startDatePicker' className='date__select'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('startDate')}
                      // className={isEdit ? 'cursor-blocked' : null}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.startDate)}
                    </Form.Label>
                    {showStartCalendar && renderStartDatePicker()}
                    {showTime &&
                      <Form.Control
                        as="select"
                        name="startTime"
                        onChange={(e) => handleModalInput(e)}
                        value={event.startTime}
                        className='time--options'
                      >
                        <option value="">Start Time</option>
                        {startTimeOptions.map((option, optionIndex) => {
                          return <option
                            key={optionIndex}
                            value={option.time}
                            disabled={option.disabled}
                          >
                            {option.time12}
                          </option>
                        })}
                      </Form.Control>
                    }
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Label>End Date</Form.Label>

                  <Form.Group aria-label='endDatePicker' className='date__select'>
                    <Form.Label
                      onClick={() => isEdit ? null : handleDateLabelClick('endDate')}
                      // className={isEdit ? 'cursor-blocked' : null}
                      style={{ cursor: isEdit ? 'not-allowed' : 'pointer' }}
                    >
                      {momentFormat.formatWeekDate(event.endDate)}
                    </Form.Label>
                    {showEndCalendar && renderEndDatePicker()}
                    {showTime &&
                      <Form.Control
                        as="select"
                        name='endTime'
                        onChange={(e) => handleModalInput(e)}
                        value={event.endTime}
                        className='time--options'
                      >
                        <option value="">End Time</option>
                        {endTimeOptions.map((option, optionIndex) => {
                          return <option
                            key={optionIndex}
                            value={option.time}
                            disabled={option.disabled}
                          >
                            {option.time12}
                          </option>
                        })}
                      </Form.Control>
                    }
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formSchedule">
                <Form.Label className="step-label">Repeat</Form.Label>
                <Form.Control
                  as="select"
                  name="schedule"
                  value={event.schedule}
                  onChange={(e) => handleModalInput(e)}
                  disabled={event.days >= 0 || isEdit}
                >
                  {scheduleOptions.map((op, opIndex) => (
                    <option value={op.value} key={opIndex}>
                      {op.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {showCustomOptions && (
                <Form.Group>
                  <Form.Label className="step-label">Repeat Every</Form.Label>
                  <div>
                    {Object.entries(event?.customOption).map(([key, value]) => (
                      <Form.Check
                        inline
                        key={key}
                        type="checkbox"
                        id={key}
                        label={key}
                        checked={value}
                        name={key}
                        onChange={(e) => handleModalInput(e)}
                        style={{ textTransform: 'capitalize' }}
                        disabled={isEdit}
                      />
                    ))}
                  </div>
                </Form.Group>
              )}
              {(['daily', 'custom'].includes(event.schedule)) && 
                <Form.Group>
                  <Form.Label className="step-label">Repeat Schedule Until</Form.Label>
                  <div>
                    <DateCalender
                      // ref={(ref) => endDatePickerRef.current = ref}
                      onChange={(date) => setRepeatPeriod(date)}
                      minDate={event.endDate || minimumDate}
                      value={repeatPeriod}
                    />
                  </div>
                </Form.Group>
              }
              
            </div>
          );

        case 3: // Step 3: Total Amount
          return (
            <div className="step-content">
              <Form.Group controlId="formTotalAmount">
                <Form.Label className="step-label">Total Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    aria-label="Amount (to the nearest dollar)"
                    type="text"
                    disabled
                    value={formatPrice(event.amount)}
                    className="cursor-blocked"
                  />
                </InputGroup>
              </Form.Group>
            </div>
          );

        case 4: // Step 4: Max Capacity
          return (
            <div className="step-content">
              {showTime && (
                <Form.Group controlId="formMaxCapacity">
                  <Form.Label className="step-label">Capacity</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Max Capacity"
                    value={event.max_capacity}
                    name="max_capacity"
                    onChange={(e) => handleModalInput(e)}
                  />
                </Form.Group>
              )}
            </div>
          );

        case 5: // Step 5: Will Payment Be Accepted
          return (
            <div className="step-content">
              <Form.Group controlId="formAllowedPayment">
                <Form.Label className="step-label">
                  Will the payment be accepted on BirthBridge?
                </Form.Label>
                <small className="form-text text-muted">
                  For you to use this and/or our virtual experience, please make
                  sure you've connected your Stripe account in the "Get Paid"
                  navigation.
                </small>
                <Form.Control
                  as="select"
                  placeholder="Allowed Payment"
                  value={event.allowed_payment}
                  name="allowed_payment"
                  onChange={(e) => handleModalInput(e)}
                >
                  <option value="online">Yes</option>
                  <option value="offline">No</option>
                </Form.Control>
              </Form.Group>
            </div>
          );

        case 6: // Step 6: Virtual Class
          return (
            <div className="step-content">
              {showTime && (
                <>
                  <Form.Group controlId="formIsVirtual">
                    <Form.Label className="step-label">Will this be a virtual class?</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Select an option"
                      value={event.is_virtual}
                      name="is_virtual"
                      onChange={(e) => handleModalInput(e)}
                    >
                      <option value={true}>Yes, it’s virtual</option>
                      <option value={false}>No, it’s in person</option>
                    </Form.Control>
                  </Form.Group>
                  {event.is_virtual && (
                    <Form.Group controlId="formClassInformation">
                      <Form.Label className="step-label">Provide details for your virtual class</Form.Label>
                      <small className="form-text text-muted">
                        These details will be included in reminder emails sent 24 hours and 2 hours before the class.
                      </small>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter details about the virtual class"
                        value={event.class_information}
                        name="class_information"
                        onChange={(e) => handleModalInput(e)}
                      />
                    </Form.Group>

                  )}
                </>
              )}
            </div>
          );

        case 7: // Step 7: Manually Approve
          return (
            <div className="step-content">
              {showTime && (
                <Form.Group controlId="formRequiredApproval">
                  <Form.Label className="step-label">Manually Approve Families</Form.Label>
                  <small className="form-text text-muted">
                    You can choose to automatically accept families or manually
                    accept each family.
                  </small>
                  <Form.Control
                    as="select"
                    placeholder="Will approval be required..."
                    value={event.required_approval}
                    name="required_approval"
                    onChange={(e) => handleModalInput(e)}
                  >
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Control>
                </Form.Group>
              )}
            </div>
          );

        case 8: // Step 8: Additional Information
          return (
            <div className="step-content">
              <Form.Group controlId="formDescription">
                <Form.Label className="step-label">Additional Information</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Any additional information?"
                  value={event.description}
                  name="description"
                  onChange={(e) => handleModalInput(e)}
                />
              </Form.Group>
            </div>
          );

        default:
          return null;
      }
    };

    return (
      <Modal size="lg" centered show={showModal.event} onHide={closeEventModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Step {currentStep} of 8
            <small className="step-indicator">
              {` (Progress: ${Math.round((currentStep / 8) * 100)}%)`}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {renderStepContent()}
            {/* Other form elements if necessary */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={closeEventModal}>
            Cancel
          </Button>

          <Button disabled={currentStep === 1} variant="secondary" onClick={handlePreviousStep}>
            Back
          </Button>

          {currentStep < 8 ? (
            <Button variant="primary" onClick={handleNextStep}>
              Next
            </Button>
          ) : (
            <>
              {!isPastSchedule && submitActions()}
            </>
          )}

          {/* Keep existing buttons intact */}
          {/* {!isPastSchedule && submitActions()} */}
          {event.schedule !== 'daily' && isEdit && !isPastSchedule && (
            <Button variant="danger" onClick={() => handleDelete()}>
              Delete
            </Button>
          )}
          {event.schedule === 'daily' && !isPastSchedule && isEdit && (
            <Dropdown className="d-inline-flex">
              <Dropdown.Toggle variant="danger" id="dropdown-basic">
                Delete
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleDeleteMultiPle('current')}>
                  Delete Current
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleDeleteMultiPle('all')}>
                  Delete All
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}

          {isEdit && showTime && (
            <Button
              variant="dark"
              className="ml-auto"
              onClick={() => setShowModal({ ...showModal, bookedUsers: true })}
              disabled={event.bookedUsers && !event.bookedUsers.length}
            >
              {event.bookedUsers && event.bookedUsers.length
                ? `View ${event.bookedUsers.length} Users`
                : 'No Users Booked'}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }


  const updateActions = () => {
    return (
      event && event.schedule === 'no_repeat'
        ? <Button onClick={() => isDisable ? null : updatingSchedules('current')} disabled={isDisable}>Update</Button>
        :
        <Dropdown className='d-inline-flex'>
          <Dropdown.Toggle
            variant='primary'
            id="dropdown-basic"
            disabled={isDisable}
          >
            Update
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => isDisable ? null : updatingSchedules('current')} disabled={isDisable} >Current</Dropdown.Item>
            <Dropdown.Item onClick={() => isDisable ? null : updatingSchedules('all')} disabled={isDisable} >All</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
    )
  }

  const submitActions = () => {
    return (
      <>
        {isEdit
          ? updateActions()
          : <Button
            disabled={isDisable}
            onClick={(e) => isDisable ? null : handleSubmitEvent(e)}
          >
            Save
          </Button>
        }
      </>
    )
  }

  const bookedUsersModal = () => {
    return (
      <BookedUsersModal
        onHide={() => setShowModal({ ...showModal, bookedUsers: false })}
        event={event}
        users={event.bookedUsers}
        show={showModal.bookedUsers}
      />
    )
  }

  const handleOnClickSchedule = () => {
    let _startDate = moment().startOf('day').toDate()
    let _endDate = moment().add(1, 'd').startOf('day').toDate()

    handleSelectSlot({ start: _startDate, end: _endDate });
  }


  if (loader) return <LoadingView loader="ThreeDots" size={40} />
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center'

      }}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '500px', maxWidth: '100%' }}>
            {`The link to your ${copiedService} schedule has been copied. You can share this with your customers or display it on your website.`}
          </Alert>
        </Snackbar>
        <Button
          className=""
          style={{ marginBottom: 12, }}
          onClick={() => handleOnClickSchedule()}
        // onClick={() => testPopup()}
        >
          <MdAdd /> Add Schedule
        </Button>
      </div>
      <div className="myCustomHeight" >

        <Calendar
          events={events.map((evt, evtIndex) => { return { ...evt, start: moment(evt.start).toDate(), end: moment(evt.end).toDate(), title: `${evt.title.split('/')[0]} | $${evt.service.amount}${evt.service.amount_type === 'hour' ? '/hour' : ' Flat'}` } })}
          startAccessor="start"
          endAccessor="end"
          views={['day', 'week', 'month']}
          defaultView={Views.MONTH}
          localizer={localizer}
          step={15} // 15 minutes per step
          timeslots={1} // Number of slots per step
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          components={{
            dateCellWrapper: EventWrapper,
            // dayColumnWrapper: EventWrapper,
          }}
          selectable
        />
        {showModal.event && eventModal()}
        {showModal.bookedUsers && bookedUsersModal()}
      </div>
    </>
  )
}

Scheduler.propTypes = {
  localizer: PropTypes.instanceOf(DateLocalizer),
}

export default Scheduler;