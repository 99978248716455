import React, { useState, useEffect } from 'react';
import API from '../../../helpers/api';
import { Form } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import 'react-tagsinput/react-tagsinput.css'; // Styles for react-tagsinput

const SelectCategorySuggestions = ({ disabled, tags, setTags, bbservice, tagsError, setErrors, multiselect, loggedUser, menus }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (value) => {
    setErrors('');
    setTags(value);
  };

  useEffect(() => {
    if (bbservice) {
      let payload = { bbservice: bbservice };

      if (loggedUser) {
        payload.userId = loggedUser.user;
      }

      fetchSuggestions(payload);
    }
  }, [bbservice]);

  useEffect(() => {
    if (menus) {
      fetchSuggestions({ menus: true });
    }
  }, [menus]);

  const fetchSuggestions = async (query) => {
    try {
      setLoading(true);
      setErrors('');
      const response = await API.apiGet('category', query);
      setLoading(false);
      if (response.data.success) {
        setSuggestions(response.data.categories); // Assuming the API returns an array of suggestions
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBlur = () => {
    if (!bbservice) {
      setErrors('Please Select Specialty');
    }
  };

  /** --- Only If Multiple --- */
  const handleTagsValue = (tags) => {
    if (suggestions && suggestions.length) {
      return suggestions
        .filter((suggestion) => tags.includes(suggestion._id.toString()))
        .map((v) => ({ label: v.name, value: v._id }));
    }
    return tags;
  };

  // Determine if the error message should be shown
  const shouldShowError =
  (tags === '' ||
    tags === 'No Specialty Found' ||
    tags === 'Select Specialty');
  return (
    <>
      {multiselect ? (
        <MultiSelect
          className={`${disabled ? 'multi-select-disabled' : ''}`}
          disabled={disabled ? true : false}
          value={handleTagsValue(tags)}
          options={suggestions.map((_category) => ({
            label: _category.name,
            value: _category._id,
          }))}
          onChange={(v) => (disabled ? null : handleInputChange(v.map((_) => _.value)))}
        />
      ) : (
        <Form.Control
          as="select"
          onBlur={() => handleBlur()}
          onChange={({ target }) => (disabled ? null : handleInputChange(target.value))}
          value={tags || ''}
          placeholder="Select Specialty"
          disabled={disabled ? true : false}
        >
          {loading && <option value={''}>Loading...</option>}
          <option value={''}>{!loading && !suggestions.length ? 'No Specialty Found' : 'Select Specialty'}</option>
          {suggestions.map((_category, _categoryIndex) => (
            <option value={_category._id} key={_category._id}>
              {_category.name}
            </option>
          ))}
        </Form.Control>
      )}
      
      {!loading && shouldShowError && (
        <span className="error-msg">
          {tags == '' && 'Please select a specialty from the dropdown.' }
          {/* {tags === 'No Specialty Found'
            ? 'No specialties found. Please try another category.'
            : tags === 'Select Specialty'
            ? 'Please select a specialty from the dropdown.'
            : ''} */}
        </span>
      )}
      {tagsError && !shouldShowError && <span className="error-msg">{tagsError}</span>}
    </>
  );
};

export default SelectCategorySuggestions;