import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const PhotoViewer = ({ children, url, title}) => {
    return (
        <PhotoProvider >
            <PhotoView src={url}>
               {children}
            </PhotoView>
        </PhotoProvider>
    )
}

export default PhotoViewer;