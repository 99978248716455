import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Container, Row } from 'react-bootstrap';
import { getTransactions } from '../../../redux/actions/transaction.action';
import { formatPrice, momentFormat } from '../../../utils/validations';
import { toast } from 'react-toastify';
import LoadingView from '../../LoadingView';


const TransactionHistories = () => {

    const dispatch = useDispatch();
    const [ loader, setLoader ] = useState(true);
    const [ transactions, setTransactions ] = useState([]);

    const transactionState = useSelector(state => state.transactions);

    useEffect(() => {
        dispatch( getTransactions() );
    }, [])

    useEffect(() => {
        if(transactionState.error){
            toast.error(transactionState.error.message);
            setLoader(false)
        }

        if( !transactionState.error ){
            if( transactionState.list && transactionState.list.success ){
                setLoader(false)
                setTransactions( transactionState.list.trnxs)
            }
            
        }
    }, [transactionState])

    const renderCoupon = (discount, coupon_code) => {
        if(discount && typeof discount === 'object' && discount.coupon){
            return discount.coupon.name
        }

        if(coupon_code){
            return coupon_code
        }

        return 'N/A'
    }

    const renderDiscount = (discount) => {
        if(discount && typeof discount === 'object' && discount.coupon){
            return discount.coupon.percent_off ? `${discount.coupon.percent_off}%` : `$${discount.coupon.amount_off}`
        }

        if(discount && typeof discount === 'object' && discount.type){
            return discount.type === 'percentage' ? `${discount.value}%` : `$${discount.value}`
        }

        return 'N/A'
    }

    const renderTransactions = () => {
        return (
            <div className='table-wallet'>
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            {/* <th scope="col">Transaction Id</th> */}
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Coupon Code</th>
                            <th scope="col">Amount</th>
                            <th scope="Col">Remarks</th>
                            <th scope="col">Status </th>
                            {/* <th scope="col">Status</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transactions.map((tx, txId) => {
                                return (
                                    <tr key={txId}>
                                        {/* <td>{tx.payment_tnx_id}</td> */}
                                        <td>{momentFormat.dateTimeFormat(tx.createdAt)}</td>
                                        <td>{renderDiscount(tx.discount)}</td>
                                        <td>{renderCoupon(tx.discount, tx.coupon_code)}</td>
                                        <td>${formatPrice(tx.total)}</td>
                                        <td>{tx.remarks || 'N/A'}</td>
                                        <td>
                                            <span className={`status-services status--${tx.status}`}>{tx.status}</span>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    return (
        <Container fluid>
             <Row>
                <div className="services-main-wraper-order">
                    {loader
                    ?   <LoadingView />
                    :transactions.length > 0
                        ? renderTransactions()
                        : <h4> No Transaction History Found</h4>
                    }
                </div>
            </Row>

        </Container>
    )
}

export default TransactionHistories;