import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { momentFormat } from '../../../utils/validations';

const BookedUsersModal = ({ show, onHide, event, users }) => {
    const createHorizontalLine = () => {
        return <span style={{ borderLeft: '1px solid grey', marginLeft:10, marginRight:10}}/>
    }
    return (
        <Modal 
            centered
            className='list__modal'
            show={show} 
            onHide={() => onHide()}
            backdrop='static'
            size='lg'
        >
            <Modal.Header closeButton>
                <div className='d-flex justify-content-between'>
                    <strong>{event.title}</strong> 
                    {createHorizontalLine()}
                    <strong>{momentFormat.formatWeekDate(event.start)} {createHorizontalLine()} {momentFormat.timeFormat(event.start)}-{momentFormat.timeFormat(event.end)}</strong>
                    {createHorizontalLine()}
                    <strong>Booked {users.length} Out of Max Capacity {event.max_capacity} </strong>
                </div>
            </Modal.Header>
            <Modal.Body>
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                        </tr>
                    </thead>
                    {(users && users.length > 0 )
                    ?
                    <tbody>
                        {users.map((user, userIndex) => {
                            return (
                                <tr key={userIndex}>
                                    <td>{userIndex+1}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNumber}</td>
                                </tr>
                            )  
                        })}
                    </tbody>
                    : <h4>No Record Available</h4>
                    }
                </Table>
            </Modal.Body>
        </Modal>
    )
}

export default BookedUsersModal;