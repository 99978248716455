import { 
    GET_BOOKMARK_SUCCESS, 
    GET_BOOKMARK_FAILED,
    POST_BOOKMARK_SUCCESS,
    POST_BOOKMARK_FAILED,
    DELETED_BOOKMARK_SUCCESS,
    DELETED_BOOKMARK_FAILED, 
    GET_ONE_BOOKMARK_SUCCESS,
    GET_ONE_BOOKMARK_FAILED
} from "../actions/types";

const initialState = {
    list: null,
    one: null,
    deleted:null,
    added:null,
    error: null
};

const bookmarkReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_BOOKMARK_SUCCESS:
            return {
                // ...state,
                list: action.payload,
                
                error: null
            }
        case GET_BOOKMARK_FAILED:
            return {
                // ...state,
                error: action.payload
            }
        case POST_BOOKMARK_SUCCESS:
            return {
                // ...state,
                added: action.payload,
                error: null
            }
        case POST_BOOKMARK_FAILED:
            return {
                // ...state,
                error:  action.payload
            }
        case DELETED_BOOKMARK_SUCCESS:
            return {
                // ...state,
                deleted: action.payload,
                error: null
            }
        case DELETED_BOOKMARK_FAILED:
            return {
                // ...state,
                error: action.payload
            }
        case GET_ONE_BOOKMARK_SUCCESS:
            return {
                // ...state,
                one: action.payload,
                error: null
            }
        
        case GET_ONE_BOOKMARK_FAILED:
            return {
                // ...state,
                error: action.payload
            } 
        default: return state;
    }
};

export default bookmarkReducer;