import React, { useEffect, useState } from 'react';
import { Container, Table, Row, Col, Button, Alert, Form, Modal, ListGroup, Accordion } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
import { formatPrice, momentFormat } from '../../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate }  from 'react-router-dom';
import LoadingView from '../../LoadingView';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { getWalletTx, withdrawWalletAmount } from '../../../redux/actions/wallet.action';
import { getStripeAccount, connectStripe, getPaymentMode, updatePaymentMode } from '../../../redux/actions/stripe.action';
import Swal from 'sweetalert2';
import { FaRegQuestionCircle } from "react-icons/fa";
import Tooltip from '../../../commonComponents/Tooltip';
import { isAuth } from '../../../helpers/auth';
import { useParams } from 'react-router-dom';

import { logEvent } from "../../../googleAnalyticWrapper/analytic";
import StripeLegalDocCRM from '../../services/StripeLegalDocCRM'
import { createClient } from 'contentful';
import "../../../App.css"

const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
});
const Wallet = () => {
    // stripe account onboard below
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const params = useParams();;
    const [showModal, setShowModal] = useState(false);
    const [accept, setAccept] = useState(false)
    const [loader, setLoader] = useState({ paymentMode: true, stripe: true, connect: false })

    const [isConnected, setConnected] = useState(false);
    const [isDisclaimerAgreed, setDisclaimer] = useState(false);

    const [stripeAccount, setStripeAccount] = useState({ accountDetail: { email: 'sd' } });
    const [redirectTo, setRedirectTo] = useState(null);
    const [payment_mode, setPaymentMode] = useState('offline');

    const [alert2, setAlert2] = useState(null);

    /** --- Redux State --- */
    const stripe = useSelector(state => state.stripe);
    const paymentMode = useSelector(state => state.paymentMode);

    const { slug } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        if (post) {
            logEvent('Page View', 'viewing_blog_post', `Viewing ${post.fields.title} Blog Post`, 1);
        }
    }, [])
    useEffect(() => {
        client.getEntries({
            content_type: 'blogPost',
            'fields.slug': slug,
        })
            .then((response) => {
                const filteredItem = response.items.filter((item) => {
                    return item.fields.slug === `${'stripe-waiver'}`
                })

                setPost(filteredItem[0]);
            })
            .catch(console.error);
    }, [slug]);





    useEffect(() => {
        dispatch(getStripeAccount());
        dispatch(getPaymentMode());
    }, []);

    /** --- Stripe Redux State --- */
    useEffect(() => {

        if (stripe.error) {
            if (stripe.error.status !== 404) {
                setAlert2({
                    variant: 'danger',
                    message: stripe.error.message
                });
                removeAlert();
            }
            setLoader({ ...loader, stripe: false, connect: false });
            // setDisclaimer(false)
            setShowModal(false);
        }

        if (!stripe.error) {
            if (stripe.account && stripe.account.success) {
                if (stripe.account.account) {
                    setConnected(stripe.account.account.status);
                    setStripeAccount(stripe.account.account);
                    setDisclaimer(stripe.account.account.is_disclaimer_policy)
                    dispatch(getPaymentMode());
                }

                if (stripe.account.redirectTo) {
                    setRedirectTo(stripe.account.redirectTo);

                }

                if(stripe.account.showSetupServicePopup){
                    // When Initially Setup 
                    Swal.fire({
                        title: 'Hurray! Your Stripe Account is ready.',
                        text: 'Your payment wallet is set up. If you haven’t set up your schedule yet, we recommend doing that next. Otherwise, you can go to your dashboard or view your orders.',
                        icon: 'success',
                        confirmButtonText: 'Set Up Schedule',
                        showCancelButton: true,
                        cancelButtonText: 'Go to Dashboard',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        preConfirm: () => {
                            return new Promise((resolve) => {
                                navigate('/scheduler'); // Redirect to Schedule Setup
                                resolve();
                            });
                        },
                        preCancel: () => {
                            return new Promise((resolve) => {
                                navigate('/dashboard'); // Redirect to Dashboard
                                resolve();
                            });
                        },
                    });
                    // Swal.fire({
                    //     title: 'Hurray! Your Stripe Account is ready.',
                    //     text: 'Please now setup your services',
                    //     icon: 'success',
                    //     confirmButtonText: 'Yes, Proceed',
                    //     showCancelButton: true,
                    //     cancelButtonText:'Skip',
                    //     customClass: {
                    //       confirmButton: 'btn btn-primary',
                    //     },
                    //     preConfirm: () => {
                    //       return new Promise((resolve) => {
                    //         navigate('/service') // `Yes`Redirect to Setup Stripe Account
                    //         resolve();
                    //       });
                    //     }
                    // })
                }
            }

            if (stripe.connect && stripe.connect.success) {
                window.location.href = stripe.connect.redirectTo;
            }

            setLoader({ ...loader, stripe: false, connect: false });
        }
    }, [stripe])


    /** --- Payment Mode Redux State --- */
    useEffect(() => {
        if (paymentMode.error) {
            let _alert = {};
            _alert['variant'] = 'danger'
            _alert['message'] = paymentMode.error.message
            setAlert2(_alert);
            removeAlert();
            setLoader({ ...loader, paymentMode: false })
            setPaymentMode(payment_mode === 'offline' ? 'online' : 'offline');
        }

        if (!paymentMode.error) {
            setAlert2(null);
            if (paymentMode.get && paymentMode.get.success) {
                if (paymentMode.get.paymentMode) {
                    setPaymentMode(paymentMode.get.paymentMode.type);
                }
            }

            if (paymentMode.update && paymentMode.update.success) {
                let _alert = {};
                _alert['variant'] = 'success'
                _alert['message'] = paymentMode.update.message
                setAlert2(_alert);
                removeAlert();
            }

            setLoader({ ...loader, paymentMode: false })
        }
    }, [paymentMode])

    const handleUpdatePaymentMode = (value) => {
        setPaymentMode(value)
        dispatch(updatePaymentMode({ payment_mode: value }));
    }

    const removeAlert = () => {
        setTimeout(() => {
            setAlert2(null)
        }, 10000)
    }

    const handleConnectStripe = () => {
        setLoader({ ...loader, connect: true })
        if (!isConnected && redirectTo) {
            window.location.href = redirectTo
            setLoader({ ...loader, connect: false })
        } else {
            dispatch(connectStripe());
        }
    }

    const handleUpdatePaymentMethod = (e) => {
        const { name, value } = e.target;
        if (value === 'online' && !isConnected && !isDisclaimerAgreed) {
            /** ---- Open a modal if payment method turns to online when stripe account is not connected ---- */
            setShowModal(true)
        } else {
            handleUpdatePaymentMode(value)
        }

    }

    const handleHideModal = () => {
        setShowModal(false);
    }

    const onClickCancel = () => handleHideModal();

    const onClickConfirm = () => {
        handleConnectStripe();
    }

    const showActionButtons = () => {
        return (
            <div>
                <Button style={{ marginRight: 8 }} variant='danger' onClick={() => onClickCancel()}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    disabled={!accept || loader.connect}
                    onClick={() => accept ? onClickConfirm() : null}
                >
                    {loader.connect ? 'Redirecting To Stripe...' : 'Confirm'}
                </Button>
            </div>
        )
    }



    const showPolicyModal = () => {
        if (!post) return;
        return (
            <Modal
                show={showModal}
                onHide={() => handleHideModal()}
                backdrop={true}
                keyboard={true}
                size='lg'
            // scrollable
            >

                {!post 
                    ? 
                    <LoadingView loader="ThreeDots"/>
                    :
                    <>
                        <Modal.Body>
                            <div className="policy--modal">
                                <StripeLegalDocCRM post={post} />

                            </div>
                        </Modal.Body>
                        <Modal.Footer style={{ alignItems: 'center', justifyContent: 'space-around', display: 'flex' }}>
                            <Form.Check
                                checked={accept}
                                onChange={() => setAccept(!accept)}
                                style={{ fontSize: 16 }}
                                label="I acknowledge and agree to the terms outlined above."
                            />
                            {showActionButtons()}
                        </Modal.Footer>
                    </>
                }

            </Modal>
        )
    }


    // stripe account onboard above



    // const dispatch = useDispatch();
    const loggedIn = isAuth();
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState({ transactions: true, balance: true, withdrawRequest: false });
    const [alert, setAlert] = useState('');
    const [currency] = useState('$');
    const [walletBalance, setWalletBalance] = useState(0);
    const [pendingBalance, setPendingBalance] = useState(0);
    const [lastWithdrawal, setLastWithdrawal] = useState(0);
    const [totalWithdrawal, setTotalWithdrawal] = useState(0);

    // const [ withdrawAmount, setWithdrawAmt] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const walletState = useSelector(state => state.wallet)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updatedWallet = () => {
        dispatch(getWalletTx());
    }

    useEffect(() => {
        updatedWallet();
    }, []);


    useEffect(() => {
        if (walletState.error) {
            let _alert = {}
            _alert['variant'] = 'danger'
            _alert['message'] = walletState.error.message
            setAlert(_alert)
            setLoading({ balance: false, transactions: false, withdrawRequest: false })
            handleCloseAlert()
        }

        if (!walletState.error) {
            if (walletState.walletTx && walletState.walletTx.success) {
                setTransactions(walletState.walletTx.walletTnxs);

                if (walletState.walletTx.walletBalance) {
                    setWalletBalance(formatPrice(walletState.walletTx.walletBalance.balance));
                    setTotalWithdrawal(walletState.walletTx.walletBalance.totalWithdrawalBalance);
                }

                if (![undefined, null, '', 'undefined', 'null'].includes(walletState.walletTx.pendingBalance)) {
                    // setPendingBalance(
                    //     ['seller'].includes(loggedIn.role)
                    //         ? walletState.walletTx.pendingBalance.seller
                    //         : walletState.walletTx.pendingBalance.staff
                    // )
                    setPendingBalance(walletState.walletTx.pendingBalance)
                }


                if (walletState.walletTx.lastWithdrawal && walletState.walletTx.lastWithdrawal.type === "withdrawal") {
                    setLastWithdrawal(walletState.walletTx.lastWithdrawal.total);
                }

                // setAlert('')
                setLoading({ balance: false, transactions: false });
            }

            if (walletState.withdraw && walletState.withdraw.success) {
                setLoading({ withdrawRequest: false })
                // setAlert('')
                updatedWallet();
                handleShow();
            }
        }
    }, [walletState])

    const handleCloseAlert = () => {
        setTimeout(() => {
            setAlert('')
        }, 8000)
    }

    const renderOrderId = (tx) => {
        if (tx.order_id) {
            if (typeof tx.order_id === 'object') {
                return tx.order_id.order_id
            } else {
                return tx.order_id
            }
        }

        return '-'
    }

    const renderStaffNameOnCommissionOrder = (tx) => {
        if (tx.order_id && typeof tx.order_id === 'object') {
            if (typeof tx.order_id.businessId === 'object') {
                if (loggedIn.user !== tx.order_id.businessId._id) {
                    return tx.order_id.businessId.name
                }
            } else {
                if (loggedIn.user !== tx.order_id.businessId) {
                    return 'Not Available'
                }
            }
        }

        return '-'
    }


    const transactionsView = () => {
        return (
            <div className='table-wallet'>
                <Table striped responsive="sm">
                    <thead>
                        <tr>
                            <th scope="col">Transaction Id</th>
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Order Id</th>
                            {loggedIn.role === 'seller' && <th scope='col'>Staff</th>}
                            <th scope="col">Is Referral</th>
                            <th scope="col">Amount</th>
                            <th scope="col">In / Out </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            transactions.map((tx, txId) => {
                                return (
                                    <tr key={txId}>
                                        <td>{tx.tnx_id}</td>
                                        <td>{momentFormat.dateFormat(tx.createdAt)}</td>
                                        <td>{renderOrderId(tx)}</td>
                                        {loggedIn.role === 'seller' && <td style={{ textTransform: 'capitalize' }}>{renderStaffNameOnCommissionOrder(tx)}</td>}
                                        <td>{tx.from_user ? tx.from_user.name : '-'}</td>
                                        <td>${formatPrice(tx.total)}</td>
                                        <td style={{ textTransform: 'capitalize', color: tx.type === 'credit' ? '#059862' : '#FF7474' }}>{tx.type === 'credit' ? 'Credit' : 'Withdrawal'}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    const showPriceLoading = () => {
        return (
            <LoadingView loader="ThreeDots" size={20}/>
        )
    }

    const handleWithdrawRequest = (balance) => {
        setLoading({ withdrawRequest: true });
        dispatch(withdrawWalletAmount(balance));
    }

    return (
        <>
            {payment_mode === 'offline' ? (
                <div>
                    {(loader.paymentMode && loader.stripe)
                        ? <LoadingView message={'Please Wait...'} />
                        :
                        <div>
                            <h2>Wallet</h2>
                            {alert2 && <Alert variant={alert2.variant}>{alert2.message}</Alert>}
                            <div className='payment-mode'>
                                <Form.Label
                                    // style={{ width: '600px', maxWidth: '100%' }}
                                >
                                    Choose online below to use our payment platform, or continue collecting payments as you normally would. Please note, to offer virtual experiences, you need to have online payments enabled.
                                </Form.Label>
                                <Form.Control
                                    as='select'
                                    name="payment_mode"
                                    value={payment_mode}
                                    onChange={(e) => handleUpdatePaymentMethod(e)}
                                >
                                    <option value="offline">Offline</option>
                                    <option value="online">Online</option>
                                </Form.Control>
                                {/* {loader.paymentMode && <span>Please Wait Fetching Updated Details...</span>} */}
                            </div>

                            {loader.stripe
                                ? <>
                                    <LoadingView loader="ThreeDots" size={40}/>
                                </>

                                : (['online'].includes(payment_mode) || (['offline'].includes(payment_mode) && isConnected)) &&
                                <>
                                    <div className='connect-stripe'>
                                        {/* <h2>Stripe Account</h2> */}
                                        <Form.Label>Stripe Account</Form.Label>
                                        <div className='account--info'>
                                            {['offline'].includes(payment_mode)
                                                &&
                                                <Alert variant="warning">
                                                    To ensure seamless processing of your future appoinments and advance payments for appointment bookings, we recommend switching to <b>online</b> payments. Enjoy faster transactions, enhanced security, and the convenience of managing your payments effortlessly.
                                                </Alert>
                                            }
                                            {isConnected
                                                ? <p>Your wallet funds will be credited to the connected Stripe account <b>"{stripeAccount.accountDetail.email}"</b> upon request or through our Auto Withdrawal facility on the 1st of every month.</p>
                                                : <Alert variant="warning">
                                                    Your stripe account onboarding appears to be pending. Click below to finish setting up your account to be able to withdraw your money.</Alert>
                                            }



                                            <Button
                                                onClick={() => !isConnected ? handleConnectStripe() : null}
                                                disabled={isConnected || loader.connect}
                                                style={{ textTransform: 'capitalize' }}
                                            >
                                                {loader.connect
                                                    ? 'Redirecting...'
                                                    : isConnected
                                                        ? `Connected`
                                                        : 'Connect Your Stripe Account'
                                                }
                                            </Button>
                                        </div>

                                    </div>

                                </>
                            }

                            {showModal && showPolicyModal()}
                        </div>
                    }
                </div>
            )
                :
                <Container fluid>
                    <Row>
                        <div className="services-main-wraper-order">
                            <div className='wallet-top-bar'>
                                <div>
                                    <p>
                                        Pending Balance
                                        <Tooltip message={'This represents the funds of orders which are not completed. Funds is made available when orders gets completed'}>
                                            <FaRegQuestionCircle className='ml-1' />
                                        </Tooltip>
                                    </p>
                                    {loading['balance']
                                        ? showPriceLoading()
                                        : <h1>{currency}{pendingBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}</h1>
                                    }
                                </div>
                                <div>
                                    <p>Available Balance
                                        <Tooltip message={'This represents the funds available to withdraw on request or by our Auto Withdrawal facility on every 1st of month'}>
                                            <FaRegQuestionCircle className='ml-1' />
                                        </Tooltip>

                                    </p>
                                    {loading['balance']
                                        ? showPriceLoading()
                                        : <h1>{currency}{walletBalance.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}</h1>
                                    }

                                </div>
                                <div>
                                    <p>Total withdrawn
                                        <Tooltip message={'This represents the funds total withdrawal'}>
                                            <FaRegQuestionCircle className='ml-1' />
                                        </Tooltip>

                                    </p>
                                    {loading['balance']
                                        ? showPriceLoading()
                                        : <h1>{currency}{totalWithdrawal.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}</h1>
                                    }

                                </div>

                                <div>
                                    <Button
                                        variant="primary"
                                        onClick={() => (loading['withdrawRequest'] || ['0', 0].includes(walletBalance)) ? null : handleWithdrawRequest(walletBalance)}
                                        disabled={['0', 0].includes(walletBalance)}
                                    >
                                        {loading['withdrawRequest'] ? 'Requesting...' : 'Withdraw'}
                                    </Button>
                                    {/* <p>Total Withdrawal: ${totalWithdrawal}</p> */}
                                    <div><small><strong>Last Withdrawal: ${lastWithdrawal}</strong></small></div>
                                </div>
                            </div>
                            {alert &&
                                <div>
                                    <Alert variant={alert.variant}>{alert.message}</Alert>
                                </div>}
                            <div>
                                <h3>Transaction Details</h3>
                            </div>
                            {loading['transactions']
                                ? <LoadingView />
                                : transactions.length
                                    ? transactionsView()
                                    : <h6>No Transactions Available</h6>
                            }
                        </div>
                    </Row>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body>
                            <div className='wallet-popup-text'>
                                <BsFillCheckCircleFill />
                                <h2>Withdrawal Successful</h2>
                                <p>Your withdrawal request has been successfully processed. The withdrawn amount will be credited to your connected account within 1-3 business days.</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Container>
            }
        </>
    )
}

export default Wallet;