import React, { useState } from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import "./style.css"

const tooltip = ['No Ratings', 'Terrible', 'Bad', 'Average', 'Great', 'Perfect'];
const color = ['', '#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045'];

function ReverseRatingReadOnly({ value, classNames, onClick }) {
  const [selectedStar, setSelectedStar] = useState(value);

  let classes = 'item__rating';
  if (classNames) {
    classes += ' ';
    classes += classNames;
  }

  const handleClick = (index) => {
    setSelectedStar(index);
    if (onClick) {
      onClick(index);
    }
  };

  return (
    <p className={classes}>
      {Array.from({ length: 5 }).map((_, index) => {
        let starIndex = index + 1;
        let isSelectedStar = starIndex >= selectedStar;

        return (
          <span
            key={starIndex}
            className={`${isSelectedStar ? 'rated' : 'not__rated'}`}
            style={{ cursor: 'pointer' }}
            onClick={() => handleClick(starIndex)}
          >
            <FaStar />
          </span>
        );
      })}
    </p>
  );
}

export default ReverseRatingReadOnly;





// import React from "react";
// import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
// // import 'bootstrap/dist/css/bootstrap.min.css';
// // import './popularStyle.css';
// // import '../../App.css';

// const tooltip = ['No Ratings', 'Terrible', 'Bad', 'Average', 'Great', 'Perfect']
// const color = ['', '#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']

// function ReverseRatingReadOnly({ value, classNames, onClick }) {
//     const starWidth =  `${(value % 1) * 100}%`;
//     let classes = `item__rating`
//     if( classNames){
//         classes += ' '
//         classes += classNames
//     }
//     return (
//         <p className={classes}>
//             {Array.from({ length: 5 }).map((star, starIndex) => {
//                 starIndex += 1 
//                 let isRated = starIndex >= Math.floor(value);
//                 let isHalfRated = starIndex === Math.floor(value) && value % 1 !== 0;
//                return  (
//                     <span
//                         key={starIndex}
//                         // className={starIndex < value ? 'rated' : 'not__rated'}
//                         className={`${(isRated || isHalfRated) ? 'rated' : 'not__rated'}`}
//                         style={{ width: starWidth, cursor: onClick ? 'pointer': 'default'}}
//                         onClick={() => onClick ? onClick(starIndex+1) : null}
//                     >
//                         {isHalfRated 
//                             ? <FaStarHalfAlt /> 
//                             : isRated
//                                 ? <FaStar />
//                                 : <FaRegStar  />   }
//                     </span>
                    
//                 )
//             })}

//             {/* {value > 0 && <span className="ml-1 mt-2" style={{ backgroundColor: color[value], color: value > 0 ? '#fff' : '#000', padding: '5px', borderRadius:'5px'}}>{tooltip[value]}</span>} */}
//         </p>
//     )
// }

// export default ReverseRatingReadOnly;
