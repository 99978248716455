import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, Form, Container, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import * as auth from '../../helpers/auth';
import { getMetaUser } from '../../redux/actions/user.action';
import { cancelPlan, clearState, getSubscriptionPlans, purchasePlan, upgradePlan } from "../../redux/actions/subscription.action";
import { formatPrice, momentFormat } from "../../utils/validations";
import LoadingView from "../LoadingView";
// import PurchaseOptionModel from "./PurchaseOptionModel";

const PaySubscription = () => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [payLoader, setPayloader] = useState('');
    const [plans, setPlans] = useState(null)
    const [subscription, setSubscription] = useState(null);
    const [tenure, setTenure] = useState('month');

    /** ---- Purchase Option Modal ---- */
    // const [selectedPlan, setSelectedPlan] = useState('');
    // const [showModal, setShowModal] = useState(false);
    // const [havePromo, setHavePromo] = useState(true);
    // const [couponCode, setCouponCode ] = useState(null);


    const userDetail = useSelector((state) => state.user.userMetaDetail);
    const subscriptionState = useSelector((state) => state.subscription);

    const getPlans = () => {
        dispatch(getSubscriptionPlans())
    }

    useEffect(() => {
        getPlans();
        return () => {
            dispatch(clearState());
        }
    }, []);

    const getMeta = () => {
        setTimeout(() => {
            dispatch(getMetaUser())
        }, 2000)
    }

    useEffect(() => {
        if (userDetail && userDetail.subscription && userDetail.subscription.subscribed && userDetail.subscription.subscriptionStatus === 'Paid') {
            setSubscription(userDetail.subscription);
            if (plans) {
                setLoader(false);
                setPayloader('')
            }
        }
    }, [userDetail]);

    useEffect(() => {
        if (!subscriptionState.error) {
            if (subscriptionState.plans && subscriptionState.plans.success) {
                setPlans(subscriptionState.plans.plans)
                setLoader(false);
            }
            if (subscriptionState.purchase && subscriptionState.purchase.success) {
                if(subscriptionState.purchase.payment_link){
                    window.location.href = subscriptionState.purchase.payment_link
                }else{
                    auth.removeSubscriptionSession();
                    window.location.replace('/')
                }
            }

            if (subscriptionState.upgrade && subscriptionState.upgrade.success) {
                toast.success(subscriptionState.upgrade.message);
                getMeta();
            }

            if (subscriptionState.cancel && subscriptionState.cancel.success) {
                toast.dismiss()
                toast.success(subscriptionState.cancel.message);
                getMeta();
            }
        }

        if (subscriptionState.error) {
            setLoader(false);
            setPayloader('')
            toast.error(subscriptionState.error.message);
        }

    }, [subscriptionState])


    useEffect(() => {
        // console.log( 'dddddddddd', subscription ,  plans)
        if (subscription && plans) {
            const _tenure = checkTenure(subscriptionPlanId())
            setTenure(_tenure)
        }
    }, [plans])

    const checkTenure = (id) => {
        // Search in the year array
        const yearSubscription = plans.year.find(sub => sub._id === id);
        if (yearSubscription) {
            return yearSubscription.subscriptionTenure;
        }

        // Search in the month array
        const monthSubscription = plans.month.find(sub => sub._id === id);
        if (monthSubscription) {
            return monthSubscription.subscriptionTenure;
        }

        // If not found in either array
        return 'month';
    }

    /** ------ Subscription Purchase/Upgrade ------ */
    const handlePlanUpgradePurchase = (planId) => {
        setPayloader(planId)
        if (subscription) {
            // console.log('Upgrade', selectedPlan)
            /** ----- Upgrade Plan ( only for the sellers whose account is sertup & profile completed ) ----- */
            dispatch(upgradePlan(planId))
        } else {
            // console.log('Purchase', selectedPlan, couponCode)
            /** ----- Purchase Plan ----- */
            dispatch(purchasePlan({subscriptionPlanId:planId}))
        }
    }

    // const handlePromoModal = (planId) => {
    //     setSelectedPlan(planId)
    //     setPayloader(planId)
    //     if(subscription){
    //         handlePlanUpgradePurchase()
    //     }else{
    //         setShowModal(true)
    //     }
    // }

    /** ------ Subscription Cancel ------ */
    const handleCancel = (planId) => {
        setPayloader(planId)
        dispatch(cancelPlan());
    }

    // console.log( 'subscription::', subscription )
    const subscriptionPlanId = () => {
        if (typeof subscription.associatedWithSubscriptionPlan === 'object') {
            return subscription.associatedWithSubscriptionPlan._id
        } else {
            return subscription.associatedWithSubscriptionPlan
        }
    }

    // const handleCloseModal = () => {
    //     setShowModal(false);
    //     // setHavePromo(false);
    //     setCouponCode(null);
    //     setPayloader('');
    // }

    // console.log('subsription', subscription );
    return (
        <div className="dashboardMainContent plan--page pb-5" style={{ marginTop: subscription ? '5rem' : '10rem' }}>
            <Container>
                <Row>
                    <Col sm={12} className="text-center">
                        <h1>Subscription Plans</h1>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center mb-5">
                    <Col sm={4}>
                        <Form.Control
                            as="select"
                            onChange={(e) => setTenure(e.target.value)}
                            value={tenure}
                            title="View"

                        >
                            <option value={'month'}>Monthly</option>
                            <option value={'year'}>Yearly</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    {loader
                        ? <LoadingView />
                        : (plans && plans[tenure])
                            ? plans[tenure].map((plan, planIndex) => {
                                let isSubscriptionActive = (subscription && (subscriptionPlanId() === plan._id)) ? true : false
                                return (
                                    <Col
                                        sm={6}
                                        md={6}
                                        xl={3}
                                        className={`card-layout-Subscription-wraper ${isSubscriptionActive ? 'active--subscription' : ''}`}
                                        key={planIndex}
                                    >
                                        {isSubscriptionActive && <div className="active--badge">Active</div>}
                                        <Card
                                            className="card-layout-Subscription h-100"
                                        >
                                            <Card.Body>
                                                {plan.discount > 0 && <span className="discount--badge">-{plan.discount}%</span>}
                                                <Card.Title className="Subscription-text">
                                                    <h4 className="text-center Subscription-text">{plan.name}</h4>
                                                    <h5 className="">${formatPrice(plan.amount)} / <span style={{ textTransform: 'capitalize' }}>{plan.subscriptionTenure}</span></h5>
                                                    {(plans[tenure].length > 1) &&
                                                        <p>
                                                            <small>
                                                                {plan.maxStaffLimit > 1 && 'Up to '}{plan.maxStaffLimit} Staff {plan.maxStaffLimit > 1 ? "Members" : "Member"}
                                                            </small>
                                                        </p>
                                                    }
                                                </Card.Title>
                                                <Button
                                                    className="action--btn mb-2"
                                                    type="button"
                                                    variant="primary"
                                                    onClick={() =>
                                                        (payLoader === plan._id)
                                                            ? null
                                                            : isSubscriptionActive
                                                                ? subscription.cancellationDate
                                                                    ? null
                                                                    : handleCancel(plan._id)
                                                                : handlePlanUpgradePurchase(plan._id)
                                                    }

                                                    disabled={(payLoader === plan._id) || (isSubscriptionActive && subscription.cancellationDate)}
                                                    style={{
                                                        textTransform: 'capitalize'
                                                    }}
                                                >
                                                    {
                                                        (payLoader === plan._id)
                                                            ? <LoadingView loader="ThreeDots" size={20}/>
                                                            : isSubscriptionActive
                                                                ? subscription.cancellationDate ? 'Cancelled' : 'Cancel'
                                                                : subscription
                                                                    ? 'Upgrade'
                                                                    : 'Get Started'
                                                    }
                                                </Button>

                                                {isSubscriptionActive &&
                                                    <span>{subscription.cancellationDate ? 'Plan Active until' : 'Next Billing On'}  {momentFormat.fullDate(subscription.renewedAt)}</span>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                            : null
                    }
                </Row>
                {(subscription && subscription.cancellationDate && !loader) &&
                    <Alert variant="warning" className="text-center">
                        You Requested for Plan Cancellation On {momentFormat.fullDate(subscription.cancellationDate)}, still you have the access upto {momentFormat.fullDate(subscription.renewedAt)}
                    </Alert>
                }
            </Container>
            {/* {showModal &&
            <PurchaseOptionModel 
                show={showModal}
                handleClose={handleCloseModal}
                havePromo={havePromo}
                setHavePromo={setHavePromo}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                handlePlanUpgradePurchase={handlePlanUpgradePurchase}
            />} */}
        </div>
    )
}


export default PaySubscription;