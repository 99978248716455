import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';
import SEO from "../../../seo";
import LoadingView from "../../LoadingView";

const ViewEducationalContent = ({ blogId }) => {
    const navigate = useNavigate();
    const { id: businessId } = useParams();
    const sampleImage = "https://picsum.photos/800/400";
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchBlog();
    }, [blogId]);

    const fetchBlog = async () => {
        try {
            const response = await API.apiGetByKey('seller', `/blogs/family/${blogId}`);
            if (response.data && response.data.success) {
                setBlog(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching blog:', error);
            toast.error(error.response?.data?.message || 'Error fetching blog');
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        navigate(`/business/${businessId}?tab=educationalContent`);
    };

    if (loading) {
        return <Container><LoadingView /></Container>;
    }

    if (!blog) {
        return (
            <Container style={{ padding: '20px' }}>
                <Button 
                    variant="outline-secondary" 
                    onClick={handleBack}
                    style={{
                        borderRadius: '20px',
                        padding: '8px 20px',
                        marginBottom: '1rem'
                    }}
                >
                    ← Back to All Blogs
                </Button>
                <h2>Blog not found</h2>
            </Container>
        );
    }

    const { title, category, metaDescription, tags, author, featuredImage, content, publishDate } = blog;

    return (
        <Container style={{ padding: '20px' }}>
            {blog && (
                <SEO
                    title={blog.title}
                    description={blog.metaDescription || blog.content?.substring(0, 155)}
                    url={`/business/${businessId}/educational-content/${blogId}`}
                    keywords={blog.tags?.join(', ') || "birth education, pregnancy resources, birth professional content"}
                    canonical={`${window.location.origin}/business/${businessId}?tab=educationalContent&blogId=${blogId}`}
                    author={blog.author}
                />
            )}
            <Button
                variant="outline-secondary"
                onClick={handleBack}
                style={{
                    borderRadius: '20px',
                    padding: '8px 20px',
                    marginBottom: '2rem'
                }}
            >
                ← Back to All Blogs
            </Button>

            <article style={{ maxWidth: '800px', margin: '0 auto' }}>
                {(featuredImage || sampleImage) && (
                    <div style={{ marginBottom: '2rem' }}>
                        <img
                            src={featuredImage || sampleImage}
                            alt={title}
                            style={{
                                width: '100%',
                                maxHeight: '400px',
                                objectFit: 'cover',
                                borderRadius: '12px',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                            }}
                        />
                    </div>
                )}

                <h1 style={{
                    fontSize: '2.5rem',
                    fontWeight: '700',
                    color: '#2c3e50',
                    marginBottom: '1.5rem'
                }}>
                    {title}
                </h1>

                <div style={{
                    padding: '15px',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    marginBottom: '2rem'
                }}>
                    <p style={{ margin: 0 }}>
                        <strong>Author:</strong>{' '}
                        <span style={{ color: '#666' }}>{author}</span>
                    </p>
                    <p style={{ margin: 0 }}>
                        <strong>Category:</strong>{' '}
                        <span style={{ color: '#666' }}>{category}</span>
                    </p>
                    <p style={{ margin: 0 }}>
                        <strong>Published:</strong>{' '}
                        <span style={{ color: '#666' }}>
                            {new Date(publishDate).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric'
                            })}
                        </span>
                    </p>
                </div>

                <div style={{
                    fontSize: '1.1rem',
                    fontStyle: 'italic',
                    color: '#666',
                    borderLeft: '4px solid #007bff',
                    paddingLeft: '1rem',
                    marginBottom: '2rem'
                }}>
                    {metaDescription}
                </div>

                <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    style={{
                        backgroundColor: '#ffffff',
                        padding: '2rem',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                        lineHeight: '1.8',
                        fontSize: '1.1rem',
                        lineBreak: 'anywhere',
                    }}
                />

                {tags && tags.length > 0 && (
                    <div style={{ marginTop: '2rem' }}>
                        <strong>Tags:</strong>{' '}
                        {tags.map((tag, index) => (
                            <span
                                key={index}
                                style={{
                                    background: '#f0f0f0',
                                    padding: '4px 12px',
                                    borderRadius: '15px',
                                    fontSize: '0.9rem',
                                    marginRight: '8px',
                                    color: '#666',
                                    display: 'inline-block',
                                    marginBottom: '8px'
                                }}
                            >
                                #{tag}
                            </span>
                        ))}
                    </div>
                )}
            </article>
        </Container>
    );
};

export default ViewEducationalContent;