import React from 'react';
import './PrivacyPolicy.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});

const StripePaymentWaiver = () => (
    <div className="stripe-payment-waiver-container">
        <h1 className="center-text">Stripe Payment Waiver for Birth Professionals</h1>
        <div className='stripe-payment-waiver-wrapper'>
            <p className="stripe-payment-waiver-content">
                <strong>Effective Date:</strong> [Insert Date]
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>Introduction</strong><br />
                This Stripe Payment Waiver ("Waiver") is an agreement between [BirthBridge, Inc.] ("BirthBridge", "we", "us", "our") and you ("Birth Professional", "you", "your"). By enabling Stripe payments on your BirthBridge account, you agree to the terms and conditions set forth in this Waiver.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>1. Acknowledgment and Acceptance</strong><br />
                By enabling Stripe payments, you acknowledge that you have read, understood, and agree to be bound by the terms of this Waiver.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>2. Stripe Account Setup</strong><br />
                You are responsible for setting up and maintaining your Stripe account. You agree to comply with all terms and conditions set forth by Stripe, Inc. ("Stripe"). If you do not set up your Stripe account, you will not be able to take payments and/or use our virtual experience on our platform.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>3. Fees and Charges</strong><br />
                Stripe charges fees for processing payments. You acknowledge that these fees are your responsibility and will be deducted from the payments you receive. BirthBridge may charge additional platform fees, which will be clearly outlined in your BirthBridge provider agreement.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>4. Liability and Indemnification</strong><br />
                You acknowledge that BirthBridge is not responsible for any issues arising from your use of Stripe, including but not limited to payment delays, processing errors, or account suspensions. You agree to indemnify and hold BirthBridge harmless from any claims, damages, or expenses arising from your use of Stripe.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>5. Dispute Resolution</strong><br />
                Any disputes related to payments processed through Stripe must be resolved directly with Stripe. BirthBridge is not responsible for mediating disputes between you and Stripe or between you and your clients regarding payments.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>6. Withdrawal of Payments</strong><br />
                You are required to withdraw your payments on a quarterly basis. If you have a balance remaining in your Stripe account by the 1st of every quarter, BirthBridge will automatically withdraw the balance to your linked bank account.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>7. Termination of Stripe Payments</strong><br />
                You may disable Stripe payments at any time through your BirthBridge account settings. BirthBridge reserves the right to disable Stripe payments on your account at any time, with or without cause.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>8. Governing Law</strong><br />
                This Waiver is governed by and construed in accordance with the laws of [Your Jurisdiction].
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>9. Changes to this Waiver</strong><br />
                BirthBridge may modify this Waiver at any time. You will be notified of any significant changes, and your continued use of Stripe payments constitutes acceptance of the revised Waiver.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>10. Contact Information</strong><br />
                If you have any questions about this Waiver, please contact us at hello@mybirthbridge.com.
            </p>
            <p className="stripe-payment-waiver-content">
                <strong>Signature</strong><br />
                By enabling Stripe payments on your BirthBridge account, you agree to the terms and conditions set forth in this Stripe Payment Waiver.
            </p>
        </div>
    </div>
);

export default function StripePaymentWaiverWrapper() {
    return (
        <ThemeProvider theme={theme}>
            <StripePaymentWaiver />
        </ThemeProvider>
    );
}