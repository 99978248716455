import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import slugify from 'slugify';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Chip, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { getSellerProfile } from "../../../redux/actions/seller.action";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../../../helpers/api';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { renderToast } from '../../../utils/';

// React Quill Modules for Full Toolbar
const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }], // Headings
        ['bold', 'italic', 'underline', 'strike'], // Formatting
        [{ list: 'ordered' }, { list: 'bullet' }], // Lists
        // ['link', 'image', 'video'], // Links, images, video embedding
        ['clean'], // Clear formatting
    ],
};

// React Quill Formats
const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
    'image',
    'video',
];

const CreateBlogPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const blogData = location.state?.blogData || {};
    const isEditing = location.state?.isEditing || false;

    const [title, setTitle] = useState(blogData.title || '');
    const [slug, setSlug] = useState(blogData.slug || '');
    const [metaDescription, setMetaDescription] = useState(blogData.metaDescription || '');
    const [tags, setTags] = useState(blogData.tags || []);
    const [tagInput, setTagInput] = useState('');
    const [author, setAuthor] = useState(blogData.author || '');
    const [featuredImage, setFeaturedImage] = useState(blogData.featuredImage || null);
    const [content, setContent] = useState(blogData.content || '');
    const [publishDate, setPublishDate] = useState(
        blogData.publishDate ? new Date(blogData.publishDate).toISOString().split('T')[0] : ''
    );
    const [imagePreview, setImagePreview] = useState(blogData.featuredImage || null);
    const [category, setCategory] = useState(blogData.category || '');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSavingDraft, setIsSavingDraft] = useState(false);

    const sellerProfileData = useSelector(state => state.seller);

    // Define the categories as a constant
    const BLOG_CATEGORIES = [
        'General Discussion',
        'Questions',
        'Discussion',
        'Announcement',
        'Career and Training',
        'Business Tips and Tools',
        'Networking and Collaboration',
        'Education and Resources',
        'Specialized Services',
        'Client Support',
        'Advocacy and Birth Rights',
        'Birth Stories and Experiences',
        'Tools and Techniques',
        'Regional Groups',
        'FAQs for New Professionals',
        'Doula Tools and Tech',
        'Community Engagement and Outreach',
    ];


    useEffect(() => {
        dispatch(getSellerProfile());
        
        if (!author) {
            setAuthor(sellerProfileData?.userDetail?.name);
        }
    }, [sellerProfileData?.userDetail?.name]);

    useEffect(() => {
        setSlug(slugify(title, { lower: true, strict: true }));
    }, [title]);

    const handleFeaturedImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFeaturedImage(file);
            const previewUrl = URL.createObjectURL(file);
            setImagePreview(previewUrl);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!title || !metaDescription || !content || !publishDate || !featuredImage) {
            toast.error('Please fill in all required fields');
            return;
        }

        setIsSubmitting(true);
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('category', category || '');
            formData.append('slug', slug);
            formData.append('metaDescription', metaDescription);
            formData.append('tags', JSON.stringify(tags));
            formData.append('author', author);
            formData.append('content', content);
            formData.append('publishDate', new Date(publishDate).toISOString());
            formData.append('status', 'published');

            if (featuredImage) {
                formData.append('files', featuredImage);
            }

            let response;
            if (isEditing) {
                response = await API.apiUpdateUploadFile('seller', `/blogs/${blogData._id}`, formData);
            } else {
                response = await API.apiUploadFileURL('seller', '/blogs', formData);
            }

            if (response.data && response.data.success) {
                toast.success(isEditing ? 'Blog updated successfully!' : 'Blog published successfully!');
                navigate('/my-blogs');
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.response?.data?.message || `Error ${isEditing ? 'updating' : 'creating'} blog`);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleSaveDraft = async () => {
        if (!title) {
            toast.error('Title is required for saving draft');
            return;
        }

        setIsSavingDraft(true);
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('slug', slug);
            formData.append('metaDescription', metaDescription || '');
            formData.append('tags', JSON.stringify(tags));
            formData.append('author', author);
            formData.append('content', content || '');
            formData.append('category', category || '');
            formData.append('publishDate', publishDate ? new Date(publishDate).toISOString() : '');
            formData.append('status', 'draft');

            if (featuredImage) {
                formData.append('files', featuredImage);
            }

            let response;
            if (isEditing) {
                response = await API.apiUploadFileURL('seller', `/blogs/${blogData._id}`, formData, 'PUT');
            } else {
                response = await API.apiUploadFileURL('seller', '/blogs', formData);
            }

            if (response.data && response.data.success) {
                toast.success(isEditing ? 'Draft updated successfully!' : 'Draft saved successfully!');
                navigate('/my-blogs');
            }
        } catch (error) {
            console.error('Error saving draft:', error);
            toast.error(error.response?.data?.message || `Error saving draft`);
        } finally {
            setIsSavingDraft(false);
        }
    };

    const handleTagInputChange = (e) => {
        setTagInput(e.target.value);
    };

    const handleAddTag = (e) => {
        e?.preventDefault();
        if (tagInput.trim()) {
            if (!tags.includes(tagInput)) {
                setTags([...tags, tagInput]);
                setTagInput('');
            }
        }
    };

    const handleDeleteTag = (tagToDelete) => {
        setTags(prevTags => prevTags.filter(tag => tag !== tagToDelete));
    };

    return (
        <Container className="mt-5">
            <div className="d-flex justify-content-between align-items-center mb-4">
                <h2>{isEditing ? 'Edit Blog' : 'Create New Blog'}</h2>
                <div className="d-flex gap-3">
                    <Button
                        style={{ marginRight: '8px' }}
                        variant="outline-secondary"
                        onClick={handleSaveDraft}
                        disabled={isSavingDraft}
                    >
                        {isSavingDraft ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                Saving...
                            </>
                        ) : (
                            'Save as Draft'
                        )}
                    </Button>
                    <Button
                        style={{ marginRight: '8px' }}
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                {isEditing ? 'Updating...' : 'Publishing...'}
                            </>
                        ) : (
                            isEditing ? 'Update Blog' : 'Publish Blog'
                        )}
                    </Button>
                    <Button
                        variant="outline-danger"
                        onClick={() => navigate('/my-blogs')}
                        disabled={isSubmitting || isSavingDraft}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
            <Form onSubmit={(e) => e.preventDefault()}>
                {/* Blog Title */}
                <Form.Group className="mb-3">
                    <Form.Label>Blog Title <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter your blog title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                    />
                </Form.Group>

                {/* Slug */}
                <Form.Group className="mb-3">
                    <Form.Label>Slug (Auto-Generated) <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <Form.Control
                        type="text"
                        value={slug}
                        placeholder="Generated automatically from title"
                        readOnly
                    />
                </Form.Group>

                {/* Meta Description */}
                <Form.Group className="mb-3">
                    <Form.Label>Meta Description (for SEO) <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter a meta description for search engines"
                        value={metaDescription}
                        onChange={(e) => setMetaDescription(e.target.value)}
                        required
                    />
                </Form.Group>

                {/* Tags/Keywords */}
                <Form.Group className="mb-3">
                    <Form.Label>Tags/Keywords <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <Box>
                        <div className="d-flex gap-2 mb-2">
                            <Form.Control
                                type="text"
                                size="sm"
                                value={tagInput}
                                onChange={handleTagInputChange}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleAddTag(e);
                                    }
                                }}
                                placeholder="Add a tag"
                            />
                            <Button
                                variant="outline-primary"
                                onClick={handleAddTag}
                            >
                                Add Tag
                            </Button>
                        </div>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {tags.map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    onDelete={() => handleDeleteTag(tag)}
                                />
                            ))}
                        </Box>
                    </Box>
                </Form.Group>

                {/* Author Name */}
                <Form.Group className="mb-3">
                    <Form.Label>Author Name <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <Form.Control
                        type="text"
                        disabled
                        placeholder="Enter your name (optional)"
                        value={sellerProfileData?.userDetail?.name}
                    />
                </Form.Group>

                {/* Featured Image */}
                <Form.Group className="mb-3">
                    <Form.Label>
                        Featured Image 
                        <span style={{ color: 'red', fontSize: '16px' }}>*</span>
                    </Form.Label>
                    <div className="d-flex gap-2 align-items-center">
                        <Button
                            variant="outline-primary"
                            onClick={() => document.getElementById('featuredImageInput').click()}
                        >
                            Upload Featured Image
                        </Button>
                        <Form.Control
                            id="featuredImageInput"
                            type="file"
                            accept="image/*"
                            onChange={handleFeaturedImageChange}
                            style={{ display: 'none' }}
                        />
                        <span className="text-muted small">
                            {featuredImage ? featuredImage.name : 'No file chosen'}
                        </span>
                    </div>
                    {imagePreview && (
                        <div style={{
                            marginTop: '10px',
                            position: 'relative',
                            width: 'fit-content'
                        }}>
                            <img
                                src={imagePreview}
                                alt="Preview"
                                style={{
                                    maxWidth: '200px',
                                    maxHeight: '200px',
                                    objectFit: 'contain',
                                    borderRadius: '4px'
                                }}
                            />
                            <Button
                                variant="danger"
                                size="sm"
                                style={{
                                    position: 'absolute',
                                    top: '5px',
                                    right: '5px',
                                    padding: '2px 6px',
                                    fontSize: '12px'
                                }}
                                onClick={() => {
                                    setFeaturedImage(null);
                                    setImagePreview(null);
                                }}
                            >
                                ×
                            </Button>
                        </div>
                    )}
                </Form.Group>

                {/* Publish Date */}
                <Form.Group className="mb-3" style={{ position: 'relative', zIndex: 3 }}>
                    <Form.Label>Publish Date <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <DatePicker
                        selected={publishDate}
                        onChange={(date) => setPublishDate(date)}
                        className="form-control"
                        dateFormat="MMMM d, yyyy"
                        minDate={new Date()}
                        placeholderText="Select publish date"
                        popperProps={{
                            positionFixed: true // Ensures the popper is positioned relative to the viewport
                        }}
                        popperPlacement="bottom-start"
                    />
                </Form.Group>

                {/* Category - Lower z-index */}
                <FormControl fullWidth className="mb-3" style={{ position: 'relative', zIndex: 2 }}>
                    <InputLabel id="category-label">Category <span style={{ color: 'red', fontSize: '16px' }}>*</span></InputLabel>
                    <Select
                        labelId="category-label"
                        id="category-select"
                        value={category}
                        label="Category"
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {BLOG_CATEGORIES.map((cat) => (
                            <MenuItem key={cat} value={cat}>
                                {cat}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Rich Text Editor */}
                <Form.Group className="mb-3">
                    <Form.Label>Blog Content <span style={{ color: 'red', fontSize: '16px' }}>*</span></Form.Label>
                    <ReactQuill
                        theme="snow"
                        value={content}
                        onChange={setContent}
                        modules={modules}
                        formats={formats}
                        placeholder="Start writing your blog here..."
                        style={{ height: '300px' }}
                    />
                </Form.Group>
            </Form>
        </Container>
    );
};

export default CreateBlogPage;
