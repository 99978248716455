import React from 'react';
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
// import {AiOutlineCloseCircle} from "react-icons/ai";
import '../../App.css';

function PaymentCancel() {
    const navigate = useNavigate()
    const location = useLocation()
    const tnx_id  = new URLSearchParams(location.search).get('tnx_id')

    return (
        <div className='stripe-cancel-main-wraper'>
            <Container>
                <Row>
                    <Col sm={12} className='mb-5'>
                        {/* <AiOutlineCloseCircle /> */}
                        <h3>Payment Failed with Transaction Id {tnx_id || 'N/A'}</h3>
                        <Alert key="danger" variant="danger">
                            We are sorry, there was an error processing your Payment #.
                        </Alert>
                        <Button variant="secondary" onClick={() => navigate("/")}>Go Home Page</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PaymentCancel