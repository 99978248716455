export const orderStatus = [
    {value:'pending', label:'Pending'}, 
    {value:'initiated', label: 'Awaiting Approval'}, 
    {value:'cancelled', label: 'Cancelled'}, 
    {value:'accepted', label: 'accepted'}, 
    {value:'completed', label: 'Completed'},
];

export const transactionStatus = [
    {value:'pending', label: 'Pending'}, 
    {value:'cancelled', label: 'Cancelled'}, 
    {value:'completed', label: 'Completed'}, 
    {value:'refund_initiated',label: 'Refund Initiated'}, 
    {value:'refund_processing', label: 'Refund Processing'}, 
    {value:'refund', label: 'Refund'},
];

export const getOrderStatus = (_status) => {
    let matchedStatus = orderStatus.find(status => status.value === _status)

    return matchedStatus.label
}
