import moment from "moment";
import { momentFormat } from "./validations";

export const calculateClassPrice = (startDate, startTime, endDate, endTime, fixedPricePerHour, amountType) => {
    if(amountType === 'fixed'){
        return parseFloat(fixedPricePerHour);
    }
    // Parse start and end times using moment
    // const start = moment(startTime, 'HH:mm');
    // const end = moment(endTime, 'HH:mm');
    const start = moment(momentFormat.combineDateTime(startDate,  startTime));
    const end = moment(momentFormat.combineDateTime(endDate,  endTime));

    // Calculate the duration in hours
    const durationHours = moment.duration(end.diff(start)).asHours();
   
    // Calculate the price for the class
    const classPrice = parseFloat(durationHours) * parseFloat(fixedPricePerHour);

    return classPrice.toFixed(1);
};

export const calculateHourlyPrice = (startDate, startTime, endDate, endTime, totalPrice, amountType) => {
    if(amountType === 'fixed'){
        return parseFloat(totalPrice);
    }

    // Parse start and end times using moment
    // const start = moment(startTime, 'HH:mm');
    // const end = moment(endTime, 'HH:mm');

    const start = moment(momentFormat.combineDateTime(startDate,  startTime));
    const end = moment(momentFormat.combineDateTime(endDate,  endTime));
    // Calculate the duration in minutes
    const durationMinutes = moment.duration(end.diff(start)).asMinutes();

    // Convert duration to hours
    const durationHours = durationMinutes / 60;

    // const durationHours = moment.duration(end.diff(start)).asHours();
    // Calculate the price per hour
    const pricePerHour = totalPrice/durationHours;

    return pricePerHour.toFixed(1);
};


export function generateDateArray(startDate, endDate) {
    const dates = [];
    let currentDate = moment(startDate);
    
    while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate.format('YYYY-MM-DD'));
        currentDate.add(1, 'day');
    }
    
    return dates;
}