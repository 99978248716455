import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

/** --- Redux ----- */
import { useSelector } from 'react-redux';

/** ------- Components ------ */
import VerifyOtp from "./EmailVerification/VerifyOtp";
import ResetPassWord from "./EmailVerification/ResetPassWord";
import RequestOtp from "./EmailVerification/RequestOtp";
import SignInModel from "./SignIn/SignInModel";
// import SellerSignUpModel from './UserSignUp/SellerSignUpModel';
// import SignUpModel from './UserSignUp/SignUpModel';

const AuthModal = ({ show, onHide, userEmail, userPassword, handleReset }) => {
    const location = window.location;
    const [email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const showModal = useSelector(state => state.showModal);

    useEffect(() => {
        if(userEmail){
            setEmail(userEmail);
        }

        if(userPassword){
            setPassword(userPassword)
        }

    }, [userEmail, userPassword])

    return (
        <Modal 
            className="modal__form" 
            show={show} 
            onHide={() => onHide()}
            backdrop='static'
        >
            <Modal.Header>
                <Button variant="secondary" onClick={() => onHide()}>&times;</Button>
            </Modal.Header>
            <Modal.Body>
                <div className="login--Wrapper">
                    { showModal.login && 
                        <SignInModel 
                            location={location}
                            onHide={() => onHide()}
                        />
                    }

                    {showModal.verifyOtp &&
                        <VerifyOtp 
                            email={email}
                            password={password}
                            emailVerify={showModal.emailVerify}
                            isSeller={showModal.isSeller}
                            handleReset={handleReset}
                        />
                    }

                    {showModal.resetPass &&  
                        <ResetPassWord
                            email={email}
                        />
                    }

                    {showModal.requestOtp &&
                        <RequestOtp 
                            email={email}
                            setEmail={setEmail}
                        />
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}


export default AuthModal;