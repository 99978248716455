import { get } from 'lodash';
import API from '../../helpers/api';
// import * as auth from '../../helpers/auth';
// import jwtDecode from 'jwt-decode';

/**   import Seller types */
import {
    PUT_SELLER_PROFILE_SUCCESS,
    PUT_SELLER_PROFILE_FAILED,
    GET_SELLER_PROFILE_SUCCESS,
    GET_SELLER_PROFILE_FAILED,
} from './types' ;


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for get Seller profile  
 * @returns 
*/

export const getSellerProfile = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('seller', `/details`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SELLER_PROFILE_SUCCESS, payload: response.data.userDetails
            });
        } else {
            await dispatch({ type: GET_SELLER_PROFILE_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SELLER_PROFILE_FAILED);
      }
    }
}

/**
 * 
 * @param {*} using for edit Seller profile  
 * @returns 
*/

export const updateSellerProfile = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('seller','/edit/details', payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: PUT_SELLER_PROFILE_SUCCESS, payload: response.data.message });
            } else {
                await dispatch({ type: PUT_SELLER_PROFILE_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, PUT_SELLER_PROFILE_FAILED);
        }
    }
}





/**------------------------- */