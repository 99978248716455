import React, { useEffect, useState } from 'react';
import { createClient } from 'contentful';
import { useParams, Navigate, Link } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Chip, Typography, Box, Card, CardContent } from '@mui/material';
import { FaThumbtack } from 'react-icons/fa';
import './styles/BlogPost.css';
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";
import LoadingView from '../LoadingView';
import { optimizedCloudinaryImage } from '../../utils/fileCompression';
import AdUnit from '../communityForum/AdUnit';


const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
});

const renderRichText = (document) => {
    const options = {
        renderNode: {
            'paragraph': (node, children) => <Typography paragraph>{children}</Typography>,
            'hyperlink': (node, children) => (
                <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            ),
        },
    };
    return documentToReactComponents(document, options);
};

const BlogPost = () => {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [morePosts, setMorePosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (post) {
            logEvent('Page View', 'viewing_blog_post', `Viewing ${post.fields.title} Blog Post`, 1);
        }
    }, [post]);

    useEffect(() => {
        setIsLoading(true);
        Promise.all([
            // Fetch main blog post
            client.getEntries({
                content_type: 'blogPost',
                'fields.slug': slug,
            }),
            // Fetch additional blog posts
            client.getEntries({
                content_type: 'blogPost',
                limit: 100,
                order: '-sys.createdAt',
            })
        ])
        .then(([postResponse, morePostsResponse]) => {
            if (postResponse.items.length > 0) {
                setPost(postResponse.items[0]);
                
                // Handle more posts
                const pinnedPosts = morePostsResponse.items.filter(item => 
                    item.fields.pinBlog && item.fields.slug !== slug);
                const regularPosts = morePostsResponse.items.filter(item => 
                    !item.fields.pinBlog && item.fields.slug !== slug);
                setMorePosts([...pinnedPosts, ...regularPosts]);
            } else {
                setNotFound(true);
            }
        })
        .catch((error) => {
            console.error('Error fetching blog data:', error);
            setNotFound(true);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [slug]);

    if (notFound) return <Navigate to="/404" replace />;
    
    return (
        <div className="blog-post-container">
            <SEO
                title={post?.fields?.title || 'Loading...'}
                description={post?.fields?.description || ''}
                url={`/${slug}`}
                keywords={post?.fields?.title || ''}
            />
            <div className="main-content" style={{ minHeight: '800px' }}>
                {isLoading ? (
                    <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: '20px',
                        padding: '20px' 
                    }}>
                        <div className="skeleton-title" />
                        <div className="skeleton-image" />
                        <div className="skeleton-content" />
                    </div>
                ) : (
                    <>
                        <Typography className="title" variant="h1">{post.fields.title}</Typography>

                        {post.fields.youtubeLink ? (
                            <div style={{ minHeight: '315px' }}>
                                <iframe
                                    width="100%"
                                    height="315"
                                    src={post.fields.youtubeLink}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                />
                            </div>
                        ) : (
                            post.fields.heroImage && (
                                <div style={{ minHeight: '300px' }}>
                                    <img
                                        src={post.fields.heroImage.fields.file.url.includes('cloudinary')
                                            ? optimizedCloudinaryImage(post.fields.heroImage.fields.file.url)
                                            : post.fields.heroImage.fields.file.url
                                        }
                                        loading="lazy"
                                        alt={post.fields.title}
                                        className="post-image"
                                    />
                                </div>
                            )
                        )}

                        <div className="post-content">
                            {renderRichText(post.fields.body)}
                        </div>

                        {post.fields.tags && post.fields.tags.length > 0 && (
                            <Box mt={4}>
                                <Typography variant="h5">Tags:</Typography>
                                <Box display="flex" flexWrap="wrap" mt={2}>
                                    {post.fields.tags.map((tag, index) => (
                                        <Chip key={index} label={tag} color="primary" style={{ margin: '4px' }} />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        <AdUnit slot={'4626134585'} />
                    </>
                )}
            </div>

            <aside className="sidebar">
                <Typography variant="h5" gutterBottom>Popular Reads</Typography>
                {isLoading ? (
                    Array(5).fill(0).map((_, index) => (
                        <div key={index} className="skeleton-card" />
                    ))
                ) : (
                    morePosts.filter(morePost => morePost.fields.displayInLIst).map((morePost) => (
                        <Card key={morePost.sys.id} className="more-blog-card">
                            <Link to={`/${morePost.fields.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Box display="flex" alignItems="center">
                                    {morePost.fields.heroImage && morePost.fields.heroImage.fields && morePost.fields.heroImage.fields.file && (
                                        <img
                                            src={
                                                morePost.fields.heroImage.fields.file.url.includes('cloudinary')
                                                    ? optimizedCloudinaryImage(morePost.fields.heroImage.fields.file.url)
                                                    : morePost.fields.heroImage.fields.file.url
                                            }
                                            className="more-blog-image"
                                            alt={morePost.fields.title || 'Blog Image'}
                                        />
                                    )}
                                    <CardContent style={{ padding: 0, marginLeft: 16, display: 'flex', alignItems: 'center' }}>
                                        <p style={{ color: 'black' }}>
                                            {morePost.fields.title}
                                        </p>
                                        {morePost.fields.pinBlog && (
                                            <FaThumbtack style={{ color: 'red', marginLeft: '8px' }} />
                                        )}
                                    </CardContent>
                                </Box>
                            </Link>
                        </Card>
                    ))
                )}
            </aside>
        </div>
    );
};

export default BlogPost;