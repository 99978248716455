import React, { useEffect, useState } from "react";
import moment from "moment";
import { Modal, Card, Form, Alert, Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt } from "react-icons/fa";
import { displayAddress, generateLetterSVG } from "../../../utils/validations";
import RatingReadOnly from "../../RatingReadOnly";
import API from "../../../helpers/api";
import LoadingView from "../../LoadingView";
import BookService from "./BookService";
import './bookService.css'
import { optimizedCloudinaryImage } from "../../../utils/fileCompression";

const BookingModal = ({ show, handleClose, service, selectedDate }) => {

  let dateSelected = selectedDate ? moment(selectedDate).startOf('day').toDate() : '';
  let owner = (!service.associatedWithUser.is_seller_with_staff && service.associatedWithUser.associatedWithSeller && service.associatedWithUser.associatedWithSeller.length) ? false : true
  const navigate = useNavigate();
  const [ loader, setLoader ] = useState({ init: true, changeService: false, moreSchedules: false });
  const [ alert, setAlert ] = useState(null);
  const [ user, setUser ] = useState(null);
  const [ currentService, setCurrentService ] = useState(null);

  // const [schedules, setSchedules] = useState([]);
  const [organizedSchedules, setOrganizedSchedules] = useState([]);

  // const [ totalSchedules, setTotalSchedules ] = useState(0);
  const [ totalPages, setTotalPages ] = useState(0);
  
  const [openBookService, setOpenBookService ] = useState(false);
  const [ bookService, setBookService ] = useState(null);

  const [page, setPage] = useState(0);


  useEffect(() => {
    (async () => {
      await Promise.all([
        getProfileWithServicesAndSchedules(),
        fetchSchedules(page, service._id),
      ]);
      setLoader({ ...loader, init:false, moreSchedules:false})
    })();
  }, []);

  // useEffect(() => {
  //   if(page <= totalPages){
      
      
  //   }
  // }, [page])

  const handlePageChange = () => {
    let _page = page + 1;
    setPage(_page);
    setLoader({...loader, init:false, moreSchedules: true });
    fetchSchedules(_page, currentService)
  }

  const getProfileWithServicesAndSchedules = async () => {
    try {     
      const response = await API.apiGet('search', `/schedules/${service.associatedWithUser._id}`);
      if(response.data && response.data.success){
        setUser(response.data.results);
        setCurrentService(service._id);
      }
    } catch (error) {
      setLoader({ ...loader, init:false,  });
      setAlert({ variant: 'danger', message: error?.response?.data?.message || 'An unknown error occurred'});
    }
  }

  const fetchSchedules = async (_page, _serviceId) => {

    try {
      const response = await API.apiGetByKey('schedule', `/user/${service.associatedWithUser._id}/service/${_serviceId}`, {page:_page, date:dateSelected});
      setLoader({...loader, ['moreSchedules']: false }); // only moreSchedule loader is off
      if(response.data && response.data.success){
        // setSchedules(_page === 0 ? response.data.availableTimeSlots : [...schedules, ...response.data.availableTimeSlots]);
        setTotalPages(response.data.totalPages);
        // setTotalSchedules(response.data.totalCount);
        if(response.data.availableTimeSlots.length > 0){
          handleGrabSchedules(_page, response.data.availableTimeSlots)
        }else{
          setOrganizedSchedules([]);
        }
      }
    } catch (error) {
      setLoader({ ...loader, init:false, changeService:false, ['moreSchedules']: false });
      setAlert({ variant: 'danger', message: error?.response?.data?.message || 'An unknown error occurred'});
    }
  }

  const handleUpdateService = async (e) => {
      setCurrentService(e.target.value);
      setPage(0)
      // setSchedules([]);
      setTotalPages(0);
      setLoader({...loader, changeService:true });
      await fetchSchedules(0, e.target.value)
      setLoader({...loader, changeService:false });
  }

  const renderDateString = (start, end) => {
    let _date = ''
    if(moment(start).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ){
      _date = 'Today'
    }else if (moment(start).format('YYYY-MM-DD') === moment().add(1, 'days').format('YYYY-MM-DD')) {
      _date = "Tomorrow"
    }else if ((moment(start).format('YYYY-MM-DD') === moment(end).format('YYYY-MM-DD'))){
      _date = `${moment(start).format('ddd, MMM D, YYYY')}`
    }else{
      _date = `${moment(start).format('ddd, MMM D, YYYY')} - ${moment(end).format('ddd, MMM D, YYYY')}`
    }
    return _date 
  }

  const handleGrabSchedules = (_page, _schedules) => {
    let organized = Object.keys(_schedules.reduce((acc, scheduler) => {
      const date = renderDateString(scheduler.start, scheduler.end);
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(scheduler);
      return acc;
    }, {})).map(date => ({ date, schedules: _schedules.filter(s => renderDateString(s.start, s.end) === date) }));

    if (_page > 0) {
      setOrganizedSchedules([...organizedSchedules, ...organized]);
    } else {
      setOrganizedSchedules(organized);
    }
    // setOrganizedSchedules(![0, '0', null, 'null'].includes(_page) ? [ ...organizedSchedules, ...organized] : organized);
  }

  const handleOpenBookService = (schedule) => {
    setOpenBookService(true)
    setBookService({ 
      service: schedule.service,
      date: schedule.date,
      selectedStartTime: schedule.start,
      selectedEndTime: schedule.end,
    })
  }

  const handleCloseBookService = () => {
    setOpenBookService(false);
    setBookService(null)
  }

  const renderBookOpenServiceModal = () => {
    return (
      <Modal 
        className="booking-modal"
        show={openBookService}
        onHide={handleCloseBookService}
        centered
        size="xl"
        backdrop='static'
        style={{ 
          // maxWidth: '100%',  
          // maxHeight: '620px'
        }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ maxHeight:'100vh', maxWidth:'100vw'}}>
          <BookService 
              date={bookService.date}
              selectedStartTime={bookService.selectedStartTime}
              selectedEndTime={bookService.selectedEndTime}
              service={bookService.service}
              displayBanner={false}
          />
        </Modal.Body>
      </Modal>
    );
  };

  const renderAppointmentModal = () => {
    return (
      <Modal
        className="booking--modal"
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        // style={{ maxHeight: '620px'}}
    >
      <Modal.Header closeButton>
        <Modal.Title>Book an Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '620px'}}>
            {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <Card className="modal--service--business">
              <Card.Img 
                alt="..." 
                variant="top" 
                src={service.associatedWithUser.profilePic ? optimizedCloudinaryImage(service.associatedWithUser.profilePic.url) : generateLetterSVG(service.associatedWithUser.name)}/>
              <Card.Body>
                <Card.Title
                    className="cursor-pointer text-capitalize"
                    onClick={() => navigate(`/business/${service.associatedWithUser._id}`)}
                >
                    {owner && service.associatedWithUser.seller_personal_name ? service.associatedWithUser.seller_personal_name : service.associatedWithUser.name}
                </Card.Title>
                
                
                {(owner && service.associatedWithUser.seller_personal_name) && 
                  <p className='mb-0'>
                    <Card.Text 
                      className="mb-0"
                    >
                      {service.associatedWithUser.name}
                    </Card.Text>
                  </p>
                }

                <RatingReadOnly 
                  value={service.associatedWithUser?.ratings || 0} 
                  reviewsCount={true}
                  reviews={Array.from({ length: service.associatedWithUser?.reviewCount || 0 }, () => [])}
                  classNames={'mb-0'}
                />
                <Card.Text className="mt-0 mb-0">
                  <small>
                    <FaMapMarkerAlt className="mr-1" />  {displayAddress(service.associatedWithUser.address)}
                  </small>
                </Card.Text>
                
                {/* <p className="mb-0">
                  <small>
                    <RiMapPinRangeFill className="mr-1" /> Service Range 50 Miles
                  </small>
                </p> */}
              </Card.Body>
            </Card>

            { loader.init 
            ? <LoadingView size={28}/>
            : user 
              ? 
                <div> 
                    <h5 className="mt-4 mb-0">Scheduling details</h5>

                    <p>Your selections will help show the right availability</p>
                    
                    <Form.Control 
                      as="select" 
                      className="schedule-model-select"
                      value={currentService}
                      onChange={(e) => handleUpdateService(e)} 

                    >
                      {user.services.map((_service, index) => (
                          <option key={index} value={_service._id} data-index={index}>
                            {_service.category.name} | <strong>{`$${_service.amount}${_service.amount_type === 'hour' ? '/hour' : ' Flat'}`}</strong>
                          </option>
                      ))}
                    </Form.Control>
                    
                    {loader.changeService
                    ? <LoadingView size={24}/>
                    : <>
                    
                      <div className="sticky--app">
                        {organizedSchedules.length > 0 ?
                        <>
                          <h5 className="mt-0 mb-0">Available Slots</h5>
                          <p>Click a time to book your appointment.</p>
                        </>
                        : <p>No slots available You may check for other services</p>
                        
                        }
                      </div>
                        {organizedSchedules.map((_schedules, index) => {
                          return (
                            <div key={_schedules.date}>
                              <h6>{_schedules.date} </h6>
                              <div className="calendar--cal">
                                <ul>
                                  {_schedules.schedules.map((scheduler, index) => (
                                    <li 
                                      key={index}
                                      className={scheduler.disabled ? "disable" : ''}
                                      // onClick={() => navigate(`/book-appointments/${scheduler.service}?date=${momentFormat.dateFormat(scheduler.date)}?time=${moment(scheduler.start).format('h:mm A')}`)}
                                      onClick={() => scheduler.disabled ? null : handleOpenBookService(scheduler)}
                                    >
                                      {scheduler.service_type === 'service' ? 'Book' : moment(scheduler.start).format('h:mm A')}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )
                        })}
                        {(organizedSchedules.length > 0) && <Button 
                          onClick={() => loader.moreSchedules ? null : handlePageChange()} 
                          disabled ={(page + 1 >= totalPages) }
                          size="sm"
                          className="w-100"
                        >
                          {(page + 1 >= totalPages) 
                            ? 'No More Availability' 
                            : loader.moreSchedules
                              ? 'Loading...' 
                              : 'View More Availability'
                          } 
                        </Button>}
                    </>
                    }    
                </div>
              : 
                <Card.Text>
                  No Record Found
                </Card.Text>
            }
      </Modal.Body>
    </Modal>
    )
  }

  
  if(openBookService){
      return renderBookOpenServiceModal()
  }else{
      return renderAppointmentModal()
  }
};

export default BookingModal;
