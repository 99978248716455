import { 
    SHOW_LOGIN_MODAL,
    SHOW_NORMAL_SIGNUP,
    SHOW_SELLER_SIGNUP,
    SHOW_VERIFY_OTP,
    SHOW_RESET_PASS,
    SHOW_REQUEST_OTP,
    SHOW_AUTH_MODAL,
} from "../actions/types";

const initialState = {
    login: true,
    normalSignUp: false,
    sellerSignUp: false,
    verifyOtp: false,  
    resetPass: false,
    requestOtp: false,
    emailVerify: false,
    isSeller: false,
};

const authModalReducer = (state = initialState, action) => {
    switch (action.type){ 
        case SHOW_AUTH_MODAL: 
        return {
            // ...state,
            authModal: action.payload,
            login: true,
            normalSignUp: false,
            sellerSignUp: false,
            verifyOtp: false,  
            resetPass: false,
            requestOtp: false  
        }
        case SHOW_LOGIN_MODAL:
            return {
                // ...state,
                authModal: state.authModal,
                login: true,
                normalSignUp: false,
                sellerSignUp: false,
                verifyOtp: false,  
                resetPass: false,
                requestOtp: false    
            }
        
        case SHOW_NORMAL_SIGNUP:
            return {
                // ...state,
                authModal: state.authModal,
                login: false,
                normalSignUp: true,
                sellerSignUp: false,
                verifyOtp: false, 
                resetPass: false,
                requestOtp: false     
            }
        
        case SHOW_SELLER_SIGNUP: 
            return {
                // ...state,
                authModal: state.authModal,
                login: false,
                normalSignUp: false,
                sellerSignUp: true,
                verifyOtp: false,  
                resetPass: false  ,
                requestOtp: false 
            }
        case SHOW_VERIFY_OTP: 
            return {
                // ...state,
                authModal: state.authModal,
                login: false,
                normalSignUp: false,
                sellerSignUp: false,
                verifyOtp: true,  
                resetPass: false  ,
                requestOtp: false ,
                emailVerify: action.payload.emailVerify ? true : false,
                isSeller:  action.payload.isSeller ? true : false,
            }
        case SHOW_RESET_PASS:
            return {
                // ...state,
                authModal: state.authModal,
                login: false,
                normalSignUp: false,
                sellerSignUp: false,
                verifyOtp: false,
                resetPass: true ,
                requestOtp: false  
            }

        case SHOW_REQUEST_OTP: 
            return {
                authModal: state.authModal,
                login: false,
                normalSignUp: false,
                sellerSignUp: false,
                verifyOtp: false,
                resetPass: false,
                requestOtp: true ,
            }
        default: return state;
    }
};

export default authModalReducer;