const defaultPlaceholder = require('../assets/images/default__placeholder.png');

export const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;

          ctx.drawImage(img, 0, 0, img.width, img.height);

          // Compress the image quality as needed (0.1 is a low quality, 1 is the highest)
          canvas.toBlob((blob) => {
              const compressedFile = new File([blob], file.name, { type: 'image/jpeg' });
              resolve(compressedFile);
            },
            'image/jpeg',
            0.8
          );
        };
      };

      reader.readAsDataURL(file);
    });
};

export const optimizedCloudinaryImage = (url) => {

  if (!url) return defaultPlaceholder;

  // Check if the URL is from Cloudinary
  const isCloudinaryImage = url.includes("res.cloudinary.com");

  if (isCloudinaryImage) {
      // Insert 'f_auto,q_auto' transformations into the URL for optimized format and quality
      return url.replace("image/upload/", "image/upload/f_avif,q_auto:good/");
  }

  // If it's not a Cloudinary image, return the URL as is
  return url;  
}