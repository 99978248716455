import React, { useEffect, useState } from 'react';
import { Button, Form, FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
import { BsSend } from 'react-icons/bs';
import { FaRegTrashAlt, FaAngleLeft, FaBan } from "react-icons/fa";
import { IoCheckmarkDone } from "react-icons/io5";
import { MdOutlineDownloadForOffline } from "react-icons/md";
import { saveAs } from 'file-saver';
import { ImAttachment } from 'react-icons/im';
import { sendMessage } from '../../../redux/actions/socket.action';
import LoadingView from '../../LoadingView';
import { generateLetterSVG, momentFormat } from '../../../utils/validations';
import moment from 'moment';
// import NodeRSA from 'node-rsa';

import { getIcon } from '../../../utils/filesIcon';
import { toast } from 'react-toastify';
import { displayOwner } from '../../../utils/nameHelper';
import { uploadFilesToCloud } from '../../../redux/actions/cloudinary.action';
import { ThreeDots } from 'react-loader-spinner';
import { optimizedCloudinaryImage } from '../../../utils/fileCompression';
import API from '../../../helpers/api';
// const NodeRSA = require('node-rsa');
import JSEncrypt from 'jsencrypt';
import { useSelector } from 'react-redux';

const SingleChat = ({ userMeta, toggle, openChatRoom, chats, loading, currentUser, room, chatLoadMore, handleDeleteMessage, bottomRef, decryptMessage }) => {
    const check = ['', null, 'null', undefined, 'undefined']

    const [message, setMessage] = useState(null);
    const [disable, setDisable] = useState(true);
    const [files, setFiles] = useState([]);
    const [selectCaption, setSelectCaption] = useState(null);
    const [publicKey, setPublicKey ] = useState('');
    // const [privateKey, setPrivateKey] = useState('');

    const [uploadProgress, setUploadProgress] = useState(0);
    const [fileLoading, setFileLoading] = useState(false);
    
    
    

    // console.log( 'Room: ', room, 'Current User:', currentUser);

    const recipient = room?.users.find(user => user._id !== currentUser.aud);
    var dates = []

    const handleClearState = () => {
        setMessage(null);
        setFiles([]);
        setSelectCaption('');
    }

    useEffect(() => {
        if(recipient ){
            // console.log( 'recipient:-', recipient );
            handleGetReceiverPublicKey(recipient._id)

        }
    }, [recipient])



    const handleGetReceiverPublicKey =  async (receiverId) => {
        try {
            const response = await API.apiGet('rsa', `/public-key/${receiverId}/${currentUser.aud}`);

            if(response.data && response.data.success){
                setPublicKey(response.data.receiverPublicKey);
                // setPrivateKey(response.data.privateKey);
            }
        } catch (error) {
            console.log('Error in fetching: ', error);
        }
    }

    const handleSendMessage = (e) => {
        e.preventDefault();
        setFileLoading(true)

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey); // Set the public key retrieved from backend
        
        // console.log( 'encrypt::', encrypt );

        const encrypted = encrypt.encrypt(message);
        const encryptedMessage = encrypted ? `ENC_${encrypted}` : null;

        /** ---- Send Message ---- */
        if (files && files.length) {
            message.forEach((caption) => {

                sendMessage({ ...caption, message: encryptedMessage, roomId: room.roomId, sender: currentUser.aud });
            });
        } else {
            sendMessage({ message: encryptedMessage, roomId: room.roomId, sender: currentUser.aud });
        }

        /** --- Clear State --- */
        setFileLoading(false)
        handleClearState();
    }

    const handleRemoveFileBeforeSend = (index) => {
        // Check if files length is 1

        let indexToDelete = index
        let numberOfItemsToDelete = 1
        // setSelectCaption(null)
        let _files = files
        if (_files.length === 1) {
            _files.splice(0, numberOfItemsToDelete); // Delete the single item at index 0
        } else {
            _files.splice(indexToDelete, numberOfItemsToDelete); // Delete the item at the specified index
        }


        setFiles([..._files])
    }

    // console.log( selectCaption )

    useEffect(() => {
        setDisable((message || (files && files.length > 0)) ? false : true);
    }, [message, files])

    useEffect(() => {
        setMessage(
            (files && files.length)
                ?
                files.map(file => {
                    return {
                        file: file,
                        message: null,
                    }
                })
                :
                null
        );
    }, [files])

    useEffect(() => {
        if (!chatLoadMore) {
            // console.log('calling....')
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
            // scrollToBottom()
        }

    }, [chats, chatLoadMore])



    const renderDate = (chat, dateNum, key) => {
        dates.push(dateNum);
        const timestampDate = moment(chat.createdAt).calendar({
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: 'dddd',
            sameElse: 'DD/MM/YYYY'
        });
        // Add to Set so it does not render again
        // return <Text style={styles.dateText}>{timestampDate}</Text>
        return <div className='chat__date' key={`date-${key}`}><span>{timestampDate}</span></div>
    };

    const renderFile = (file) => {
        let url = file.url
        switch (file.file_type) {
            case 'csv':
                return <img alt="file icon" loading="lazy" src={'csv'} height={40} width={'auto'} />
            case 'txt':
                return <img alt="file icon" loading="lazy" src={'txt'} height={40} width={'auto'} />
            case 'jpeg':
            case 'jpg':
            case 'webp':
            case 'png':
            case 'gif':
            case 'bmp':
            case 'svg':
                return <img alt="file icon" loading="lazy" src={url} height={40} width={'auto'} />
            case 'heic':
                return <img alt="file icon" loading="lazy" src={'hiec'} height={40} width={'auto'} />

            case 'wav':
            case 'mpeg':
            case 'ogg':
            case 'mp3':
            case 'aac':
                return <audio src={url} controls autoPlay={false} />

            case 'mp4':
            case 'hevc':
                // case 'video/x-msvideo':
                // case 'video/quicktime':
                // case 'video/x-matroska':
                return <video src={url} controls autoPlay={false} height={40} width={30} />

            case 'pdf':
                return <img alt="file icon" loading="lazy" src={getIcon('pdf')} height={30} width={30} />
            case 'doc':
            case 'docx':
                return <img alt="file icon" loading="lazy" src={getIcon('word')} height={30} width={30} />
            case 'xls':
            case 'xlsx':
                return <img alt="file icon" loading="lazy" src={getIcon('xls')} height={30} width={30} />
            case 'ppt':
                return <img alt="file icon" loading="lazy" src={getIcon('ppt')} height={30} width={30} />
            case 'zip':
            case 'tar':
            case 'rar':
                return <img alt="file icon" loading="lazy" src={getIcon('zip')} height={30} width={30} />
            default:
                return <img alt="file icon" loading="lazy" src={getIcon('any')} height={30} width={30} />
        }
    }

    function downloadFile(fileUrl, filename) {
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                saveAs(blob, filename);
            })
            .catch(error => {
                console.error('Error downloading file:', error);
            });
    }

    // console.log( chats )
    const renderMessage = () => {
        return (
            <>
                {chats.map((chat, chatIndex) => {
                    let sender = (chat.sender === currentUser.aud) ? true : false
                    const dateNum = moment(chat.createdAt).format('L');
                    return (
                        <>
                            {dates.includes(dateNum) ? null : renderDate(chat, dateNum, chatIndex)}
                            <ListGroupItem
                                className={sender ? 'sender' : null}
                                key={chatIndex}
                                aria-details={sender}
                            >
                                <p>
                                    {chat.is_deleted
                                        ? <>
                                            <FaBan />
                                            {sender
                                                ? <i>You deleted this message</i>
                                                : <i>This message was deleted</i>
                                            }
                                        </>

                                        :
                                        <>
                                            {chat.file &&
                                                <div className='message__file'>
                                                    {renderFile(chat.file)}
                                                    <span className='file__download' onClick={() => downloadFile(chat.file.url, chat.file.name, chat.file.file_type)}><MdOutlineDownloadForOffline /></span>
                                                </div>
                                            }
                                            <pre className='format-message'>
                                                {decryptMessage(chat.message)}
                                            </pre>
                                            {/* <small>{chat.createdAt}</small> */}
                                            <small>
                                                <span>
                                                    {momentFormat.timeFormat(chat.createdAt)}
                                                </span>
                                                {sender && <span className='ml-1'><IoCheckmarkDone color={chat.is_read && '#2c98ff'} /></span>}
                                            </small>
                                            {sender &&
                                                <span className='message__actions'>
                                                    {/* <FaPencilAlt/> */}
                                                    <FaRegTrashAlt onClick={() => handleDeleteMessage(chat._id)} />
                                                </span>
                                            }
                                        </>
                                    }
                                </p>
                            </ListGroupItem>
                        </>
                    )
                })}

            </>
        )
    }

    const handleMessage = (value) => {

        
        if(userMeta && !userMeta.is_active) return;

        if (files && files.length) {
            const updatedCaptions = message.map((caption, index) => {
                if (index === selectCaption) {
                    return { ...caption, message: value };
                }
                return caption;
            });
            setMessage(updatedCaptions);
        } else {
            setMessage(value);
        }
    };

    // const uploadFile = (e) => {
    //     const _files = e.target.files
    //     const filePromises = [];

    //     for (let i = 0; i < _files.length; i++) {
    //         const file = _files[i];
    //         const reader = new FileReader();
    //         const promise = new Promise((resolve, reject) => {
    //             if( file.size > (1024 * 1024 * 1024) * 100){
    //                 reject(file.name + ' size should be less than 100GB' );
    //             }
    //             reader.onload = (e) => {
    //                 const newFile = {
    //                     fileType: file.type,
    //                     file: e.target.result,
    //                     name: file.name
    //                 }

    //                 resolve(newFile);
    //             };

    //             reader.onerror = (error) => {
    //                 reject(error);
    //             };

    //         });

    //         filePromises.push(promise);
    //         reader.readAsDataURL(file);
    //     }

    //     Promise.all(filePromises)
    //     .then((file) => {
    //         setFiles([ ...files, ...file]);
    //     })
    //     .catch((error) => {
    //         renderToast(error.message || error || 'Error occurred while uploading files', 'error')
    //         console.error(error);
    //     });
    // }

    const uploadFile = async (event, field) => {
        const _files = event.target.files;
        const formData = new FormData();
        let hasError = false;
        // console.log( files )
        for (let i = 0; i < _files.length; i++) {
            const file = _files[i];
            if (file.size > (1024 * 1024 * 1024) * 100) {
                renderToast(file.name + ' size should be less than 100gb', 'error')
                hasError = true; // Set hasError to true if there's an error
                break; // Exit the loop early if there's an error
            }
            // _files.push({ })
            formData.append('files', file);
        }

        if (!hasError && formData.has('files')) { // Proceed with upload only if there are no errors
            const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading);
            if (response && response.data && response.data.success) {
                setFiles([...files, ...response.data.files]);
            }
        }
    };

    const renderToast = (message, variant) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    return (
        <div className={`message__box ${toggle ? 'toggleChat' : null}`}>
            <div className="user__Status">
                <span className='back__arrow' onClick={() => openChatRoom(false)}><FaAngleLeft /></span>
                {recipient && 
                <span className="chat__uicon">
                    {(recipient.profilePic && recipient.profilePic.url) 
                    ?   <img 
                            loading="lazy" 
                            src={optimizedCloudinaryImage(recipient.profilePic.url) } 
                            alt="Profile" 
                        />
                    : generateLetterSVG(recipient?.name, 32)}

                </span>}
                <p>
                    <strong>{displayOwner(recipient)}</strong>
                </p>
            </div>
            <div className="message__list" >
                <ListGroup id="message__listgroup">
                    {loading.chatLoadMore && <div className='loadmore__message__loader'>Loading...</div>}
                    {loading.chat
                        ? <LoadingView />
                        : (chats && chats.length > 0)
                            ? renderMessage()
                            : null
                    }
                    <div ref={bottomRef}></div>
                </ListGroup>
            </div>
            <div className="type__message">
                <Form>
                    {(files && files.length > 0) &&
                        <div className='attached__files'>
                            {files.map((file, fileIndex) => (
                                <div className='attached__file' key={fileIndex}>
                                    <div onClick={() => setSelectCaption(fileIndex)} style={{ cursor: 'pointer' }}>
                                        <img loading="lazy" alt="msg icon" src={getIcon(file.file_type)} height={50} width={50} />
                                        <span className='attached__file__name'>{file.name}</span>
                                    </div>
                                    <FaRegTrashAlt onClick={() => handleRemoveFileBeforeSend(fileIndex)} />
                                </div>
                            ))}
                        </div>
                    }
                    {uploadProgress > 0 && <p className='text-center' >{uploadProgress === 100 ? 'Uploaded' : 'Uploading...'} {uploadProgress}%</p>}
                    <Form.Group>
                        <label 
                            htmlFor="attachFile" 
                            style={{ cursor: ( recipient?.isOutOfCountry ) ? 'not-allowed' : 'default'}}
                        >
                            <FormControl
                                type="file"
                                multiple
                                hidden
                                id="attachFile"
                                onChange={(e) => ( recipient?.isOutOfCountry ) ? null : uploadFile(e)}
                                disabled={fileLoading || ( recipient?.isOutOfCountry )}
                            />
                            <ImAttachment />
                        </label>

                        <Form.Control
                            as="textarea"
                            name="message"
                            placeholder={
                                files.length
                                    ? check.includes(selectCaption)
                                        ? `Click on file to add captions for each`
                                        : `Write a caption for ${files[selectCaption].name}`
                                    :
                                    fileLoading ?
                                        <ThreeDots
                                            height="25"
                                            width="25"
                                            radius="9"

                                            // color="#AED9E0"
                                            color="black"
                                            ariaLabel="three-dots-loading"
                                            visible={true}
                                        />
                                        :
                                        'Write a message...'
                            }
                            value={
                                files.length
                                    ? (!check.includes(selectCaption) && (message && message.length))
                                        ? message[selectCaption].message || ''
                                        : ``
                                    : message || ''
                            }
                            onChange={({ target }) => handleMessage(target.value)}
                            disabled={(((files && files.length) && check.includes(selectCaption))) || ( recipient?.isOutOfCountry )}
                            style={{ cursor: ( recipient?.isOutOfCountry ) ? 'not-allowed' : 'default'}}
                        />
                        <Button
                            variant="primary"
                            type='submit'
                            onClick={(e) => handleSendMessage(e)}
                            disabled={disable || fileLoading}
                            style={{ cursor: ( recipient?.isOutOfCountry ) ? 'not-allowed' : 'default'}}
                        >
                            <BsSend />
                        </Button>
                    </Form.Group>
                </Form>
            </div>
        </div>
    )
}

export default SingleChat;