import React from 'react';
import { FiInfo } from 'react-icons/fi';

const emojiMapping = {
    "Other Support Services": "🌟",
    "Childbirth Preparation Classes": "🍼",
    "Mental Health Support": "🧠",
    "Breastfeeding Support": "🤱",
    "Family/Infant Care and Services": "👶",
    "Fitness & Wellness": "🏃",
    "Birth Support": "🤰"
};

const CustomHomeCategoryServiceCard = ({ index, navigate, url, name, show = false }) => {
    return (
        <li
            key={index}
            className="category-card"
            onClick={() => navigate(url)}
        >
            {/* Replace image with emoji inside a paragraph */}
            <p style={{ fontSize: '35px', margin: '10px 0 0 0' }}>
                {emojiMapping[name] || "❓"} {/* Fallback emoji if name is not in mapping */}
            </p>
            <span>{name || 'N/A'}</span>
            {show && (
                <a
                    href={url}
                    className="bbservice__link"
                >
                    <FiInfo size={24} />
                </a>
            )}
        </li>
    );
};

export default CustomHomeCategoryServiceCard;