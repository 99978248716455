import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ImSpinner2 } from 'react-icons/im';
import './pagination.css'

const Pagination = ({
  totalPages,
  currentPage,
  onPageChange,
  siblingCount = 1,
  boundaryCount = 1,
  isLoading
}) => {

  const range = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, index) => index + start);
  };

  const createPaginationRange = () => {
    const totalPageNumbers = siblingCount + boundaryCount * 2 + 5;
    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > boundaryCount + 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - boundaryCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftRange = range(1, boundaryCount + 2 + siblingCount);
      return [...leftRange, '...', lastPageIndex];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightRange = range(totalPages - boundaryCount - 2 - siblingCount, totalPages);
      return [firstPageIndex, '...', ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
    }
  };

  const paginationRange = createPaginationRange();

  return (
    <ul className="pagination">
      <li
        className={`pagination-item ${currentPage === 1 || isLoading ? 'disabled' : ''}`}
        onClick={() => !isLoading && onPageChange(currentPage - 1)}
      >
        <FaChevronLeft />
      </li>
      {paginationRange.map((pageNumber, index) =>
        pageNumber === '...' ? (
          <li key={index} className="pagination-item dots">
            &#8230;
          </li>
        ) : (
          <li
            key={index}
            className={`pagination-item ${
                pageNumber === currentPage ? 'active' : ''
              } ${isLoading ? 'disabled' : ''}`}
            onClick={() => !isLoading && onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        )
      )}
      <li
         className={`pagination-item ${currentPage === totalPages || isLoading ? 'disabled' : ''}`}
        onClick={() => !isLoading && onPageChange(currentPage + 1)}
      >
        <FaChevronRight />
      </li>
      {isLoading && (
        <li className="pagination-item loader">
          <ImSpinner2 className="spinner" />
        </li>
      )}
    </ul>
  );
};

export default Pagination;