import React, { useEffect } from 'react';
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
// import {AiOutlineCloseCircle} from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { releaseLockedSlot } from '../../redux/actions/orders.action';
import '../../App.css';

function StripeCancel() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const searchQuery = new URLSearchParams(useLocation().search)
    const orderId = searchQuery.get('order_id');

    useEffect(() => {
        if(orderId){
            dispatch( releaseLockedSlot(orderId));
        }
    }, [])


    return (
        <div className='stripe-cancel-main-wraper'>
            <Container>
                <Row>
                    <Col sm={12} className='mb-5'>
                        {/* <AiOutlineCloseCircle /> */}
                        <h3>Payment Failed</h3>
                        <Alert key="danger" variant="danger">
                            We are sorry, there was an error processing your Payment #{orderId}.
                        </Alert>
                        <Button variant="secondary" onClick={() => navigate("/")}>Go Home Page</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StripeCancel