import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, ListGroup } from "react-bootstrap";
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from "react-router-dom";
import { notValid, emailValidation } from "../../../utils/validations";
import { useSelector, useDispatch } from "react-redux";
import { signInWithGoogle } from '../../../redux/actions/firebase.actions';
import { clearReduxState, login, showAuthModal, showNormalSignUp, showRequestOTP, showSellerSignUp } from '../../../redux/actions/auth.actions';
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner';
import CustomGoogleSignIn from "../../../commonComponents/CustomGoogleSignIn";
import AuthModal from "../AuthModal";

function SignInModelPage({ location, onHide }) {
    location = location || window.location;

    const navigate = useNavigate();
    const intialValue = { email: '', password: '' };
    const alertValue = { message: '', show: false, variant: '' };
    const dispatch = useDispatch();
    const [user, setUser] = useState(intialValue);
    const [error, setError] = useState(intialValue);
    const [loading, setLoading] = useState(false);

    const [showLogin, setLoginShow] = useState(false);

    const [isSellerSignupActive, setSellerSignupActive] = useState(null);
    const [isBuyerSignupActive, setBuyerSignupActive] = useState(null);

    const apiResult = useSelector(state => state.auth);
    const metaState = useSelector(state => state.meta);
    const showModal = useSelector(state => state.showModal);

    // useEffect(() => {
    //     return () => {
    //         dispatch(clearReduxState());
    //     }
    // }, []);

    useEffect(() => {
        if (showModal.authModal !== null || showModal.authModal !== undefined) {
            setLoginShow(showModal.authModal);
        }
    }, [showModal.authModal]);

    const handleRequestOtpModel = () => {
        dispatch(showAuthModal(true)); 
        dispatch(showRequestOTP())
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }

    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};

        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }

        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }

        return isValid;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            dispatch(login(user))
        }
    }

    useEffect(() => {
        if (apiResult && apiResult.loginError) {
            showToaster('error', apiResult.loginError.message || apiResult.loginError);
            setLoading(false)
            dispatch(clearReduxState());
        }

        // if (apiResult && apiResult.token) {
        //     showToaster('success', 'Successfully Authenticated')
        //     setLoading(false)
        //     if (location) {
        //         window.location.href = location.href
        //     } else {
        //         window.location.replace('/')
        //     }
        // }
    }, [apiResult])

    useEffect(() => {
        if (metaState.platform && metaState.platform.success) {
            if (metaState.platform.sellerSignup) {
                setSellerSignupActive(metaState.platform.sellerSignup.is_active)
            }

            if (metaState.platform.buyerSignup) {
                setBuyerSignupActive(metaState.platform.buyerSignup.is_active)
            }
        }
    }, [metaState])


    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        dispatch(signInWithGoogle());
    }

    const showToaster = (variant, message) => {
        toast.dismiss()
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }

    const handlenavigate = (url) => {
        if (onHide) onHide();

        navigate(url)
    }

    return (
        <Container className="py-5 login--Wrapper">
            <Row className="justify-content-center">
                <Col md={6} lg={5}>
                    <Form className="p-4 border rounded bg-light">
                        {
                        // process.env.NODE_ENV === 'development' && 
                        <>
                            {/* <Form.Group className="mb-3">
                                <ListGroup className="mb-3">
                                    <ListGroup.Item
                                        className="d-flex align-items-center justify-content-center py-2"
                                        onClick={handleGoogleSignIn}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <FcGoogle size={24} className="mr-2" /> Log In with Google
                                    </ListGroup.Item>
                                </ListGroup>
                            </Form.Group> */}
                            <CustomGoogleSignIn handleGoogleSignIn={handleGoogleSignIn}/>
                            <p className="text-center mb-3">OR</p>
                        </>
                        }
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="email"
                                placeholder="Email Address"
                                required
                                name="email"
                                value={user.email}
                                onChange={handleChangeInput}
                                className={error.email ? 'is-invalid' : ''}
                            />
                            {error.email && <div className="invalid-feedback">{error.email}</div>}
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={user.password || ''}
                                onChange={handleChangeInput}
                                className={error.password ? 'is-invalid' : ''}
                            />
                            {error.password && <div className="invalid-feedback">{error.password}</div>}
                        </Form.Group>
                        {/* <Form.Group className=" text-center">
                            <a
                                className="pointer"
                                onClick={handleRequestOtpModel}
                            >
                                Forgot password?
                            </a>
                        </Form.Group> */}
                        <Form.Group className="form--checkbox">
                                <a
                                    className="pointer"
                                    onClick={handleRequestOtpModel}
                                    
                                >
                                    Forgot password?
                                </a>
                            </Form.Group>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={loading}
                            onClick={onSubmit}
                            className="w-100 mb-3"
                            style={{ display: 'flex', justifyContent: 'center' }}
                        >
                            {loading ?
                                <ThreeDots
                                    height="25"
                                    width="25"
                                    radius="9"

                                    // color="#AED9E0"
                                    color="black"
                                    ariaLabel="three-dots-loading"
                                    visible={true}
                                />
                                :
                                'Login'
                            }
                        </Button>

                        {(isBuyerSignupActive || isSellerSignupActive) && (
                            <p className="text-center">
                                Don't have an account? Register as a
                                {isBuyerSignupActive && (
                                    <>
                                        <a
                                            className="pointer mx-1"
                                            onClick={() => handlenavigate('/family-signup')}
                                        >
                                            Family
                                        </a>
                                    </>
                                )}
                                {isBuyerSignupActive && isSellerSignupActive && <span>or</span>}
                                {isSellerSignupActive && (
                                    <>
                                        <a
                                            className="pointer mx-1"
                                            onClick={() => handlenavigate('/bridger-signup')}
                                        >
                                            Birth Professional
                                        </a>
                                    </>
                                )}
                            </p>
                        )}
                    </Form>
                </Col>
            </Row>
            {showLogin &&
                <AuthModal
                    show={showLogin}
                    onHide={() => dispatch(showAuthModal(false))}
                />
            } 
        </Container>
    );
}

export default SignInModelPage;