import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'

const RatingFeedback = ({loading, showReviewModal, feedback, handleCloseReviewModal, handleSubmitCompleteRequest, handleChangeFeedback}) => {
    return (
        <Modal
            show={showReviewModal}
            onHide={() => handleCloseReviewModal()}
            backdrop="static"
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>How was your experience ?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='review-modal'>
                    <h5>Share Your Feedback</h5>
                    <Rating
                        onClick={(e) => handleChangeFeedback(e, 'rating')}
                        ratingValue={feedback.rating || 0}
                        size={32}
                        // label
                        transition
                        fillColor='orange'
                        emptyColor='gray'
                        fillColorArray={['#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']} 
                        showTooltip
                        // tooltipArray={['Terrible', 'Bad', 'Average', 'Great', 'Prefect']}
                        // className='foo' // Will remove the inline style if applied
                    />
                    <h5 className='mt-3'>Write a Review ( Optional )</h5>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control 
                            as="textarea" 
                            rows={5}
                            onChange={(e) => handleChangeFeedback(e.target.value, 'review')}
                            value={feedback.review || ''} 
                        />
                    </Form.Group>
                    <Form.Check 
                        label={`Hide my name on public reviews list and show as anonymous?`}
                        checked={feedback.hideName}
                        onChange={(e) => handleChangeFeedback(e.target.checked, 'hideName')}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={() => handleCloseReviewModal()}
                >
                    Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={() => loading ? null : handleSubmitCompleteRequest()}
                    disabled={loading || feedback.rating < 1}
                >
                    {loading ? 'Sending...' : 'Submit Review'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default RatingFeedback;