import React from 'react';
// import { Card } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './termsAndConditions.css';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});

const TermsAndConditionsBirthProfessionals = () => (
    <div className="terms-conditions-container">
        <h1 className="center-text">Terms and Conditions for Birth Professionals</h1>
        <div className='terms-conditions-wrapper'>
            <p className="terms-conditions-content">
                <strong>1. Introduction</strong><br />
                Welcome to BirthBridge! These Terms and Conditions govern your use of our platform and services as a Birth Professional. By signing up and using BirthBridge, you agree to comply with these terms.
            </p>
            <p className="terms-conditions-content">
                <strong>2. Eligibility</strong><br />
                You must be a certified and licensed birth professional (e.g., doula, midwife, lactation consultant) to register and offer services on BirthBridge.
            </p>
            <p className="terms-conditions-content">
                <strong>3. Account Registration</strong><br />
                - Provide accurate, complete, and current information during registration.<br />
                - Maintain the security and confidentiality of your account credentials.<br />
                - Notify us immediately of any unauthorized use of your account.
            </p>
            <p className="terms-conditions-content">
                <strong>4. Professional Conduct</strong><br />
                - Offer services professionally and ethically.<br />
                - Maintain all necessary certifications and licenses.<br />
                - Comply with all applicable laws and regulations.<br />
                - Provide accurate information about your services, including availability and pricing.
            </p>
            <p className="terms-conditions-content">
                <strong>5. Payments</strong><br />
                - Payments for your services will be processed through Stripe.<br />
                - Ensure your Stripe account is active and linked to BirthBridge.<br />
                - Withdraw funds quarterly, or they will be automatically withdrawn on the 1st of every quarter.
            </p>
            <p className="terms-conditions-content">
                <strong>6. Cancellation and Refunds</strong><br />
                - Establish clear cancellation and refund policies for your services.<br />
                - Communicate these policies to clients prior to booking.
            </p>
            <p className="terms-conditions-content">
                <strong>7. Reviews and Ratings</strong><br />
                - Accept and respond to client reviews professionally.<br />
                - Do not engage in any activity to manipulate your reviews.
            </p>
            <p className="terms-conditions-content">
                <strong>8. Content and Intellectual Property</strong><br />
                - You retain ownership of any content you create and share on BirthBridge.<br />
                - Grant BirthBridge a non-exclusive license to use, display, and distribute your content on the platform.
            </p>
            <p className="terms-conditions-content">
                <strong>9. Liability and Indemnity</strong><br />
                - BirthBridge is not liable for any disputes or issues arising from your services.<br />
                - Indemnify BirthBridge against any claims, damages, or expenses arising from your use of the platform.
            </p>
            <p className="terms-conditions-content">
                <strong>10. Termination</strong><br />
                - BirthBridge may terminate or suspend your account if you violate these terms.<br />
                - You may terminate your account at any time by contacting BirthBridge support.
            </p>
            <p className="terms-conditions-content">
                <strong>11. Amendments</strong><br />
                - BirthBridge reserves the right to modify these terms at any time.<br />
                - We will notify you of any significant changes.
            </p>
        </div>
    </div>
);

export default function TermsAndConditionsBirthProfessionalsWrapper() {
    return (
        <ThemeProvider theme={theme}>
            <TermsAndConditionsBirthProfessionals />
        </ThemeProvider>
    );
}
