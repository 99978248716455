import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const listStyling = { marginBottom: '24px' }
const LandingPageAboutBirthBridge = () => {
    return (
        <section className="popular--Wrapper pt-0">
            <Container>
                <Row className="align-items-center">
                    {/* Left side Image */}
                    <Col xs={12} md={6} className="mb-4 mb-md-0">
                        <img
                            style={{ width: '100%', borderRadius: '10px' }}
                            src="https://res.cloudinary.com/fureverhomed/image/upload/f_auto,f_avif/v1730693343/mvtwnujb0ft2jy8lxqol.jpg"
                            alt="about birth bridge image"
                        />
                    </Col>

                    {/* Right side */}
                    <Col xs={12} md={6}>
                        <h2>Supporting Your Journey Every Step of the Way</h2>
                        <p style={{ marginBottom: 12 }}>
                            BirthBridge is designed to empower birth professionals and families alike. Our platform simplifies the process of connecting, communicating, and collaborating, so everyone can focus on what matters most—supporting families.
                        </p>

                        <ul>
                            <li style={listStyling}>
                                <strong> 📝 Blogs and Forums:</strong> Engage with a vibrant community by sharing your insights through blogs or connecting with families and professionals in our forums. Build relationships, exchange ideas, and grow together.
                            </li>
                            <li style={listStyling}>
                                <strong>🌟 Explore Trusted Professionals:</strong> Families can discover doulas, lactation consultants, mental health counselors, and many other birth specialists. Many professionals showcase their recommended networks, offering a full circle of support.
                            </li>
                            <li style={listStyling}>
                                <strong>📅 Seamless Scheduling:</strong> View availability, book appointments easily, and manage schedules efficiently through user-friendly tools designed with flexibility in mind.
                            </li>
                            <li style={listStyling}>
                                <strong>💬 Direct Communication:</strong> Built-in messaging allows families to connect directly with professionals, ask questions, and share their needs effortlessly.
                            </li>
                            <li style={listStyling}>
                                <strong>⭐ Verified Reviews:</strong> Professionals can display genuine client feedback, giving families confidence in their care choices.
                            </li>
                            <li style={listStyling}>
                                <strong>🔗 Professional Network Building:</strong> Birth professionals can share their curated networks of trusted collaborators, helping families build their ideal support team.
                            </li>
                            <li style={listStyling}>
                                <strong>💳 Secure Payments:</strong> Convenient payment options keep everything organized in one place, making transactions stress-free for professionals and families alike.
                            </li>
                        </ul>
                        {/* <p>
                            At BirthBridge, we’re more than a platform—we’re a community dedicated to simplifying the journey for birth professionals and the families they support. Let’s build something amazing together.
                        </p>
                        <p>
                            Have ideas or need help? Email us anytime at{' '}
                            <a href="mailto:hello@mybirthbridge.com">hello@mybirthbridge.com</a>.
                        </p> */}
                    </Col>
                </Row>
            </Container>
        </section>

    );
};

export default LandingPageAboutBirthBridge;
