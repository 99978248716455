import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import FileViewModal from '../FileViewer';
import PhotoViewer from '../PhotoViewer';
import PDFThumbnail from '../PDFThumbnail';
import { generateMonths } from '../../utils/validations';
const CustomCertificatesCard = ({ item }) => {
    const [openModal, setOpenModal] = useState(false);
    const months = generateMonths()

    const yearMonth = () => {
        return (
            <p className="certificate-description">
                {
                    (item.last_updated && item.last_updated.month) &&
                    <span className='mr-1'><strong>Month:</strong> {months[Number(item.last_updated.month) - 1]?.name || 'N/A'}</span>
                }
                {
                    (item.last_updated && item.last_updated.year) && <span><strong>Year:</strong> {item.last_updated.year}</span>
                }
            </p>
        )
    }
    if (item) {
        return (
            <div className="main-certificate-wraper">

                {item.file_type === "pdf"
                    ? <div className="thumbnail--div">
                        {item.url.startsWith('data:')
                            ? <PDFThumbnail pdfBase64={item.url} title={item.title || ''} />
                            : <PDFThumbnail pdfUrl={item.url} title={item.title || ''} />
                        }

                    </div>
                    : <div className="certificate-main">
                        <img
                            loading="lazy"
                            alt={`Custom birth professional certificates`}
                            src={item.url}
                        />
                    </div>
                }


                <div className="certificate-text">
                    <div className='mb-1 mt-1 mr-2 d-flex justify-content-between align-items-center'>
                        {item.title ? <h4 className="certificate-title">{item.title || ''}</h4> : yearMonth()}
                        {item.file_type !== 'pdf'
                            ? <PhotoViewer url={item.url} title={item.name}>
                                <Button>View</Button>
                            </PhotoViewer>
                            : <Button onClick={() => setOpenModal(true)}>View</Button>
                        }
                    </div>
                    {item.title && yearMonth()}

                </div>
                {openModal &&
                    <FileViewModal
                        show={openModal}
                        onHide={() => setOpenModal(false)}
                        type={item.file_type}
                        url={item.url}
                        title={item.title}
                    // description={item.description}
                    />
                }
            </div>
        )
    } else {
        return null;
    }
}

export default CustomCertificatesCard;