import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import AdminSidebar from './Sidebar/NavSidebar';

const SellersModule = ({ children}) => {
    return (
        <section className="popular--Wrapper edit--page">
            <Container fluid>
                <Row>
                    <Col sm={12} className="px-0">
                        <div className="sidebar--wrapper">
                            <AdminSidebar/>
                            <div className="admin--content">
                                {children}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SellersModule;