import {
    GET_USER_PROFILE_SUCCESS, 
    GET_USER_PROFILE_FAILED,
    PUT_USER_PROFILE_SUCCESS,
    PUT_USER_PROFILE_FAILED,
    GET_USER_META_SUCCESS,
    GET_USER_META_FAILED,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILED,
    GET_REFERRALS_LIST_SUCCESS,
    GET_REFERRALS_LIST_FAILED
} from "../actions/types";

const initialState = {
  user: null,
  error: null,
  userDetail: null,
  userPutDetail: null, 
  userMetaDetail: null,
  deleted: null,
};

const userReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_USER_PROFILE_SUCCESS :
            return {
                ...state,
                userDetail : action.payload,
                error: null,
            }
        case PUT_USER_PROFILE_SUCCESS : 
            return {
                ...state,
                userPutDetail : action.payload,
                error: null,
            }
        case PUT_USER_PROFILE_FAILED :
            return {
                ...state,
                error : action.payload,
            }
        case GET_USER_PROFILE_FAILED : 
            return {
                ...state,
                error : action.payload ,
            }
        case GET_USER_META_SUCCESS :
            return {
                ...state,
                userMetaDetail : action.payload,
                error : null
            }
        case GET_USER_META_FAILED : 
            return {
                ...state,
                error : action.payload,
            } 
        case DELETE_USER_SUCCESS:
            return {
                deleted: action.payload,
                deletedError: null
            } 
        case DELETE_USER_FAILED:
            return {
                deletedError: action.payload,
            }
        case UPDATE_PASSWORD_SUCCESS:
            return {
                passwordUpdated: action.payload,
                passwordUpdatedError: null
            }
        case UPDATE_PASSWORD_FAILED:
            return {
                passwordUpdatedError: action.payload
            }
        case GET_REFERRALS_LIST_SUCCESS:
            return {
                referrals: action.payload,
                error:null,
            }
        case GET_REFERRALS_LIST_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};

export default userReducer;