import { get } from 'lodash';
import API from '../../helpers/api';
// import * as auth from '../../helpers/auth';
// import jwtDecode from 'jwt-decode';

/**   import Seller types */
import {
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILED,
    PUT_SERVICES_SUCCESS,
    PUT_SERVICES_FAILED,
    GET_SINGLE_SERVICES_SUCCESS,
    GET_SINGLE_SERVICES_FAILED,
    GET_SERVICES_BB_SUCCESS,
    GET_SERVICES_BB_FAILED,
    GET_FILTER_SERVICES_FAILED,
    GET_FILTER_SERVICES_SUCCESS,
    RESET_REDUX_STATE,
} from './types' ;


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for services 
 * @returns 
*/

export const getAllServices = (staffId) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('services', staffId ? `?staffId=${staffId}` : null );
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SERVICES_SUCCESS, payload: response.data.services
            });
        } else {
            await dispatch({ type: GET_SERVICES_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SERVICES_FAILED);
      }
    }
}


export const updateSevices = (id,payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('services',`/edit/${id}`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: PUT_SERVICES_SUCCESS, payload: response.data.message });
            } else {
                await dispatch({ type: PUT_SERVICES_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, PUT_SERVICES_FAILED);
        }
    }
}


export const getFilterService = (query) => {
    return async (dispatch) => {
        try{
            const response = await API.apiGetByKey('services', `/filter`, query);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_FILTER_SERVICES_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_FILTER_SERVICES_FAILED, payload: response.data });
            }

        } catch (error) {
            errorRequest(error, dispatch, GET_FILTER_SERVICES_FAILED)
        }
    }
}


/**------------------------- */
export const getAllBBServices = (payload) => {
    return async (dispatch) => {
      try {
        let url = `/birth-bridge`
        if(payload){
            url += `?userId=${payload.userId}`
        }

        const response = await API.apiGet('services', url);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_SERVICES_BB_SUCCESS, payload: response.data.bbService});
        } else {
            await dispatch({ type: GET_SERVICES_BB_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_SERVICES_BB_FAILED);
      }
    }
}

export const getOneService = (serviceId) => {
    return async (dispatch) => {
        try{
            const response = await API.apiGet('services', `/${serviceId}`);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_SINGLE_SERVICES_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_SINGLE_SERVICES_FAILED, payload: response.data });
            }

        } catch (error) {
            errorRequest(error, dispatch, GET_SINGLE_SERVICES_FAILED)
        }
    }
}

export const resetReduxState = () => {
    return async dispatch => {
        dispatch({ type: RESET_REDUX_STATE });
    }
}