 const csv = require('../assets/icons/csv.png');
 const audio = require('../assets/icons/audio.png');
 const video = require('../assets/icons/video.png');
 const hiec = require('../assets/icons/hiec.png');
 const default_doc = require('../assets/icons/default.png');
 const image = require('../assets/icons/image.png');
 const pdf = require('../assets/icons/pdf.png');
 const txt = require('../assets/icons/txt.png');
 const xls = require('../assets/icons/xls.png');
 const word = require('../assets/icons/word.png');
 const default_profile_pic = require('../assets/images/default__profile.png')

export const getIcon = (type) => {
    switch (type.toLowerCase()){
        case 'csv': return csv;
        case 'audio':  return audio;
        case 'video': return video;
        case 'hiec':  return hiec;
        case 'image':  return image;
        case 'pdf': return pdf;
        case 'txt': return txt;
        case 'xls': return xls;
        case 'word':  return word;
        case 'profile': return default_profile_pic;
        default:  return default_doc;
    }

}