import {
    SWITCH_MESSENGER_TOGGLE,
    GET_CHAT_ROOMS_SUCCESS,
    GET_CHAT_ROOMS_FAILED,
    RECEIVED_ROOM_JOINED,
    GET_ROOM_CHATS_SUCCESS,
    GET_ROOM_CHATS_FAILED,
    RECEIVED_MESSAGE,
    DELETE_CHAT,
    RECEIVED_READ_STATUS,
    UNREAD_MESSAGE_COUNT,
} from "../actions/types";

const initialState = {
    switchToggle: false,
    error: null,
    rooms: [],
    roomJoined: null,
    chats:[],
    newMessage: null,
    unreadCount: null
};

const socketReducer = (state = initialState, action) => {
    switch (action.type){ 
        case SWITCH_MESSENGER_TOGGLE:
            return {
                rooms:state.rooms,
                switchToggle: action.payload
            }
        case GET_CHAT_ROOMS_SUCCESS:
            return {
                ...state,
                rooms: action.payload,
                newMessage: null,
                error: null
            }
        case GET_CHAT_ROOMS_FAILED:
            return {
                // ...state, 
                error: action.payload
            }
        case RECEIVED_ROOM_JOINED:
            if(state && state.rooms && state.rooms.rooms){
                let newRooms = [...state.rooms.rooms];
                let _existRoom = newRooms.find(room => room.roomId === action.payload.roomId)
                if(!_existRoom){
                    newRooms.push(action.payload);
                    state.rooms.rooms = newRooms
                }
            }
            return {
                ...state,
                roomJoined: action.payload,
            }
        case GET_ROOM_CHATS_SUCCESS:
            return {
                ...state,
                chats: action.payload,
                newMessage: null,
                error: null
            }
        case GET_ROOM_CHATS_FAILED:
            return {
                error: action.payload
            }
        case RECEIVED_MESSAGE:   
            if( action.payload.message && state.rooms && state.rooms.rooms){

                let _rooms = [...state.rooms.rooms]
                let _roomIndex = _rooms.findIndex(room => room.roomId === action.payload.message.roomId);
                if(_roomIndex !== -1){
                      // Update last_message and unreadMessageCount
                        _rooms[_roomIndex] = {
                            ..._rooms[_roomIndex],
                            last_message: action.payload.message,
                            unreadMessageCount: action.payload.room.unreadMessageCount,
                        };

                        // Remove the room from its current position and unshift it to the top
                        const [updatedRoom] = _rooms.splice(_roomIndex, 1);
                        _rooms.unshift(updatedRoom);
                }
                
                // _rooms[_roomIndex].last_message = action.payload.message
                // _rooms[_roomIndex].unreadMessageCount = action.payload.room.unreadMessageCount
                
                state.rooms = { ...state.rooms, rooms: _rooms}
            }

            return {
                ...state,
                newMessage: action.payload.message, // changed
                rooms: state.rooms,
                // chats: null
            }
        case RECEIVED_READ_STATUS:
            return {
                ...state,
                is_read_id: action.payload.messageId,
                room_id:action.payload.roomId
                
            }
        case DELETE_CHAT:
            return {
                rooms:state.rooms,
                deletedMessage: action.payload
            }
        case UNREAD_MESSAGE_COUNT:
            return {
                rooms:state.rooms,
                unreadCount: action.payload
            }
        default: return state;
    }
};

export default socketReducer;