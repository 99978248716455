import React, { useState, useEffect } from "react";
import { Col, Container, Button, Form, Row, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { emailValidation, formatPhoneNumber } from "../../utils/validations";
import timeZones from "../../utils/timeZones";
import API from '../../helpers/api';
import { logout } from "../../helpers/auth";
import { toast } from 'react-toastify';
import { MdEdit } from "react-icons/md";
import { deleteUserAccount, updateAccountPassword } from "../../redux/actions/user.action";
import { getPlaces, getPlaceDetails } from "../../redux/actions/places.action";
import LoadingView from "../LoadingView";

// import { getTimeZones } from "../../redux/actions/timeSlots.action";
// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './profileStyle.css';
// import '../../App.css';

function MyProfile() {
    const initialValue = {
        name: "",
        email: "", 
        phoneNumber: "",
        // alternatePhoneNumber: "", 
        gender: "",
        dob: '',
        // addressOne: '',
        // addressTwo: '',
        address: {
            street: '',
            landmark:'',
            city: '',
            state: '',
            country:'',
            postalCode: '',
            lat:'',
            lng:''
        },
        profilePic: null,
    }
    const dispatch = useDispatch();
    // const [dob, setDob] = useState({ month: '', day: '', year: '' });
    const [loading, setLoading] = useState(false);
    const [ loader, setLoader ]= useState({ init: true, deleteAccount: false, changePassword: false});
    // const [Active, setActive] = useState(false); //// use for onSubmit click then active 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userProfileDetails, setuserProfileDetails] = useState(null);
    
    // const [ timeZones, setTimeZones] = useState([]);

    const [ password, setPassword ] = useState({ current: '', new:'', confirm: ''});
    const [passwordError, setPasswordError] = useState('');
    
    const [locations, setLocations] = useState([]);
    const [showPlaces, setShowPlaces] = useState(false);
    const [ searchLocation, setSearchLocation] = useState('');

    const [showAddressFields, setShowAddressFields ] = useState(false)

    const [userDetails, setUserDetails] = useState({ ...initialValue});
    const [errors, setErrors] = useState({...initialValue});
    const [ deletedPassword, setDeletedPassword ] = useState('');

    const [showDelete, setDeleteShow] = useState(false);
    const handleDeleteClose = () => setDeleteShow(false);
    const handleDeleteShow = () => setDeleteShow(true);

    const userState = useSelector(state => state.user);
    const places = useSelector(state => state.places);
    const timeZoneState = useSelector(state => state.slots)


    useEffect(() => {
        fetchSingleServices()
    }, [])

    const fetchSingleServices = async () => {
        try {
            const response = await API.apiGet('user', `/details`);
            setuserProfileDetails(response.data.userDetails);
            setLoader({ init: false})
        } catch (error) {
            setLoader({ init: false})
        }
    }

    useEffect(() => {
        if (userProfileDetails !== undefined && userProfileDetails !== null) {
            setUserDetails({
                ...userDetails,
                name: userProfileDetails?.name,
                // last_name: userProfileDetails?.last_name,
                timeZone: userProfileDetails?.timeZone,
                email: userProfileDetails?.email,
                phoneNumber: userProfileDetails?.phoneNumber,
                // alternatePhoneNumber: userProfileDetails?.alternatePhoneNumber,
                gender: userProfileDetails?.gender,
                dob: userProfileDetails?.dob,
                address: userProfileDetails?.address,
                profilePic: userProfileDetails?.profilePic,
            });
        }
    }, [userProfileDetails])

    // useEffect(() => {
    //     if( !timeZoneState.error  && timeZoneState.timeZones && timeZoneState.timeZones.success){
    //       // console.log(timeZoneState)
    //       setTimeZones(timeZoneState.timeZones.timeZones);
    
    //     }
    //   }, [timeZoneState])
    

    useEffect(() => {
        if(userState.deletedError ){
            renderToast(userState.deletedError.message, 'error')
            setLoader({ deleteAccount: false})
        }


        if( !userState.deletedError ){
            if( userState.deleted && userState.deleted.success){
                renderToast(userState.deleted.message, 'success')
                setLoader({ deleteAccount: false})
                logout();
                
            }
        }

        if( userState.passwordUpdatedError){
            renderToast(userState.passwordUpdatedError.message, 'error')
            setLoader({ changePassword: false })
        }

        if(!userState.passwordUpdatedError){
           
            if( userState.passwordUpdated && userState.passwordUpdated.success){
                renderToast(userState.passwordUpdated.message, 'success')
                setLoader({ changePassword: false})
            }
        }

    }, [userState]);

    useEffect(() => {
        if(places.places && places.places.success && !places.error) {
            setLocations( places.places.suggestions )
        }
  
        if( places.placeDetail && places.placeDetail.success && !places.error){
            // console.log('place detail',  places.placeDetail )
            setUserDetails({ ...userDetails, address:places.placeDetail.details  });
        }
      }, [places])


      useEffect(() => {
        const timer = setTimeout(() => {
            if(searchLocation){
              setShowPlaces(true)
              dispatch(getPlaces(searchLocation));
            }else{
              setShowPlaces(false)
            }
        }, 500);
        
        return () => clearTimeout(timer);

      }, [searchLocation]);


    const renderToast = (message, variant) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleChange = (e, parameter, addressParam) => {
        let value = e.target.value;
        if ((parameter === 'alternatePhoneNumber' || parameter === 'phoneNumber') && value.trim() !== '') {
            value = formatPhoneNumber(value);
        }

        if( parameter === 'address' ){
            let address = {...userDetails.address}
            address[addressParam] = value
            setUserDetails({ ...userDetails, address });

            let _errAddress = { ...errors.address }
            _errAddress[addressParam] = ''
            setErrors({ ...errors, address: _errAddress});
        }else{
            setUserDetails({ ...userDetails, [parameter]: value });
            setErrors({ ...errors, [parameter]: '' });
        }
        
    };

    const handleFileChange = (event, field) => {
        setErrors({[field]:'' })
        const files = event.target.files;
        
        const filePromises = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            const promise = new Promise((resolve, reject) => {
                if( file.size > (1024 * 1024) * 10){
                    reject(file.name + ' size should be less than 10mb' );
                }
                reader.onload = (e) => {
                    const newPhoto = {
                        file_type: file.type,
                        url: e.target.result,
                    }
              
                    resolve(newPhoto);
                };
        
                reader.onerror = (error) => {
                    reject(error);
                };

            });

            filePromises.push(promise);
            reader.readAsDataURL(file);
        }
  
        Promise.all(filePromises)
        .then((newPhoto) => {
            if( field === 'profilePic'){
                setUserDetails((prevProfile) => ({
                    ...userDetails,
                    profilePic: newPhoto[0],
                }));
            }
        })
        .catch((error) => {
            renderToast(error.message || error || 'Error occurred while uploading files', 'error')
            // console.error(error);
        });
    };


    const handleValidation = () => {
        let fields = userDetails;
        let formIsValid = true;
        if (!fields.name) {
            formIsValid = false;
            errors.name = "Name is required";
        }
        // if (!fields.last_name) {
        //     formIsValid = false;
        //     errors.last_name = "Last name is required";
        // }
        if (!fields.email) {
            formIsValid = false;
            errors.email = "email is required";
        } else if (!emailValidation(fields.email)) {
            formIsValid = false;
            errors.email = "Please enter a valid email address";
        }
        // if (!fields.gender) {
        //     formIsValid = false;
        //     errors.gender = "Gender is required";
        // }
        // if (!fields.phoneNumber) {
        //     formIsValid = false;
        //     errors.phoneNumber = "Phone Number is required";
        // }
        // if (!fields.alternatePhoneNumber) {
        //     formIsValid = false;
        //     errors.alternatePhoneNumber = "Alternate Phone Number is required";
        // }


        // if (!fields.address.street) {
        //     formIsValid = false;
        //     errors.address.street = "Street is required";
        // }
        // // if (!fields.address.landmark) {
        // //     formIsValid = false;
        // //     errors.address.landmark = "Landmark is required";
        // // }
        // if (!fields.address.state) {
        //     formIsValid = false;
        //     errors.address.state = "State is required";
        // }
        // if (!fields.address.city) {
        //     formIsValid = false;
        //     errors.address.city = "City is required";
        // }

        // if (!fields.address.country) {
        //     formIsValid = false;
        //     errors.address.country = "Country is required";
        // }

        // if (!fields.address.postalCode) {
        //     formIsValid = false;
        //     errors.address.postalCode = "Postal Code is required";
        // }

        if (fields.address && typeof fields.address === 'object') {

            if (Object.values(fields.address).every(add => !add)) {
              formIsValid = false;
              errors.location = "Address is required";
            } else {
      
            //   if (!fields.address.street) {
            //     formIsValid = false;
            //     errors.address.street = "Street is required";
            //   }
              if (!fields.address.city) {
                formIsValid = false;
                errors.address.city = "City is required";
              }
              if (!fields.address.state) {
                formIsValid = false;
                errors.address.state = "State is required";
              }
              if (!fields.address.country) {
                formIsValid = false;
                errors.address.country = "Country is required";
              }
              if (!fields.address.postalCode) {
                formIsValid = false;
                errors.address.postalCode = "Postal Code is required";
              }
            }
          }

        if(!fields.profilePic){
            formIsValid = false;
            errors.profilePic ='Profile Picture is required';
        }

        setErrors({ ...errors });
        return formIsValid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (handleValidation()) {
            setLoading(true)
            try {
                const response = await API.apiPostUrl('user', `/edit/details`, userDetails);
                setLoading(false);
                
                if (response.data && response.data.success) {
                    renderToast( response.data.message, 'success')
                }else{
                    renderToast( response.data.message, 'error') 
                }
            } catch (error) {
                setLoading(false);
                renderToast(error.message || error?.response?.data?.message || 'Error occurred while updating', 'error')
            }
        }
    };

    const handleDeleteAccount = () => {
        setLoader({ deleteAccount: true })
        dispatch( deleteUserAccount() );
    }

    const handlePasswordChange = (e) => {
        const { value, name } = e.target;
        setPassword({ ...password, [name]: value});
        validatePassword(name, value)
    }

    const validatePassword = (fieldName, value) => {
        if ((fieldName === 'confirm' && value !== password.new ) || (fieldName === 'new' &&  value !== password.confirm)) {
          setPasswordError('New and Confirm Passwords do not match');
        } else if (fieldName === 'new' &&  value.length < 8) {
            setPasswordError('New Password must be at least 8 characters long');
        }else {
          setPasswordError('');
        }
      };

    const handleUpdatePassword = () => {
        setLoader({ changePassword: true });
        dispatch( updateAccountPassword({ password: password.new, currentPassword: password.current }) );
    }

    // const handleSelectPlace = (place) => {
    //     setShowPlaces(false)
    //     setSearchLocation('');
    //     dispatch(getPlaceDetails(place.place_id))
    //       // setSellerProfile({ ...SellerProfile, seller_business_address: `${place.name}, ${place.country}` })
    //   }

      const handleSelectPlace = (place) => {
        setShowPlaces(false)
        setSearchLocation('');
        setErrors({ ...errors, location: '' })
        dispatch(getPlaceDetails(place.place_id))
      }

    const isAddressBlank = (obj) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] !== null && obj[key].trim() !== '') {
                    return false; // Return false if any value is not null or blank
                }
            }
        }
        return true; // Return true if all values are null or blank
    }

    useEffect(() => {
        if(!isAddressBlank(userDetails.address)){
          setShowAddressFields(true)
        }else{
          setShowAddressFields(false)
        }
    }, [userDetails.address])


    return (
        <>
          
            <section className="profile--Wrapper main-profile-buyer mt-5">
                <Container>
                    {loader.init 
                    ?   <LoadingView loader="ThreeDots"/>
                    :
                        <Form>
                            <h3>My Profile</h3>
                            <Row className="mt-5"> 
                                <Col sm={6}>
                                    <Form.Group className="form-group col-sm-12 mb-0">
                                        <div className="buyer-profile-pic">
                                            <div className="photo--grid profile-photo-staff">
                                                <label for="upload--file">
                                                    <img loading="lazy" src={(userDetails.profilePic && userDetails.profilePic.url) ? userDetails.profilePic.url : "images/profile.png "} alt="profile" />
                                                    <span className="edit--img"><MdEdit/></span>
                                                </label>
                                                <input type="file" id="upload--file" hidden onChange={(e) => handleFileChange(e, 'profilePic')} />
                                            </div>
                                        </div>
                                        {(errors && errors?.profilePic) &&  <span className="error error-message">{errors.profilePic}</span>}
                                    </Form.Group>
                                    <div className="buyer-profile-text">
                                        <p className="profile-photo-text">Profile Photo:</p>
                                        <p>Your profile photo shows when you book an appointment to allow<br/> the business to better serve you.</p>
                                    </div>
                                    <Col sm={12}>
                                        <hr />
                                        <h4>Account Management</h4>
                                        <a onClick={handleShow} className="text-info mr-3 pointer">Change Password</a>
                                        <a onClick={handleDeleteShow} className="text-warning pointer">Delete Account</a>
                                    </Col>
                                </Col>
                                
                                <Col sm={6}>
                                    <div className="form-group col-sm-12 col-md-12">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter name"
                                            value={userDetails?.name || ""}
                                            onChange={(e) => handleChange(e, 'name')}
                                        />
                                        {(errors && errors?.name) &&  <span className="error error-message">{errors.name}</span>}
                                    </div>
                                    
                                    <div className="form-group col-sm-12 col-md-12">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control 
                                            type="email"
                                            placeholder="Enter E-Mail"
                                            value={userDetails?.email || ""}
                                            onChange={(e) => handleChange(e, 'email')}
                                        />
                                         {(errors && errors?.email) &&  <span className="error error-message">{errors.email}</span>}
                                    </div>
                                    {/* <div className="col-sm-12 col-md-6 form-group">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control type="text" placeholder="Create a username" value="jeff_Marcos" />
                                </div> */}
                                    <div className="form-group col-sm-12 col-md-12">
                                        <Form.Label>Gender</Form.Label>
                                        <Form.Control as="select" value={userDetails?.gender} onChange={(e) => handleChange(e, 'gender')}>
                                            <option selected>Select Gender (Optional)</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </Form.Control>
                                        {(errors && errors?.gender) &&  <span className="error error-message">{errors.gender}</span>}
                                    </div>

                                    <div className="form-group col-sm-12 col-md-12 mb-3">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            size="sm"
                                            value={searchLocation}
                                            onChange={(e) => setSearchLocation(e.target.value)}
                                            placeholder="Search Your Location..." 
                                        />
                                        {(errors && errors.location) && <span className="error-msg">{errors.location}</span>}
                                        {(showPlaces && locations.length > 0) && 
                                        <div className="places--list">
                                            {locations.map((suggestion, pIdx) => (
                                                <span 
                                                    key={pIdx}
                                                    onClick={() => handleSelectPlace(suggestion)}
                                                >
                                                    {suggestion.description}  
                                                </span>
                                            ))}
                                        </div>}
                                    </div>

                                 {  showAddressFields && 
                                    <div className="form-group col-sm-12 col-md-12">
                                        <Form.Control 
                                            type="text"
                                            placeholder="Street"
                                            value={userDetails?.address?.street || ""}
                                            name="address.street"
                                            onChange={(e) => handleChange(e, 'address', 'street')}
                                            // className="mb-2"
                                        />
                                         
                                         <Form.Control 
                                            type="text"
                                            placeholder="Landmark ( Optional )"
                                            name="address.landmark"
                                            value={userDetails?.address?.landmark || ""}
                                            onChange={(e) => handleChange(e, 'address', 'landmark')}
                                            className="mt-2"
                                        />
                                         {/* {(errors && errors?.landmark) &&  <span className="error error-message">{errors.landmark}</span>} */}
                                        
                                        <Form.Control 
                                            type="text"
                                            placeholder="City"
                                            name="address.city"
                                            value={userDetails?.address?.city || ""}
                                            onChange={(e) => handleChange(e, 'address', 'city')}
                                            className="mt-2"
                                        />
                                          {(errors && errors?.address?.city) &&  <span className="error error-message">{errors.address?.city}</span>}
                                         <Form.Control 
                                            type="text"
                                            placeholder="State / Province"
                                            name="address.state"
                                            value={userDetails?.address?.state || ""}
                                            onChange={(e) => handleChange(e, 'address', 'state')}
                                            className="mt-2"
                                        />
                                         {(errors && errors?.address?.state) &&  <span className="error error-message">{errors.address.state}</span>}
                                         <Form.Control 
                                            type="text"
                                            placeholder="Country"
                                            name="address.country"
                                            value={userDetails?.address?.country || ""}
                                            onChange={(e) => handleChange(e, 'address', 'country')}
                                            className="mt-2"
                                        />
                                         {(errors && errors?.address?.country) &&  <span className="error error-message">{errors.address.country}</span>}
                                        
                                        <Form.Control 
                                            type="text"
                                            placeholder="Zip Code"
                                            name="address.postalCode"
                                            value={userDetails?.address?.postalCode || ""}
                                            onChange={(e) => handleChange(e, 'address', 'postalCode')}
                                            className="mt-2"
                                        />
                                        {(errors && errors?.address?.postalCode) &&  <span className="error error-message">{errors.address.postalCode}</span>}
                                        
                                    </div>}
                                   
                                    {/* <div className="form-group col-sm-12 col-md-12 mt-2">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text"
                                            placeholder="Enter your mobile phone"
                                            value={userDetails?.phoneNumber || ""}
                                            onChange={(e) => handleChange(e, 'phoneNumber')}
                                        />
                                        {errors && errors?.phoneNumber && <span className="error error-message">{errors?.phoneNumber}</span>}
                                    </div> */}
                                    {/* <div className="col-sm-12 col-md-12">
                                        <Form.Label>Alternate Phone Number</Form.Label>
                                        <Form.Control type="text"
                                            placeholder="Enter your alternate phone number"
                                            value={userDetails?.alternatePhoneNumber || ""}
                                            onChange={(e) => handleChange(e, 'alternatePhoneNumber')}
                                        />
                                        <span className="error error-message">{errors && errors?.alternatePhoneNumber}</span>
                                    </div> */}
                                    <Form.Group className="col-sm-12">
                                       
                                    </Form.Group>
                                    <div className="form-group col-sm-12 col-md-12">
                                    <Form.Label>Preferred Timezone For Emails</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={(e) => handleChange(e, 'timeZone')}
                                            value={userDetails?.timeZone}
                                            name="timeZone"
                                        >
                                            {
                                                timeZones.map((timezone, index) => (
                                                <option key={index} value={timezone.Description}>
                                                    {timezone.Description.concat(' ' + timezone.Location)}
                                                </option>
                                                ))
                                            }
                                        </Form.Control>
                                    </div>
                                </Col>
                                
                               
                                <Col sm={12} className="text-right">
                                    <hr />
                                    <Button variant="primary" type="submit" disabled={loading} onClick={handleSubmit}>
                                        {loading ? 'Please Wait...' : 'Save Changes'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    }
                </Container>
            </section>

            {/* /Change Password Modal/ */}
            <Modal className="password--Modal" show={show} onHide={handleClose} centered>
                <Modal.Header>
                    <Modal.Title>Change Password
                        <Button onClick={handleClose}>&times;</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="form-group">
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter current password"
                                value={password.current}
                                onChange={(e) => handlePasswordChange(e)}
                                name="current" 
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter new password" 
                                value={password.new}
                                name="new"
                                onChange={(e) => handlePasswordChange(e)}
                            />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="Confirm new password"
                                name="confirm"
                                onChange={(e) => handlePasswordChange(e)}
                                value={password.confirm} 
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="primary" 
                        onClick={(e) => handleUpdatePassword(e)}
                        type="submit"
                        disabled={ !(password.current || password.new || password.confirm) || loader.changePassword}
                    >
                       {loader.changePassword ? 'Updating...': 'Update'}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* /Delete Account Modal/ */}
            <Modal className="Delete--Modal" show={showDelete} onHide={handleDeleteClose} centered>
                <Modal.Header>
                    <Modal.Title>Delete Account
                        <Button onClick={handleDeleteClose}>&times;</Button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this account? The profile and account history associated with this account will no longer be available to access. This action cannot be undone.</p>
                    <Form>
                        <Form.Group className="form-group">
                            <Form.Label>Enter Password</Form.Label>
                            <Form.Control 
                                type="password" 
                                placeholder="Enter current password"
                                value= {deletedPassword}
                                onChange={(e) => setDeletedPassword(e.target.value)}
                            />  
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Cancel
                    </Button>
                    <Button 
                        variant="outline-danger"
                        onClick={() => handleDeleteAccount()}
                        disabled={!deletedPassword || loader.deleteAccount}
                    >
                        {loader.deleteAccount ? 'Validating...' : 'Validate & Delete Account'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default MyProfile;