import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Avatar,
    Button,
    TextField,
    Stack,
    CircularProgress,
    Tooltip,
    IconButton,
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider,
    Chip,
    Menu,
    MenuItem,
    FormControl,
    InputLabel,
    Select
} from '@mui/material';
import { FiHeart, FiMessageSquare, FiArrowLeft, FiEdit2, FiTrash2, FiMoreVertical } from 'react-icons/fi';
import { styled } from '@mui/material/styles';
import { communityService } from '../../services/communityService';
import { formatDate } from '../../utils/dateUtils';
import { initializeAds, refreshAds } from '../../utils/adUtils';
import { getUserProfile } from "../../redux/actions/user.action";
import { useDispatch, useSelector } from "react-redux";
import { FaCheckCircle, FaExternalLinkAlt } from 'react-icons/fa';
import { alpha } from '@mui/material/styles';
import SEO from "../../seo";
import { CATEGORIES_FILTER, CATEGORY_STYLES } from './postsUtils';
import * as auth from '../../helpers/auth';


const AD_SLOTS = {
    LEFT_SIDEBAR: '2920797521',
    RIGHT_SIDEBAR: '6486011166'
};

// Create a separate AdUnit component
const AdUnit = ({ slot }) => {
    const [adError, setAdError] = useState(false);
    const componentId = `ad-${slot}-${Math.random().toString(36).substr(2, 9)}`;
    const containerRef = useRef(null); // Add ref for container

    useEffect(() => {
        console.log(`[AdSense][${componentId}] Component mounted`);
        console.log(`[AdSense][${componentId}] Environment:`, process.env.NODE_ENV);
        
        const initializeAd = () => {
            console.log(`[AdSense][${componentId}] Starting initialization...`);
            
            if (process.env.NODE_ENV !== 'production') {
                console.log(`[AdSense][${componentId}] Skipping - development environment`);
                return;
            }

            if (!window.adsbygoogle) {
                console.error(`[AdSense][${componentId}] Error: AdSense script not loaded`);
                setAdError(true);
                return;
            }

            try {
                // Check container dimensions
                if (containerRef.current) {
                    const { offsetWidth } = containerRef.current;
                    console.log(`[AdSense][${componentId}] Container width:`, offsetWidth);
                    
                    if (offsetWidth === 0) {
                        console.log(`[AdSense][${componentId}] Waiting for container to have width...`);
                        return; // Exit and wait for next render when width is available
                    }
                }

                const adElement = containerRef.current?.querySelector('.adsbygoogle');
                console.log(`[AdSense][${componentId}] Ad element found:`, !!adElement);
                
                if (!adElement) {
                    console.error(`[AdSense][${componentId}] Error: Ad element not found in DOM`);
                    return;
                }

                if (adElement.hasAttribute('data-adsbygoogle-initialized')) {
                    console.log(`[AdSense][${componentId}] Ad already initialized, skipping`);
                    return;
                }

                console.log(`[AdSense][${componentId}] Pushing ad configuration:`, {
                    slot,
                    format: 'auto',
                    responsive: true
                });

                (window.adsbygoogle = window.adsbygoogle || []).push({});
                adElement.setAttribute('data-adsbygoogle-initialized', 'true');
                
                console.log(`[AdSense][${componentId}] Ad push successful`);

            } catch (error) {
                console.error(`[AdSense][${componentId}] Initialization error:`, {
                    error: error.message,
                    stack: error.stack,
                    slot,
                    componentId
                });
                setAdError(true);
            }
        };

        // Initial attempt
        const timeoutId = setTimeout(() => {
            console.log(`[AdSense][${componentId}] Initial initialization attempt`);
            initializeAd();
        }, 250); // Increased delay

        // Retry mechanism
        const retryId = setInterval(() => {
            if (containerRef.current?.offsetWidth > 0) {
                console.log(`[AdSense][${componentId}] Retrying initialization`);
                initializeAd();
            }
        }, 1000);

        return () => {
            console.log(`[AdSense][${componentId}] Component unmounting`);
            clearTimeout(timeoutId);
            clearInterval(retryId);
        };
    }, [slot, componentId]);

    if (process.env.NODE_ENV !== 'production') {
        return (
            <Box 
                ref={containerRef}
                sx={{ 
                    width: '100%', 
                    height: '600px', 
                    bgcolor: '#f0f0f0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography>Ad Placeholder (DEV)</Typography>
            </Box>
        );
    }

    if (adError) {
        return null;
    }

    return (
        <Box 
            ref={containerRef}
            sx={{ 
                width: '100%',
                height: '600px',
                position: 'relative'
            }}
        >
            <ins
                className="adsbygoogle"
                style={{ 
                    display: 'block', 
                    width: '100%', 
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0
                }}
                data-ad-client="ca-pub-8268178276012102"
                data-ad-slot={slot}
                data-ad-format="auto"
                data-full-width-responsive="true"
                data-component-id={componentId}
            />
        </Box>
    );
};

export default AdUnit; 