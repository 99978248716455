import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import LoadingView from '../LoadingView';
import { useNavigate } from 'react-router-dom';
import { getAllBBServices } from '../../redux/actions/services.action';
import CustomHomeCategoryServiceCard from '../../commonComponents/CustomHomeCategoryServiceCard';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
import { Card, CardContent, CardMedia, Typography, Chip, Box, Pagination } from '@mui/material';
import './styles/BlogList.css';
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";
import { optimizedCloudinaryImage } from '../../utils/fileCompression';
import { FaThumbtack } from 'react-icons/fa';

const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
});



const LandingPageBlogs = () => {


    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 6;

    const nav = useNavigate();
    const handleNav = () => {
        nav('/search')
    }

    useEffect(() => {
        client.getEntries({ content_type: 'blogPost' })
            .then((response) => {
                // Separate pinned and regular posts
                const pinnedPosts = response.items.filter(post => post.fields.pinBlog);
                const regularPosts = response.items.filter(post => !post.fields.pinBlog);

                // Concatenate pinned posts at the start of the regular posts
                const orderedPosts = [...pinnedPosts, ...regularPosts];

                setPosts(orderedPosts);
            })
            .catch(console.error);
    }, []);

    const displayedPosts = posts
        .filter(post => post.fields.displayInLIst)
        .slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

    return (
        <section className="blog-list-section">
            <Container>
                <h2 className="title">Latest Blogs</h2>
                <Row className="g-4">
                    {displayedPosts.map((post) => (
                        <Col xs={12} md={6} style={{ marginBottom: '24px' }} key={post.sys.id}>
                            <Card style={{ height: '100%' }} className="blog-card2">
                                {post.fields.heroImage && (
                                    <div className="blog-image-container2">
                                        <img
                                            src={
                                                post.fields.heroImage.fields.file.url.includes('cloudinary')
                                                    ? optimizedCloudinaryImage(post.fields.heroImage.fields.file.url)
                                                    : post.fields.heroImage.fields.file.url
                                            }
                                            alt={post.fields.title}
                                            className="blog-image2"
                                        />
                                    </div>
                                )}
                                <CardContent className="content-container">
                                    <Typography
                                        style={{ marginTop: '24px', marginBottom: '24px' }}
                                        variant="h5"
                                        component="div"
                                        className="blog-title"
                                    >
                                        <Link to={`/${post.fields.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            {post.fields.title}
                                        </Link>
                                    </Typography>
                                    <Typography
                                        style={{ marginBottom: '24px' }}
                                        variant="body2"
                                        color="text.secondary"
                                        className="blog-description"
                                    >
                                        {post.fields.description.content[0].content[0].value}
                                    </Typography>
                                    {post.fields.tags && post.fields.tags.length > 0 && (
                                        <Box mt={2} className="blog-tags">
                                            {post.fields.tags.map((tag, index) => (
                                                <Chip key={index} label={tag} className="blog-chip" />
                                            ))}
                                        </Box>
                                    )}
                                </CardContent>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>


        </section>


    );
};

export default LandingPageBlogs;