import { get } from 'lodash';
import { auth } from '../../utils/firebase';
import * as _auth from '../../helpers/auth';
import API from '../../helpers/api';
import { LOGIN_SUCCESS, LOGIN_FAILED  } from './types';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const provider = new GoogleAuthProvider();
provider.addScope('profile');
provider.addScope('email');
// provider.addScope('')

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

const errorCodes = ['auth/popup-closed-by-user']; 

export const signInWithGoogle = () => {
    return async dispatch => {
        try {
            const googleResult = await signInWithPopup(auth, provider);
            var _user = {
                name: googleResult._tokenResponse.fullName,
                email:  googleResult._tokenResponse.email,
                profilePic: googleResult._tokenResponse.photoUrl,
                uid: googleResult.user.uid,
                signInProvider: 'google'
            }
            const response = await API.apiPostUrl('auth', '/social-login', _user);
            
          /** -- If Response Success -- */
            if(response.data.accessToken){
            /** -- If Response with token and unpaidSubscription -- */
                _auth.login(response.data.accessToken);
                window.location.replace('/')
              await dispatch({ type: LOGIN_SUCCESS, payload: response.data.accessToken });
            }else{
              await dispatch({ type: LOGIN_FAILED, payload: response.data.message });
            }
        } catch (error) {
            console.log('[Error Code]:', error.code);
            if(!errorCodes.includes(error.code) ){
                errorRequest(error, dispatch, LOGIN_FAILED);
            }
        }
    }
}


