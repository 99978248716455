import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Chip, Typography, Box } from '@mui/material';
import './styles/BlogPost.css'; // Import the CSS file
import SEO from "../../seo";


const renderRichText = (document) => {
    const options = {
        renderNode: {
            'paragraph': (node, children) => <Typography paragraph>{children}</Typography>,
            'hyperlink': (node, children) => (
                <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            ),
        },
    };

    return documentToReactComponents(document, options);
};

const StripeLegalDocCRM = (props) => {

    return (
        <div className="blog-post">
            <SEO
                title={`${props.post.fields.title}`}
                description={`${props.post.fields.description}`}
                url={`/${props.post.fields.slug}`}
                keywords={`${props.post.fields.title}`}
            />
            <Typography className="title" variant="h1">{props.post.fields.title}</Typography>
            <p style={{textAlign:'center'}}>
                Ensure you've read and accepted the terms before linking your Stripe Bank account to be able to withdraw your funds.
            </p>

            {props.post.fields.youtubeLink ? <iframe width="100%" height="315" src={props.post.fields.youtubeLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> : props.post.fields.heroImage && (
                <img
                loading="lazy"
                    src={props.post.fields.heroImage.fields.file.url}
                    alt={props.post.fields.title}
                    className="Stripe legal document crm"
                />
            )}



            <div className="post-content">
                {renderRichText(props.post.fields.body)}
            </div>

        </div>
    );
};

export default StripeLegalDocCRM;
