import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, ListGroup } from "react-bootstrap";
// import { AiFillApple } from 'react-icons/ai';
// import { BsFacebook } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from "react-router-dom";
// import { AiOutlinePlus } from 'react-icons/ai';
import { notValid, emailValidation } from "../../../utils/validations";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signInWithGoogle } from '../../../redux/actions/firebase.actions';
import { clearReduxState, login, showRequestOTP } from '../../../redux/actions/auth.actions';
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner';
import CustomGoogleSignIn from "../../../commonComponents/CustomGoogleSignIn";

function SignInModel({ location, onHide }) {
    const navigate = useNavigate();
    const intialValue = { email: '', password: '' };
    const alertValue = { message: '', show: false, variant: '' };
    const dispatch = useDispatch();
    // const[alert , setAlert] = useState(alertValue)
    const [user, setUser] = useState(intialValue);
    const [error, setError] = useState(intialValue);
    const [loading, setLoading] = useState(false);

    const [isSellerSignupActive, setSellerSignupActive] = useState(null);
    const [isBuyerSignupActive, setBuyerSignupActive] = useState(null);

    const apiResult = useSelector(state => state.auth);
    const metaState = useSelector(state => state.meta);

    // useEffect(() => {
    //     return () => {
    //         dispatch(clearReduxState());
    //     }
    // }, []);


    const handleRequestOtpModel = () => {
        
        dispatch(showRequestOTP())
    }

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }

    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};

        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }

        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }

        return isValid;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            dispatch(login(user))
        }
    }

    useEffect(() => {
        if (apiResult && apiResult.loginError) {
            showToaster('error', apiResult.loginError.message || apiResult.loginError);
            setLoading(false)
            dispatch(clearReduxState());
        }

        // if (apiResult && apiResult.token) {
        //     showToaster('success', 'Successfully Authenticated')
        //     setLoading(false)
        //     if (location) {
        //         window.location.href = location.href
        //     } else {
        //         window.location.replace('/')
        //     }
        // }

       
    }, [apiResult])

    useEffect(() => {
        if (metaState.platform && metaState.platform.success) {
            if (metaState.platform.sellerSignup) {
                setSellerSignupActive(metaState.platform.sellerSignup.is_active)
            }

            if (metaState.platform.buyerSignup) {
                setBuyerSignupActive(metaState.platform.buyerSignup.is_active)
            }
        }
    }, [metaState])


    const handleGoogleSignIn = async (e) => {
        e.preventDefault();
        dispatch(signInWithGoogle());
    }

    const showToaster = (variant, message) => {
        // toast[variant](message);
        toast.dismiss()
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    }

    const handlenavigate = (url) => {
        onHide();
        navigate(url)
    }
    return (
        <>
            <Container>
                {/* <Toaster/> */}
                <Row>
                    <Col sm={12} lg={6} className="pr-lg-0 d-none d-lg-block">
                        <a className="company--brand">
                            <img loading='lazy' src="/images/logo.webp" alt="BirthBridge Logo" />
                            <span>BirthBridge</span>
                        </a>
                    </Col>
                    <Col sm={12} lg={6} className="pl-lg-0">
                        <Form>
                            {
                            // (process.env.NODE_ENV === 'development' )&&
                            <>
                                {/* <Form.Group className="form-group">
                                    <ListGroup>
                                        <ListGroup.Item
                                            className="ff--google"
                                            onClick={(e) => handleGoogleSignIn(e)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <FcGoogle /> Log In with Google
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Form.Group> */}
                                <CustomGoogleSignIn handleGoogleSignIn={handleGoogleSignIn}/>
                                <p className="mb-3 mt-2 text-center"><span>Or, log in using your email and password below.</span></p>
                            </>
                            }
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="email"
                                    placeholder="Email Address"
                                    required
                                    name="email"
                                    value={user.email}
                                    onChange={handleChangeInput}
                                />
                                {error.email && <span className="error error-message">{error.email}</span>}
                            </Form.Group>
                            <Form.Group className="form-group">
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    value={user.password || ''}
                                    onChange={handleChangeInput}
                                />
                                {error.password && <span className="error error-message">{error.password}</span>}
                            </Form.Group>
                            <Form.Group className="form-group form--checkbox">
                                <a
                                    // href=":;"
                                    className="pointer"
                                    onClick={handleRequestOtpModel}
                                >
                                    Forgot password?
                                </a>
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={loading}
                                onClick={(e) => onSubmit(e)}
                                style={{ display: 'flex', justifyContent: 'center' }}
                            >
                                {loading ?
                                    <ThreeDots
                                        height="25"
                                        width="25"
                                        radius="9"
                                        color="#AED9E0"
                                        ariaLabel="three-dots-loading"
                                        visible={true}
                                    />
                                    :
                                    'Login'
                                }


                                {/* {loading ? 'Please Wait...' : 'Login'} */}
                            </Button>

                            {/* <p>Don't have an account? Register as a <a className="pointer"  onClick={()=>handleUserShow()}>User</a> or <a className="pointer"  onClick={()=> handleSeller()}>Business owner</a></p> */}
                            {(isBuyerSignupActive || isSellerSignupActive) &&
                                <p>
                                    Don't have an account? Register as a

                                    {isBuyerSignupActive
                                        &&
                                        <a
                                            className="pointer ml-1 mr-1"
                                            onClick={() => handlenavigate('/family-signup')}
                                        > Family</a>}
                                    {(isBuyerSignupActive && isSellerSignupActive) && <span className="mr-1 ml-1">or</span>}
                                    {isSellerSignupActive
                                        &&
                                        <a
                                            className="pointer mr-1 ml-1"
                                            onClick={() => handlenavigate('/bridger-signup')}
                                        >Birth Professional</a>}
                                </p>}
                        </Form>

                    </Col>
                </Row>
            </Container>
        </>




    )
}

export default SignInModel