import { 
    GET_SEARCH_RESULTS_SUCCESS, 
    GET_SEARCH_RESULTS_FAILED, 
    GET_LIST_TO_SEARCH_SUCCESS, 
    GET_LIST_TO_SEARCH_FAILED, 
    LIST_DISCOVER_SUCCESS,
    LIST_DISCOVER_FAILED
} from "../actions/types";

const initialState = {
    searchList: null,
    discoverSellers: null,
    results:null,
    error: null
};

const searchReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_SEARCH_RESULTS_SUCCESS:
            return {
                results: action.payload,
                error: null
            }
        case GET_SEARCH_RESULTS_FAILED:
            return {
                error: action.payload
            }
        case GET_LIST_TO_SEARCH_SUCCESS:
            return {
                searchList: action.payload,
                error: null
            }
        case GET_LIST_TO_SEARCH_FAILED:
            return {
                error: action.paylaod
            }
        case LIST_DISCOVER_SUCCESS:
            return {
                discoverSellers: action.payload,
                error: null
            }
        case LIST_DISCOVER_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};

export default searchReducer;