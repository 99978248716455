import React, { useState, useEffect } from 'react';
import './Faqs.css';
import { Card, InputGroup, FormControl } from 'react-bootstrap';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Pagination, Chip, Stack } from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import faqData from './faq.json'
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";

const contactUsHeader1 = require('../../assets/images/faq_header.webp');
// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});

const Header = () => {
    const navigate = useNavigate();

    return (
        <header className="header">
            <div className="faq-header-content">
                <div className="leftside-content">
                    <Card.Img
                        alt="birth professional frequently asked questions header image"
                        variant="top"
                        className="header-img"
                        src={contactUsHeader1} />
                </div>
                <div className="rightside-content">
                    <div className='rightside-wrapper'>
                        <h1 className="header-main-text">Have Questions About BirthBridge? We're Here for You!</h1>
                        <p style={{ margin: '16px 0px' }}>
                            Whether you’re a birth professional or a family seeking support, we’re here to guide you every step of the way! Explore our FAQs below or reach out to us for personalized help. At BirthBridge, we’re all about making your journey simple, supportive, and stress-free. Need more info? Fill out the form below or drop us an email at hello@mybirthbridge.com – we’d love to hear from you!
                        </p>
                    </div>

                </div>
            </div>
        </header>
    )
}

const Faq = ({ faqData, itemsPerPage }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('All');

    // Get unique categories from FAQ data
    const categories = ['All', ...new Set(faqData.map(faq => faq.category))];

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        setCurrentPage(1); // Reset to first page when changing category
    };

    const filteredFaqData = faqData.filter(faq => {
        const matchesSearch = faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
            faq.answer.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesCategory = selectedCategory === 'All' || faq.category === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    const offset = (currentPage - 1) * itemsPerPage;
    const currentFaqData = filteredFaqData.slice(offset, offset + itemsPerPage);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to first page when searching
    };

    useEffect(() => {
        logEvent('Page View', 'Viewing FAQs', 'Viewing FAQs Page', 1);
    }, [])

    return (
        <>
            <SEO
                title="FAQs"
                description="Find answers to frequently asked questions about BirthBridge, including details about services, booking, and support for families and birth professionals."
                url="/faqs"
                keywords="FAQs, BirthBridge, birth professionals, services, booking, support, prenatal care, postpartum care, doula services, midwife services, lactation consultant, family support, pregnancy care, infant care"
            />
            <div className="faq-container">
                <div className="faq-wrapper">
                    <h1 className="center-text">Frequently Asked Questions</h1>
                    <div className="faqs-content-wrapper">
                        <div className="search-container">
                            <InputGroup className="mb-3">
                                <FormControl
                                    placeholder="Search FAQs"
                                    aria-label="Search FAQs"
                                    aria-describedby="basic-addon2"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </InputGroup>
                        </div>

                        {/* Category Chips */}
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                flexWrap: 'wrap',
                                gap: 1,
                                mb: 3,
                                '& > *': { margin: '4px !important' }
                            }}
                        >
                            {categories.map((category) => (
                                <Chip
                                    key={category}
                                    label={category}
                                    onClick={() => handleCategoryChange(category)}
                                    color={selectedCategory === category ? "primary" : "default"}
                                    variant={selectedCategory === category ? "filled" : "outlined"}
                                    clickable
                                />
                            ))}
                        </Stack>

                        {currentFaqData.map((faq, index) => (
                            <Accordion key={index}>
                                <AccordionSummary expandIcon={<MdExpandMore />}>
                                    <Typography>{faq.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>{faq.answer}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}

                        <div style={{ paddingTop: 24 }}>
                            <Pagination
                                count={Math.ceil(filteredFaqData.length / itemsPerPage)}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                className="pagination"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default function FaqComponent() {
    const itemsPerPage = 10; // Adjust as needed

    return (
        <ThemeProvider theme={theme}>
            <Header />

            <Faq faqData={faqData} itemsPerPage={itemsPerPage} />
        </ThemeProvider>
    );
}