import { get } from 'lodash';
import API from '../../helpers/api';
import { 
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAILED,
  GET_STATISTICS_SUCCESS,
  GET_STATISTICS_FAILED
} from './types';

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

/** ---------- Seller / Staff ---------- */
export const getStats = (query) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('statistics', query);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_STATISTICS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_STATISTICS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_STATISTICS_FAILED);
        }
    }
}