import { GET_TIME_SLOTS_SUCCESS, GET_TIME_SLOTS_FAILED, GET_AVAILABLE_DATES_SUCCESS, GET_AVAILABLE_DATES_FAILED, REFRESH_TIMESLOT, GET_TIMEZONE_SUCCESS, GET_TIMEZONE_FAILED } from "../actions/types";

const initialState = {
    timeSlots: null,
    date:null,
    error: null
};

const timeSlotsReducer =  (state = initialState, action) => {
    switch (action.type){ 
        case GET_TIME_SLOTS_SUCCESS:
            return {
                timeSlots: action.payload,
                error: null
            }
        case GET_TIME_SLOTS_FAILED: 
            return {
                error: action.payload,
            }
        case GET_AVAILABLE_DATES_SUCCESS:
            return {
                date: action.payload,
                error: null
            }
        case GET_AVAILABLE_DATES_FAILED:
            return {
                error: action.payload
            }
        case GET_TIMEZONE_SUCCESS:
            return {
                timeZones: action.payload,
                error: null
            }
        case GET_TIMEZONE_FAILED:
            return {
                error: action.payload
            }
        case REFRESH_TIMESLOT:
            return {
                stateClear:null
            }
        default: return state;
    }
};

export default timeSlotsReducer;