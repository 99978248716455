import React, { useState, useEffect, Suspense } from 'react';
import { Container } from "react-bootstrap";

import SEO from '../../seo';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import '../../App.css';

import SearchBar from '../SearchBar';
import LoadingView from '../LoadingView';
import sectionDivider from './section_divider.avif';
// import { useSelector } from 'react-redux';
// const ListByCategories = React.lazy(() => import('../ListByCategories'));
const ListByService = React.lazy(() => import('../ListByService'));
const LandingPageAboutBirthBridge = React.lazy(() => import('../LandingPageAboutBirthBridge'));
const LandingPageSupportFamilies = React.lazy(() => import('../LandingPageSupportFamilies'));
const LandingPageBlogs = React.lazy(() => import('../LandingPageBlogs'));
const DiscoverBirthProfessionals = React.lazy(() => import('../DiscoverBirthProfessionals'));

const displayTitleService = [
    "a Doula",
    "a Postpartum Doula",
    "a Lactation Consultant",
    "a Prenatal Care",
    "a Pregnancy Nutritionist",
    "a Midwife",
    "Prenatal Classes",
    "Lamaze Classes",
    "Breastfeeding Classes",
    "a Birth Photographer",
    "a Postpartum Yoga Instructor",
    "a Prenatal Yoga Instructor",
    "an Infant Massage Specialist",
    "a Bradley Method Class"
];

const SectionDivider = ({ img }) => {
    return <section className="custom-section">
        <div className="image-container">
            <img src={img} loading="lazy" alt="Section Divider" />
        </div>
    </section>
}
const SectionDivider2 = ({ img }) => {
    return <section className="custom-section2">
        <div className="image-container2">
            <img src={img} loading="lazy" alt="Section Divider" />
        </div>
    </section>
}

const HomeComponent = () => {
    const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
    const [animate, setAnimate] = useState('enter');
    const [loadAdditionalComponents, setLoadAdditionalComponents] = useState(false);
  
    useEffect(() => {
        setTimeout(() => {
            const interval = setInterval(() => {
                setAnimate('exit');
                setTimeout(() => {
                    setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % displayTitleService.length);
                    setAnimate('enter');
                }, 500);
            }, 5000);

            return () => clearInterval(interval);
        }, 3000); // Delay animation by 1.5 seconds
    }, []);

    // User interaction detection
    useEffect(() => {
        const handleUserInteraction = () => {
            setLoadAdditionalComponents(true);
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('scroll', handleUserInteraction);
        };

        window.addEventListener('click', handleUserInteraction);
        window.addEventListener('scroll', handleUserInteraction);

        return () => {
            window.removeEventListener('click', handleUserInteraction);
            window.removeEventListener('scroll', handleUserInteraction);
        };
    }, []);



    return (
        <>
            <SEO
                title="Welcome to BirthBridge"
                description="Connect with top birth professionals and easily book prenatal, birth, and postpartum services. Join BirthBridge today for seamless and supportive care."
                keywords="BirthBridge, birth professionals, prenatal care, postpartum care, doula services, midwife services, lactation consultant, birth services, family support, pregnancy care, infant care, booking birth professionals, trusted birth services"
                url="/"
            />

            <Container>
                <section className="banner__search">
                    <div style={{ width: '90%', margin: '0 auto' }} className="Inner--Content">
                        <h1 className={`animated-title ${animate}`} style={{ fontWeight: 'bold', fontSize: '32px' }}>
                            Find {displayTitleService[currentTitleIndex]} Near You
                        </h1>

                        <SearchBar />
                    </div>
                </section>
            </Container>



            <Suspense
                fallback={
                    <div style={{ height: '500px' }}>
                        <LoadingView />
                    </div>
                }
            >
                <DiscoverBirthProfessionals/>
            </Suspense>

            <Suspense
                fallback={
                    <div style={{ height: '500px' }}>
                        <LoadingView />
                    </div>
                }
            >
                <LandingPageAboutBirthBridge/>
            </Suspense>





            {/* Conditionally load ListByService and ListByCategories based on user interaction */}
            {loadAdditionalComponents && (
                <>
                    <SectionDivider img={sectionDivider} />
                    <Suspense
                        fallback={
                            <div style={{ height: '400px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <ListByService />
                    </Suspense>




                    {/* <Suspense 
                        fallback={
                            <div style={{ height: '400px' }}>
                            <LoadingView />
                            </div>
                            }
                            >
                            <ListByCategories />
                            </Suspense> */}
                    <SectionDivider2 img={'https://res.cloudinary.com/fureverhomed/image/upload/f_auto,f_avif/v1730090248/dopuoqfpmkwoa9ckom6v.jpg'} />
                    <Suspense
                        fallback={
                            <div style={{ height: '500px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <LandingPageSupportFamilies />
                    </Suspense>
                    <Suspense
                        fallback={
                            <div style={{ height: '500px' }}>
                                <LoadingView />
                            </div>
                        }
                    >
                        <LandingPageBlogs />
                    </Suspense>
                </>
            )}
        </>
    );
};

export default HomeComponent;