export const displayOwner = (user) => {

    if( user){  
        if( user.is_seller_with_staff ){
            let n =`${user.name}`

            if(user.seller_personal_name ){
                n += ` | ${user.seller_personal_name}`
            }

            return n
        }

        return user.name
    }
}