import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem,
    Box,
    Alert
} from '@mui/material';
import { communityService } from '../../services/communityService';
import { useSelector } from 'react-redux';
import {CATEGORIES} from './postsUtils.js';
// const CATEGORIES = [
//     { value: 'general', label: 'General Discussion' },
//     { value: 'question', label: 'Questions' },
//     { value: 'discussion', label: 'Discussion' },
//     { value: 'announcement', label: 'Announcement' },
//     { value: 'career', label: 'Career and Training' },
//     { value: 'business', label: 'Business Tips and Tools' },
//     { value: 'networking', label: 'Networking and Collaboration' },
//     { value: 'education', label: 'Education and Resources' },
//     { value: 'specialized', label: 'Specialized Services' },
//     { value: 'client_support', label: 'Client Support' },
//     { value: 'advocacy', label: 'Advocacy and Birth Rights' },
//     { value: 'birth_stories', label: 'Birth Stories and Experiences' },
//     { value: 'tools_techniques', label: 'Tools and Techniques' },
//     { value: 'regional', label: 'Regional Groups' },
//     { value: 'new_professionals', label: 'FAQs for New Professionals' },
//     { value: 'tech_tools', label: 'Doula Tools and Tech' },
//     { value: 'outreach', label: 'Community Engagement and Outreach' }
// ];

const CreatePostModal = ({ open, onClose, onPostCreated }) => {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        category: ''
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const sellerProfileData = useSelector(state => state.user);
    const currentUser = sellerProfileData?.userDetail;

    const handleChange = (e) => {

        setFormData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!formData.title.trim() || !formData.category) {
            setError('Title and category are required');
            return;
        }

        try {
            setLoading(true);

            const response = await communityService.createPost(formData);
            if (response.data.success) {
                const newPost = {
                    ...response.data.data,
                    author: {
                        _id: currentUser._id,
                        name: currentUser.name,
                        profilePic: currentUser.profilePic
                    }
                };
                onPostCreated(newPost);
                onClose();
                setFormData({ title: '', description: '', category: '' });
            }
        } catch (error) {
            console.log('error',error);
            setError(error.response?.data?.message || 'Error creating post');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Create New Post</DialogTitle>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            name="title"
                            label="Title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                            fullWidth
                        />

                        <TextField
                            name="description"
                            label="Description"
                            value={formData.description}
                            onChange={handleChange}
                            multiline
                            rows={4}
                            fullWidth
                        />

                        <TextField
                            name="category"
                            label="Category"
                            value={formData.category}
                            onChange={handleChange}
                            select
                            required
                            fullWidth
                        >
                            {CATEGORIES.map((category) => (
                                <MenuItem key={category.value} value={category.label}>
                                    {category.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button 
                        type="submit" 
                        variant="contained"
                        disabled={loading}
                    >
                        {loading ? 'Creating...' : 'Create Post'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CreatePostModal; 