import {
    GET_STAFF_SUCCESS,
    GET_STAFF_FAILED,
    POST_STAFF_SUCCESS,
    POST_STAFF_FAILED,
    GET_STAFF_META_SUCCESS,
    GET_STAFF_META_FAILED,
    DELETE_STAFF_SUCCESS,
    DELETE_STAFF_FAILED,
    GET_DELETED_STAFF_SUCCESS,
    GET_DELETED_STAFF_FAILED,
    INVITE_STAFF_VERIFICATION_SUCCESS,
    INVITE_STAFF_VERIFICATION_FAILED,
    UPDATE_STAFF_PASSWORD_SUCCESS,
    UPDATE_STAFF_PASSWORD_FAILED,
    GET_SINGLE_STAFF_SUCCESS,
    GET_SINGLE_STAFF_FAILED,
    UPDATE_ONE_STAFF_SUCCESS,
    UPDATE_ONE_STAFF_FAILED,
} from "../actions/types";


const initialState = {
    updatePassStaff:null,
    singleStaff:null,
    errorSingleStaff:null,
    error: null
};

const staffReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STAFF_SUCCESS:
            return {
                list: action.payload,
                error: null
            }
        case GET_STAFF_FAILED:
            return {
                error: action.payload,
            }
        case GET_STAFF_META_SUCCESS:
            return {
                metaDetails: action.payload,
                errorMeta: null
            }
        case GET_STAFF_META_FAILED:
            return {
                errorMeta: action.payload,
            }
        case POST_STAFF_SUCCESS:
            return {
                StaffAdd: action.payload,
                errorStaffPost: null
            }
        case POST_STAFF_FAILED:
            return {
                errorStaffPost: action.payload,
            }
        case GET_DELETED_STAFF_SUCCESS :
            return {
                deletedList : action.payload,
                error: null
            }
        case GET_DELETED_STAFF_FAILED : 
            return {
                error : action.payload,
            }
        case DELETE_STAFF_SUCCESS:
            return {
                deleted: action.payload,
                error: null
            }
        case DELETE_STAFF_FAILED:
            return {
                error: action.payload,
            }
        case INVITE_STAFF_VERIFICATION_SUCCESS:
            return {
                invite: action.payload,
                error: null
            }
        case INVITE_STAFF_VERIFICATION_FAILED:
            return {
                error: action.payload,
            }
        case UPDATE_STAFF_PASSWORD_SUCCESS:
            return {
                updatePassStaff: action.payload,
                error: null
            }
        case UPDATE_STAFF_PASSWORD_FAILED:
            return {
                error: action.payload,
            }
        case GET_SINGLE_STAFF_SUCCESS:
            return {
                singleStaff: action.payload
            }
        case GET_SINGLE_STAFF_FAILED:
            return {
                errorSingleStaff: action.payload,
            }
        case UPDATE_ONE_STAFF_SUCCESS:
            return {
                update: action.payload,
                error: null
            }
        case UPDATE_ONE_STAFF_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};

export default staffReducer;