import React, { useEffect, useState, useMemo, useRef } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Container, Row, Button, Col, Card, Alert, Tooltip } from 'react-bootstrap';
import { MdLocationCity } from "react-icons/md";
import { BiCalendarCheck } from 'react-icons/bi';
import { GiPathDistance } from 'react-icons/gi';
import { IoFilterCircleOutline } from "react-icons/io5";
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SearchBar from '../SearchBar';
import { getSearchResults } from '../../redux/actions/search.action';
import { convertMetersToMiles, displayAddress, generateLetterSVG, momentFormat, objectToSearchParams, showOverview } from '../../utils/validations';
import { getCategories } from '../../redux/actions/categories.action';
import RatingReadOnly from '../RatingReadOnly';
import { RiMapPinRangeFill } from 'react-icons/ri';
import { getAllBBServices } from '../../redux/actions/services.action';
import LoadingView from '../LoadingView';
import CustomizeCalendar from '../../commonComponents/CustomizeCalendar';
import BookingModal from '../SingleBusiness/Booking/BookingModal';
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";
import Pagination from '../../commonComponents/CustomPagination';
import { optimizedCloudinaryImage } from '../../utils/fileCompression';
import SearchFilter from './SearchFilter';
import { Helmet } from 'react-helmet';
// import NearBySearch from '../NearByCompanies/LocationSearch';
import Slider from 'rc-slider';
import { getGeoIpLocation } from '../../redux/actions/places.action';
import 'rc-slider/assets/index.css';
import './search.css';

// import { de } from 'date-fns/locale';
// import supportingfamiliestopleftfeather from './supportingfamiliestopleftfeather.webp';
// import topRightFeather from './topRightFeature.webp';

// const displayTitleService = [
//     "a Doula",
//     "a Postpartum Doula",
//     "a Lactation Consultant",
//     "a Prenatal Care",
//     "a Pregnancy Nutritionist",
//     "a Midwife",
//     "Prenatal Classes",
//     "Lamaze Classes",
//     "Breastfeeding Classes",
//     "a Birth Photographer",
//     "a Postpartum Yoga Instructor",
//     "a Prenatal Yoga Instructor",
//     "an Infant Massage Specialist",
//     "a Bradley Method Class"
// ];


const virtualIcon = require('../../assets/images/virtual.png');

const SearchComponent = () => {
    const calendarRef = useRef(null);

    const today = moment().startOf('day').toDate();

    /** ---- Difference of start - end date (does not include in count) days in single page --- */
    const daysDifference = 13

    /** --- Total No of Schdules 140  ---  */
    const totalSchedules = 140


    const url = `/search`;

    const currentDate = new Date();

    /** ---- Returns decoded token if logged ---- */
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const { search } = useParams();

    /** ---- Get URL Search Query Values ---- */
    const searchParams = new URLSearchParams(location.search); // Extract filter values from the URL query parameters

    const searchStartDate = searchParams.get('searchStartDate');
    const searchEndDate = searchParams.get('searchEndDate');

    const [urlUpdated, setUrlUpdated] = useState(false);

    /** ------- Near by Dropdown ------- */
    const [nearByOpen, setNearByOpen] = useState(true);

    /** ------ Date Range ----- */
    const [showCalendar, setShowCalendar] = useState(false);

    /** ------ Sidebar Toggle State ------ */
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const [filter, setFilter] = useState(null);
    const memoizedFilter = useMemo(() => ({ ...filter }), [filter]);

    const [selectedDate, setSelectedDate] = useState('');
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [serviceToBook, setServiceToBook] = useState(null)

    const [loading, setLoading] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);

    const [results, setResults] = useState({ services: [] });
    // const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const [updateSchedulesFromAPI, setUpdateSchedulesFromAPI] = useState(true);
    /** ------ Schedules Filter start/end date per page ------- */
    const [date, setDate] = useState({ start: '', end: '' });

    /** ----- Schedules First Page Start, schedule last page End date ------- */
    const [firstPageStart, setFirstPageStart] = useState('');
    const [lastPageEnd, setLastPageEnd] = useState('');

    const [distanceRange, setDistanceRange] = useState(null);

    const [totalServices, setTotalServices] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limitPerPage, setLimitPerPage] = useState(0);

    /** ---------- Near By Search --------- */
    const [coordinates, setCoordinates] = useState(null);
    // const [loading, setLoading] = useState(false);
    const [detectingLocation, setDetectingLocation] = useState(true);
    const [autoDetectedAddress, setAutoDetectedAddress] = useState(null);
    const [address, setAddress] = useState(null);
    const [permissionStatus, setPermissionStatus] = useState({ status: false, message: '' });

    /** ---- Redux State ---- */
    const searchState = useSelector(state => state.search);
    const memoizedSearchState = useMemo(() => ({ ...searchState }), [searchState]);

    const placeState = useSelector(state => state.places);


    useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);

        dispatch(getCategories())
        dispatch(getAllBBServices())

        if (!searchStartDate && !searchEndDate) {
            handleFirstPageLastPageDate(moment(), moment().add(totalSchedules - 1, 'd'));
            setDate({ start: moment(), end: moment().add(daysDifference, 'd') })
        } else {
            handleFirstPageLastPageDate(moment(searchStartDate), moment(searchStartDate).add(totalSchedules - 1, 'd'));
        }

        // handlePermissionCheck()

        // console.log('Permission Request');
        handlePermissionRequest();

        // Optionally, you can also return a cleanup function to scroll back to top
        // when the component unmounts (i.e., when navigating away from the page).
        return () => {
            window.scrollTo(0, 0);
            // document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // The empty dependency array ensures this effect runs only once

    useEffect(() => {
        const parsedValues = {};
        // Loop through the search parameters
        searchParams.forEach((value, key) => {
            value = decodeURIComponent(value)
            // Check if the key already exists in the parsedValues object
            if (parsedValues[key]) {
                // If it exists and is an array, push the new value to the array
                if (Array.isArray(parsedValues[key])) {
                    parsedValues[key].push(value);
                } else {
                    // If it exists and is not an array, convert it to an array and push the new value
                    parsedValues[key] = [parsedValues[key], value];
                }
            } else {
                // If the key doesn't exist, assign the value directly
                parsedValues[key] = value;
            }
        });
        // console.log( parsedValues );
        //setUrlUpdated(true)
        setUrlUpdated(true);
        //setFilter({ ...parsedValues });

        setFilter((prevValues) => {
            const updatedFilter = { ...prevValues, ...parsedValues };
            // Retain `hasSchedules: true` if it's not explicitly set in the URL query
            if (!parsedValues.hasOwnProperty("hasSchedules")) {
                // Optional Chaining (?.)
                // Nullish Coalescing (??)
                updatedFilter.hasSchedules = prevValues ? prevValues.hasSchedules : false; // Default to true if not already defined
                setUrlUpdated(false);
            }

            return updatedFilter;
        });



        const timer = setTimeout(() => {

            if (updateSchedulesFromAPI) {
                setLoading(true)
                setError('')
                let queryPayload = { ...parsedValues };
                delete queryPayload.searchStartDate
                delete queryPayload.searchEndDate
                handleFetchServices({ ...queryPayload, page });
            }
        }, 800);

        return () => clearTimeout(timer);
    }, [location.search]);


    useEffect(() => {
        if (Object.keys(memoizedFilter).length > 0 || (Object.keys(memoizedFilter).length === 0 && filter !== memoizedFilter)) {
            setPage(1);
        }
    }, [memoizedFilter])

    // useState(() => {
    //     console.log('Initial State...', filter )
    //     setFilter(prevValues =>  { 
    //         return { ...prevValues,  hasSchedules: true} 
    //     });
    // }, [])

    useEffect(() => {
        if (filter && filter.location) {
            setAddress(filter.location);
        } else {
            setAddress(autoDetectedAddress)
        }
    }, [filter]);

    useEffect(() => {
        if (coordinates !== null && coordinates.length) {
            // const query = { search: 'near-by', lat: coordinates[0], lng: coordinates[1] };
            // if (props.handleGetBusinesses) {
            //     props.handleGetBusinesses(query);
            // }
            const loc = { lat: coordinates[0], lng: coordinates[1], };
            getLocationAndDistance(loc);
        }
    }, [coordinates]);

    useEffect(() => {
        // console.log( placeState );
        if (placeState.error) {
            setDetectingLocation(false);
        }
        if (!placeState.error) {
            if (placeState.geoIp && placeState.geoIp.success) {
                setAddress(placeState.geoIp.address);
                setAutoDetectedAddress(placeState.geoIp.address)
                /** ----------- Enabling Find Near Me ------------ */
                setDetectingLocation(false);
                // handleDistanceApply()

                // if (props.handleBusinessesLoading) {
                //     props.handleBusinessesLoading(false);
                // }
            }
            // if (placeState.placeDetail && placeState.placeDetail.success && !placeState.error) {
            //     setAddress(`${placeState.placeDetail.details.city}, ${placeState.placeDetail.details.state}, ${placeState.placeDetail.details.country}`);
            //     setCoordinates(placeState.placeDetail.details.location);
            //     // if (props.handleBusinessesLoading) {
            //     //     props.handleBusinessesLoading(false);
            //     // }
            // }
        }
    }, [placeState]);


    useEffect(() => {
        if (searchStartDate && searchEndDate && moment(searchEndDate).diff(moment(searchStartDate), 'day') === 13) {
            setDate({ start: moment(searchStartDate), end: moment(searchEndDate) })
        }
    }, [searchStartDate, searchEndDate])

    /** ------ Redux State Search Results ----- */
    useEffect(() => {
        // console.log( searchState )
        if (memoizedSearchState.error) {
            setError(memoizedSearchState.error.message)
            setLoading(false);
        }

        if (!memoizedSearchState.error) {
            setError('')
            if (memoizedSearchState.results && memoizedSearchState.results.success) {
                setLimitPerPage(memoizedSearchState.results.perPage)
                setResults({ services: memoizedSearchState.results.services });
                setTotalPages(memoizedSearchState.results.totalPages);
                setTotalServices(searchState.results.totalCount);
                setLoading(false);
                setMoreLoading(false)

            }
        }
    }, [memoizedSearchState])


    useEffect(() => {
        if (filter && !urlUpdated) {
            setUpdateSchedulesFromAPI(true);
            // console.log( 'filter', filter );
            const searchParamsString = objectToSearchParams(filter);
            navigate(url.concat(`?${searchParamsString}`))
        }
    }, [filter])


    useEffect(() => {
        if (moment(date.start).isSameOrAfter(firstPageStart) && moment(date.end).isSameOrBefore(lastPageEnd)) {
            setUpdateSchedulesFromAPI(false);
        } else {
            handleFirstPageLastPageDate(moment(date.start), moment(date.start).add(totalSchedules - 1, 'd'));
            setUpdateSchedulesFromAPI(true);
        }
    }, [date.start, date.end, firstPageStart, lastPageEnd]);

    useEffect(() => {
        logEvent('Page View', 'Viewing Search Page', 'Viewing search page of Birth Professionals', 1);
    }, []);

    useEffect(() => {
        if (filter && (filter.location || filter.nearBy)) {
            // inputRef.current.focus();

            // Declare a variable to store the timeout ID
            let timeoutId;

            // Set the timeout and store its ID
            timeoutId = setTimeout(() => {
                handleUpdateFilter({ distanceRange: distanceRange?.distanceRange })
            }, 250);

            // Return a cleanup function to clear the timeout
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [distanceRange?.distanceRange]);

    const handleDistanceRange = (value) => setDistanceRange({ ...distanceRange, distanceRange: value });

    /** ---- USed to handle Schedules Dates to prevent loading on every page change ---- */
    const handleFirstPageLastPageDate = (_start, _end) => {
        setFirstPageStart(_start);
        setLastPageEnd(_end);
    }

    /** ------ Sidebar Toggle State -------- */

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    // const handlePermissionCheck = () => {
    //     if ('permissions' in navigator && 'geolocation' in navigator) {
    //         navigator.permissions.query({ name: 'geolocation' })
    //             .then((_permissionStatus) => {
    //                 switch (_permissionStatus.state) {
    //                     case 'granted':
    //                         // setPermissionStatus({ message: 'Location permission granted', status: true });
    //                         setNearByOpen(false);
    //                         break;
    //                     case 'prompt':
    //                         // setPermissionStatus({ message: 'Location permission is not granted yet. No prompt will be shown.', status: false });
    //                         break;
    //                     case 'denied':
    //                         // setPermissionStatus({ status: false, message: 'Location permission denied. Please enable location services in your browser settings.' });
    //                         break;
    //                     default:
    //                     // setPermissionStatus({ status: false, message: 'Unknown permission status.' });
    //                 }
    //             })
    //             .catch((error) => {
    //                 // setPermissionStatus({ status: false, message: `Error checking permission: ${error.message}` });
    //             });
    //     } else {
    //         // setPermissionStatus({ status: false, message: 'Geolocation is not supported by this browser' });
    //     }
    // };

    const getCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            setPermissionStatus({ status: true, message: 'Granted' });
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            setCoordinates([lat, lng]);
            localStorage.setItem('current-location', JSON.stringify({ lat, lng }));
            setDetectingLocation(true);
            // if (props.handleBusinessesLoading) {
            //     props.handleBusinessesLoading(true);
            // }
            dispatch(getGeoIpLocation({ lat: lat, lng: lng }));
        }, () => {
            localStorage.removeItem('current-location');
            console.log('Geolocation permission denied');
            setPermissionStatus({ status: false, message: 'Location permission denied. Please enable location services in your browser settings.' });
            setDetectingLocation(false);
            // if (props.handleBusinessesLoading) {
            //     props.handleBusinessesLoading(false);
            // }
        });
    }

    const handlePermissionRequest = () => {
        // console.log('Asking Permission...');
        if ('permissions' in navigator && 'geolocation' in navigator) {
            navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
                if (permissionStatus.state === 'granted') {
                    console.log('Geolocation permission already granted');
                    setPermissionStatus({ message: 'Allowed Already', status: true });
                    getCurrentPosition();
                } else if (permissionStatus.state === 'prompt') {
                    setPermissionStatus({ message: 'Please Allow Location', status: false });
                    getCurrentPosition();
                } else {
                    console.log('Geolocation permission denied');
                    setPermissionStatus({ status: false, message: 'Location permission have been blocked. Please enable location services in your browser settings.' });
                    setDetectingLocation(false);
                    // if (props.handleBusinessesLoading) {
                    //     props.handleBusinessesLoading(false);
                    // }
                }
            });
        } else {
            console.log('Geolocation is not supported by this browser');
            setPermissionStatus({ status: false, message: 'Geolocation is not supported by this browser' });
            setDetectingLocation(false);
        }
    };

    const handleSearchDateObject = (parsedValues) => {
        let searchDates = {
            today: today,
        }
        if (parsedValues.searchStartDate) {
            searchDates.searchStartDate = moment(searchStartDate).startOf('day').toDate(); //momentFormat.utcDateTime();
        }
        if (parsedValues.searchEndDate) {
            searchDates.searchEndDate = moment(searchEndDate).startOf('day').toDate(); //momentFormat.utcDateTime(moment(searchEndDate));
        }

        return searchDates
    }

    const handleFetchServices = (payload) => {
        payload = { ...payload, ...handleSearchDateObject(payload) }
        dispatch(getSearchResults(payload));
    }

    const handleFetchMoreServices = (_page) => {
        setMoreLoading(true)
        setPage(_page);
        handleFetchServices({ ...filter, page: _page })
    }
    /** ----- Used To Update Filter and URL at same time ------ */
    const handleUpdateFilter = (data, removeCall) => {

        setUrlUpdated(false);
        let _filter = {}

        if (removeCall) {
            _filter = { ...data }
        } else {
            _filter = { ...filter, ...data }
        }

        if (!_filter.distanceRange && filter.location && !filter.nearBy && distanceRange && distanceRange?.distanceRange) {
            _filter['distanceRange'] = distanceRange?.distanceRange
        }

        if (data.location) {
            delete _filter['lat']
            delete _filter['lng']
            delete _filter['nearBy']
            // delete _filter
        }

        if (data.nearBy) {
            delete _filter['location']
        }

        if (!_filter.location && !_filter.nearBy) {
            // console.log( data );
            delete _filter['distanceRange']
        }

        setFilter(_filter);
    }

    const handleOnClickApptDate = (service, date) => {
        setShowBookingModal(true);
        setServiceToBook(service);
        setSelectedDate(date);
    }

    const handleCloseBookingModal = () => {
        setShowBookingModal(false);
        setServiceToBook(null);
    }

    const renderSingleBusiness = () => {
        return (
            results.services.map((_service, _serviceIndex) => {
                _service = _service.service
                if ((typeof _service.associatedWithUser === 'object' && !Object.keys(_service.associatedWithUser).length) || !_service.associatedWithUser) {
                    return null;
                }

                let owner = (!_service.associatedWithUser.is_seller_with_staff && _service.associatedWithUser.associatedWithSeller && _service.associatedWithUser.associatedWithSeller.length) ? false : true
                return (
                    <Card
                        key={_serviceIndex}
                        className='search-single-business'
                    >
                        <div className='search-single-business-header'>
                            {(_service.associatedWithUser.profilePic && _service.associatedWithUser.profilePic.url)
                                ? <Card.Img
                                    loading="lazy"
                                    alt={`Profile image for ${_service.associatedWithUser.name}`}
                                    className="cursor-pointer"
                                    onClick={() => navigate(`/business/${_service.associatedWithUser._id}`)}
                                    variant="top"
                                    src={optimizedCloudinaryImage(_service.associatedWithUser.profilePic.url)}
                                />
                                : generateLetterSVG(_service.associatedWithUser.name, 36)}
                            {_service.is_virtual &&
                                <Tooltip
                                    title="This is a virtual service"
                                    arrow
                                    sx={{ fontSize: '14px', backgroundColor: '#75a0a7' }}
                                >

                                    <Card.Img
                                        loading="lazy"
                                        alt={`${_service.category.name}`}
                                        className="card-virtual-icon"
                                        variant="bottom"
                                        src={virtualIcon}
                                    />
                                </Tooltip>
                            }
                        </div>

                        <Card.Body>
                            <Card.Text>
                                <Card.Title
                                    className="cursor-pointer text-capitalize"
                                    onClick={() => navigate(`/business/${_service.associatedWithUser._id}`)}
                                >
                                    {owner && _service.associatedWithUser.seller_personal_name ? _service.associatedWithUser.seller_personal_name : _service.associatedWithUser.name}
                                </Card.Title>
                                {(owner && _service.associatedWithUser.seller_personal_name) && <p className='mb-0'><small>{_service.associatedWithUser.name}</small></p>}
                                <RatingReadOnly
                                    value={_service.associatedWithUser?.ratings || 0}
                                    reviewsCount={true}
                                    reviews={Array.from({ length: _service.associatedWithUser?.reviewCount || 0 }, () => [])}
                                    classNames={'mb-0'}
                                />
                                <p style={{ marginTop: 8 }} className='business-category-product mb-0'>
                                    {_service.category.name}
                                </p>
                                <p style={{ marginTop: 8 }} className='mt-0 mb-0 w-100'>
                                    {showOverview(_service.associatedWithUser.seller_business_desc)}
                                </p>

                                {_service.associatedWithUser.language?.length > 0 && (
                                    <p style={{ marginTop: 8 }}>
                                        {_service.associatedWithUser.language.map((language, index) => (
                                            <span key={index} className='mr-1'>
                                                {language.label}
                                                {index < _service.associatedWithUser.language.length - 1 && ', '}
                                            </span>
                                        ))}
                                    </p>
                                )}
                                <p className='mb-1'>
                                    <small>
                                        <MdLocationCity className='mr-1' size={18} />
                                        {displayAddress(_service.associatedWithUser.address)}
                                    </small>
                                </p>
                                <p className='mb-1'>
                                    <small>
                                        <RiMapPinRangeFill className='mr-1' size={18} />
                                        Service Range {_service.associatedWithUser.seller_serve_distance} Miles
                                    </small>
                                </p>

                                {!['undefined', undefined, null, 'null', ''].includes(_service.associatedWithUser.distance) &&
                                    <p className='mb-1'>
                                        <small>
                                            <GiPathDistance className='mr-1' size={18} />
                                            Away {convertMetersToMiles(_service.associatedWithUser.distance)}
                                        </small>
                                    </p>
                                }
                                <p>
                                    <small> <BiCalendarCheck className='mr-1' size={18} /> Booked: {_service.associatedWithUser?.totalBookings || 0}</small>
                                </p>

                            </Card.Text>
                            <CustomizeCalendar
                                start={date.start}
                                end={date.end}
                                schedules={_service.schedulers}
                                // service={_service}
                                associatedWithUser={_service.associatedWithUser}
                                onClick={(date) => handleOnClickApptDate(_service, date)}
                                handleGotoNextApptDate={(date) => handleGotoApptDate(date)}
                            />
                        </Card.Body>
                    </Card>
                )
            })
        )
    }

    const handleRating = (v) => {
        handleUpdateFilter({ rating: v });
    }

    const handleClearIndividualFilter = (field) => {
        let _filter = { ...filter };
        if (field === 'nearBy') {
            delete _filter['lat']
            delete _filter['lng']
            delete _filter['distanceRange']
        }

        if (field === 'dateFilter') {
            delete _filter['searchStartDate']
            delete _filter['searchEndDate']
            setShowCalendar(false)
        }

        if (field === 'location') {
            delete _filter['location']
            delete _filter['distanceRange']
        }

        delete _filter[field];
        handleUpdateFilter(_filter, true)
    }

    const handleClearFilter = () => {
        setFilter({});
        navigate('/search')
    }

    const handleUpdateSearchDates = (_start, _end) => {
        handleUpdateFilter({
            searchStartDate: momentFormat.dateFormat(_start), // format('YYYY-MM-DD'), 
            searchEndDate: momentFormat.dateFormat(_end) //format('YYYY-MM-DD') 
        });
    }

    /** ---- Handle Next 14 Days ---- */
    const handleRightArrowClick = () => {
        const newStartDate = moment(date.start).add(daysDifference + 1, 'days');
        const newEndDate = moment(date.end).add(daysDifference + 1, 'days');
        setDate({ start: newStartDate, end: newEndDate });

        handleUpdateSearchDates(newStartDate, newEndDate);
    };

    /** --- handle visit selected date --- */
    const handleGotoApptDate = (gotoDate) => {
        const newStartDate = moment(gotoDate);
        const newEndDate = moment(gotoDate).add(daysDifference, 'days');
        setDate({ start: newStartDate, end: newEndDate })
        /** ---- handleUpdateFilter ---- */
        handleUpdateSearchDates(newStartDate, newEndDate)
    }

    /** ---- Handle Previous 14 Days ----- */
    const handleLeftArrowClick = () => {

        const newStartDate = moment(date.start).subtract(daysDifference + 1, 'days');
        if (newStartDate.isBefore(moment(), 'day')) {
            const currentDate = moment();
            const newEndDate = moment(currentDate).add(daysDifference, 'days');
            setDate({ start: currentDate, end: newEndDate });
            handleUpdateSearchDates(currentDate, newEndDate)
        } else {
            const newEndDate = moment(date.end).subtract(daysDifference + 1, 'days');
            setDate({ start: newStartDate, end: newEndDate });
            handleUpdateSearchDates(newStartDate, newEndDate)
        }
    };

    const handleVirtual = () => {
        if (!['true', true].includes(filter.virtual)) {
            let data = {}
            data.virtual = true
            handleUpdateFilter(data)
        } else {
            handleClearIndividualFilter('virtual')
        }
    }

    const handleHasSchedules = () => {
        if (!['true', true].includes(filter.hasSchedules)) {
            let data = {}
            data.hasSchedules = true
            handleUpdateFilter(data)
        } else {
            handleClearIndividualFilter('hasSchedules');
        }
    }

    const getLocationAndDistance = (data) => {
        // data.nearBy = true
        data.distanceRange = 10 // by default
        setDistanceRange(data)
    }

    const handleDistanceApply = () => {
        // console.log('filter', filter, distanceRange);
        // if(filter.location){
        //     handleClearIndividualFilter('location');
        // }

        if (!['true', true].includes(filter.nearBy)) {
            let data = {}
            data = { ...distanceRange }
            data.nearBy = true

            handleUpdateFilter(data);
            setNearByOpen(false);

        } else {
            handleClearIndividualFilter('nearBy')
        }
    }

    const handleCalendarDate = (_date) => {
        handleGotoApptDate(_date);
        setShowCalendar(false); // close the calendar when date is selected 
    }

    return (
        <>

            <SEO
                title="Find Trusted Birth Professionals Near You | BirthBridge"
                description="Search for certified birth professionals including doulas, midwives, and lactation consultants. Discover personalized support for your prenatal, birth, and postpartum journey."
                url="/search"
                keywords="birth professionals, doulas, midwives, lactation consultants, prenatal care, postpartum support"
            />
            <Helmet>
                {/* Schema for Search Results */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SearchResultsPage",
                        "name": "Search for Birth Professionals",
                        "description": "Find and connect with certified birth professionals including doulas, midwives, and lactation consultants.",
                        "potentialAction": {
                            "@type": "SearchAction",
                            "target": `${process.env.REACT_APP_URL}/search?q={search_term_string}`,
                            "query-input": "required name=search_term_string"
                        }
                    })}
                </script>

                {/* Schema for Breadcrumb */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": `${process.env.REACT_APP_URL}/`
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Search Results",
                                "item": `${process.env.REACT_APP_URL}/search`
                            }
                        ]
                    })}
                </script>
                <link rel="prev" href={`${url}?page=${page - 1}`} />
                <link rel="next" href={`${url}?page=${page + 1}`} />
            </Helmet>


            <Container>
                <Row>



                    <section className="popular--Wrapper pt-0 pb-0" id='discover--list'>
                        <Container fluid className="p-0">
                            <Row className='m-0'>
                                <Col sm={12} className='p-0'>
                                    <div className='search--page--main'>
                                        {/* Sidebar overlay */}
                                        <div
                                            className={`sidebar-overlay ${isSidebarOpen ? 'show' : ''}`}
                                            onClick={toggleSidebar}
                                        ></div>

                                        <aside
                                            className={`search--filter--main ${isSidebarOpen ? 'open' : ''}`}
                                        >
                                            <div className="search--filter--frame">
                                                <SearchFilter
                                                    filter={filter}
                                                    setFilter={setFilter}
                                                    handleClearIndividualFilter={handleClearIndividualFilter}
                                                    handleVirtual={handleVirtual}
                                                    handleHasSchedules={handleHasSchedules}
                                                    nearByOpen={nearByOpen}
                                                    setNearByOpen={setNearByOpen}
                                                    distanceRange={distanceRange}
                                                    handleDistanceApply={handleDistanceApply}
                                                    // getLocationAndDistance={getLocationAndDistance}
                                                    showCalendar={showCalendar}
                                                    setShowCalendar={setShowCalendar}
                                                    handleRating={handleRating}
                                                    calendarRef={calendarRef}
                                                    handleCalendarDate={handleCalendarDate}
                                                    currentDate={currentDate}
                                                    date={date}
                                                    handleClearFilter={handleClearFilter}
                                                    handleUpdateFilter={handleUpdateFilter}
                                                    toggleSidebar={toggleSidebar}
                                                />
                                            </div>
                                        </aside>
                                        <section className="search__results">

                                            {/* <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-115px',
                                                    right: '0',
                                                    zIndex: '1',
                                                }}>

                                                <img
                                                    src={topRightFeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '5%',
                                                    // right: '0',
                                                    zIndex: '1',
                                                }}>
                                                <img
                                                    src={supportingfamiliestopleftfeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '25%',
                                                    // right: '0',
                                                    zIndex: '1',
                                                }}>
                                                <img
                                                    src={supportingfamiliestopleftfeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '35%',
                                                    right: '0',
                                                    zIndex: '1',
                                                }}>

                                                <img
                                                    src={topRightFeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>


                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    // right: '0',
                                                    zIndex: '1',
                                                }}>
                                                <img
                                                    src={supportingfamiliestopleftfeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '60%',
                                                    right: '0',
                                                    zIndex: '1',
                                                }}>

                                                <img
                                                    src={topRightFeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '75%',
                                                    // right: '0',
                                                    zIndex: '1',
                                                }}>
                                                <img
                                                    src={supportingfamiliestopleftfeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '90%',
                                                    right: '0',
                                                    zIndex: '1',
                                                }}>

                                                <img
                                                    src={topRightFeather}
                                                    style={{
                                                        height: '300px',
                                                        width: '300px',
                                                    }}
                                                />
                                            </div> */}


                                            <h1 style={{
                                                margin: '24px 0px 24px 0px',
                                                width: '800px',
                                                maxWidth: '100%'
                                            }}>
                                                Find Certified Birth Professionals Near You
                                            </h1>


                                            <div className="banner__search justify-content-start text-left align-items-start pt-2 pb-4 px-3">
                                                <div className="Inner--Content m-0 w-100">
                                                    <SearchBar
                                                        filter={filter}
                                                        handleUpdateFilter={handleUpdateFilter}
                                                        handleClearIndividualFilter={handleClearIndividualFilter}
                                                    />
                                                </div>

                                                {(!permissionStatus.status && !loading) && <Alert variant='danger' className='mb-0 mt-3 w-100 location--alert'><span>{permissionStatus.message}</span> <Button aria-label="Allow" size='sm' className="ml-1" onClick={() => handlePermissionRequest()}> Allow </Button></Alert>}

                                                {(filter && (filter.nearBy || filter.location)) &&
                                                    <div className="find__near">
                                                        <div className='find__near__geolocation'>

                                                            {/* <NearBySearch
                                                    getLocationAndDistance={(d) => getLocationAndDistance(d)}
                                                    distanceRange={distanceRange?.distanceRange}
                                                    handleUpdateFilter={handleUpdateFilter}
                                                    searchedLocation = {filter?.location || null } // update address of slider when location changed
                                                    nearBy={filter?.nearBy || null}
                                                /> */}


                                                            <div className='location__search'>
                                                                <p className=''>
                                                                    <span>Birth Professionals within <span> <RiMapPinRangeFill /> {distanceRange?.distanceRange} Miles of </span>{' '}
                                                                        <strong>{detectingLocation ? 'Detecting...' : address}</strong>
                                                                        {/* <Tooltip 
                                                            message={loading ? 'Detecting location...' : 'Auto Detected Geolocation'}></Tooltip> */}
                                                                    </span>
                                                                </p>
                                                            </div>

                                                            {(filter && (filter.nearBy || filter.location) && distanceRange?.distanceRange) &&
                                                                <Slider
                                                                    min={0}
                                                                    max={1000}
                                                                    value={distanceRange.distanceRange}
                                                                    onChange={(value) => handleDistanceRange(value)}
                                                                    tooltip="miles"
                                                                />
                                                            }
                                                            {/* <Button
                                                    variant="secondary"
                                                    className="mr-2 clear-filter"
                                                    onClick={() => handleClearIndividualFilter('nearBy')}
                                                >
                                                    Clear
                                                </Button> */}
                                                        </div>
                                                    </div>}
                                            </div>
                                            {error &&
                                                <Alert variant='danger' className='text-center'>{error}</Alert>
                                            }
                                            {loading
                                                ? <LoadingView appLoader={true} />
                                                : <>
                                                    <div className='sticky--title'>
                                                        <div className='sticky--title--child'>
                                                            {(results.services && results.services.length > 0) &&
                                                                <h5>
                                                                    Showing {(page - 1) * limitPerPage + 1} - {Math.min(page * limitPerPage, totalServices)} of {totalServices} Total Records
                                                                </h5>
                                                            }
                                                            <div className='date--change'>
                                                                <span>
                                                                    {moment(date.start).isSame(moment(), 'day')
                                                                        ? 'Today'
                                                                        : moment(date.start).format('ddd')}, {moment(date.start).format('ll')} - {moment(date.end).format('ddd, MMM D, YYYY')}
                                                                </span>

                                                                <span>
                                                                    <Button aria-label="Left Arrow" variant="primary" onClick={() => handleLeftArrowClick()} disabled={!moment(date.start).isAfter(currentDate, 'day')}><FaAngleLeft /></Button>
                                                                    <Button aria-label="Right Arrow" variant="primary" onClick={() => handleRightArrowClick()}><FaAngleRight /></Button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='filter--icon'>
                                                            <IoFilterCircleOutline className='sidebar-toggle-btn' onClick={toggleSidebar} />
                                                        </div>
                                                    </div>
                                                    <div className='business--card--grid'>
                                                        {(results.services && results.services.length)
                                                            ?
                                                            <>
                                                                {renderSingleBusiness()}
                                                                {totalPages > 1 &&
                                                                    <Pagination
                                                                        totalPages={totalPages}
                                                                        currentPage={page}
                                                                        onPageChange={(v) => handleFetchMoreServices(v)}
                                                                        isLoading={moreLoading}
                                                                    />
                                                                }
                                                            </>
                                                            : <div className='text-center'>No Results Found</div>
                                                        }
                                                    </div>
                                                </>
                                            }
                                        </section>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </section>
                    {showBookingModal &&
                        <BookingModal
                            show={showBookingModal}
                            handleClose={() => handleCloseBookingModal()}
                            service={serviceToBook}
                            selectedDate={selectedDate}
                        />
                    }
                </Row>
            </Container>

        </>
    )
}

export default SearchComponent;