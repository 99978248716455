import React from 'react';
import { FcGoogle } from 'react-icons/fc'; // Google logo from react-icons
import RatingReadOnly from '../../components/RatingReadOnly';
import './ReviewOnGoogle.css'; // Import your custom CSS

const ReviewUsOnGoogle = ({ link }) => {
  return (
    <div className="review-container">
        <a href={link} target="_blank" rel="noopener noreferrer" className='review-container-link'>
            <span>
                <span className="review-text mr-2">
                    Review Us On
                </span>
                <RatingReadOnly value={5} />
            </span>
            <FcGoogle className="google-logo" /> {/* Google Logo from react-icons */}
        </a>
    </div>
  );
};

export default ReviewUsOnGoogle;
