// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// ---- anmol.fullstackdeveloper@gmail.com --- web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCKp3orzF_C4sJBPzbc3LVxo_dlaktzb2E",
//   authDomain: "birthbridge-d7428.firebaseapp.com",
//   projectId: "birthbridge-d7428",
//   storageBucket: "birthbridge-d7428.appspot.com",
//   messagingSenderId: "31258896059",
//   appId: "1:31258896059:web:c4b4326ce822987717ab9e"
// };

// ---- Jeff Mail --- web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY, 
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;