/**
 *  Defined Each API URL as keys to use in project
 */
const apiKeys = {
    auth: "/auth",
    usermeta:'/meta/user',
    user: "/users",
    seller:'/seller',
    cloudinary: '/cloudinary',
    staff:'/staffs',
    community:'/community',
    services:"/services",
    staffGet:'/staffs',
    staffSuspended:"/staffs/get/suspended",
    meta: '/meta',
    staffMeta:'/meta/staff',
    staffSuspentOne:"/staffs/delete",
    InviteverificationLink:"/staffs/resent/verification/link",
    staffUpdatePass:"/auth/verify/link/set/password",
    userEditDetails:"/user/users/edit/details",
    buisnesses:"/buisnesses",
    getCart:"/cart",
    deleteCartItem:"/cart/item/delete",
    stripe: '/stripe',
    places: '/places',
    search: '/search',
    timeSlots: '/time-slots',
    bookmark:'/bookmark',
    orders: '/orders',
    wallet: '/wallet',
    rating: '/rating',
    withdrawal: '/withdrawal',
    subscription:'/subscription',
    schedule:'/schedule',
    paymentMode: '/payment-mode',
    transactions: '/transactions',
    category: '/category',
    chat: '/chat',
    statistics: '/statistics',
    languages: '/languages',
    referral:'/referral',
    suggested:'/suggested',
    discovery:'/discovery',
    rsa:'/rsa'
};
  
export default apiKeys;
  