import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { numericValidation, deepClone } from "../../../utils/validations";
import { verifyOtp, login, showResetPassword, showLoginModal } from "../../../redux/actions/auth.actions";import { toast } from "react-toastify";


function VerifyOtp({ email, password, emailVerify, isSeller, handleReset }) {

    const intialValue = { email: email, otp: '' };

    const alertValue = { message: '', show: false, variant: '' };

    const [alert, setAlert] = useState(alertValue)

    const [user, setUser] = useState(deepClone(intialValue));

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(false);
    const dispatch = useDispatch();

    const apiResult = useSelector(state => state.auth);

    useEffect(() => {
        setUser({ ...user, email: email, password: password })
    }, [email, password])

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }

    const isValid = () => {
        let isValid = true
        let otpErrorMessage = "please enter your verification code "
        let NumericOtp = "Please enter a valid OTP"
        let errorMessages = {};
        if (!numericValidation(user.otp)) {
            errorMessages['otp'] = NumericOtp;
            isValid = false;
        }
        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }
        return isValid;
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            dispatch(verifyOtp(user))
        }
    }

    useEffect(() => {
        if (apiResult.error) {
            let _alert = {}
            _alert['show'] = true
            _alert['message'] = apiResult.error
            _alert['variant'] = "danger"
            setAlert(_alert)
            // setApiError(apiResult.error)
            setUser(intialValue)
            setLoading(false)
        }

        if (apiResult.verifyMessage) {
            let _alert = {}

            _alert['show'] = true
            _alert['message'] = apiResult.verifyMessage
            _alert['variant'] = "success"
            setAlert(_alert);
            dispatch(showResetPassword())
        }

        if (apiResult.emailVerifyMeassage) {
            let _alert = {}

            _alert['show'] = true
            _alert['message'] = apiResult.emailVerifyMeassage
            _alert['variant'] = "success"
            setAlert(_alert);

            if (isSeller) {
                if (handleReset) {
                    handleReset()
                }
                showToaster('success', "You have been placed on the waiting list. We will email you once you have been approved!")

                dispatch(showLoginModal());

            } else {
                if (email !== 'undefined' && password !== 'undefined') {
                    const user = { email: email, password: password }
                    dispatch(login(user))
                }
            }
        }

        if (apiResult && apiResult.token) {
            alert['show'] = true
            alert['message'] = 'Success'
            alert['variant'] = "success"
            setAlert(alert);
            window.location.replace('/')
        }

    }, [apiResult])


    const showToaster = (variant, message) => {
        toast.dismiss();
        toast[variant](message, {
            position: "top-right",
            // autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }


    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }

    return (

        <>
            <Container>
                <Row>
                    <Col sm={12} lg={6} className="pr-lg-0">
                        <a className="company--brand">
                            <img loading='lazy' src="/images/logo.webp" alt="BirthBridge Logo" />
                            <span>BirthBridge</span>
                        </a>
                    </Col>
                    <Col sm={12} lg={6} className="pl-lg-0">
                        <Form>
                            {alert['show'] && showAlertMessage()}
                            <h3>Verify Your Email</h3>
                            <h6>Please enter the code sent on {email}</h6>
                            <Form.Group className="form-group mt-2">
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Code"
                                    name="otp"
                                    value={user.otp || ''}
                                    onChange={handleChangeInput}
                                />
                                {error.otp && <span className="error error-message">{error.otp}</span>}
                            </Form.Group>
                            <Button
                            aria-label="Verify"
                                variant="primary"
                                type="submit"
                                disabled={loading}
                                onClick={(e) => onSubmit(e)}
                            >
                                {loading ? 'Verifying...' : 'Verify'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>


    )
}

export default VerifyOtp