import axios from 'axios';

const HOST = process.env.REACT_APP_API_HOST
const VERSION = process.env.REACT_APP_API_VERSION
const API = HOST + VERSION;

const instance = axios.create({
  baseURL: API,
});

export default instance;