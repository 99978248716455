function isDSTActive(timezone) {
    const now = new Date();
    // For simplicity, using fixed dates for DST start and end. Adjust as per the actual rules.
    
    /** ---------- USA daylight time from march 14 , November 7 ------------------ */

    // const dstStart = new Date(now.getFullYear(), 2, 14); // March 14
    // const dstEnd = new Date(now.getFullYear(), 10, 7);  // November 7

    const dstStart = new Date(now.getFullYear(), timezone.DSTStart.month - 1, timezone.DSTStart.day);
    const dstEnd = new Date(now.getFullYear(), timezone.DSTStart.month - 1, timezone.DSTStart.day);
  
    return now >= dstStart && now < dstEnd;
  }
  
  function getCurrentOffset(timeZone) {
    if (timeZone.DST && isDSTActive(timeZone)) {
      return  true // timeZone.DSTDescription;
    }
    return false //timeZone.Description;
  }
  
  function updateUserProfile(timeZoneName, timeZones) {
    const timeZone = timeZones.find(tz => tz.Description === timeZoneName);
    if (!timeZone) {
      console.error('Time zone not found');
      return;
    }
    
    const offset = getCurrentOffset(timeZone);
    
    // Update user profile with the correct offset
    return offset
  }

  export {
    updateUserProfile
  }