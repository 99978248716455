import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';

const BlogPreview = ({ blogData, onBack }) => {
    const { title, slug, metaDescription, tags, content, author, featuredImage, publishDate } = blogData;

    // Sample image for preview if no featured image is selected
    const sampleImage = "https://picsum.photos/800/400";

    return (
        <Container className="blog-view-container py-5">
            <Row>
                <Col>
                    <Button
                        variant="outline-secondary"
                        onClick={onBack}
                        className="mb-4"
                        style={{
                            borderRadius: '20px',
                            padding: '8px 20px'
                        }}
                    >
                        ← Back to Edit
                    </Button>

                    <article className="blog-content">
                        {(featuredImage || sampleImage) && (
                            <div className="featured-image-container mb-4">
                                <Image
                                    src={featuredImage ? URL.createObjectURL(featuredImage) : sampleImage}
                                    alt={title}
                                    style={{
                                        width: '100%',
                                        maxHeight: '400px',
                                        objectFit: 'cover',
                                        borderRadius: '12px',
                                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                                    }}
                                />
                            </div>
                        )}

                        <h1 className="blog-title mb-4" style={{
                            fontSize: '2.5rem',
                            fontWeight: '700',
                            color: '#2c3e50'
                        }}>
                            {title}
                        </h1>

                        <div className="blog-meta mb-4" style={{
                            padding: '15px',
                            backgroundColor: '#f8f9fa',
                            borderRadius: '8px',
                            marginBottom: '2rem'
                        }}>
                            <div className="">
                                <p className="mb-0">
                                    <strong>Author:</strong>{' '}
                                    <span style={{ color: '#666' }}>{author}</span>
                                </p>
                                <p className="mb-0">
                                    <strong>Published:</strong>{' '}
                                    <span style={{ color: '#666' }}>
                                        {publishDate ? new Date(publishDate).toLocaleDateString('en-US', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        }) : 'Not published yet'}
                                    </span>
                                </p>
                                <p className="mb-0">
                                    <strong>Slug:</strong>{' '}
                                    <span style={{ color: '#666' }}>{slug}</span>
                                </p>
                            </div>


                        </div>

                        <div className="meta-description mb-4" style={{
                            fontSize: '1.1rem',
                            fontStyle: 'italic',
                            color: '#666',
                            borderLeft: '4px solid #007bff',
                            paddingLeft: '1rem',
                        }}>
                            {metaDescription}
                        </div>

                        <div
                            className="blog-content-body"
                            dangerouslySetInnerHTML={{ __html: content }}
                            style={{
                                backgroundColor: '#ffffff',
                                padding: '2rem',
                                borderRadius: '12px',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                                lineHeight: '1.8',
                                fontSize: '1.1rem'
                            }}
                        />

                        {tags && tags.length > 0 && (
                            <div className="mt-3">
                                <strong>Tags:</strong>{' '}
                                {tags.map((tag, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            background: '#e9ecef',
                                            padding: '4px 12px',
                                            borderRadius: '15px',
                                            fontSize: '0.9rem',
                                            marginRight: '8px',
                                            color: '#495057',
                                            display: 'inline-block',
                                            marginBottom: '8px'
                                        }}
                                    >
                                        #{tag}
                                    </span>
                                ))}
                            </div>
                        )}


                    </article>
                </Col>
            </Row>
        </Container>
    );
};

export default BlogPreview;
