import React, { useEffect, useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  FormControlLabel,
  Radio,
  Checkbox,
  Chip,
  FormHelperText
} from '@mui/material';
import DatePicker from 'react-datepicker';
import { useNavigate, useLocation } from 'react-router-dom';
import race from "../../../../utils/race";  // Import Race Options
import { medicineApproachOptions } from "../../../../utils/medicineApproach";
import Files from '../Files';
import { AiOutlinePlus } from 'react-icons/ai';
import API from '../../../../helpers/api';
import { IoClose } from 'react-icons/io5';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import { momentFormat, numericValidation } from '../../../../utils/validations';
import { uploadFilesToCloud } from '../../../../redux/actions/cloudinary.action';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import CustomCongratulationsModal from '../../../../commonComponents/CustomCongratulationsModal';

import 'react-datepicker/dist/react-datepicker.css';
import './stepFormStyle.css'

const StepForm = ({
  dispatch,

  sellerProfile, // state to hold profile data  
  handleProfilePicChange, // Profile Pic input handler with cropper

  uploadProgress = { profilePic: false, seller_business_photos: false, files: false },
  fileLoading = { profilePic: false, seller_business_photos: false, files: false },
  languages,
  isSellerWithStaff,

  handleProductError, // Error handler for certificates
  handleFileInputChange, // Input handler for certificates
  bbServiceList, // birth bridge service
  loggedUserBBServiceList, // logged in user birth bridge services
  setSellerProfile, // state to update profile data
  profileErrors, // Error handler for profile module
  handleFileChange, // Input file upload handler for certificates
  handleShowFileView, // file viewer handler
  handleRemoveFile, // Delete handler for certificate
  loggedUser,
  getAllBBServices,
  setIsLoggedUserService,

  /** ----- Certificate Error Handler ------ */
  // getProductErrors,
  // validateYear,
  // hasAnyValue,
  // hasNonNullValue
  /** ---- Fetch Latest Data of Logged user ---- */
  getMetaUser

}) => {
  const _localStorage_sp_id = 'sp__id'
  const _localStorage_secialty = '__specialty__';

  const navigate = useNavigate();
  const location = useLocation();

  const [selectOpen, setSelectOpen] = useState(false);
  const [showFinish, setShowFinish] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(-1); // Start with section intro (-1)

  const [specialties, setSpecialty] = useState([]);
  const [specialtyLoading, setSpecialtyLoading] = useState(false); // Currenly now in use
  const [savedService, setSavedService] = useState(null);
  const [triggerRender, setTriggerRender] = useState(false);
  const [triggerHack] = useState(true);

  const [waiverUploadProgress, setWaiverUploadProgress] = useState({ disclaimer_policy_document: false })
  const [waiverFileLoading, setWaiverFileLoading] = useState({ disclaimer_policy_document: false });

  const [formData, setFormData] = useState({
    business: {
      profilePic: '',
      name: '',
      email: '',
      seller_business_desc: ''
    },
    certificates: {
      files: [],
      // type: [], 
      // title: '', 
      // expiration: '', 
      // specialty: [] 
    },
    preferences: {
      language: [],
      yearsOfExperience: '',
      race: '',
      takesHealthInsurance: '',
      medicineApproach: []
    },

    services: {
      bbservice: '',
      amount_type: '',
      desc: '',
      disclaimer_policy: '', //'no' 

    },
    // schedule: { specialty: [], date: '', payment: '', additionalInfo: '' },
  });

  const [prevFormData, setPrevFormData] = useState(); // Initialize the previous form data

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  // Get the current section key
  const currentSectionKey = Object.keys(formData)[activeStep - 1] || null;
  // console.log( isNextDisabled, isSellerWithStaff )
  useEffect(() => {
    if (location.state && location.state.redirect === 'scheduler') {
      const { currentStep } = location.state
      if (currentStep) {
        setActiveStep(currentStep);
      }
    }
  }, [location])

  const saveFormDataOnDB = async (currentSectionKey, questionIndex) => {
    const questions = currentSectionKey ? questionMap[currentSectionKey] || [] : [];
    const question = questions[questionIndex];
    const questionKey = question?.key;

    var callMeta = false

    try {
      const payload = {};
      payload[currentSectionKey] = {}; // Initialize the section in payload

      // Special logic for services
      if (currentSectionKey === 'services') {
        if (questionKey === 'bbservice') {
          payload[currentSectionKey]['bbservice'] = formData[currentSectionKey]['bbservice'];

          payload[currentSectionKey]['service_type'] = formData[currentSectionKey]['service_type'];
          if (formData[currentSectionKey]['service_type'] === 'class') {
            payload[currentSectionKey]['max_capacity'] = formData[currentSectionKey]['max_capacity'];
            payload[currentSectionKey]['is_virtual'] = formData[currentSectionKey]['is_virtual'];
          }
        } else if (questionKey === 'category') {
          payload[currentSectionKey]['category'] = formData[currentSectionKey]['category'];
        } else if (questionKey === 'amount_type') {
          // Save amount under services
          payload[currentSectionKey]['amount_type'] = formData[currentSectionKey]['amount_type'];
          payload[currentSectionKey]['amount'] = formData[currentSectionKey]['amount'];
        } else if (formData[currentSectionKey]['service_type'] === 'class') {
          if (questionKey === 'max_capacity') {
            payload[currentSectionKey]['max_capacity'] = formData[currentSectionKey]['max_capacity'];
          }
          if (questionKey === 'is_virtual') {
            payload[currentSectionKey]['is_virtual'] = formData[currentSectionKey]['is_virtual'];
          }
        } else if (questionKey === 'disclaimer_policy') {
          payload[currentSectionKey]['disclaimer_policy'] = formData[currentSectionKey]['disclaimer_policy'];
          if (['yes', 'Yes', true, 'true'].includes(formData[currentSectionKey]['disclaimer_policy'])) {
            payload[currentSectionKey]['disclaimer_policy_document'] = formData[currentSectionKey]['disclaimer_policy_document'];
          }
        } else {
          // General field saving for services
          payload[currentSectionKey][questionKey] = formData[currentSectionKey][questionKey];
        }
        payload[currentSectionKey]['serviceId'] = savedService
        /** ------- Commenting temporary on 11 December 2024 for development and fixes ----- */
        if (questionIndex === questions.length - 1) {
          payload[currentSectionKey]['is_profile_completed'] = true
          callMeta = true
        }
      } else if (currentSectionKey === 'business') {
        if (questionKey === 'name') {
          payload[currentSectionKey]['name'] = formData[currentSectionKey]['name']
          // if (isSellerWithStaff) {
            payload[currentSectionKey]['seller_personal_name'] = formData[currentSectionKey]['seller_personal_name'];
          // }
        } else {
          payload[currentSectionKey][questionKey] = formData[currentSectionKey][questionKey];
        }
      } else {
        // General field saving for other sections
        payload[currentSectionKey][questionKey] = formData[currentSectionKey][questionKey];
      }


      // API Call
      const response = await API.apiPostUrl('seller', `/setup-profile-services?section=${currentSectionKey}`, payload);

      if (response.data && response.data.success) {
        if (response.data.service) {
          setSavedService(response.data.service?._id);
          localStorage.setItem(_localStorage_sp_id, response.data.service?._id)
        }

        if (callMeta) {
          dispatch(getMetaUser())
        }
      }
    } catch (error) {
      toast.error(`Error in updating ${questionKey}`);
    }
  };



  // Load Initial Data 
  const mapSellerProfileToFormData = (profile, _savedSpecialtyOnStorage) => ({
    business: {
      profilePic: profile?.profilePic || '',
      name: profile?.name || '',
      seller_personal_name: profile?.seller_personal_name || '',
      email: profile?.email || '',
      seller_business_desc: profile?.seller_business_desc || '',
    },
    certificates: {
      files: profile?.files || [],
    },
    preferences: {
      language: profile?.language || [],
      race: profile?.race || '',
      yearsOfExperience: profile?.yearsOfExperience || '0-2 years',
      takesHealthInsurance: profile?.takesHealthInsurance || 'no',
      medicineApproach: profile?.medicineApproach || [],
    },
    services: _savedSpecialtyOnStorage
      ? { ..._savedSpecialtyOnStorage }
      : {
        bbservice: '',
        amount_type: '',
        desc: '',
        disclaimer_policy: '', //'no'
      },
  });


  /** ----------------- Constants ------------------ */

  const steps = [
    {
      label: 'Welcome to BirthBridge!',
      description: `Creating your profile takes about 5-10 minutes, and your progress is automatically saved with every step. This means you can pause anytime and pick up right where you left off, without missing a beat.

      Let’s get started and create a profile that highlights your unique skills while joining a supportive community of birth professionals. Together, we’re building something special!`
    },
    {
      label: 'Business Details',
      description: 'Fill in details about you and your business to help others get to know you!'
    },
    {
      label: 'Certifications',
      description: 'Showcase your certifications to highlight your expertise and build trust with the families you support.'
    },
    {
      label: 'Your Preferences',
      description: 'Share the types of clients, specialties, and preferences that align with your goals to personalize your experience.'
    },
    {
      label: 'Specialties Offered',
      description: 'Add the specialties you provide to showcase your services, set pricing, and help families find the support they need.'
    },
    {
      label: 'Availability',
      description: 'Set your availability so families can easily see when you’re ready to work. Keep it updated for smooth scheduling!'
    },
  ];


  const questionMap = {
    business: [
      {
        key: 'profilePic',
        label: 'Upload Business Profile Picture',
        type: 'file',
        fileType: 'image',
        required: true,
        description: 'A professional photo helps build trust and makes your profile stand out to families looking for support.'
      },
      {
        key: 'name',
        label: 'Business Name',
        type: 'text',
        required: true,
        description: 'Enter your business name to help families easily find and connect with your services.'
      },
      {
        key: 'email',
        label: 'Business Email',
        type: 'text',
        disabled: true,
        required: true,
        description: 'Please provide your email address for account access and communication.'
      },
      {
        key: 'seller_business_desc',
        label: 'Business Description',
        type: 'textarea',
        required: true,
        description: 'Share a brief description of your business, highlighting your services, values, and mission to help families connect with what makes you unique.'
      },
    ],
    certificates: [
      {
        key: 'files',
        label: 'Upload Certificate',
        type: 'file',
        fileType: 'pdf',
        required: true,
        description: `Showcase your qualifications by uploading relevant certifications. This helps families build trust in your expertise and demonstrates your commitment to professional growth.
        
        We welcome all certifications that reflect your unique contributions to supporting families,`
      },
    ],
    preferences: [
      {
        key: 'yearsOfExperience',
        label: 'Years of Experience',
        type: 'select',
        isMulti: false,
        options: [
          { value: '0-2 years', label: '0-2 years' },
          { value: '3-5 years', label: '3-5 years' },
          { value: '6-10 years', label: '6-10 years' },
          { value: '10+ years', label: '10+ years' },
        ],
        required: true,
        description: 'Select the range that best reflects your years of professional experience in the birth field to help families understand your expertise level.'
      },
      {
        key: 'language',
        label: 'Spoken Languages',
        type: 'select',
        isMulti: true,
        options: languages,
        required: true,
        description: 'Indicate the languages you speak to help families who may prefer communication in their native language.'
      },
      {
        key: 'race',
        label: 'Race',
        type: 'select',
        isMulti: false,
        options: race,
        required: true,
        description: 'This information helps BirthBridge better tailor recommendations and connections.'
      },
      {
        key: 'takesHealthInsurance',
        label: 'Do you accept Health Insurance?',
        type: 'radio',
        options: ['no', 'yes'],
        required: true,
        description: `Let families know if you accept health insurance.`,
        subText: `While BirthBridge doesn’t process insurance yet, you can handle arrangements offline. We plan to support this feature soon!`
      },

      {
        key: 'medicineApproach',
        label: 'Medical Approach',
        type: 'select',
        isMulti: true,
        options: medicineApproachOptions,
        required: true,
        description: `Share your medical or holistic approach to help families understand how your philosophy aligns with their needs and expectations. 
      
        Whether you focus on evidence-based practices, holistic care, or a blend of approaches, this is your opportunity to highlight the methods and values that guide your work.`
      },

    ],
    services: [
      {
        key: 'bbservice',
        label: 'Category',
        type: 'select',
        isMulti: false,
        options: loggedUserBBServiceList,
        required: true,
        description: `The category reflects the certifications you selected in the previous step. This ensures your services align with your qualifications and the areas of expertise you've already highlighted.
      
        Since this is based on your certifications, you won’t need to select anything outside of what you’ve already chosen. If you need to update your categories, revisit the Certifications tab to make adjustments.`
      },
      {
        key: 'category',
        label: 'Specialty',
        type: 'select',
        isMulti: false,
        options: specialties,
        required: true,
        description: `Showcase your area of expertise to help families understand the unique care and services you offer.`
      },
      {
        key: 'amount_type',
        label: 'Pricing',
        type: 'select',
        isMulti: false,
        options: [
          { label: 'Flat', value: 'fixed' },
          { label: 'Hourly', value: 'hour' }
        ],
        required: true,
        description: `Choose how to present your pricing: 
        "Flat" for a clear, set fee, or "Hourly" for flexibility based on time spent. Both options help families understand and feel confident about your pricing.`
      },
      ...((formData['services']['service_type'] === 'class')
        ? [{
          key: 'max_capacity',
          label: 'Capacity',
          type: 'text',
          required: true,
          description: `Specify the maximum number of participants for this class to ensure a comfortable and personalized experience.`
        },
        {
          key: 'is_virtual',
          label: 'Will this be a virtual class?',
          type: 'radio',

          options: [false, true],
          required: true,
          description: `Indicate whether this class is offered virtually, making it accessible from anywhere.`
        }]
        : []),
      {
        key: 'desc',
        label: 'Specialty Details',
        type: 'textarea',
        required: true,
        description: `Describe your specialties clearly. Share what families can expect and how you support them, helping them make informed decisions.`,
      },
      {
        key: 'disclaimer_policy',
        label: 'Attach a Waiver or Policy Document?',
        type: 'radio',
        options: ['no', 'yes'],
        required: true,
        description: `Indicate if you'd like to include a waiver or policy document. Select "Yes" to upload it here. Note: Agreements must be managed outside the platform for now.`

      },
    ],
  };



  useEffect(() => {
    (async () => {
      if (sellerProfile) {
        /** --------------- Retreiving Data from Localstorage (only incase if specialty left by the user to filled ) ---------- */
        var _savedSpecialtyOnStorage = localStorage.getItem(_localStorage_secialty);
        var sp_id = localStorage.getItem(_localStorage_sp_id);
        if (_savedSpecialtyOnStorage) {
          _savedSpecialtyOnStorage = JSON.parse(_savedSpecialtyOnStorage);
          sp_id = sp_id

          if (sp_id && _savedSpecialtyOnStorage.bbservice) {
            await fetchSpecialties(_savedSpecialtyOnStorage.bbservice)
            setSavedService(sp_id);

          }
        }
        /** ----------------Localstorage code  Ends --------------------- */

        const newFormData = mapSellerProfileToFormData(sellerProfile, _savedSpecialtyOnStorage);
        if (JSON.stringify(newFormData) !== JSON.stringify(formData)) {
          setFormData(newFormData);
        }
      }
    })()
  }, [sellerProfile]);

  useEffect(() => {
    if (questionIndex == -1) {
      setIsNextDisabled(false);
    } else {
      setIsNextDisabled(!isStepValid());
    }


  }, [triggerRender, formData, questionIndex, currentSectionKey]);

  /** ------------ To Validate Field ------------- */

  const isStepValid = () => {
    // Get the questions for the current section
    const questions = currentSectionKey ? questionMap[currentSectionKey] || [] : [];
    const question = questions[questionIndex];

    // If no question exists, consider the step invalid
    if (!question) return false;


    if (question.required) {

      // Get the value from formData
      const value = formData[currentSectionKey]?.[question.key];



      // Check for empty strings, null, or undefined
      if (typeof value === 'string' && value.trim() === '') return false;
      if (Array.isArray(value) && value.length === 0) return false;
      if (value === null || value === undefined) return false;

      // Check if the value is an object (but not an array)
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Ensure every key inside the object has a non-empty value
        return Object.values(value).every(
          (subValue) => subValue !== null && subValue !== undefined && subValue.toString().trim() !== ''
        );
      }

      if (currentSectionKey === 'business' && question.key === 'name') {
        if (
          // isSellerWithStaff && 
          [null, undefined, 'null', ''].includes(formData[currentSectionKey]?.['seller_personal_name']) || (typeof formData[currentSectionKey]?.['seller_personal_name'] === 'string' && formData[currentSectionKey]?.['seller_personal_name'].trim() === '')) return false;
      }

      /** ----------- Validation for certificates ------------ */
      // Special validation for the `files` field in the `certificates` section
      if (currentSectionKey === 'certificates' && question.key === 'files') {
        if (!Array.isArray(value)) return false; // Ensure `files` is an array

        for (const file of value) {
          // Validate main fields

          if (!file.url || typeof file.url !== 'string' || file.url.trim() === '') return false;
          if (!file.file_type || typeof file.file_type !== 'string' || file.file_type.trim() === '') return false;
          if (!file.name || typeof file.name !== 'string' || file.name.trim() === '') return false;
          if (!file.title || typeof file.title !== 'string' || file.title.trim() === '') return false;
          if (!file.cert_type || typeof file.cert_type !== 'string' || file.cert_type.trim() === '') return false;
          // if (file.cert_type && (typeof file.cert_type !== 'string' || file.cert_type.trim() === '')) return false;
          // Validate `last_updated` fields if they are provided
          if (file.last_updated === undefined) return false;
          if (file.last_updated) {
            const { year } = file.last_updated;
            // if (!month) return false; // Check MM format
            if (!year) return false; // Check YYYY format
            if (year === 'Year') return false; // Check YYYY format
          }

          // Validate nested `product` array
          if (!Array.isArray(file.product) || file.product.length === 0) return false;

          for (const product of file.product) {
            if (!product.service) return false; // `service` must be a valid ObjectId
            if (!Array.isArray(product.category) || product.category.length === 0) return false; // `category` must be a non-empty array
          }
        }
      }

      // if (currentSectionKey === 'services' && question.key === 'bbservice') {

      //   if ([null, undefined, 'null', ''].includes(formData[currentSectionKey]?.['category']) || (typeof formData[currentSectionKey]?.['category'] === 'string' && formData[currentSectionKey]?.['category'].trim() === '')) return false;

      //   if (formData[currentSectionKey]?.['service_type'] === 'class' && ([null, undefined, 'null', ''].includes(formData[currentSectionKey]?.['max_capacity']) || !numericValidation(formData[currentSectionKey]?.['max_capacity']) || formData[currentSectionKey]?.['max_capacity'].toString().trim() === '')) return false;
      // }

      if (currentSectionKey === 'services' && question.key === 'amount_type') {
        if ([null, undefined, 'null', ''].includes(formData[currentSectionKey]?.['amount']) || !numericValidation(formData[currentSectionKey]?.['amount']) || formData[currentSectionKey]?.['amount'].toString().trim() === '') return false;
      }

      if (currentSectionKey === 'services' && question.key === "disclaimer_policy") {
        if ([null, undefined, ''].includes(formData[currentSectionKey]?.["disclaimer_policy"])) return false;
        if (['yes', 'Yes', true, 'true'].includes(formData[currentSectionKey]?.["disclaimer_policy"]) && [null, undefined, 'null', ''].includes(formData[currentSectionKey]?.['disclaimer_policy_document'])) return false;
      }
    }

    // If no conditions failed, the step is valid
    return true;
  };


  /** ----- Removing Document ------ */
  const handleWaiverDocumentRemove = () => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [currentSectionKey]: {
          ...prevData[currentSectionKey],
          ['disclaimer_policy_document']: null
        },
      };
    });
  }

  /* ------- Handling Waiver Document Change -------- */

  const handleWaiverDocumentChange = async (event, field) => {
    const files = event.target.files;
    const formData = new FormData();
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!['application/pdf'].includes(file.type)) {
        // setErrors({ ...errors, [field]: file.name + ' file type is not supported' });
        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      if (file.size > (1024 * 1024) * 10) {
        // setErrors({ ...errors, [field]: file.name + ' size should be less than 10mb' });
        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      formData.append('files', file);
    }

    if (!hasError && formData.has('files')) { // Proceed with upload only if there are no errors
      const response = await uploadFilesToCloud(formData, setWaiverUploadProgress, setWaiverFileLoading, field);
      if (response && response.data && response.data.success) {
        setFormData((prevData) => {
          return {
            ...prevData,
            [currentSectionKey]: {
              ...prevData[currentSectionKey],
              ['disclaimer_policy_document']: { ...response.data.files[0], last_updated: moment().toDate() }
            },
          };
        });
      }
    }
  };

  const handleTrackServiceToRetreiveOnReload = (isToBeDeletedFromLocalStorage) => {
    /**
     * Active Step 4 is services steps and
     */
    if (activeStep === 4 && currentSectionKey === 'services' && !isToBeDeletedFromLocalStorage) {
      const _services = formData['services']
      localStorage.setItem(_localStorage_secialty, JSON.stringify(_services))
      // localStorage.setItem('sp_id', savedService);
    }

    if (isToBeDeletedFromLocalStorage) {
      localStorage.removeItem(_localStorage_secialty);
      localStorage.removeItem(_localStorage_sp_id);
    }
  }


  const handleNext = (skip) => {
    const questions = currentSectionKey ? questionMap[currentSectionKey] || [] : [];

    if (questionIndex === -1) {
      // Move from section intro to the first question or directly to the next section
      if (questions.length > 0) {
        setQuestionIndex(0);
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    } else {
      if (questionIndex < questions.length - 1) {
        // Move to the next question
        setQuestionIndex((prevIndex) => prevIndex + 1);
      } else {
        // Move to the next section
        setActiveStep((prevStep) => prevStep + 1);
        setQuestionIndex(-1); // Reset to section intro
      }
    }

    // Check if there is a change in form data
    const isFormDataChanged = hasFormDataChanged();

    if (isFormDataChanged &&
      // activeStep !== steps.length - 2 && 
      questionIndex !== -1) { // Avoid API call on "Start Section"
      // API call
      saveFormDataOnDB(currentSectionKey, questionIndex);
      handleTrackServiceToRetreiveOnReload(); // Track Services
    }

    /** ----- Redirect to Scheduler When Reaches Availability ------- */
    if (activeStep === 5 && !skip) {
      localStorage.removeItem(_localStorage_sp_id);
      localStorage.removeItem(_localStorage_secialty);
      navigate('/scheduler?redirect=profile', { state: { redirect: 'profile', activeStep: 5, questionIndex: -1 } })
    }


    if (activeStep === 3) {
      setIsLoggedUserService(true)
      dispatch(getAllBBServices({ userId: loggedUser.user }))
    }
  };

  const handleBack = () => {
    // if (activeStep === steps.length - 1) {
    //   // Prevent going back from the Finish screen
    //   return;
    // }

    if (questionIndex === -1) {
      // Go back to the previous section intro
      setActiveStep((prevStep) => prevStep - 1);
      const prevSectionKey = Object.keys(formData)[activeStep - 2];
      const prevQuestions = prevSectionKey ? questionMap[prevSectionKey] : [];
      setQuestionIndex(prevQuestions.length - 1);
    } else if (questionIndex > 0) {
      // Go back to the previous question
      setQuestionIndex((prevIndex) => prevIndex - 1);
    } else {
      // Go back to the section intro
      setQuestionIndex(-1);
    }
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const hasFormDataChanged = () => {
    // Compare the current form data with the previous state
    const hasChanged = !deepEqual(formData, prevFormData);

    if (hasChanged) {
      // Update the previous form data state
      setPrevFormData({ ...formData });
    }

    return hasChanged;
  };

  /**
   * 
   * @param {*} key 
   * @param {*} value 
   * @param {Object} selectedOption is only used with key equals bbservice
   * @returns 
   */
  const handleChange = async (key, value, selectedOption) => {
    if (!currentSectionKey) return;

    setFormData((prevData) => {
      const isMultiSelect = Array.isArray(value); // Check if the value is an array (for multi-select)

      const newState = {
        ...prevData,
        [currentSectionKey]: {
          ...prevData[currentSectionKey],
          [key]: isMultiSelect ? value : value, // Handle multi-select
        },
      };

      // Conditionally set `service_type` if the key is `bbservice`
      if (key === "bbservice" && selectedOption?.service_type) {
        newState[currentSectionKey].service_type = selectedOption.service_type;
        newState[currentSectionKey]['category'] = '';

        // If the `service_type` is 'service', set `amount_type` to 'fixed'
        if (selectedOption.service_type === 'service') {
          newState[currentSectionKey].amount_type = 'fixed';
        }

      }

      if (currentSectionKey === 'preferences' && key === 'language') {
        newState[currentSectionKey]['language'] = value.map(v => languages.find(lang => lang.value === v));
      }

      return newState;
    });

    if (key === "bbservice") {
      await fetchSpecialties(value);
    }
  };

  const fetchSpecialties = async (bbservice) => {
    try {
      setSpecialtyLoading(true);
      const response = await API.apiGet('category', { bbservice: bbservice, userId: loggedUser.user });
      if (response.data.success) {
        setSpecialty(response.data.categories); // Assuming the API returns an array of suggestions
      }
    } catch (error) {
    } finally {
      setSpecialtyLoading(false);
    }
  };

  const renderDynamicSelectOptions = (question, option, optionIndex) => {
    var label = option.label
    var value = option

    if (['race'].includes(question.key)) {
      label = option.name
    }

    if (['amount_type', 'race', 'medicineApproach', 'language'].includes(question.key)) {
      value = option.value
    }


    if (['bbservice', 'category'].includes(question.key)) {
      value = option._id
      label = option.name
    }

    return (
      <MenuItem
        key={optionIndex}
        value={value}
        {...(question.key === 'bbservice' ? { 'data-service-type': option.service_type } : {})}
      >
        {label}
      </MenuItem>
    )
  }

  const renderDynamicSelectedValue = (question, selected) => {
    let selectedChip = (label, index) => <Chip key={index} label={label} />

    if (question.isMulti) {
      return (
        <div
          style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
        >
          {selected.map((item, itemIndex) => {
            var label = item
            if (['medicineApproach'].includes(question.key)) {
              label = medicineApproachOptions.find((mA) => mA.value === item)?.label
            } else if (['language'].includes(question.key)) {
              label = languages.find(lang => lang.value === item)?.label
            } else {
              label = typeof item === 'string' ? item : item.label
            }

            return selectedChip(label, itemIndex)
          })}
        </div>
      )
    } else {
      // Single
      if (['race'].includes(question.key)) {
        return race.find((r) => r.value === selected)?.name
      } else if (['bbservice'].includes(question.key)) {
        return loggedUserBBServiceList.find(bbs => bbs._id === selected)?.name
      } else if (['category'].includes(question.key)) {
        return specialties.find(s => s._id === selected)?.name
      } else if (['amount_type'].includes(question.key)) {
        return question.options.find(at => at.value === selected)?.label
      } else {
        return selected?.label || selected
      }
    }
  }

  const getSelectInputValue = (sectionData, question) => {

    if (question.key === 'language') {
      return sectionData['language']?.map(la => la.value);
    }

    return sectionData[question.key] || (question.isMulti ? [] : '')
  }


  const renderStepContent = () => {
    // if (activeStep === steps.length - 1) {
    //   // Render Finish screen

    // }

    if (questionIndex === -1) {
      // Render section intro
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h5">{steps[activeStep].label}</Typography>
          <Typography
            variant="body1"
            style={{ margin: '20px 0', textAlign: 'center', whiteSpace: 'pre-line' }}
          >
            {steps[activeStep].description}
          </Typography>
        </div>
      );
    }

    const questions = currentSectionKey ? questionMap[currentSectionKey] || [] : [];
    const question = questions[questionIndex];

    if (!question) {
      // Safeguard: If questionIndex is out of bounds
      // console.error(`Invalid questionIndex: ${questionIndex}`);
      return <Typography>Invalid question. Please try again.</Typography>;
    }

    const sectionData = formData[currentSectionKey] || {};

    return (
      <div style={{ maxWidth: '700px', margin: 'auto' }}>
        <div className='mt-2 mb-2 text-center'>
          <Typography
            variant="h6"
            gutterBottom
          >
            {question.label}
          </Typography>
          {question.description && (
            <Typography
              variant="body1"
              style={{ margin: '20px 0', whiteSpace: 'pre-line', textAlign: 'center' }}
            >
              {question.description}
            </Typography>
          )}
        </div>
        {/* ------- Profile Picture Upload ------- */}
        {(question.type === 'file' && question.key === 'profilePic') &&
          (
            <div className='signup--step--profile--picture'>
              <label htmlFor="profile__upload" className="text-center">
                {fileLoading.profilePic
                  ? <p>Loading...</p>//<LoadingView size={48} />
                  : <img
                    className="profile_picture"
                    loading="lazy"
                    style={{ height: '250px', width: '100%' }}
                    src={
                      (sectionData[question.key] && sectionData[question.key].url)
                        ? sectionData[question.key].url
                        : "/images/profile.png"
                    }
                  />
                }
              </label>
              <input
                hidden
                id="profile__upload"
                type="file"
                name={'profilePic'}
                accept={question.fileType === 'image' ? 'image/*' : question.fileType}
                disabled={fileLoading.profilePic}
                onChange={(e) => fileLoading.profilePic
                  ? null
                  : handleProfilePicChange(e, 'profilePic')
                }
              />
              {uploadProgress[question.key] > 0 && <p className="upload-progress-status">Uploaded {uploadProgress[question.key]}%</p>}
            </div>
          )}

        {/* --------- Certificate File Upload  -------- */}
        {(question.type === 'file' && question.key === 'files') &&
          (
            <div>
              <input
                type="file"
                id="upload--file"
                hidden
                multiple
                onChange={(e) => fileLoading.files ? null : handleFileChange(e, 'files')}
                accept={['image/jpeg', 'application/pdf', 'image/png', 'image/jpg']}
                disabled={fileLoading.files}
              />
              <label
                className='signup--step--upload--certificate'
                htmlFor="upload--file"
              >
                {fileLoading.files
                  ? <>{uploadProgress.files > 0 && `Uploaded ${uploadProgress.files}%`} Please Wait....</>
                  : <> <AiOutlinePlus /> Upload Certificate (JPG, PNG, or PDF, Max 10MB)</>
                }
              </label>
              {/* Note about certificate deletion */}
              <small style={{ display: 'block', marginTop: '10px', color: '#6c757d' }}>
                Note: Certificates cannot be deleted through the website. If you'd like to remove it, please contact us at <a href="mailto:hello@mybirthbridge.com">hello@mybirthbridge.com</a> for assistance.
              </small>

              {/* ------- Certificate Input fields -------- */}
              {(sectionData[question.key] && sectionData[question.key].length > 0) &&
                <div
                  className="certificate-files mt-2 mb-2"
                  id="certificate-files"
                >
                  {sectionData[question.key].map((file, fileIndex) => {
                    return (
                      <Files
                        setTriggerRender={setTriggerRender}
                        triggerRender={triggerRender}
                        triggerHack={triggerHack}
                        key={fileIndex}
                        file={file}
                        SellerProfile={sellerProfile}
                        setSellerProfile={setSellerProfile}
                        filesIndex={fileIndex}
                        handleShowFileView={handleShowFileView}
                        handleRemoveFile={handleRemoveFile}
                        handleFileInputChange={handleFileInputChange}
                        bbServices={bbServiceList}
                        productError={profileErrors?.products?.[fileIndex]}
                        certificateTimeError={profileErrors?.certificateTime?.[fileIndex]}
                        setProductErrors={(productIndex, field) => handleProductError(fileIndex, productIndex, field)}
                      />
                    )
                  })}
                </div>
              }
            </div>
          )
        }
        {question.type === 'textarea' && (
          <Box fullWidth margin="normal">
            {/* Custom Label */}
            {/* <label htmlFor={`text-field-${question.key}`} style={{ display: 'block', marginBottom: '8px', fontWeight: 'bold' }}>
              {question.label}
            </label> */}

            {/* TextField without the `label` prop */}
            <TextField
              id={`text-field-${question.key}`}
              value={sectionData[question.key] || ''}
              onChange={(e) => handleChange(question.key, e.target.value)}
              multiline
              rows={4}
              fullWidth
              margin="normal"
              error={question.required && !sectionData[question.key]}
              helperText={
                question.required && !sectionData[question.key]
                  ? `${question.label} is required`
                  : ''
              }
            />
          </Box>
          // <TextField
          //   label={question.label}
          //   value={sectionData[question.key] || ''}
          //   onChange={(e) => handleChange(question.key, e.target.value)}
          //   multiline
          //   rows={4}
          //   fullWidth
          //   margin="normal"
          //   error={question.required && !sectionData[question.key]}
          //   helperText={
          //     question.required && !sectionData[question.key]
          //       ? `${question.label} is required`
          //       : ''
          //   }
          // />
        )}

        {question.type === 'text' && (
          <>
            <label style={{ marginBottom: 5 }}>{question.label}</label>
            <TextField
              style={{ marginTop: 6 }}
              type={question.key === 'max_capacity' ? "number" : "text"}
              // placeholder={question.label}
              value={sectionData[question.key] || ''}
              onChange={(e) => handleChange(question.key, e.target.value)}
              fullWidth
              margin="normal"
              disabled={question.disabled || false}
              error={question.required && !sectionData[question.key]}
              helperText={
                question.required && !sectionData[question.key]
                  ? `${question.label} is required`
                  : ''
              }
            />
          </>
        )}

        {(
          // isSellerWithStaff && 
        currentSectionKey === 'business' && question.key === "name") && (
          <div style={{ marginTop: 23 }}>
            <label style={{ marginBottom: 5 }}>{'Your name'}</label>
            <TextField
              style={{ marginTop: 6 }}
              // label={'Your Name'}
              value={sectionData['seller_personal_name'] || ''}
              onChange={(e) => handleChange('seller_personal_name', e.target.value)}
              fullWidth
              margin="normal"
              // disabled={question.disabled || false}
              error={
                // isSellerWithStaff && 
                !sectionData['seller_personal_name']}
              helperText={
                // isSellerWithStaff && 
                !sectionData['seller_personal_name']
                  ? `Your name is required`
                  : ''
              }
            />
          </div>
        )}

        {question.type === 'date' && (
          <DatePicker
            selected={sectionData[question.key] || ''}
            onChange={(date) => handleChange(question.key, date)}
            dateFormat="MM/dd/yyyy"
            placeholderText="Select a date"
            className="form-control"
          />
        )}

        {question.type === 'select' && (
          <FormControl fullWidth margin="normal">
            <label>{question.label}</label>
            {/* <InputLabel>{question.label}</InputLabel> */}
            <Select
              value={
                getSelectInputValue(sectionData, question)
              } // Default value for multi-select or single-select
              onChange={(e) => {
                var selectedOption = null;

                // Apply selectedOption logic only for `bbservice`
                if (question.key === 'bbservice') {
                  selectedOption = question.options.find((opt) => opt._id === e.target.value);
                }

                handleChange(question.key, e.target.value, selectedOption); // Pass selectedOption only for `bbservice`

                // Force close the dropdown after selection
                setSelectOpen(false);
              }}
              multiple={!!question.isMulti} // Ensure `multiple` is always a boolean
              renderValue={(selected) => renderDynamicSelectedValue(question, selected)}
              {...(question.key === 'amount_type' && sectionData['service_type'] === 'service' ? { disabled: true } : {})}
              open={selectOpen} // Controlled open state
              onOpen={() => setSelectOpen(true)} // Open the dropdown
              onClose={() => setSelectOpen(false)} // Close the dropdown
            >
              {question.options.map((option, optionIndex) => (
                renderDynamicSelectOptions(question, option, optionIndex)
              ))}
            </Select>
          </FormControl>
        )}

        {/* -------- Service Amount --------  */}
        {
          (question.key === "amount_type" && sectionData[question.key]) &&
          (
            <FormControl fullWidth margin="normal">
              <label>Amount</label>
              {/* <label>{question.options.find(at => at.value === sectionData[question.key]).label}</label> */}
              <TextField
                type="number"
                // label=
                value={sectionData['amount'] || ''}
                onChange={(e) => handleChange('amount', e.target.value)}
                fullWidth
                margin="normal"
              />
            </FormControl>
          )
        }

        {question.type === 'radio' && (
          <div className="hihihi" style={{ textAlign: 'center' }}>
            {question.options.map((option) => (
              <FormControlLabel
                key={option}
                style={{ textAlign: 'center' }}
                control={
                  <Radio
                    style={{ textAlign: 'center' }}
                    checked={sectionData[question.key] === option}
                    onChange={() => handleChange(question.key, option)}
                    value={option}
                  />
                }
                label={
                  question.key === 'is_virtual'
                    ? option === true ? 'Yes' : 'No'
                    : option.charAt(0).toUpperCase() + option.slice(1)} // Capitalize the first character
              />
            ))}
          </div>
        )}

        {/* --------- Waiver Policy Upload --------- */}
        {(question.key === 'disclaimer_policy' && sectionData[question.key] === 'yes') &&
          (
            <Form.Group className="col-sm-12">
              <Form.Label>Waiver Policy Document</Form.Label>
              {sectionData['disclaimer_policy_document'] ? (
                <p className="cert__title mb-3 d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                  <span className="mr-2">
                    {sectionData['disclaimer_policy_document'].name}{' '}
                    {sectionData['disclaimer_policy_document'].last_updated && (
                      <strong>
                        {`(Last Updated: ${momentFormat.dateTimeFormat(
                          sectionData['disclaimer_policy_document'].last_updated
                        )})`}
                      </strong>
                    )}
                  </span>

                  <span
                    className="ml-auto badge-success px-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleShowFileView(sectionData['disclaimer_policy_document'])}
                  >
                    View
                  </span>
                  <span
                    className="cerificate--files--close badge-danger ml-1 px-1"
                    onClick={() => handleWaiverDocumentRemove('disclaimer_policy_document')}
                    style={{ cursor: 'pointer' }}
                  >
                    <IoClose />
                  </span>
                </p>
              ) : (
                <>
                  <input
                    type="file"
                    id="upload--file"
                    hidden
                    onChange={(e) =>
                      waiverFileLoading.disclaimer_policy_document
                        ? null
                        : handleWaiverDocumentChange(e, 'disclaimer_policy_document')
                    }
                    accept={['application/pdf']}
                    disabled={waiverFileLoading.disclaimer_policy_document}
                  />
                  <label htmlFor="upload--file">
                    {waiverFileLoading.disclaimer_policy_document ? (
                      <>
                        {' '}
                        {waiverUploadProgress.disclaimer_policy_document > 0 &&
                          `Uploaded ${waiverUploadProgress.disclaimer_policy_document}%`}{' '}
                        Please Wait...{' '}
                      </>
                    ) : (
                      <>
                        {' '}
                        <AiOutlinePlus /> Upload Waiver Policy Document in ( pdf ),
                        must be less than 10mb in size
                      </>
                    )}
                  </label>
                </>
              )}
            </Form.Group>
          )}

        {question.type === 'checkbox' && (
          <FormControlLabel
            control={
              <Checkbox
                checked={sectionData[question.key] || false}
                onChange={(e) => handleChange(question.key, e.target.checked)}
              />
            }
            label={question.label}
          />
        )}
      </div>
    );
  };


  const handleSkipAvailability = () => {
    Swal.fire({
      title: 'Are You Sure?',
      html: `
        <p>Skipping your availability setup might make it harder for families to find and connect with you when they need support.</p>
        <p>Keeping your availability up-to-date ensures seamless scheduling, minimizes conflicts, and shows families when you're ready to work with them.</p>
        <p>If you'd like to come back to this later, that's okay too! You can always adjust your availability anytime from your profile settings.</p>
        <p><strong>Would you like to proceed without setting up your availability now?</strong></p>
      `,
      icon: 'warning',
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-secondary'
      },
      preConfirm: () => {
        return new Promise((resolve) => {
          // handleNext(true) // Uncomment this if necessary to handle skipping the section
          localStorage.removeItem(_localStorage_sp_id);
          localStorage.removeItem(_localStorage_secialty);
          setShowFinish(true); // Finish Step
          resolve();
        });
      }
    });
    // Swal.fire({
    //   title: 'Are You Sure?',
    //   // text: ``,
    //   icon: 'success',
    //   confirmButtonText: 'Yes',
    //   showCancelButton: true,
    //   cancelButtonText: 'No',
    //   customClass: {
    //     confirmButton: 'btn btn-primary',
    //   },
    //   preConfirm: () => {
    //     return new Promise((resolve) => {
    //       // handleNext(true) // here true represent the skip section
    //       setShowFinish(true); // Finish Step
    //       resolve();
    //     });
    //   }
    // })
  }

  const handleAddNewSpecialty = () => {
    setFormData((prevData) => {
      return {
        ...prevData,
        ['services']: {
          amount_type: "",
          bbservice: "",
          desc: "",
          disclaimer_policy: ""
        },
      };
    });
    handleTrackServiceToRetreiveOnReload(true); // Delete Service Retreival From Local Storage
  }

  const questions = currentSectionKey ? questionMap[currentSectionKey] || [] : [];
  const question = questions[questionIndex];

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop="20px"
      padding="20px"
    >
      <Box sx={{ width: '100%', overflowX: 'auto' }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  '& .MuiStepIcon-root': {
                    color: index === activeStep ? '#5b6e74 !important' : '#d3d3d3',
                  },
                  '& .MuiStepLabel-label': {
                    fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' }, // Adjust font size for smaller screens
                    whiteSpace: 'nowrap', // Prevent text wrapping
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Box marginY="20px" width="100%" textAlign={activeStep === steps.length - 1 ? 'center' : 'inherit'}>
        {renderStepContent()}
      </Box>
      {question?.subText && <Box>
        <FormHelperText style={{ fontSize: '14px' }}>
          {question.subText}
        </FormHelperText>
      </Box>}

      <Box
        display="flex"
        flexDirection='row'
        alignItems={'center'}
        justifyContent='space-evenly'
        flexWrap="wrap"
        marginTop="20px"
      >
        <div style={{ display: 'flex', }}>
          {activeStep > 0 && activeStep <= steps.length - 1 && (
            <Button
              onClick={handleBack}
              variant="outlined"
              style={{
                height: 45,
                margin: 10,
                cursor: `${'pointer'}`
              }}
            >
              Back
            </Button>
          )}



          {(activeStep === 5 && questionIndex === -1) &&
            <>
              <Button
                onClick={() => handleSkipAvailability()} // true is used to skip the current section
                variant="outlined"
                style={{
                  margin: 10,
                  color: 'white',
                  backgroundColor: '#d77e5e',
                  borderColor: '#8a9ca2',
                  height: 45,
                  cursor: `${'pointer'}`
                }}
              >
                Skip
              </Button>
              <Button
                onClick={() => {
                  setActiveStep(4)
                  setQuestionIndex(0)
                  setSavedService(null)
                  handleAddNewSpecialty()
                }
                } // true is used to skip the current section
                variant="outlined"
                style={{
                  margin: 10,
                  width: 'max-content',
                  backgroundColor: '#d7ecf0 !important',
                  color: 'black !important',
                  borderColor: '#8a9ca2',
                  height: 45,
                  cursor: `${'pointer'}`
                }}
              >
                Create Another Specialty
              </Button>
            </>

          }
        </div>

        <div style={{ margin: 8 }}>
          <Button
            onClick={() => handleNext(false)} // No Skips if false
            variant="contained"
            color="primary"
            disabled={questionIndex === -1 ? false : isNextDisabled} // Disable button if the step is invalid || enable always if start section displayed
            style={{
              backgroundColor: isNextDisabled ? '#8a9ca2' : '#5b6e74',
              width: 200,
              height: 45,
              margin: 10,
              cursor: `${isNextDisabled ? 'not-allowed' : 'pointer'}`
            }}
          >
            {questionIndex === -1
              ? 'Start Section'
              : 'Next'
            }
          </Button>
        </div>

        {showFinish &&
          <CustomCongratulationsModal
            show={showFinish}
            onHide={() => setShowFinish(false)}
          />
        }
      </Box>
    </Box>
  );
};

export default StepForm;