import React, { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import StepAddress from '../Authentication/UserSignUp/seller/stepAddress';
import API from '../../helpers/api';
import { useDispatch } from 'react-redux';
import './style.css';

const AddressModal = ({ show, onHide, getMetaUser }) => {
    const dispatch = useDispatch();
    const [ user, setUser ] = useState({ address:{} });
    const [ error, setError] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ alert, setAlert] = useState({ variant:'', show:false, message:''});

    const handleChangeInput = (e) => {
        setAlert({ variant: '', show: false, message: '' }); // Reset alert
        let { name, value } = e.target;
    
        // Update address fields
        if (name.startsWith('address.')) {
            const addressKey = name.split('.')[1]; // Extract the specific address key (e.g., 'street', 'city')
            setUser((prevUser) => ({
                ...prevUser,
                address: {
                    ...prevUser.address,
                    [addressKey]: value, // Update the specific address property
                },
            }));
        } else {
            // Update other fields
            setUser((prevUser) => ({
                ...prevUser,
                [name]: value,
            }));
        }
    
        // Reset error for the specific field
        setError((prevError) => ({
            ...prevError,
            [name]: '',
        }));
    };
    

    const updateAddress = async () => {
        // API call to update address
        // For simplicity, I'm assuming it's a POST request
        try {
            setAlert({ variant: '', show:false, message: ''});
            setLoading(true);
            const response = await API.apiPostUrl('user', `/update-address`, user);
            if(response.data && response.data.success){
                // console.log(response.data);
                dispatch(getMetaUser())
            }
        } catch (error) {
            let message = ''
            if(error.response.data.message){
                message = error.response.data.message
                setAlert({ variant: 'danger', message, show: true});
            }
        } finally {
            setLoading(false);
            onHide();
        }
    }


    return (
        <Modal
            show={show}
            onHide={() => onHide()}
            size="lg"
            backdrop='static'
            // style={{ height: '500px' }}
            className='address--modal'
        >
            <Modal.Header className='text-center'>
                Add Your Address to Continue
            </Modal.Header>
            <Modal.Body>
                {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
                {/* <h5>Enter your address</h5> */}
                <StepAddress 
                    error={error}
                    setError={setError}
                    label={'Type in your address'}
                    handleChangeInput={handleChangeInput}
                    seller={user}
                />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                {/* <Button variant="secondary" onClick={() => onHide()}>Close</Button> */}
                <Button variant="primary" onClick={() => updateAddress()} disabled={loading}>{loading ? 'Saving...': 'Save'}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddressModal;