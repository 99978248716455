import {
    POST_SERVICES_SUCCESS,
    POST_SERVICES_FAILED,
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILED,
    PUT_SERVICES_SUCCESS,
    PUT_SERVICES_FAILED,
    GET_SINGLE_SERVICES_SUCCESS,
    GET_SINGLE_SERVICES_FAILED,
    GET_SERVICES_BB_SUCCESS,
    GET_SERVICES_BB_FAILED,
    GET_FILTER_SERVICES_SUCCESS,
    GET_FILTER_SERVICES_FAILED,

    RESET_REDUX_STATE
} from "../actions/types";

const initialState = {
  services: null,
  servicesDetails:null,
  ServicesAdd:null,
  errorServicesPost:null,
  error: null,
  clearServicesUpdateDispatched: false,
  BBService:null,
  errorBBService:null,
};

const servicesReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_SERVICES_SUCCESS :
            return {
                ...state,
                servicesDetails : action.payload ,
                error: null
            }
        case GET_SERVICES_FAILED : 
            return {
                ...state,
                error : action.payload ,
            }
        case POST_SERVICES_SUCCESS:
            return {
                ...state,
                ServicesAdd : action.payload,
                errorServicesPost: null
            }
        case POST_SERVICES_FAILED: 
            return {
                ...state,
                errorServicesPost : action.payload ,
            }
        case PUT_SERVICES_SUCCESS:
            return {
                ...state,
                ServicesUpdate : action.payload,
                errorServicesupdate: null
            }
        case PUT_SERVICES_FAILED: 
            return {
                ...state,
                errorServicesupdate : action.payload ,
            }
        case GET_SINGLE_SERVICES_SUCCESS:
            return {
                getSingleService : action.payload,
                error: null
            }
        case GET_SINGLE_SERVICES_FAILED: 
            return {
                error : action.payload ,
            }
        case GET_SERVICES_BB_SUCCESS:
            return {
                ...state,
                BBService : action.payload ,
                errorBBService: null
            }
        case GET_SERVICES_BB_FAILED: 
            return {
                ...state,
                errorBBService : action.payload ,
            }
        case GET_FILTER_SERVICES_SUCCESS:
            return {
                filter: action.payload,
                error: null
            }
        case GET_FILTER_SERVICES_FAILED:
            return {
                error: action.payload
            }
        case RESET_REDUX_STATE:
            return {
                reset: null 
            }
        default: return state;
    }
};

export default servicesReducer;