import React, { useEffect, useState } from "react";
import { Col, Container, Row, Navbar, Nav, Modal, Form, Button, ListGroup , Alert} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { notValid , emailValidation , numericValidation } from "../../../utils/validations";
import { resetPassword  , login, showLoginModal} from "../../../redux/actions/auth.actions";
import SignIn from "../SignIn/SignInModel";


function ResetPassWord({email , showSignInModel , resetPassWord}) {
    const intialValue = {email: email , password: ''};
    const alertValue = { message: '', show: false, variant: ''};
    const[alert , setAlert] = useState(alertValue)
    const [user , setUser] = useState(intialValue);
    const [error, setError] = useState(intialValue);
    const [loading, setLoading] = useState(false);
    const [errorApi , setErrorApi] = useState(false);
    const [apiError , setApiError] = useState('')
    const [openSignIn , setOpenSignIn] = useState(false)
    const dispatch = useDispatch();
    const apiResult = useSelector(state => state.auth);
    const closeSignInModel = ()=>setOpenSignIn(false);

    const handleChangeInput = (e) => {
        const{name , value} = e.target ; 
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }

    const isValid = () => {
        let isValid = true
        let passwordErrorMessage = "Please provide password";
        let userNameErrorMessage = "Please provide Email";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address"
        let errorMessages = {};

        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }

        if (notValid(user.password)) {
            errorMessages['password'] = passwordErrorMessage;
            isValid = false;
        }

        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }
        return isValid;
    }


    const onSubmit = async(e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            dispatch(resetPassword(user))
            setErrorApi(true)
        }
    }

    useEffect(()=>{
        if(errorApi){
            if(apiResult.error){
                let _alert = {}
                _alert['show'] = true
                _alert['message'] = apiResult.error
                _alert['variant'] = "danger"
                setAlert(_alert)
                setApiError(apiResult.error)
                setLoading(false)
            }
        }
    },[errorApi])

    useEffect(() => {
        if (apiResult && apiResult.updateMessage) {
            let _alert = {}
            _alert['show'] = true
            _alert['message'] = apiResult.updateMessage
            _alert['variant'] = "success"
            setAlert(_alert)
            setLoading(false)
            setTimeout(() => {
                dispatch(showLoginModal())
            }, 350)
            
        }
    }, [apiResult])

    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }

  return (
        <>
            <Container>
                <Row>
                    <Col sm={12} lg={6} className="pr-lg-0">
                        <a className="company--brand">
                            <img 
                                loading='lazy'
                                src="/images/logo.webp" 
                                alt="BirthBridge Logo"
                             />
                            <span>BirthBridge</span>
                        </a>
                    </Col>
                    <Col sm={12} lg={6} className="pl-lg-0">
                        <Form>
                            {alert['show'] && showAlertMessage()}
                            <p className="mb-3 mt-2"><span>Reset Your Password</span></p>
                            {/* <Form.Group className="form-group">
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email Address"  
                                    required
                                    name="email"
                                    value={user.email}
                                    onChange={handleChangeInput}
                                />
                            {error.email && <span className="error error-message">{error.email}</span>}    
                            </Form.Group> */}
                            <Form.Group className="form-group">
                                <Form.Control 
                                    type="password" 
                                    placeholder="Password" 
                                    name="password"
                                    value={user.password || ''}
                                    onChange={handleChangeInput}
                            />
                            {error.password && <span className="error error-message">{error.password}</span>}
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={loading} onClick={(e) => onSubmit(e)}>
                                {loading ? 'Please Wait...' : 'Update'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
  )
}

export default ResetPassWord