import React, { useState, useEffect } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { IoClose, IoEye } from 'react-icons/io5';
import PhotoViewer from '../../../commonComponents/PhotoViewer';
import { deepClone, generateYears, generateMonths } from '../../../utils/validations';
import SelectCategorySuggestions from '../ServiceManagement/SelectCategorySuggestions';
import { certificateTypes } from '../../../utils/certificateTypes';
import ReactSelect from 'react-select';
import './formStyles.css';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Box } from '@mui/material';
import TestComponentRender from './TestComponentRender'
const singleProduct = {
    service: '',
    category: []
}

const showOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
]


const Files = ({
    filesIndex,
    file,
    SellerProfile,
    setSellerProfile,
    handleShowFileView,
    handleRemoveFile,
    handleFileInputChange,
    bbServices,
    productError,
    setProductErrors,
    certificateTimeError,
    setTriggerRender,
    triggerRender,
    triggerHack,
}) => {
    const [errors, setErrors] = useState([]);
    const months = generateMonths();
    const years = generateYears();

    useEffect(() => {
        if (!file.product || (Array.isArray(file.product) && !file.product.length)) {
            const { copySellerProfileFiles, copyFile } = handleClone()
            copyFile.product = []
            copyFile.product.push(singleProduct)
            copySellerProfileFiles[filesIndex] = copyFile
            handleSetSellerProfile('files', copySellerProfileFiles)
        }
    }, [file]);

    useEffect(() => {
        if (productError && productError.length) {
            setErrors(productError);
        } else {
            setErrors([]);
        }
    }, [productError])

    const handleClone = () => {
        return { copySellerProfileFiles: deepClone(SellerProfile.files), copyFile: deepClone(file) }
    }

    const handleSetSellerProfile = (field, data) => {
        setSellerProfile({ ...SellerProfile, [field]: data });
    }

    const handleChange = (value, field, index) => {
        const { copySellerProfileFiles, copyFile } = handleClone();
        copyFile.product[index][field] = value
        copySellerProfileFiles[filesIndex] = copyFile;
        setProductErrors(index, field)
        handleSetSellerProfile('files', copySellerProfileFiles)
    }

    const handleAddMoreProduct = (fileProduct) => {
        if (fileProduct) {
            setProductErrors(fileProduct.length - 1)
        } else {
            setProductErrors(0)
        }
        const { copySellerProfileFiles, copyFile } = handleClone()
        copyFile.product.push(singleProduct)
        copySellerProfileFiles[filesIndex] = copyFile
        handleSetSellerProfile('files', copySellerProfileFiles)
    }

    const removeProduct = (productIndex) => {
        const { copySellerProfileFiles, copyFile } = handleClone();
        copyFile.product.splice(productIndex, 1);
        copySellerProfileFiles[filesIndex] = copyFile
        handleSetSellerProfile('files', copySellerProfileFiles)
    }

    const renderError = (index, field) => {
        let error = null

        if (errors && errors.length) {
            if (field && typeof errors[index] === 'object' && errors[index][field]) {
                error = (<span className='error-msg'>{errors[index][field]}</span>)
            }

            if (typeof errors[index] === 'string' && errors[index]) {
                error = (<span className='error-msg'>{errors[index]}</span>)
            }
        }
        return error
    }

    return (
        <div className="certificate-files-upload mt-3" key={`_${filesIndex}`}>
            <TestComponentRender
                setTriggerRender={setTriggerRender}
                triggerRender={triggerRender}
                triggerHack={triggerHack}
                filesIndex={filesIndex}
                handleShowFileView={handleShowFileView}
                file={file}
                handleFileInputChange={handleFileInputChange}
                handleRemoveFile={handleRemoveFile}
                certificateTimeError={certificateTimeError}
                years={years}
                months={months}
            />
            {/* <p className='cert__title m-0 d-flex align-items-center'>
                <span className="mr-2" >
                    {filesIndex + 1} -
                </span>
                <span className="mr-2" >
                    {file.name}
                </span>
                {file.file_type === 'pdf'
                    ?
                    <span
                        className='ml-auto badge-success px-2'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleShowFileView(file)}
                    >
                        View
                    </span>
                    : <PhotoViewer url={file.url} title={file.name}>
                        <span
                            className='ml-auto badge-success px-2'
                            style={{ cursor: 'pointer' }}
                        >
                            View
                        </span>
                    </PhotoViewer>
                }
                {!file.is_saved &&
                    <span
                        className="cerificate--files--close badge-danger ml-1 px-1"
                        onClick={() => handleRemoveFile(filesIndex, 'files')}
                        style={{ cursor: 'pointer' }}
                    >
                        <IoClose />
                    </span>
                }
            </p>
            <Box className="col-sm-12 mt-3 p-0">
                <FormControl component="fieldset">
                    <Form.Label className="mb-2">Make Visible to Families</Form.Label>
                    <RadioGroup
                        row
                        aria-label="visible-to-families"
                        name={`visible-to-families-${filesIndex}`}
                        value={['yes', true, 'true', 'Yes'].includes(file.show) ? true : false}
                        onChange={(e) =>
                            handleFileInputChange({
                                target: { name: 'show', value: JSON.parse(e.target.value) },
                            }, filesIndex)
                        }
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                </FormControl>
            </Box>
            <Form.Group className='col-sm-12 mt-3 p-0'>
                <Form.Label>Certificate Type</Form.Label>
                <ReactSelect
                    value={file.cert_type ? certificateTypes.find(ct => ct.value === file.cert_type) : ''}
                    onChange={(e) => handleFileInputChange({ target: { name: 'cert_type', value: e.value } }, filesIndex)}
                    options={certificateTypes}
                />
            </Form.Group>
            <Form.Group className="col-sm-12 p-0">
                <Form.Label>Certificate Title</Form.Label>
                <Form.Control
                    type="text"
                    name="title"
                    value={file?.title || ''}
                    onChange={(e) => handleFileInputChange(e, filesIndex)}
                    placeholder={`e.g., DONA`}
                />
            </Form.Group>
            <Form.Group className="col-sm-12 p-0">
                <Form.Label>Certificate Expiration (Year/Month)</Form.Label>
                <div className='row mt-2'>
                    <Form.Group className='col-sm-6 mb-3 mb-lg-0'>
                        <Form.Control
                            as="select"
                            aria-label="Year"
                            name='year'
                            value={file?.last_updated?.year || ''}
                            onChange={(e) => handleFileInputChange(e, filesIndex, 'last_updated')}
                        >
                            <option>Year</option>
                            {
                                years.map((year, yearIndex) => {
                                    return <option value={year} key={yearIndex}>{year}</option>
                                })
                            }
                        </Form.Control>
                        {certificateTimeError && certificateTimeError.year && <span className='error-msg'>{certificateTimeError.year}</span>}
                    </Form.Group>
                    <Form.Group className='col-sm-6 mb-0'>
                        <Form.Control
                            as="select"
                            aria-label="Month"
                            name='month'
                            value={file?.last_updated?.month || ''}
                            onChange={(e) => handleFileInputChange(e, filesIndex, 'last_updated')}
                        >
                            <option>Month</option>
                            {
                                months.map((month, monthIndex) => {
                                    return <option value={month.number} key={monthIndex}>{month.name}</option>
                                })
                            }
                        </Form.Control>
                    </Form.Group>
                </div>
            </Form.Group> */}

            <div className='d-flex align-items-center justify-content-between mb-2'>
                <Form.Label>Relevant Certificate Specialty<span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span> </Form.Label>
                <Button style={{ fontSize: '10px' }} onClick={() => handleAddMoreProduct(file.product)}>Add Specialty</Button>
            </div>
            {file.product && file.product.map((product, productIndex) => (
                <>
                    <Row key={productIndex} className='product--display'>
                        <Form.Group className='col-sm-6'>
                            <Form.Control
                                disabled={product.is_saved}
                                as="select"
                                value={product.service}
                                onChange={(e) => product.is_saved ? null : handleChange(e.target.value, 'service', productIndex)}
                            >
                                <option>Select Specialty Category </option>
                                {bbServices && bbServices.length
                                    ? bbServices?.map((item, bbIndex) => {
                                        return (
                                            <option
                                                value={item._id}
                                                data-service-type={item.service_type}
                                                key={bbIndex}
                                            >
                                                {item.name}
                                            </option>
                                        )
                                    })
                                    :
                                    <h4>Product Not Found</h4>
                                }
                            </Form.Control>
                            {renderError(productIndex, 'service')}
                        </Form.Group>
                        <Form.Group className='col-sm-6'>
                            <SelectCategorySuggestions
                                disabled={product.is_saved}
                                tags={product.category}
                                setTags={(tagsArray) => handleChange(tagsArray, 'category', productIndex)}
                                bbservice={product.service}
                                // tagsError={errors.category}
                                setErrors={(v) =>
                                    // setErrors({ ...errors, category:v})
                                    console.log('Error ', v)
                                }
                                multiselect={true}
                            />
                            {renderError(productIndex, 'category')}
                        </Form.Group>
                        {!product.is_saved && <span className='product-remove' onClick={() => removeProduct(productIndex)}><IoClose /></span>}
                    </Row>
                    {renderError(productIndex)}
                </>
            ))}
        </div>
    )
}

export default Files;