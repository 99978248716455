export const culturalExpertiseOptions = [
    { label: "Jamaican Birth Traditions", value: "Jamaican Birth Traditions" },
    { label: "Caribbean Heritage", value: "Caribbean Heritage" },
    { label: "African Diaspora Practices", value: "African Diaspora Practices" },
    { label: "Hispanic/Latino Heritage", value: "Hispanic/Latino Heritage" },
    { label: "Native American Practices", value: "Native American Practices" },
    { label: "European Traditions", value: "European Traditions" },
    { label: "South Asian Birth Traditions", value: "South Asian Birth Traditions" },
    { label: "East Asian Birth Practices", value: "East Asian Birth Practices" },
    { label: "Middle Eastern Birth Traditions", value: "Middle Eastern Birth Traditions" },
    { label: "Jewish Postpartum Care (Kodesh/Mechayei Nefesh)", value: "Jewish Postpartum Care (Kodesh/Mechayei Nefesh)" },
    { label: "Muslim Birth Practices", value: "Muslim Birth Practices" },
    { label: "Pacific Islander Traditions", value: "Pacific Islander Traditions" },
    { label: "African Traditional Birth Practices", value: "African Traditional Birth Practices" },
    { label: "Indigenous Midwifery", value: "Indigenous Midwifery" },
    { label: "Holistic and Spiritual Practices", value: "Holistic and Spiritual Practices" },
    { label: "Ayurvedic Postpartum Care", value: "Ayurvedic Postpartum Care" },
    { label: "Chinese Confinement Practices (Zuo Yue Zi)", value: "Chinese Confinement Practices (Zuo Yue Zi)" },
    { label: "Traditional Mexican Birth Support (Parteras)", value: "Traditional Mexican Birth Support (Parteras)" },
    { label: "Doula Services for LGBTQ+ Families", value: "Doula Services for LGBTQ+ Families" },
    { label: "Interfaith and Multicultural Families", value: "Interfaith and Multicultural Families" }
  ];
  
  