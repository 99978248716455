import React, { useState, useEffect, Suspense } from 'react';
import { Container } from "react-bootstrap";

import SEO from '../../seo';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import '../../App.css';
import LandingPageAboutBirthProfessionals from './LandingPageAboutBirthProfessionals.js'
import LandingPageHowWeHelpYou from './LandingPageHowWeHelpYou'
import LandingPageBlogs from '../LandingPageBlogs'
import sectionDivider from './section_divider.avif';
import Header from './header'
import OurServices from './ourServices'
const SectionDivider = ({ img }) => {
    return <section className="custom-section">
        <div className="image-container">
            <img src={img} loading="lazy" alt="Section Divider" />
        </div>
    </section>

}
const SectionDivider2 = ({ img }) => {
    return <section className="custom-section">
        <div className="image-container">
            <img src={img}
                style={{
                    width: '100%',
                    height: '400px',
                    borderRadius: '10px',
                    objectFit: 'cover',
                    objectPosition: '0px -290px' // Adjust this to move the image within the container
                }}
                loading="lazy"
                alt="Section Divider" />
        </div>
    </section>

}

const HomeComponent = () => {



    return (
        <>
            <SEO
                title="Welcome to BirthBridge"
                description="Connect with top birth professionals and easily book prenatal, birth, and postpartum services. Join BirthBridge today for seamless and supportive care."
                keywords="BirthBridge, birth professionals, prenatal care, postpartum care, doula services, midwife services, lactation consultant, birth services, family support, pregnancy care, infant care, booking birth professionals, trusted birth services"
                url="/"
            />
            <Header
                title="Empower Your Practice with BirthBridge"
                subTitle="Join a supportive platform designed for birth professionals, by birth professionals. Whether you're growing your practice, managing your schedule, or connecting with families, BirthBridge provides the tools you need to thrive. Together, we’re building a community that supports, collaborates, and empowers every step of the way."
                btnDetails={{
                    showBtn: true,
                    btnText: "Join Our Community",
                    btnUrl: '/bridger-signup'
                }}
            />
            <LandingPageAboutBirthProfessionals />
            <SectionDivider img={sectionDivider} />
            <LandingPageHowWeHelpYou />
            <OurServices />
        </>
    );
};

export default HomeComponent;