module.exports = {

    /** ---------- Time Zones ------ */
    GET_TIMEZONE_SUCCESS:"GET_TIMEZONE_SUCCESS",
    GET_TIMEZONE_FAILED:"GET_TIMEZONE_FAILED",
    /** -- COOMON -- */
    GET_UPLOAD_PROGRESS: "GET_UPLOAD_PROGRESS",
    SHOW_LOGIN_MODAL:'SHOW_LOGIN_MODAL',
    SHOW_SELLER_SIGNUP: 'SHOW_SELLER_SIGNUP',
    SHOW_NORMAL_SIGNUP: 'SHOW_NORMAL_SIGNUP',
    SHOW_VERIFY_OTP: 'SHOW_VERIFY_OTP',
    SHOW_RESET_PASS: 'SHOW_RESET_PASS',
    SHOW_REQUEST_OTP: 'SHOW_REQUEST_OTP',
    SHOW_AUTH_MODAL: 'SHOW_AUTH_MODAL',
    
    SWITCH_APP_VIEW: 'SWITCH_APP_VIEW',
    
    /** ---------------- AUTH -----------------------------------*/
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGIN_SUCCESS: " LOGIN_SUCCESS",
    LOGOUT: "LOGOUT",

    SOCIAL_LOGIN_SUCCESS: 'SOCIAL_LOGIN_SUCCESS',
    SOCIAL_LOGIN_FAILED: 'SOCIAL_LOGIN_FAILED',
    REQUEST_OTP_SUCCESS: "REQUEST_OTP_SUCCESS",
    REQUEST_OTP_FAILED: "REQUEST_OTP_FAILED",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAILED: "VERIFY_OTP_FAILED",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
    POST_JOIN_BROKER_SUCCESS: "POST_JOIN_BROKER_SUCCESS",
    POST_JOIN_BROKER_FAILED: "POST_JOIN_BROKER_FAILED",
    CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
    CREATE_ACCOUNT_FAILED: "CREATE_ACCOUNT_FAILED",
    VERIFY_EMAIL_OTP_SUCCESS: "REQUEST_EMAIL_OTP_SUCCESS",
    VERIFY_EMAIL_OTP_FAILED: "REQUEST_EMAIL_OTP_FAILED",
    CREATE_SELLER_ACCOUNT_SUCCESS: "CREATE_SELLER_ACCOUNT_SUCCESS",
    CREATE_SELLER_ACCOUNT_FAILED: "CREATE_SELLER_ACCOUNT_FAILED",
    LOGIN_FAILED_UPDATE_PASSWORD: "LOGIN_FAILED_UPDATE_PASSWORD",
    LOGIN_SUCCESS_UNPAID_SUBSCRIPTION: "LOGIN_SUCCESS_UNPAID_SUBSCRIPTION",
    PATCH_FIRST_NEW_PASSWORD_SUCCESS: "PATCH_FIRST_NEW_PASSWORD_SUCCESS",
    PATCH_FIRST_NEW_PASSWORD_FAILED: "PATCH_FIRST_NEW_PASSWORD_FAILED",
    VERIFY_STAFF_JOINING_LINK_SUCCESS:"VERIFY_STAFF_JOINING_LINK_SUCCESS",
    VERIFY_STAFF_JOINING_LINK_FAILED:"VERIFY_STAFF_JOINING_LINK_FAILED",
    AUTH_COMMON_ERROR: "AUTH_COMMON_ERROR",
    CLEAR_REDUX_STATE: "CLEAR_REDUX_STATE",

    /** ------------------ SUBSCRIPTION -------------------- */
    GET_SUBSCRIPTION_PLANS_SUCCESS: 'GET_SUBSCRIPTION_PLANS_SUCCESS',
    GET_SUBSCRIPTION_PLANS_FAILED: 'GET_SUBSCRIPTION_PLANS_FAILED',
    PURCHASE_PLAN_SUCCESS:"PURCHASE_PLAN_SUCCESS",
    PURCHASE_PLAN_FAILED:"PURCHASE_PLAN_FAILED",
    UPGRADE_PLAN_SUCCESS:"UPGRADE_PLAN_SUCCESS",
    UPGRADE_PLAN_FAILED:"UPGRADE_PLAN_FAILED",
    CANCEL_PLAN_SUCCESS:"CANCEL_PLAN_SUCCESS",
    CANCEL_PLAN_FAILED: 'CANCEL_PLAN_FAILED',
    CLEAR_SUBSCRIPTION_STATE: "CLEAR_SUBSCRIPTION_STATE",

    /**---------  user -------------------------- */
    GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
    GET_USER_PROFILE_FAILED: "GET_USER_PROFILE_FAILED",
    PUT_USER_PROFILE_SUCCESS: "PUT_USER_PROFILE_SUCCESS",
    PUT_USER_PROFILE_FAILED: "PUT_USER_PROFILE_FAILED",

    GET_USER_META_SUCCESS: "GET_USER_META_SUCCESS",
    GET_USER_META_FAILED: "GET_USER_META_FAILED",

    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILED: 'DELETE_USER_FAILED',

    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILED: 'UPDATE_PASSWORD_FAILED',

    POST_TIMEZONE_SUCCES: 'POST_TIMEZONE_SUCCESS',
    POST_TIMEZONE_FAILED: 'POST_TIMEZONE_FAILED',
    /** ------------------------------------------------ */

    /**---------  Seller -------------------------- */
    PUT_SELLER_PROFILE_SUCCESS: "PUT_SELLER_PROFILE_SUCCESS",
    PUT_SELLER_PROFILE_FAILED: "PUT_SELLER_PROFILE_FAILED",
    GET_SELLER_PROFILE_SUCCESS: "GET_SELLER_PROFILE_SUCCESS",
    GET_SELLER_PROFILE_FAILED: "GET_SELLER_PROFILE_FAILED",

    /**---------  Staff -------------------------- */
    GET_STAFF_SUCCESS: "GET_STAFF_SUCCESS",
    GET_STAFF_FAILED: "GET_STAFF_FAILED",
    POST_STAFF_SUCCESS: "POST_STAFF_SUCCESS",
    POST_STAFF_FAILED: "POST_STAFF_FAILED",
    GET_STAFF_META_SUCCESS: "GET_STAFF_META_SUCCESS",
    GET_STAFF_META_FAILED: "GET_STAFF_META_FAILED",
    DELETE_STAFF_SUCCESS: "DELETE_STAFF_SUCCESS",
    DELETE_STAFF_FAILED: "DELETE_STAFF_FAILED",
    GET_DELETED_STAFF_SUCCESS: "GET_DELETED_STAFF_SUCCESS",
    GET_DELETED_STAFF_FAILED: "GET_DELETED_STAFF_FAILED",
    INVITE_STAFF_VERIFICATION_SUCCESS: "INVITE_STAFF_VERIFICATION_SUCCESS",
    INVITE_STAFF_VERIFICATION_FAILED: "INVITE_STAFF_VERIFICATION_FAILED",
    UPDATE_STAFF_PASSWORD_SUCCESS: "UPDATE_STAFF_PASSWORD_SUCCESS",
    UPDATE_STAFF_PASSWORD_FAILED: "UPDATE_STAFF_PASSWORD_FAILED",
    GET_SINGLE_STAFF_SUCCESS: "GET_SINGLE_STAFF_SUCCESS",
    GET_SINGLE_STAFF_FAILED: "GET_SINGLE_STAFF_FAILED",
    UPDATE_ONE_STAFF_SUCCESS: 'UPDATE_ONE_STAFF_SUCCESS',
    UPDATE_ONE_STAFF_FAILED: 'UPDATE_ONE_STAFF_FAILED',

    /**---------  Services -------------------------- */
    GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
    GET_SERVICES_FAILED: "GET_SERVICES_FAILED",
    POST_SERVICES_SUCCESS: "POST_SERVICES_SUCCESS",
    POST_SERVICES_FAILED: "POST_SERVICES_FAILED",
    PUT_SERVICES_SUCCESS: "PUT_SERVICES_SUCCESS",
    PUT_SERVICES_FAILED: "PUT_SERVICES_FAILED",
    GET_SINGLE_SERVICES_SUCCESS: "GET_SINGLE_SERVICES_SUCCESS",
    GET_SINGLE_SERVICES_FAILED: "GET_SINGLE_SERVICES_FAILED",
    SERVICE_COMMON_ERROR: "SERVICE_COMMON_ERROR",
    GET_SERVICES_BB_SUCCESS: "GET_SERVICES_BB_SUCCESS",
    GET_SERVICES_BB_FAILED: "GET_SERVICES_BB_FAILED",
    GET_FILTER_SERVICES_SUCCESS: 'GET_FILTER_SERVICES_SUCCESS',
    GET_FILTER_SERVICES_FAILED: 'GET_FILTER_SERVICES_FAILED',
    RESET_REDUX_STATE: 'RESET_REDUX_STATE',

    /**---------  business -------------------------- */
    GET_ALL_BUSINESS_SUCCESS: "GET_ALL_BUSINESS_SUCCESS",
    GET_ALL_BUSINESS_FAILED: "GET_ALL_BUSINESS_FAILED",
    GET_SINGLE_BUSINESS_SUCCESS: "GET_SINGLE_BUSINESS_SUCCESS",
    GET_SINGLE_BUSINESS_FAILED: "GET_SINGLE_BUSINESS_FAILED",

    GET_TOPRATED_BUISNESS_SUCCESS: 'GET_TOPRATED_BUISNESS_SUCCESS',
    GET_TOPRATED_BUISNESS_FAILED: 'GET_TOPRATED_BUISNESS_FAILED',

    GET_TOPFAVOURITE_BUISNESS_SUCCESS:'GET_TOPFAVOURITE_BUISNESS_SUCCESS',
    GET_TOPFAVOURITE_BUISNESS_FAILED: 'GET_TOPFAVOURITE_BUISNESS_FAILED',

    GET_NEAR_BY_BUSINESS_SUCCESS: "GET_NEAR_BY_BUSINESS_SUCCESS",
    GET_NEAR_BY_BUSINESS_FAILED:"GET_NEAR_BY_BUSINESS_FAILED",

    GET_ALL_BIRTH_PROFESSIONALS_SUCCESS: "GET_ALL_BIRTH_PROFESSIONALS_SUCCESS",
    GET_ALL_BIRTH_PROFESSIONALS_FAILED: "GET_ALL_BIRTH_PROFESSIONALS_FAILED",

/**---------  Add to card -------------------------- */
    // ADD_TO_CARD_SUCCESS: "ADD_TO_CARD_SUCCESS",
    // ADD_TO_CARD_FAILED:"ADD_TO_CARD_FAILED",
    GER_CART_ITEM_SUCCESS:"GER_CART_ITEM_SUCCESS",
    GER_CART_ITEM_FAILED:"GER_CART_ITEM_FAILED",
    DELETE_CART_ITEM_SUCCESS:"DELETE_CART_ITEM_SUCCESS",
    DELETE_CART_ITEM_FAILED:"DELETE_CART_ITEM_FAILED",
    CLEAR_ADD_TO_CART_STATUS:"CLEAR_ADD_TO_CART_STATUS",
    CLEAR_ERROR:"CLEAR_ERROR",

/**---------  auth -------------------------- */


/** ---------- STRIPE ----------- */
    STRIPE_CONNECT_SUCCESS: 'STRIPE_CONNECT_SUCCESS',
    STRIPE_CONNECT_FAILED: 'STRIPE_CONNECT_FAILED',
    GET_STRIPE_ACCOUNT_SUCCESS: 'GET_STRIPE_ACCOUNT_SUCCESS',
    GET_STRIPE_ACCOUNT_FAILED: 'GET_STRIPE_ACCOUNT_FAILED',
    GET_ONBOARDING_SUCCESS: 'GET_ONBOARDING_SUCCESS',
    GET_ONBOARDING_FAILED: 'GET_ONBOARDING_FAILED',
    GET_PAYMENT_MODE_SUCCESS:'GET_PAYMENT_MODE_SUCCESS',
    GET_PAYMENT_MODE_FAILED:"GET_PAYMENT_MODE_FAILED",
    PUT_PAYMENT_MODE_SUCCESS:'PUT_PAYMENT_MODE_SUCCESS',
    PUT_PAYMENT_MODE_FAILED: 'PUT_PAYMENT_MODE_FAILED',

/** -------------- PLACES ---------- */
    GET_PLACES_SUCCESS:'GET_PLACES_SUCCESS',
    GET_PLACES_FAILED:'GET_PLACES_FAILED',
    GET_PLACE_DETAIL_SUCCESS: 'GET_PLACE_DETAIL_SUCCESS',
    GET_PLACE_DETAIL_FAILED: 'GET_PLACE_DETAIL_FAILED',
    GET_GEOIP_LOCATION_SUCCESS: 'GET_GEOIP_LOCATION_SUCCESS',
    GET_GEOIP_LOCATION_FAILED: 'GET_GEOIP_LOCATION_FAILED',
    CLEAR_PLACE_REDUX_STATE:'CLEAR_PLACE_REDUX_STATE',

/** ------------- SEARCH ---------- */
    GET_BUSINESS_NAMELOCATION_SUCCESS: 'GET_BUSINESS_NAMELOCATION_SUCCESS',
    GET_BUSINESS_NAMELOCATION_FAILED: 'GET_BUSINESS_NAMELOCATION_SUCCESS',
    GET_SEARCHED_BUSINESSES_SUCCESS:'GET_SEARCHED_BUSINESSES_SUCCESS',
    GET_SEARCHED_BUSINESSES_FAILED:'GET_SEARCHED_BUSINESSES_FAILED',
    GET_SEARCH_RESULTS_SUCCESS: 'GET_SEARCH_RESULTS_SUCCESS',
    GET_SEARCH_RESULTS_FAILED: 'GET_SEARCH_RESULTS_FAILED',
    GET_LIST_TO_SEARCH_SUCCESS: 'GET_LIST_TO_SEARCH_SUCCESS',
    GET_LIST_TO_SEARCH_FAILED: 'GET_LIST_TO_SEARCH_FAILED',

/** ----  Discover  */

    LIST_DISCOVER_SUCCESS:"LIST_DISCOVER_SUCCESS",
    LIST_DISCOVER_FAILED:"LIST_DISCOVER_FAILED",

/** ------------ TIME SLOTS ----------- */
    GET_AVAILABLE_DATES_SUCCESS:'GET_AVAILABLE_DATES_SUCCESS',
    GET_AVAILABLE_DATES_FAILED: 'GET_AVAILABLE_DATES_FAILED',
    GET_TIME_SLOTS_SUCCESS:'GET_TIME_SLOTS_SUCCESS',
    GET_TIME_SLOTS_FAILED: 'GET_TIME_SLOTS_FAILED',
    REFRESH_TIMESLOT:'REFRESH_TIMESLOT',

/** ------------ BOOKMARK ------------- */
    POST_BOOKMARK_SUCCESS:'POST_BOOKMARK_SUCCESS',
    POST_BOOKMARK_FAILED:'POST_BOOKMARK_FAILED',
    GET_BOOKMARK_SUCCESS:'GET_BOOKMARK_SUCCESS',
    GET_BOOKMARK_FAILED:'GET_BOOKMARK_FAILED',
    DELETED_BOOKMARK_SUCCESS:'DELETED_BOOKMARK_SUCCESS',
    DELETED_BOOKMARK_FAILED:'DELETED_BOOKMARK_FAILED',
    GET_ONE_BOOKMARK_SUCCESS: 'GET_ONE_BOOKMARK_SUCCESS',
    GET_ONE_BOOKMARK_FAILED: 'GET_ONE_BOOKMARK_FAILED',
    
/** ----------- APPOINTMENT / ORDERS---------- */
    /** -------- Buyers ------ */
    GET_BUYER_ORDERS_SUCCESS:'GET_BUYER_ORDERS_SUCCESS',
    GET_BUYER_ORDERS_FAILED: 'GET_BUYER_ORDERS_FAILED',

    /** ------- Sellers /Staff ------ */
    GET_BUSINESS_ORDERS_SUCCESS:'GET_BUSINESS_ORDERS_SUCCESS',
    GET_BUSINESS_ORDERS_FAILED: 'GET_BUSINESS_ORDERS_FAILED',
    /* ----------------------- */

    APPROVE_ORDER_SUCCESS: 'APPROVE_ORDER_SUCCESS',
    APPROVE_ORDER_FAILED: 'APPROVE_ORDER_FAILED',

    CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
    CANCEL_ORDER_FAILED: 'CANCEL_ORDER_FAILED',

    COMPLETE_ORDER_SUCCESS: 'COMPLETE_ORDER_SUCCESS',
    COMPLETE_ORDER_FAILED: 'COMPLETE_ORDER_FAILED',

    RELEASE_SLOT_SUCCESS: 'RELEASE_SLOT_SUCCESS',
    RELEASE_SLOT_FAILED: 'RELEASE_SLOT_FAILED',

    RESET_ORDER_STATE:'RESET_ORDER_STATE',
    /** -------------- WALLET --------------- */
    GET_WALLET_SUCCESS: 'GET_WALLET_SUCCESS',
    GET_WALLET_FAILED: 'GET_WALLET_FAILED',

    WITHDRAW_BAL_SUCCESS: 'WITHDRAW_BAL_SUCCESS',
    WITHDRAW_BAL_FAILED: 'WITHDRAW_BAL_FAILED',
    
    /** ------ RATING ----- */
    /** ---- Called in Order ---- */
    POST_RATING_SUCCESS: 'POST_RATING_SUCCESS',
    POST_RATING_FAILED: 'POST_RATING_FAILED',

    GET_REVIEW_SUCCESS: 'GET_REVIEW_SUCCESS',
    GET_REVIEW_FAILED: 'GET_REVIEW_FAILED',

    /** ----- SERVICE SCHEDULER ------ */
    POST_SCHEDULE_SUCCESS: 'POST_SCHEDULER_SUCCESS',
    POST_SCHEDULE_FAILED:'POST_SCHEDULER_FAILED',
    GET_SCHEDULES_SUCCESS: 'GET_SCHEDULES_SUCCESS',
    GET_SCHEDULES_FAILED: 'GET_SCHEDULES_FAILED',
    PUT_SCHEDULES_SUCCESS:'PUT_SCHEDULES_SUCCESS',
    PUT_SCHEDULES_FAILED: 'PUT_SCHEDULES_FAILED',
    DELETE_SCHEDULES_SUCCESS: 'DELETE_SCHEDULES_SUCCESS',
    DELETE_SCHEDULES_FAILED: 'DELETE_SCHEDULES_FAILED',


    /** ----------- TRANSACTIONS ------------- */
    GET_TRANSACTIONS_SUCCESS: 'GET_TRANSACTIONS_SUCCESS',
    GET_TRANSACTIONS_FAILED: 'GET_TRANSACTIONS_FAILED',

    /** ------------ CATEGORIES -------------- */
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILED: 'GET_CATEGORIES_FAILED',

    /** ------------ MESSENGER ---------- */
    SWITCH_MESSENGER_TOGGLE: 'SWITCH_MESSENGER_TOGGLE',
    GET_CHAT_ROOMS_SUCCESS: 'GET_CHAT_ROOMS_SUCCESS',
    GET_CHAT_ROOMS_FAILED: 'GET_CHAT_ROOMS_FAILED',
    GET_ROOM_CHATS_SUCCESS: 'GET_ROOM_CHAT_SUCCESS',
    GET_ROOM_CHATS_FAILED: 'GET_ROOM_CHAT_FAILED',
    RECEIVED_ROOM_JOINED:'RECEIVED_ROOM_JOINED',
    DELETE_CHAT: 'DELETE_CHAT',
    RECEIVED_MESSAGE: 'RECEIVED_MESSAGE',
    RECEIVED_READ_STATUS: "RECEIVED_READ_STATUS",
    UNREAD_MESSAGE_COUNT:"UNREAD_MESSAGE_COUNT",

    /** ------------- STATISTICS ------------- */
    GET_STATISTICS_SUCCESS: 'GET_STATISTICS_SUCCESS',
    GET_STATISTICS_FAILED: 'GET_STATISTICS_FAILED',

    /** ---------- REFERRALS --------- */
    GET_REFERRALS_LIST_SUCCESS: 'GET_REFERRALS_LIST_SUCCESS',
    GET_REFERRALS_LIST_FAILED: 'GET_REFERRALS_LIST_FAILED',

    /** -------- LANGUAGES --------- */
    GET_LANGUAGE_SUCCESS:'GET_LANGUAGE_SUCCESS',
    GET_LANGUAGE_FAILED:'GET_LANGUAGE_FAILED',

    /** -------- REFERRAL ---------- */
    GET_PLATFORM_META_SUCCESS: 'GET_PLATFORM_META_SUCCESS',
    GET_PLATFORM_META_FAILED: 'GET_PLATFORM_META_FAILED',

    /** ------- SUGGESTED ---------- */
    GET_SUGGESTED_SUCCESS: 'GET_SUGGESTED_SUCCESS',
    GET_SUGGESTED_FAILED: 'GET_SUGGESTED_FAILED',

    CREATE_SUGGESTED_SUCCESS: 'CREATE_SUGGESTED_SUCCESS',
    CREATE_SUGGESTED_FAILED: 'CREATE_SUGGESTED_FAILED',

}  