import { 
    POST_SCHEDULE_SUCCESS, 
    POST_SCHEDULE_FAILED, 
    GET_SCHEDULES_SUCCESS,
    GET_SCHEDULES_FAILED,
    DELETE_SCHEDULES_SUCCESS,
    DELETE_SCHEDULES_FAILED,
    PUT_SCHEDULES_SUCCESS,
    PUT_SCHEDULES_FAILED
} from "../actions/types";

const initialState = {
    error : null,
    post: null,
    get: null,
    put: null,
}

const scheduleReducer = ( state = initialState, action ) => {
    switch(action.type){
        case POST_SCHEDULE_SUCCESS:
            return {
                post: action.payload,
                error: null
            }
        case POST_SCHEDULE_FAILED:
            return {
                error: action.payload,
            }
        case GET_SCHEDULES_SUCCESS:
            return {
                get:action.payload,
                error: null
            }
        case GET_SCHEDULES_FAILED:
            return {
                error:action.payload
            }
        case DELETE_SCHEDULES_SUCCESS:
            return {
                delete: action.payload,
                error: null
            }
        case DELETE_SCHEDULES_FAILED:
            return {
                error: action.payload
            }
        case PUT_SCHEDULES_SUCCESS: 
            return {
                put: action.payload,
                error: null
            }
        case PUT_SCHEDULES_FAILED:
            return {
                error: action.payload
            }
        default: return state
    }
}

export default scheduleReducer