import React, { useEffect, useState } from 'react';
import CustomSuggestedSearch from '../../commonComponents/CustomSuggestedSearch';
import API from '../../helpers/api';
import { toast } from 'react-toastify';
import LoadingView from '../LoadingView';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CustomSuggestedCard from '../../commonComponents/CustomSuggestedCard';
import { useDispatch, useSelector } from 'react-redux';
import { switchAppView } from '../../redux/actions/meta.action';
import { isAuth } from '../../helpers/auth';

const SavedSuggestedList = () => {
    const loggedIn = isAuth();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
    const [removeLoader, setRemoveLoader] = useState('');
    const [suggested, setSuggested] = useState([]);

    const socketState = useSelector(state => state.socket);
    const userMeta = useSelector((state) => state.user.userMetaDetail);

    const getSavedSuggestedList = async () => {
        try {
            const response = await API.apiGet(`suggested`, `?userId=${loggedIn.aud}`);
            if (response.data && response.data.success) {
                setSuggested(response.data.data)
            }
        } catch (error) {
            toast.error(error.message || error.response.data.message);
        } finally {
            setLoader(false);
        }
    }


    useEffect(() => {
        (async () => {
            await getSavedSuggestedList()
        })()
    }, [])


    useEffect(() => {
        if (socketState) {
            const { roomJoined } = socketState
            if (roomJoined) {
                // switching app view when seller initiate chat 
                if (['seller', 'staff'].includes(loggedIn.role)) {
                    dispatch(switchAppView('seller'));
                }

                navigate(`/chat/${roomJoined.roomId}`);
            }
        }
    }, [socketState.roomJoined])

    const handleRemoveSuggested = async (suggestedId) => {
        try {
            setRemoveLoader(suggestedId)
            const response = await API.apiDelete(`suggested`, `/${suggestedId}`);
            if (response.data && response.data.success) {
                toast.success(response.data.message);
                getSavedSuggestedList();
            }
        } catch (error) {
            toast.error(error.message || error.response.data.message);
        } finally {
            setRemoveLoader('');
        }
    }

    return (
        <div className='suggested-main'>
            <CustomSuggestedSearch
                getSavedSuggestedList={getSavedSuggestedList}
                suggestedList={suggested}
                userMeta={userMeta}
            />
            {loader
                ? <LoadingView />
                : <div className='saved--suggested--list mt-5'>
                    <Row>
                        {suggested.map((item, itemIndex) => (
                            <Col xs={12} sm={12} md={12} lg={4} key={itemIndex} className="mb-4">
                                <CustomSuggestedCard
                                    item={item.suggestedUser}
                                    itemId={item._id}
                                    key={itemIndex}
                                    navigate={navigate}
                                    showCardRemoveIcon={true}
                                    handleRemoveSuggested={() => handleRemoveSuggested(item._id)}
                                    removeLoader={removeLoader}
                                    userMeta={userMeta}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            }

            {(!(suggested.length > 0) && !loader) &&
                <div className='mt-5'>
                    <h6>You haven't saved any professionals yet. Start building your list by searching for and adding trusted professionals you know and recommend.</h6>
                </div>}
        </div>
    )
}

export default SavedSuggestedList;