import React, { useState, useEffect } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import './ViewAllMyBlogs.css';
import { useNavigate } from 'react-router-dom';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import LoadingView from "../../LoadingView";


// const mockBlogs = [
//     {
//         id: 1,
//         title: 'Blog Title 1',
//         slug: 'blog-title-1',
//         metaDescription: 'A short description for Blog 1',
//         tags: ['React', 'SEO'],
//         author: 'John Doe',
//         featuredImage: null, // Add an image file if needed
//         content: "<p>This is the content of Blog 1</p>",
//         publishDate: '2025-01-01',
//     },
//     {
//         id: 2,
//         title: 'Blog Title 2',
//         slug: 'blog-title-2',
//         metaDescription: 'A short description for Blog 2',
//         tags: ['JavaScript', 'Web'],
//         author: 'Jane Smith',
//         featuredImage: null, // Add an image file if needed
//         content: "<p>This is the content of Blog 2</p>",
//         publishDate: '2025-01-02',
//     },
// ];


const ViewAllMyBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Add this constant for the sample image
    const sampleImage = "https://picsum.photos/350/180";

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await API.apiGetByKey('seller', '/blogs');
            if (response.data && response.data.success) {
                setBlogs(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching blogs:', error);
            toast.error(error.response?.data?.message || 'Error fetching blogs');
        } finally {
            setLoading(false);
        }
    };

    const handleViewBlog = (blog) => {
        navigate(`/my-blogs/${blog._id}`);
    };

    const handleEditBlog = (blog) => {
        navigate('/create-blogs', { 
            state: { 
                blogData: blog,
                isEditing: true 
            } 
        });
    };

    const handleDeleteBlog = async (blog) => {
        try {
            // Show confirmation dialog
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                // Make API call to delete using the correct method
                const response = await API.apiDelete('seller', `/blogs/${blog._id}`);
                
                if (response.data.success) {
                    // Remove blog from state
                    setBlogs(prevBlogs => prevBlogs.filter(b => b._id !== blog._id));
                    toast.success('Blog deleted successfully');
                }
            }
        } catch (error) {
            console.error('Error deleting blog:', error);
            toast.error(error.response?.data?.message || 'Error deleting blog');
        }
    };

    if (loading) {
        return <Container><LoadingView /></Container>;
    }

    return (
        <Container style={{ padding: '20px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '2rem' }}>Content</h2>
            <div style={{ 
                display: 'grid', 
                gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 350px))',
                gap: '20px',
                padding: '20px',
                justifyContent: 'center',
                maxWidth: '1200px',
                margin: '0 auto'
            }}>
                {blogs.length === 0 ? (
                    <div style={{ 
                        textAlign: 'center', 
                        padding: '40px 20px',
                        width: '100%'
                    }}>
                        <p style={{ marginBottom: '20px' }}>No blogs found. Start sharing your expertise!</p>
                        <Button
                            variant="primary"
                            onClick={() => navigate('/create-blogs')}
                            style={{
                                padding: '10px 24px',
                                fontSize: '16px'
                            }}
                        >
                            Create Your First Blog
                        </Button>
                    </div>
                ) : (
                    blogs.map((blog) => (
                        <Card
                            key={blog._id}
                            style={{
                                width: '100%',
                                height: '450px',
                                border: '1px solid #ddd',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                            }}
                        >
                            {blog.status === 'draft' && (
                                <div style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    background: 'rgba(108, 117, 125, 0.95)',
                                    color: 'white',
                                    padding: '4px 12px',
                                    borderRadius: '12px',
                                    fontSize: '0.75rem',
                                    fontWeight: '500',
                                    zIndex: 1,
                                    backdropFilter: 'blur(4px)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}>
                                    Draft
                                </div>
                            )}
                            <div style={{ 
                                height: '180px', 
                                width: '100%', 
                                overflow: 'hidden'
                            }}>
                                <img
                                    src={blog.featuredImage || sampleImage}
                                    alt={blog.title}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                            <div style={{ 
                                padding: '12px',
                                display: 'flex',
                                width:'100%',
                                flexDirection: 'column',
                                height: 'calc(100% - 180px)',
                                textAlign: 'left'
                            }}>
                                <h3 style={{
                                    margin: '0 0 12px 0',
                                    fontSize: '1.2rem',
                                    fontWeight: '600',
                                    width: '100%'
                                }}>{blog.title}</h3>
                                <p style={{
                                    margin: '0 0 12px 0',
                                    fontSize: '0.9rem',
                                    color: '#666',
                                    flexGrow: 1,
                                    width: '100%'
                                }}>Category: {blog.category}</p>
                                <p style={{
                                    margin: '0 0 12px 0',
                                    fontSize: '0.9rem',
                                    color: '#666',
                                    flexGrow: 1,
                                    width: '100%'
                                }}>{blog.metaDescription}</p>
                                <div style={{ 
                                    marginBottom: '12px',
                                    width: '100%'
                                }}>
                                    {blog.tags && blog.tags.map((tag, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                background: '#f0f0f0',
                                                padding: '4px 8px',
                                                borderRadius: '12px',
                                                fontSize: '0.75rem',
                                                marginRight: '6px',
                                                marginBottom: '6px',
                                                display: 'inline-block',
                                                color: '#666'
                                            }}
                                        >
                                            #{tag}
                                        </span>
                                    ))}
                                </div>
                                <div style={{ 
                                    display: 'flex', 
                                    gap: '12px',
                                    width: '100%'
                                }}>
                                    <Button
                                        variant="outline-danger"
                                        onClick={() => handleDeleteBlog(blog)}
                                        style={{
                                            padding: '8px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <FaTrash style={{ color: '#dc3545' }} />
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => handleEditBlog(blog)}
                                        style={{
                                            flex: 1,
                                            padding: '8px'
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleViewBlog(blog)}
                                        style={{
                                            flex: 1,
                                            padding: '8px'
                                        }}
                                    >
                                        View
                                    </Button>
                                </div>
                            </div>
                        </Card>
                    ))
                )}
            </div>
        </Container>
    );
};

export default ViewAllMyBlogs;