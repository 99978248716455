import React, { useState, useEffect, useRef, useCallback, useLayoutEffect } from "react";
import { Col, Container, Row, Form, Button, Alert, Modal, Card } from "react-bootstrap";
import moment from 'moment';
import { isAuth, sellerClientView } from '../../../helpers/auth';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import Calendar from 'react-calendar';
import PdfViewer from '../../../commonComponents/PDFViewer';
import { displayAddress, formatPrice, generateLetterSVG, momentFormat, removeCommaInDigit } from "../../../utils/validations";
import API from '../../../helpers/api';
import { showAuthModal } from '../../../redux/actions/auth.actions';
import { clearTimeSlotReduxState, getTimeSlots } from "../../../redux/actions/timeSlots.action";
import { getOneService, resetReduxState } from "../../../redux/actions/services.action";
import { RiMapPinRangeFill } from "react-icons/ri";
import { FaMapMarkerAlt } from "react-icons/fa";
import LoadingView from "../../LoadingView";
import { timeZone } from "../../../utils/dateTimeUtil";
import { optimizedCloudinaryImage } from "../../../utils/fileCompression";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import '../../Home/style.css';
import './bookService.css';
// import '../../../App.css';
import Tooltip from '@mui/material/Tooltip';

const virtualIcon = require('../../../assets/images/virtual.png');

/**
 * 
 * @param {ObjectId} service 
 * @param {Boolean} displayBanner 
 * @param {Date} date 
 * @param {Date} selectedStartTime 
 * @param {Date} selectedEndTime
 * @returns 
 */
function BookService({ service, displayBanner = true, date, selectedStartTime, selectedEndTime }) {

    const optionRefs = useRef([]);
    var selectRef = useRef(null);
    const calendarRef = useRef(null)

    const { id } = useParams();

    const loggedIn = isAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    /** ---- Service ----- */
    const [item, setItem] = useState(null)


    const [userMeta, setUserMeta] = useState(null)

    const [showModal, setShowModal] = useState(false);
    const [accept, setAccept] = useState(false);

    /** ---- Date Selection ---- */
    const [selectedDate, setSelectedDate] = useState(date ? moment(date).startOf('day').toDate() : '');

    /** ---- Services Availability on Dates ---- */
    const [availableDates, setAvailableDates] = useState([]);
    const [paymentMode, setPaymentMode] = useState('offline');

    /** --- Schedules for Selected day --- */
    const [timeSlots, setTimeSlots] = useState([]);

    const [askToAcceptWaiverPolicy, setAskToAcceptWaiverPolicy] = useState(true);

    /** ----- UTC time ----- */
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    /** ----- Users System Local time ----- */
    const [localStartTime, setLocalStartTime] = useState("");
    const [localEndTime, setLocalEndTime] = useState("");

    /** ----- Schedule Mongo Object Id ----- */
    const [serviceScheduleId, setServiceScheduleId] = useState('');

    /** --- Service Amount Then It Updates according to selected schedule --- */
    const [amount, setAmount] = useState(null);
    const [description, setDescription] = useState('');
    const [isVirtual, setVirtual] = useState(false)

    /** ---- Book Button Disable / Enable ---- */
    const [disableBook, setDisableBook] = useState(true);

    /** ---- Loader ---- */
    const [loading, setLoading] = useState({ init: true, date: true, booking: false, calculate: true, time: false });

    const [alert, setAlert] = useState('');

    /** --- To Check if donation is selected --- */
    const [addDonation, setAddDonation] = useState(false);

    /** ---- Service Different Amount / Fees to buyer ---- */
    const [fixedStripeFees, setFixedStripeFees] = useState({ type: '', value: 0 });
    const [serviceFee, setServiceFee] = useState({ type: '', value: 0 });
    const [stripeFees, setStripeFees] = useState({ type: '', value: 0 });
    const [donationAmount, setDonationAmount] = useState({ type: '', value: 0 }) //cents

    /** ----- Calculated Cost ----- */
    const [cost, setCost] = useState({ serviceCost: 0, stripeCost: 0, totalCost: 0 });

    const [errors, setErrors] = useState({
        selectedDate: '',
        time: ''
    });

    /** ---- Redux State ---- */
    const serviceState = useSelector(state => state.services)
    const slots = useSelector(state => state.slots);
    const metaUser = useSelector((state) => state.user);

    /** --- Payload --- */
    const newTimeSlot = {
        service_id: item?._id || id,
        time_slot: {
            date: moment(selectedDate).toDate(),
            start_time: startTime ? momentFormat.combineDateTime(moment(selectedDate).toDate(), localStartTime) : '',
            end_time: endTime ? momentFormat.combineDateTime(moment(selectedDate).toDate(), localEndTime) : '',
            local_start_time: localStartTime,
            local_end_time: localEndTime,
            description: description,
            is_virtual: isVirtual,
            cost: cost
        },
        service_scheduler_id: serviceScheduleId,
        donation: addDonation
    }

    /* --- Get Service --- */
    useEffect(() => {
        /** -- Reset Dates -- */
        if (id) {
            dispatch(getOneService(id));
        }

        if (service) {
            dispatch(getOneService(service));
        }

        // Scroll to the top of the page when the component mounts
        if (id) {
            window.scrollTo(0, 0);
        }
        // Optionally, you can also return a cleanup function to scroll back to top
        // when the component unmounts (i.e., when navigating away from the page).
        return () => {
            if (id) {
                window.scrollTo(0, 0);
            }
            // *** -- Clear Time Slot Redux State ---- *** //
            dispatch(clearTimeSlotReduxState());
            // *** -- Clear Service Redux State ---- *** //
            dispatch(resetReduxState());
        };

    }, []); // The empty dependency array ensures this effect runs only once

    /** -----  Get Service -------*/
    useEffect(() => {
        if (serviceState.error) {
            setLoading({ ...loading, init: false });
        }

        if (!serviceState.error && serviceState.getSingleService && serviceState.getSingleService.success) {
            setItem(serviceState.getSingleService.service);
            // setLoading({ ...loading, init: false })
        }
    }, [serviceState]);

    useEffect(() => {
        if (item) {
            (async () => {
                await getAvailableDates(item._id, item.associatedWithUser?._id, moment().startOf('day').toDate())
            })()
        }
    }, [item])

    useEffect(() => {
        if (!metaUser.error && metaUser.userMetaDetail) {
            setUserMeta(metaUser.userMetaDetail);
        }
      }, [metaUser]);

    /** ----- Update Time Slot on Eequest from Booking Modal ----- */
    useLayoutEffect(() => {

        const timer = setTimeout(() => {
            if (!selectedStartTime || !selectedEndTime || !timeSlots.length || !selectRef.current) return;

            const selectElement = selectRef.current;
            if (!selectElement) return;

            // Format times for comparison and setting attributes
            let utc24 = handleTimeFormat(selectedStartTime, selectedEndTime, 'utc24');
            let local24 = handleTimeFormat(selectedStartTime, selectedEndTime, 'local24');

            // Find the selected option in the timeSlots
            const selectedOptionIndex = timeSlots.findIndex(slot => {
                let sk = handleTimeFormat(slot.start, slot.end, 'utc24') === utc24
                return sk
            }
            );

            if (selectedOptionIndex !== -1) {
                const slot = timeSlots[selectedOptionIndex];
                const optionElement = optionRefs.current[selectedOptionIndex];

                if (optionElement) {

                    optionElement.setAttribute('data-localTime', local24);
                    optionElement.setAttribute('data-booked-length', slot.booked);
                    optionElement.setAttribute('data-max-capacity', slot.max_capacity);
                    optionElement.setAttribute('data-service-scheduler', slot.scheduleMongoId);
                    optionElement.setAttribute('data-is-virtual', slot.is_virtual);
                    optionElement.setAttribute('data-amount', slot.amount);
                    optionElement.setAttribute('data-allowed_payment', slot.allowed_payment);
                    optionElement.setAttribute('data-description', slot.description);

                    // Programmatically disable the option based on slot conditions
                    const isDisabled = (slot.booked >= slot.max_capacity) || slot.disabled;
                    optionElement.disabled = isDisabled;
                }
            }

            // Ensure options are fully rendered before selecting
            // Automatically select the option and trigger onChange, only if the slot is not disabled
            const optionToSelect = Array.from(selectElement.options).find(option => option.value === utc24 && !option.disabled); // Ensure it's not disabled
            if (optionToSelect) {
                selectElement.value = optionToSelect.value;
                // Create a synthetic event to pass to handleTimeSlotChange
                const event = {
                    target: {
                        value: utc24,
                        selectedOptions: [optionToSelect]
                    }
                };

                handleTimeSlotChange(event); // Trigger the handler
            }
        }, 100)  // Timeout with 100ms delay to allow rendering

        return () => clearTimeout(timer); // Cleanup on component unmount

    }, [selectedStartTime, selectedEndTime, timeSlots]);

    /** ----- Get Slots ----- */
    useEffect(() => {
        if (!slots.error) {
            // if (slots.date && slots.date.success) {

            //     /** ---- Defining the available days ----- */
            //     setAvailableDates(slots.date.data.availableDates);

            //     setAskToAcceptWaiverPolicy(slots.date.data.askToAcceptWaiverPolicy);
            //     /** ---- Defining the service fees ---- */
            //     if (slots.date.data.commission) {
            //         setServiceFee(slots.date.data.commission);
            //     }
            //     if (slots.date.data.stripeFees) {
            //         setStripeFees(slots.date.data.stripeFees);
            //     }

            //     if (slots.date.data.fixedStripeFees) {
            //         setFixedStripeFees(slots.date.data.fixedStripeFees);
            //     }

            //     if (slots.date.data.donationAmount) {
            //         setDonationAmount(slots.date.data.donationAmount);
            //     }
            //     if (item && item.bbservice.service_type !== 'class') {
            //         setLoading({ ...loading, date: false, calculate: false })
            //     } else {
            //         setLoading({ ...loading, date: false })
            //     }
            // }

            if (slots.timeSlots && slots.timeSlots.success) {
                // console.log('[BookServie]:Available Slots', slots.timeSlots.availableTimeSlots)
                setTimeSlots(slots.timeSlots.availableTimeSlots);
                // console.log('[slots]:loading', loading);
                setLoading({ ...loading, time: false })
            }
        }

        if (slots.error) {
            let _alert = {}
            _alert['variant'] = 'danger'
            _alert['message'] = slots.error.message

            setAlert(_alert);
            setLoading(Object.fromEntries(Object.keys(loading).map(key => [key, false])));
        }
    }, [slots])

    /** -- Disable Loading when timeslot and available dates are loaded --  */
    // useEffect(() => {
    //     if(timeSlots.length > 0 && availableDates.length > 0){
    //         setLoading(Object.fromEntries(Object.keys(loading).map(key => [key, false])));
    //     }
    // }, [timeSlots, availableDates]);

    const getAvailableDates = async (serviceId, sellerOrStaffId, currentDate) => {
        try {
            // console.log('payload', { serviceId, sellerOrStaffId, currentDate} );
            // console.log('previous available Dates ', availableDates );
            const response = await API.apiPostUrl('timeSlots', `/available-dates`, { serviceId, sellerOrStaffId, currentDate });
            // console.log( 'new response :', response.data );

            if (response.data && response.data.success) {
                // console.log( response.data );
                const result = response.data
                // await dispatch({ type: GET_AVAILABLE_DATES_SUCCESS, payload: response.data });
                /** ---- Defining the available days ----- */
                setAvailableDates(result.data.availableDates);

                setAskToAcceptWaiverPolicy(result.data.askToAcceptWaiverPolicy);
                /** ---- Defining the service fees ---- */
                if (result.data.commission) {
                    setServiceFee(result.data.commission);
                }
                if (result.data.stripeFees) {
                    setStripeFees(result.data.stripeFees);
                }

                if (result.data.fixedStripeFees) {
                    setFixedStripeFees(result.data.fixedStripeFees);
                }

                if (result.data.donationAmount) {
                    setDonationAmount(result.data.donationAmount);
                }

            } else {
                let _alert = {}
                _alert['variant'] = 'danger'
                _alert['message'] = response.data.message
                setAlert(_alert);
            }


        } catch (error) {

            let _alert = {}
            _alert['variant'] = 'danger'
            if (error.response && error.response.data && error.response.data.message) {
                _alert['message'] = error.response.data.message
            } else {
                _alert['message'] = error.message
            }
            setAlert(_alert);
            // errorRequest(error, dispatch, GET_AVAILABLE_DATES_FAILED);
        } finally {
            if (item && item.bbservice.service_type !== 'class') {
                setLoading({ ...loading, init: false, date: false, calculate: false })
            } else {
                setLoading({ ...loading, init: false, date: false })
            }
        }
    }

    /* --- Time Slots for selected {value} date --- */
    const fetchTimeSlots = (value) => {
        // console.log( 'fetchTimeSlot:value', value );
        // console.log( 'item:', item )
        if (item && item.bbservice.service_type === 'class') {
            setLoading({ ...loading, time: true });
            dispatch(getTimeSlots({ serviceId: item._id, sellerOrStaffId: item.associatedWithUser._id, date: value, timeZone }));
        } else {

            const matchedDate = getDescription({ date: value });

            if (matchedDate) {
                if (matchedDate.description) {
                    setDescription(matchedDate.description);
                }
                savePaymentMode(matchedDate.payment_mode)
            }

            setDisableBook(false);
        }
    }

    /** --- Handle Validation --- */
    const handleValidation = () => {
        let formIsValid = true;

        if (!selectedDate) {
            formIsValid = false;
            errors.selectedDate = "Date is required";
        }
        if (timeSlots.length && (!startTime || !endTime)) {
            formIsValid = false;
            errors.time = "Time Slot is Required";
        }

        setErrors({ ...errors });
        return formIsValid;
    }

    /** ---- Update Cost ---- */
    useEffect(() => {
        if (item && ![null, 'null', undefined, 'undefined'].includes(amount)) {
            let amt = removeCommaInDigit(amount)
            let sc = calculateServiceFees(amt);
            let tc = calculateTotalAmount(amt);
            let stripe = calculateStripeFees(amt);
            setCost({
                cost: amt,
                serviceCost: sc,
                totalCost: tc,
                stripeCost: stripe
            })
        }
    }, [serviceFee, stripeFees, amount, serviceScheduleId, addDonation])

    const savePaymentMode = (mode) => {
        setPaymentMode(mode);
    }

    const handleTimeFormat = (_start, _end, type) => {
        if (type === 'utc24') {
            let utcSlotTime_24 = `${moment.utc(_start).format('HH:mm')} - ${moment.utc(_end).format('HH:mm')}`
            return utcSlotTime_24
        }

        if (type === 'local24') {
            let localSlotTime_24 = `${momentFormat.timeInHHmm(_start)} - ${momentFormat.timeInHHmm(_end)}`
            return localSlotTime_24
        }

        if (type === 'local12') {
            let localSlotTime_12 = `${momentFormat.timIn12Format(_start)} - ${momentFormat.timIn12Format(_end)}`
            return localSlotTime_12
        }

    }

    const handleTimeSlotChange = useCallback((event) => {
        if (event.target.value) {
            setDescription('')
            const selectedOption = event.target.selectedOptions[0];
            const bookedSeats = selectedOption.getAttribute('data-booked-length');
            const maxCapacity = selectedOption.getAttribute('data-max-capacity');
            const serviceSchedulerId = selectedOption.getAttribute('data-service-scheduler'); // Schedule Slot Mongoose ObjectId
            const localTime = selectedOption.getAttribute('data-localTime'); // Local Start Time - Local End Time in 24 hour format
            const virtual = JSON.parse(selectedOption.getAttribute('data-is-virtual'));
            const allowed_payment = selectedOption.getAttribute('data-allowed_payment');
            const slotDescription = selectedOption.getAttribute('data-description');

            savePaymentMode(allowed_payment);
            setDescription(slotDescription);

            const amt = selectedOption.getAttribute('data-amount');
            // console.log( 'data-amount', amt )
            setAmount(amt);
            // console.log('sdd',  virtual )
            setVirtual(virtual ? true : false)

            const _utcSlot = event.target.value.split(' - ') // UTC Start Time - UTC End Time in 24 hour Format
            const _utcStartTime = _utcSlot[0];
            const _utcEndTime = _utcSlot[1];

            setStartTime(_utcStartTime);
            setEndTime(_utcEndTime);

            const _localSlot = localTime.split(' - ');

            setLocalStartTime(_localSlot[0])
            setLocalEndTime(_localSlot[1])
            setServiceScheduleId(serviceSchedulerId);

            setDisableBook((Number(bookedSeats) < Number(maxCapacity)) ? false : true)
            // console.log('loader: handleTimeSlotChange', loading);
            setLoading({ ...loading, calculate: false })
        }
    }, [selectRef, timeSlots]);

    const handleApiError = (error) => {
        if (error?.response?.data?.message) {
            toast.error(error.response.data.message, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const createSession = () => {
        return API.apiPostUrl('orders', '/create/session', newTimeSlot);
    };

    const createOrder = (sessionId) => {
        return API.apiGet('orders', `/create/single/${sessionId}`);
    };

    const handleBookService = async () => {
        try {
            if (!handleValidation()) {
                return;
            }

            setLoading({ booking: true });
            const sessionResponse = await createSession();
            if (sessionResponse?.data?.data) {
                const orderResponse = await createOrder(sessionResponse.data.data._id);

                if (orderResponse.data && orderResponse.data.success) {
                    if (orderResponse.data.payment_link) {
                        window.location.replace(orderResponse.data.payment_link);
                    }

                    if (orderResponse.data.order_id) {
                        navigate(`/booking?status=success`, { state: { order_id: orderResponse.data.order_id } })
                    }
                }
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading({ booking: false });
        }
    };

    const calculateServiceFees = (amt) => {
        let price = 0;
        if (paymentMode === 'online' && serviceFee && serviceFee.type === 'percent' && serviceFee.value > 0) {
            price = parseFloat((amt * serviceFee.value) / 100)
        }
        if (paymentMode === 'online' && serviceFee && serviceFee.type === 'price' && serviceFee.value > 0) {
            price = parseFloat((serviceFee.value))
        }

        return parseFloat(price.toFixed(2)) // price
    }

    const calculateStripeFees = (amt) => {
        let price = 0
        if (paymentMode === 'online' && stripeFees && stripeFees.type === 'percent' && stripeFees.value > 0) {
            price = parseFloat((amt * stripeFees.value) / 100)
        }
        if (paymentMode === 'online' && stripeFees && stripeFees.type === 'price' && stripeFees.value > 0) {
            price = parseFloat((stripeFees.value))
        }

        if (paymentMode === 'online' && fixedStripeFees && fixedStripeFees.type === 'percent' && fixedStripeFees.value > 0) {
            price += parseFloat((amt * fixedStripeFees.value) / 100)
        }

        if (paymentMode === 'online' && fixedStripeFees && fixedStripeFees.type === 'price' && fixedStripeFees.value > 0) {
            price += parseFloat((fixedStripeFees.value))
        }

        return parseFloat(price.toFixed(2))
    }

    const calculateTotalAmount = (amt) => {
        var total = parseFloat(calculateServiceFees(amt) + calculateStripeFees(amt) + amt);

        if (paymentMode === 'online' && donationAmount && donationAmount.type === 'price' && donationAmount.value > 0) {
            total = addCentsToDollars(total, donationAmount.value)
        }

        return parseFloat(total.toFixed(2));

    }

    const addCentsToDollars = (dollars, cents) => {
        let totalCents = addDonation && paymentMode === 'online' ? (dollars) + parseFloat(cents) : dollars;

        return totalCents // To keep two decimal places
    }

    const showPriceLoading = () => {
        return (
            <LoadingView loader="ThreeDots" size={20} />
        )
    }

    const showTimeLoading = () => {
        return (
            <LoadingView loader="ThreeDots" size={40} />
        )
    }



    const isDayAvailable = ({ date }) => availableDates && availableDates.some((availableDay) => moment(date).isSame(moment(availableDay.date).startOf('day').toDate()));

    const getDescription = ({ date }) => availableDates && availableDates.find((day) => moment(date).isSame(moment(day.date).startOf('day').toDate()));

    const getSchedulerId = ({ date }) => availableDates && availableDates.find((day) => moment(date).isSame(moment(day.date).startOf('day').toDate()))

    // Function to disable days that are not available
    // const tileDisabled = ({ date }) => !isDayAvailable({ date });

    /** ---- Disable Dates which are not available ----- */
    const tileDisabled = ({ date, view }) => {
        if (view === 'month') {
            const currentMonth = moment(calendarRef.current.state.viewDate).month();
            const currentYear = moment(calendarRef.current.state.viewDate).year();
            const dateToCheck = moment(date);

            return dateToCheck.month() !== currentMonth || dateToCheck.year() !== currentYear;
        }
        return !isDayAvailable({ date });
    };


    const handleDayClick = (value) => {
        // console.log( value )
        // console.log( 'Calling...')
        setDescription('')
        setAmount(item?.service_type === 'class' ? null : item?.amount)
        if (item?.service_type === 'class') {
            setTimeSlots([]);
            setStartTime("");
            setEndTime("");
        }

        if (isDayAvailable({ date: value })) {

            const scheduler = getSchedulerId({ date: value });
            setServiceScheduleId(scheduler.scheduleMongoId)
            setSelectedDate(value);
            fetchTimeSlots(value);
            if (item?.service_type === 'service') {
                setLoading({ ...loading, calculate: false });
                setDisableBook(false)
            }
        } else {
            // let _alert = {}
            // _alert['variant'] = 'danger'
            // _alert['message'] = 'Day is not available'
            setDisableBook(true)
            // setAlert(_alert);
        }
    };

    /** --- Check if selected date is today and available --- */
    useEffect(() => {
        if (selectedDate && availableDates && availableDates.length > 0) {
            if (moment(moment(selectedDate).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD')) && isDayAvailable({ date: selectedDate })) {
                handleDayClick(selectedDate);
                // console.log('Todays Day')
                // fetchTimeSlots(selectedDate)
            } else {
                if (date && item) { // or date, depending on your requirements
                    handleDayClick(selectedDate);
                    // console.log(selectedDate, date);
                    // console.log('Received Date');
                }
            }
        }
    }, [selectedDate, availableDates])

    const showActionButtons = () => {
        return (
            <>
                <Button
                    variant='danger'
                    onClick={() => setShowModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    className="ml-2"
                    variant='primary'
                    disabled={!accept || loading['booking']}
                    onClick={() => accept ? handleBookService() : null}
                >
                    {loading['booking'] ? 'Redirecting...' : 'Continue'}
                </Button>
            </>
        )
    }



    const showPolicyModal = () => {
        return (
            <Modal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop={true}
                keyboard={true}
                size='lg'
            >
                <Modal.Header closeButton>
                    Terms Of Service Agreement
                </Modal.Header>
                <Modal.Body>
                    <div className="policy--modal">
                        <PdfViewer pdfDataUrl={item?.disclaimer_policy_document.url} />

                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex align-items-center justify-content-between">

                    <div className='policy--accept'>
                        <Form.Check
                            checked={accept}
                            onChange={() => setAccept(!accept)}
                            style={{ fontSize: 16 }}
                            label="I acknowledge and agree to the terms outlined above."
                        />
                    </div>
                    <div>
                        {showActionButtons()}
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    // console.log( item )

    const renderName = (personal, businessName) => {
        if (personal) {
            return `${businessName} | ${personal}`
        }

        if (!personal) {
            return `${businessName}`
        }
    }

    // console.log( 'loading', loading );
    // console.log( userMeta );

    return (
        <>
            {displayBanner &&
                <section className="page--banner">
                    <Container>
                        <h1>Book Appointment</h1>
                    </Container>
                </section>
            }
            <section className={`popular--Wrapper deals--wrapper main__booking__service ${displayBanner ? 'main-wraper-add-review' : 'main-wrapper-booking-modal'}`} >
                {loading.init
                    ? <LoadingView
                        size={28}
                    // message={'Hold on fetching latest records for you'}
                    />

                    : <Container>
                        <Row>
                            <Col sm={6} className="main-wraper-service-book">
                                <Card style={{ boxShadow: 'none', height: 'auto' }} className="mb-2">
                                    <div className='card--media'>
                                        <Card.Link className='cursor-pointer' style={{ backgroundColor: 'transparent' }}>
                                            <Card.Img
                                                variant="top"
                                                src={item?.associatedWithUser.profilePic
                                                    ? optimizedCloudinaryImage(item?.associatedWithUser.profilePic?.url)
                                                    : generateLetterSVG(item?.associatedWithUser.seller_personal_name || item?.associatedWithUser.name)
                                                }
                                                alt={`${item?.associatedWithUser.seller_personal_name || item?.associatedWithUser.name}`}
                                            />
                                        </Card.Link>
                                        <Card.Title>
                                            <Card.Link>
                                                <div>
                                                    <span style={{ display: 'block' }} className="text-capitalize">
                                                        {renderName(item?.associatedWithUser?.seller_personal_name || null, item?.associatedWithUser.name)}
                                                    </span>
                                                    <span className='text-capitalize mb-1'>
                                                        {typeof item?.category === 'object' ? item.category.name : item?.category} / {item?.bbservice?.name}
                                                    </span>
                                                </div>
                                                {item?.associatedWithUser?.address &&
                                                    <span className='d-flex mt-1'>
                                                        <small className="mr-1"><FaMapMarkerAlt /> {displayAddress(item?.associatedWithUser?.address)}</small>
                                                        <small><RiMapPinRangeFill /> {item?.associatedWithUser.seller_serve_distance} Miles</small>
                                                    </span>}
                                            </Card.Link>

                                        </Card.Title>
                                        {isVirtual &&

                                            <Tooltip
                                                title="This is a virtual service"
                                                arrow
                                                sx={{ fontSize: '14px', backgroundColor: '#75a0a7' }}
                                            >
                                                <img src={virtualIcon} alt="virtual icon" loading="lazy" style={{ height: '30px', width: '30px' }} />

                                            </Tooltip>
                                        }
                                    </div>
                                    <Card.Body className='cursor-pointer'>
                                        <Card.Title>
                                            <Card.Link>
                                                <small
                                                    className='mb-2 mt-2 mr-0 d-block card--desc text-capitalise'
                                                >
                                                    {
                                                        description
                                                            ? description
                                                            : item?.desc
                                                                ? item.desc
                                                                : item?.bbservice.description
                                                    }
                                                </small>
                                            </Card.Link>
                                        </Card.Title>
                                    </Card.Body>
                                </Card>
                                <hr />
                                {amount &&
                                    <div className="add-review-left-side book--service--pricing">
                                        <div className="bookservice-all-total">
                                            <h5>Cost</h5>
                                            {loading['calculate'] ? showPriceLoading() : <h5>${formatPrice(cost.cost)}</h5>}
                                        </div>
                                        {paymentMode === 'online' && serviceFee && serviceFee.value !== 0 &&
                                            <div className="bookservice-all-total">
                                                <h5>Service Fees ({`${serviceFee.value}${serviceFee.type === 'price' ? "$" : '%'}`})</h5>
                                                {loading['calculate'] ? showPriceLoading() : <h5>${formatPrice(cost.serviceCost)}</h5>}
                                            </div>}

                                        {paymentMode === 'online' && stripeFees && stripeFees.value !== 0 && <div className="bookservice-all-total">
                                            <h5>Stripe Fees ({`${stripeFees.value}${stripeFees.type === 'price' ? "$" : '%'}`} + {`${fixedStripeFees.value}${fixedStripeFees.type === 'price' ? "$" : '%'}`})</h5>
                                            {loading['calculate'] ? showPriceLoading() : <h5>${formatPrice(cost.stripeCost)}</h5>}
                                        </div>}

                                        {(paymentMode === 'online' && addDonation) &&
                                            <div className="bookservice-all-total">
                                                <h5>Donation </h5>
                                                <h5>${donationAmount.value}</h5>
                                            </div>
                                        }

                                        <div className="bookservice-all-total book--service--totalcost">
                                            <h5><strong>Total Cost</strong></h5>
                                            {loading['calculate'] ? showPriceLoading() : <h5><strong>${formatPrice(cost.totalCost)}</strong></h5>}
                                        </div>
                                    </div>
                                }
                            </Col>
                            <Col sm={6}>
                                <div className="select-time-slot">
                                    <p>Choose a Date to Book Your Appointment.</p>
                                    {loading.date
                                        ?
                                        <div className="time__loader">
                                            {showTimeLoading()}
                                        </div>
                                        :
                                        <Calendar
                                            onChange={handleDayClick}
                                            value={selectedDate}
                                            tileDisabled={({ date }) => tileDisabled({ date })}
                                            ref={calendarRef}
                                            view="month"

                                        />
                                    }
                                    {!loading.date
                                        ?
                                        (loading.time)
                                            ? <div className="time__loader">
                                                {showTimeLoading()}
                                            </div>
                                            : (timeSlots && timeSlots.length > 0) &&
                                            <div className="mt-3">
                                                <Form.Group style={{ width: '100%' }}>
                                                    <p className="mb-1">Select Your Suitable Time Slot</p>
                                                    {/* <small className="info mb-2">Time slots are in 24 hour format</small> */}
                                                    <Form.Control
                                                        as="select"
                                                        name="service"
                                                        value={`${startTime} - ${endTime}`} // utc 24 format
                                                        onChange={(e) => handleTimeSlotChange(e)}
                                                        ref={selectRef}
                                                    >
                                                        <option value=""> -- Available Time Slots -- </option>
                                                        {timeSlots.length > 0
                                                            ? timeSlots.map((slot, slotIdx) => {
                                                                let utcSlotTime = handleTimeFormat(slot.start, slot.end, 'utc24'); //`${moment.utc(slot.start).format('HH:mm')} - ${moment.utc(slot.end).format('HH:mm')}`
                                                                let localSlotTime = handleTimeFormat(slot.start, slot.end, 'local24'); //`${momentFormat.timeInHHmm(slot.start)} - ${momentFormat.timeInHHmm(slot.end)}`
                                                                let displayLocalSlotTimeIn12Format = handleTimeFormat(slot.start, slot.end, 'local12'); //`${momentFormat.timIn12Format(slot.start)} - ${momentFormat.timIn12Format(slot.end)}`
                                                                return (
                                                                    <option
                                                                        className="time-slot-select"
                                                                        key={slotIdx}
                                                                        ref={el => optionRefs.current[slotIdx] = el} // Assign ref
                                                                        value={utcSlotTime}
                                                                        disabled={(slot.booked >= slot.max_capacity) || slot.disabled}
                                                                        data-booked-length={slot.booked}
                                                                        data-max-capacity={slot.max_capacity}
                                                                        data-service-scheduler={slot.scheduleMongoId}
                                                                        data-localTime={localSlotTime}
                                                                        data-is-virtual={slot.is_virtual}
                                                                        data-amount={slot.amount}
                                                                        data-allowed_payment={slot.allowed_payment}
                                                                        data-description={slot.description}
                                                                    >
                                                                        {displayLocalSlotTimeIn12Format} &nbsp; | &nbsp;
                                                                        <span
                                                                            className="class--seats--available"
                                                                            style={{ marginLeft: '10px' }}
                                                                        >
                                                                            {slot.max_capacity - slot.booked} Seats Left out of {slot.max_capacity}
                                                                        </span>

                                                                        {slot.is_virtual &&
                                                                            <>
                                                                                &nbsp; | &nbsp;
                                                                                <span
                                                                                    className="class--seats--available"
                                                                                    style={{ marginLeft: '10px' }}
                                                                                >
                                                                                    Virtual Class
                                                                                </span>
                                                                            </>
                                                                        }
                                                                    </option>
                                                                )
                                                            })
                                                            : <option>No Time Slot Available</option>
                                                        }
                                                    </Form.Control>
                                                    {(errors && errors.time) && <span className="error-msg">{'Please select time slot'}</span>}
                                                </Form.Group>
                                            </div>
                                        : null
                                    }
                                    {/* {isVirtual && <p>This Is Virtual Class</p>} */}

                                </div>
                            </Col>

                            {alert &&
                                <Col sm={12}>
                                    <Alert variant={alert.variant} value={alert.message}>{alert.message}</Alert>
                                </Col>}

                            {!sellerClientView() &&
                                <Col sm={12} className="text-center mt-5">
                                    {(amount && paymentMode === 'online') &&
                                        <Form.Check
                                            type="checkbox"
                                            label="Add 25 cents to support families navigating surrogacy or adoption, helping them build their dreams."
                                            className="mb-3"
                                            onChange={() => setAddDonation(!addDonation)}
                                            checked={addDonation}
                                        />
                                    }
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        style={{ cursor: (item?.associatedWithUser?.isOutOfCountry ) ? 'not-allowed' : 'default'}}
                                        disabled={!amount || loading['booking'] || loading['calculate'] || disableBook || (userMeta && !userMeta.isStateAllowed) || (item?.associatedWithUser?.isOutOfCountry )}
                                        onClick={() => loggedIn 
                                                        ? ((userMeta && !userMeta.isStateAllowed) || (item?.associatedWithUser?.isOutOfCountry ))
                                                            ? null
                                                            : askToAcceptWaiverPolicy 
                                                                ? setShowModal(true) 
                                                                : handleBookService() 
                                                        : dispatch(showAuthModal(true))
                                                    }
                                    >

                                        {loading['booking'] ? 'Please Wait...' : 'Confirm Your Booking'}
                                    </Button>
                                </Col>}
                        </Row>
                    </Container>
                }
                {showModal && showPolicyModal()}
            </section>
        </>
    );
}

export default BookService;