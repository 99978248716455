import { GET_LANGUAGE_SUCCESS, GET_LANGUAGE_FAILED } from "../actions/types";

const initialState = {
    get: null,
    error: null,
};

const languageReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_LANGUAGE_SUCCESS :
            return {
                get: action.payload,
                error: null
            }
        case GET_LANGUAGE_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};

export default languageReducer;