import {
    PUT_SELLER_PROFILE_SUCCESS,
    PUT_SELLER_PROFILE_FAILED,
    GET_SELLER_PROFILE_SUCCESS,
    GET_SELLER_PROFILE_FAILED
} from "../actions/types";

const initialState = {
  seller: null,
  error: null
};

const sellerReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_SELLER_PROFILE_SUCCESS :
            return {
                ...state,
                userDetail : action.payload ,
            }
        case GET_SELLER_PROFILE_FAILED : 
            return {
                ...state,
                error : action.payload ,
            }
        case PUT_SELLER_PROFILE_SUCCESS :
            return {
                ...state,
                updatesellerDetails : action.payload ,
            }
        case PUT_SELLER_PROFILE_FAILED : 
            return {
                ...state,
                errorSeller : action.payload ,
            }
       
        default: return state;
    }
};

export default sellerReducer;