import { get } from 'lodash';
import API from '../../helpers/api';
import { 
    GET_BUYER_ORDERS_SUCCESS,
    GET_BUYER_ORDERS_FAILED,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILED, 
    GET_BUSINESS_ORDERS_SUCCESS,
    GET_BUSINESS_ORDERS_FAILED,
    APPROVE_ORDER_FAILED,
    APPROVE_ORDER_SUCCESS,
    COMPLETE_ORDER_SUCCESS,
    COMPLETE_ORDER_FAILED,
    POST_RATING_SUCCESS,
    POST_RATING_FAILED,
    GET_REVIEW_SUCCESS,
    GET_REVIEW_FAILED,
    RESET_ORDER_STATE,
    RELEASE_SLOT_SUCCESS,
    RELEASE_SLOT_FAILED
} from './types';

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

/** ---------- Buyers ---------- */
export const getBuyersOrders = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('orders');
            if (response.data && response.data.success) {
                await dispatch({ type: GET_BUYER_ORDERS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_BUYER_ORDERS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_BUYER_ORDERS_FAILED);
        }
    }
}

/** ---- Get Orders for Business ------ */
export const getSellerStaffOrders = (query) => {
    return async ( dispatch ) => {
        try {
            const response = await API.apiGetByKey('orders', `/business`, query);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_BUSINESS_ORDERS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_BUSINESS_ORDERS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_BUSINESS_ORDERS_FAILED);
        }
    }
}

export const approveOrder = (orderId) => {
    return async ( dispatch ) => {
        try {
            const response = await API.apiGet('orders', `/approve/${orderId}`);
            if (response.data && response.data.success) {
                await dispatch({ type: APPROVE_ORDER_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: APPROVE_ORDER_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, APPROVE_ORDER_FAILED);
        }
    }
}

/** --- Cancel Order For Buyers / Sellers ----- */
export const cancelOrder = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('orders', `/cancel`, payload);
            if (response.data && response.data.success) {
                await dispatch({ type: CANCEL_ORDER_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: CANCEL_ORDER_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, CANCEL_ORDER_FAILED);
        }
    }
}


/** --- complete Order  ----- */
export const completeOrder = (orderId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('orders', `/complete/${orderId}`);
            if (response.data && response.data.success) {
                await dispatch({ type: COMPLETE_ORDER_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: COMPLETE_ORDER_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, COMPLETE_ORDER_FAILED);
        }
    }
}

export const provideFeedback = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPost('rating', payload);
            if (response.data && response.data.success) {
                await dispatch({ type: POST_RATING_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: POST_RATING_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, POST_RATING_FAILED);
        }
    }
}

export const getFeedbacks = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('rating', payload);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_REVIEW_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_REVIEW_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_REVIEW_FAILED);
        }
    }
}

export const releaseLockedSlot = (orderId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('orders', `/cancel/${orderId}`);
            if (response.data && response.data.success) {
                await dispatch({ type: RELEASE_SLOT_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: RELEASE_SLOT_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, RELEASE_SLOT_FAILED);
        }
    }
}

export const clearOrderReduxState = () => {
    return async ( dispatch ) => {
       await dispatch({ type: RESET_ORDER_STATE });
    }
}

