import { get } from 'lodash';
import API from '../../helpers/api';
import { 
    GET_BOOKMARK_SUCCESS,
    GET_BOOKMARK_FAILED,
    DELETED_BOOKMARK_SUCCESS,
    DELETED_BOOKMARK_FAILED,
    POST_BOOKMARK_SUCCESS,
    POST_BOOKMARK_FAILED,
    GET_ONE_BOOKMARK_SUCCESS,
    GET_ONE_BOOKMARK_FAILED, 
} from './types';


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

/**
 * Get All bookmarks
 */
export const getBookmarks = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('bookmark');
            if (response.data && response.data.success) {
                await dispatch({ type: GET_BOOKMARK_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_BOOKMARK_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_BOOKMARK_FAILED);
        }
    }
}


/**
 * Adding bookmark
 */
export const addBookmark = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('bookmark', '/add', payload);
            if(response.data && response.data.success){
                await dispatch({ type: POST_BOOKMARK_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: POST_BOOKMARK_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, POST_BOOKMARK_FAILED);
        }
    }
}

/**
 * 
 * @param {ObjectId} bookMarkId 
 * @returns message
 */
export const deleteBookmark = (bookMarkId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiDelete('bookmark', `/delete/${bookMarkId}`);
            if(response.data && response.data.success){
                await dispatch({ type: DELETED_BOOKMARK_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: DELETED_BOOKMARK_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, DELETED_BOOKMARK_FAILED)
        }
    }
}

export const getOneBookmark = (businessId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('bookmark', `/${businessId}`);
            if(response.data && response.data.success){
                await dispatch({ type: GET_ONE_BOOKMARK_SUCCESS, payload: response.data });
            }else{
                await dispatch({ type: GET_ONE_BOOKMARK_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, DELETED_BOOKMARK_FAILED)
        }
    }
}