import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button, Col, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { login, verifyStaffJoiningLink } from "../../../redux/actions/auth.actions";
// import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { updateStaffPass } from "../../../redux/actions/staff.action";
// import SignIn from "../SignIn";
import "./staffJoin.css"

const StaffJoin = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get("email");
    const [loader, setLoader] = useState(false)

    // const [showLogin, setLoginShow] = useState(false);

    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passwordInput, setPasswordInput] = useState({
        password: '',
        confirmPassword: ''
    })

    /** ---- Redux State updatePassStaff -- */
    // const updateMessage = useSelector(state => state.staff.updatePassStaff);

    const authState = useSelector(state => state.auth);
    const staffState = useSelector(state => state.staff);
    // const apiError = useSelector(state => state.staff.ErrorupdatePassStaff)

 
    useEffect(() => {
        if (!email || !token) {
            window.location.href = '/';
        }

        if(email && token){
            // localStorage.clear();
            dispatch(verifyStaffJoiningLink({ email, token }))
        }
    }, [token, email])

    useEffect(() => {
        if (authState && authState.loginError) {
            showToaster('error', authState.loginError)
            setLoader(false)
        }

        if (authState && authState.token) {
            showToaster('success', 'Your Password is Updated');
            setLoader(false);
        }

        /** ------ This Case was used if verify link is not valid ----  */
        if(authState && authState.error ){
            showToaster('error', authState.error);
            window.location.href = '/';
        }
    }, [authState])

    useEffect(() => {
        // console.log('staff-state', staffState)
        if(staffState.error){
            setLoader(false)
            showToaster('error', staffState.error.message);
        }

        if(!staffState.error && staffState.updatePassStaff){
            if(staffState.updatePassStaff.success){
                // showToaster('success' , staffState.updatePassStaff.message);
                dispatch(login({ email, password: passwordInput.password }));
            }else{
                setLoader(false)
                showToaster('error' , 'Oops Something went wrong');
            }
        }
    }, [staffState])

    // const handleLoginClose = () => setLoginShow(false);
    // const handleLoginShow = () => setLoginShow(true);

    const handlePasswordChange = (evnt) => {
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue }
        setPasswordInput(NewPasswordInput);
    }

    const handleValidation = ({ target: { name, value } }) => {
        value = value.trim();
        const passwordInputValue = value;
        const passwordInputFieldName = name;

        /** -- For Password -- */
        if (passwordInputFieldName === 'password') {

            const passwordLength = passwordInputValue.length;
            let errMsg = "";
            if (passwordLength === 0) {
                errMsg = "Password is empty";
            }

            else {
                errMsg = "";
            }
            setPasswordError(errMsg);
        }

        /** -- For Confirm Password --  */
        if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password" && passwordInput.confirmPassword.length > 0)) {
            if (passwordInput.confirmPassword !== passwordInput.password) {
                setConfirmPasswordError("Confirm password is not matched");
            } else {
                setConfirmPasswordError("");
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoader(true)
        const payload = {
            password: passwordInput.password,
            token: token,
            email: email
        }
        dispatch(updateStaffPass(payload))
    }



    // useEffect(() => {
    //     if (updateMessage) {
    //         toast.success(updateMessage, {
    //             position: "top-right",
    //             autoClose: 4000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //         // setLoader(false)
    //         dispatch(login({ email, password: passwordInput.password }));
    //         // setLoginShow(true)

    //     }
    //     if (apiError) {
    //         toast.error(apiError.message, {
    //             position: "top-right",
    //             autoClose: 4000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: "light",
    //         });
    //         setLoader(false)
    //     }
    // }, [updateMessage, apiError]);




    const showToaster = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    return (
        <>
            <Container className="main-staff-update-pass">
                <Row>
                    <Col sm={12} lg={6} className="pr-lg-0 staff-up-img-sec">
                        <a className="company--brand">
                            <img loading='lazy' src="/images/logo.webp" alt="BirthBridge Logo" />
                            <span>BirthBridge</span>
                        </a>
                    </Col>
                    <Col sm={12} lg={6} className="pl-lg-0 main-staff-content-up-pass pl-1">
                        <div className="text-center">
                            <h2>Update Password</h2>
                        </div>
                        <Form className="m-1">
                            <Form.Group className="mb-3">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="New Password"
                                    name="password"
                                    value={passwordInput.password}
                                    onChange={handlePasswordChange}
                                    onKeyUp={handleValidation}
                                />
                                {passwordError && <span className="text-danger">{passwordError}</span>}
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirm New Password"
                                    value={passwordInput.confirmPassword}
                                    onChange={handlePasswordChange}
                                    onKeyUp={handleValidation}
                                />
                                {confirmPasswordError && <span className="text-danger">{confirmPasswordError}</span>}
                            </Form.Group>
                            <div className="text-center">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={loader}
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    {loader ? 'Updating...' : 'Update'}
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default StaffJoin;
