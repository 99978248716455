import React, { useState } from 'react';
import CreateBlog from './CreateBlog';
import BlogPreview from './BlogPreview';

const CreateBlogManagement = () => {
    const [isPreview, setIsPreview] = useState(false);
    const [blogData, setBlogData] = useState({
        title: '',
        category: '',
        slug: '',
        metaDescription: '',
        tags: [],
        tagInput: '',
        author: '',
        featuredImage: null,
        content: '',
        publishDate: ''
    });

    const handlePreview = (data) => {
        setBlogData(data);
        setIsPreview(true);
    };

    const handleBackToEdit = () => {
        setIsPreview(false);
    };

    const handleUpdateBlogData = (newData) => {
        setBlogData(newData);
    };

    return (
        <div>
            {isPreview ? (
                <BlogPreview blogData={blogData} onBack={handleBackToEdit} />
            ) : (
                <CreateBlog 
                    onPreview={handlePreview} 
                    blogData={blogData} 
                    onUpdateBlogData={handleUpdateBlogData}
                />
            )}
        </div>
    );
};

export default CreateBlogManagement;