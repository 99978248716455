import { GET_PLACES_SUCCESS, GET_PLACES_FAILED, GET_PLACE_DETAIL_SUCCESS, GET_PLACE_DETAIL_FAILED, GET_GEOIP_LOCATION_SUCCESS, GET_GEOIP_LOCATION_FAILED, CLEAR_PLACE_REDUX_STATE } from "../actions/types";

const initialState = {
    places: null,
    placeDetail: null,
    error: null
};

const placesReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_PLACES_SUCCESS:
            return {
                // ...state,
                places: action.payload,
                error: null
            }
        case GET_PLACES_FAILED: 
            return {
                // ...state,
                error : action.payload,
            }
        case GET_PLACE_DETAIL_SUCCESS:
            return {
                placeDetail: action.payload,
                error: null,
            }
        case GET_PLACE_DETAIL_FAILED:
            return {
                error: action.payload
            }
        case GET_GEOIP_LOCATION_SUCCESS:
            return {
                geoIp: action.payload,
                error: null
            }
        case GET_GEOIP_LOCATION_FAILED:
            return {
                error: action.payload
            }
        case CLEAR_PLACE_REDUX_STATE:
            return {
                placeDetail: null,
                places: null
            }
        default: return state;
    }
};

export default placesReducer;