import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import StartBusiness from './start-a-business-with-no-money.jpg'
import whyloveus from './whyloveus.jpg'
import growwithus from './growwithus.jpg'
const listStyling = {marginBottom:'32px'}
const LandingPageHowWeHelpYou = () => {
    return (
        <>
            {/* Section 1: Key Features */}
            <section className="popular--Wrapper pt-0">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        {/* Image */}
                        <Col xs={12} md={6} className="mb-4 mb-md-0">
                            <img
                                style={{ width: '100%', borderRadius: '10px' }}
                                src={growwithus}
                                alt="Empowering Birth Professionals"
                            />
                        </Col>

                        {/* Text */}
                        <Col xs={12} md={6}>
                            <h2>Grow Your Practice with Ease</h2>
                            <p>
                                BirthBridge makes it simple to attract new clients, manage your schedule, and focus on what matters most—supporting families. From seamless scheduling to secure payments, we’re here to help you thrive.
                            </p>
                            <ul>
                                <li style={listStyling}><strong>📅 Streamlined Scheduling:</strong> Manage availability and appointments effortlessly with tools designed for birth professionals.</li>
                                <li style={listStyling}><strong>💳 Secure Payments:</strong> Simplify transactions with a secure platform families trust.</li>
                                <li style={listStyling}><strong>⭐ Build Trust:</strong> Showcase reviews and testimonials to boost credibility and attract new clients.</li>
                                <li style={listStyling}><strong>🌐 Professional Networking:</strong> Share your network of trusted collaborators with families to provide comprehensive support.</li>
                                <li style={listStyling}><strong>🤝 Community Engagement:</strong> Use our forum to connect with families and collaborate with professionals, or share your expertise through personalized blogs.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Section 2: No Risk to Get Started */}
            <section className="popular--Wrapper pt-5 bg-light">
                <Container>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <img
                                style={{ width: '100%', borderRadius: '10px' }}
                                src={StartBusiness}
                                alt="No Risk Signup"
                            />
                        </Col>
                        <Col xs={12} md={6} className="mb-4 mb-md-0">
                            <h2>No Risk to Get Started</h2>
                            <p>
                                Joining BirthBridge is easy and commitment-free. You only pay when you gain bookings, ensuring you see value before investing. There’s no subscription or upfront cost.
                            </p>
                            <Button style={{ backgroundColor: '#73a5b5', color: 'white', }} variant="primary" href="/bridger-signup">
                                Get Started for Free
                            </Button>
                        </Col>

                        {/* Image */}
                    </Row>
                </Container>
            </section>

            {/* Section 3: Why You'll Love Us */}
            <section className="popular--Wrapper pt-5">
                <Container>
                    <Row className="align-items-center flex-row-reverse">
                        {/* Image */}
                        <Col xs={12} md={6} className="mb-4 mb-md-0">
                            <img
                                style={{ width: '100%', borderRadius: '10px' }}
                                src={whyloveus}
                                alt="Why You'll Love Us"
                            />
                        </Col>

                        {/* Text */}
                        <Col xs={12} md={6}>
                            <h2>Why You'll Love Us</h2>
                            <ul>
                                <li style={listStyling}><strong>🌟 Attract More Clients:</strong> Get discovered by families seeking trusted professionals like you.</li>
                                <li style={listStyling}><strong>📈 Boost Efficiency:</strong> Simplify your day-to-day with scheduling, payments, and communication tools all in one place.</li>
                                <li style={listStyling}><strong>💬 Personalized Support:</strong> Reach out to our dedicated team whenever you need assistance or have ideas to share.</li>
                                <li style={listStyling}><strong>✨ Stay in Control:</strong> Decide your availability, services, and rates—BirthBridge works around your preferences.</li>
                                <li style={listStyling}><strong>✍️ Share and Connect:</strong> Post blogs, share experiences, and engage with families and professionals in our vibrant forum.</li>
                            </ul>
                            <Button
                                style={{ backgroundColor: '#73a5b5', color: 'white', }}
                                variant="primary"
                                href="/innovative-birth-marketplace-what-sets-birthbridge-apart">
                                Learn More About Our Platform
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default LandingPageHowWeHelpYou;