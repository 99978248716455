import React from 'react'
import { BsExclamationTriangle } from 'react-icons/bs';

const UpgradeSubscriptionBox = () => {
    return (
        <div className="upgrade--subscription--plan text-center">
            <div className="mb-3">
                <BsExclamationTriangle size={52} color="orange" className="mb-3" />
                <h4><strong>Upgrade Your Subscription Plan</strong></h4>
            </div>
            <p>
                Your current plan staff limit reached the maximum, you need to upgrade your subscription plan.
            </p>
            <p>
                Please reach out to the BirthBridge team to update your plan!
            </p>
            <p>
                hello@mybirthbridge.com
            </p>
        </div>
    )
}

export default UpgradeSubscriptionBox;