// import moment from 'moment';
import React from 'react';
import { generateLetterSVG, momentFormat } from '../../utils/validations';
import RatingReadOnly from '../../components/RatingReadOnly';
import { optimizedCloudinaryImage } from '../../utils/fileCompression';

const CustomReviewCard = ({ review, key, reviews, singleBusinessDetails }) => {

    if (!review) {
        return null
    }

    return (
        <div className="reviews--single" key={key}>
            <div className="user--Image">
                <span className="image--round">
                   {(review.buyer.profilePic && !review.hideName)
                   ? <img 
                        loading='lazy' 
                        src={optimizedCloudinaryImage(review.buyer.profilePic.url)} 
                        alt={`Profile picture for ${review.buyer.name}`} 
                    />
                    : generateLetterSVG(review.hideName ? 'Anonymous' : review.buyer.name, 14)}
                </span>
                <div className="user--review--detail">
                    <h6>
                        {review.hideName ? 'Anonymous' : review.buyer.name}
                        <span>{momentFormat.fullDate(review.createdAt)}</span>
                    </h6>
                    <RatingReadOnly value={review.rating} reviews={reviews} />
                </div>
            </div>
            <div className="user--Rating">
                <span className="mt-2">{review.review}</span>
            </div>
        </div>
    )
}

export default CustomReviewCard;