import React, { useEffect, useState } from 'react';
import LoadingView from '../../LoadingView';
import API from '../../../helpers/api';
import { isAuth } from "../../../helpers/auth";
import { Container, Row, Table } from 'react-bootstrap';
import RatingReadOnly from '../../RatingReadOnly';
import { momentFormat } from '../../../utils/validations';

const Reviews = () => {
    const loggedIn = isAuth();
    const [ loader, setLoader ] = useState(true);
    const [ reviews, setReviews ] = useState([]);


    useEffect(() => {
        if(loggedIn){
            getReviews();
        }
    }, []);

    const getReviews = async () => {
        try{
            const response = await API.apiGet('rating', { userId:loggedIn.aud });
            setLoader(false)
            if(response.data && response.data.success){
                setReviews(response.data.reviews)
            }
        }catch (error){
            // console.log(error);
            setLoader(false);
        }
    }

    const renderTable = () => {
        return (
            <>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th scope="col">Order Id</th>
                            <th scope='col'>Customer</th>
                            <th scope="col">Rating</th>
                            <th scope="col">Feedback</th>
                            <th scope='col'>Review Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reviews.map((review, reviewIndex) => {
                           
                            return (
                                <tr
                                    key={reviewIndex}
                                >
                                    <td aria-description='orderId'>{review.orderId.order_id}</td>
                                    <td aria-description='customer'>
                                        {(review.orderId.isFeedbackGiven && review.buyer && typeof review.buyer === 'object')
                                            ?  review.buyer.name 
                                            : 'N/A'
                                        }
                                    </td>
                                    <td aria-description='rating'>{review.rating ? <RatingReadOnly value={review.rating}/> : 'N/A'}</td>
                                    <td aria-description='feedback'>{review.review || 'N/A'}</td>
                                    <td aria-description='reviewDate'>{review.createdAt ? momentFormat.dateTimeFormat(review.createdAt) : 'N/A'}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </>
        )
    }
    
    return (
        <Container fluid>
             <Row>
                <div className="services-main-wraper-order">
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <h3>Reviews</h3>
                    </div>
                    <div className='table-wallet-v2'>
                        {loader
                        ?   <LoadingView />
                        :   (reviews.length > 0)
                            ? renderTable()
                            : <h4> No Reviews Found</h4>
                        }
                    </div>
                </div>
            </Row>

        </Container>
    )
}

export default Reviews;