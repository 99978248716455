import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row, Navbar, Nav, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BiLogOutCircle, BiClipboard } from 'react-icons/bi';
import { CgProfile } from 'react-icons/cg';
import { BsBookmark, BsChatLeftText } from 'react-icons/bs'
    ;
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { logout } from "../../helpers/auth";
// import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { switchAppView } from "../../redux/actions/meta.action";

import { getUserProfile } from "../../redux/actions/user.action";
import { showAuthModal, logout } from "../../redux/actions/auth.actions";
import AuthModal from "../Authentication/AuthModal";
import { optimizedCloudinaryImage } from "../../utils/fileCompression";
import { generateLetterSVG } from "../../utils/validations";

// import 'bootstrap/dist/css/bootstrap.min.css';
import './headerStyle.css';
import './loginStyle.css';
// import '../../App.css';
import BrowseSpecialtiesModal from "./BrowseSpecialtiesModal";
function HeaderSite() {
    const navRef = useRef(null);

    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [showLogin, setLoginShow] = useState(false);
    
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userData, setUserData] = useState();
    const [expanded, setExpanded] = useState(false);
    /** ---- Update Message Count ---- */
    const [messageCount, setMessageCount] = useState(null);


    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.user);
    const showModal = useSelector(state => state.showModal);

    const socketState = useSelector(state => state.socket)

    // const searchParams = new URLSearchParams(location.search);
    // const token = searchParams.get('token');

    useEffect(() => {
        if (auth.loggedIn) {
            dispatch(getUserProfile())
        }
    }, [auth.loggedIn, dispatch]);

    useEffect(() => {
        if (user.userDetail) {
            setUserData(user.userDetail);
            setIsLoggedIn(true);
        }
    }, [user]);

    useEffect(() => {
        if (showModal.authModal !== null || showModal.authModal !== undefined) {
            setLoginShow(showModal.authModal);
        }
    }, [showModal.authModal]);

    /** ------ Message Count State ------ */
    useEffect(() => {
        if (socketState.unreadCount) {
            setMessageCount(socketState.unreadCount.unreadCount)
        }
    }, [socketState])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [navRef]);

    const handleToggle = () => setExpanded(!expanded);

    const renderAccountMenus = () => {
        return (
            <Dropdown className="order-lg-last">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    <UserAvatar userData={userData} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {userData?.name &&
                        <Dropdown.Item className="my-profile-text">
                            <Link to="/profile">
                                <CgProfile /> {userData.name}
                            </Link>
                        </Dropdown.Item>
                    }
                    {(userData && userData.role === 'buyer') &&
                        <>
                            <Dropdown.Item>
                                <Link to="/bookmarks">
                                    <BsBookmark /> Bookmarks
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to="/appointments">
                                    <BiClipboard /> Appointments
                                </Link>
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <Link to="/chat">
                                    <span>
                                        <BsChatLeftText /><span className="nav--title">Conversations</span>
                                    </span>
                                    {(messageCount > 0) &&
                                        <span className="nav--count--badge ml-1">{messageCount}</span>
                                    }
                                </Link>
                            </Dropdown.Item>
                        </>}
                    <Dropdown.Item onClick={() => logout()}><BiLogOutCircle /> Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    }

    return (
        <>
            <header className="App-header my-profile-page-main-wraper header-main">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <Navbar ref={navRef} expand="lg" className="p-0" expanded={expanded}>
                                <Navbar.Brand>
                                    <Link to="/">
                                        <img src="/images/logo.webp" loading='lazy' alt="BirthBridge Logo" width={'60px'} />
                                        <span>BirthBridge</span>
                                    </Link>
                                </Navbar.Brand>
                                <Navbar.Toggle className="ml-auto" aria-controls="basic-navbar-nav" onClick={handleToggle} />
                                {isLoggedIn ? renderAccountMenus() : null}
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <NavLinks
                                        isLoggedIn={auth.loggedIn}
                                        onClose={() => setExpanded(false)}
                                        userData={userData}
                                    />
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
            {showLogin &&
                <AuthModal
                    show={showLogin}
                    onHide={() => dispatch(showAuthModal(false))}
                />
            }
        </>
    );
}

const UserAvatar = ({ userData }) => {
    return (
        <div className="my-pic-user">
            {(userData?.profilePic && userData?.profilePic?.url) ? (
                <img src={optimizedCloudinaryImage(userData?.profilePic.url)} alt="profile picture" loading="lazy" />
            ) : (
                <div className="user-buyer-name">
                    {generateLetterSVG(userData.name, 26)}
                </div>
            )}
        </div>
    );
};

const NavLinks = ({ isLoggedIn, onClose, userData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showSpecialtiesModal, setShowSpecialtiesModal] = useState(false);
    return (
        <Nav className="ml-auto">

            <Nav.Link onClick={() => setShowSpecialtiesModal(true)}>Browse Specialties</Nav.Link>

            <BrowseSpecialtiesModal
                show={showSpecialtiesModal}
                onHide={() => setShowSpecialtiesModal(false)}
            />

            <Nav.Link as={Link} to="/search" onClick={onClose}>Find Birth Professionals</Nav.Link>
            <Nav.Link as={Link} to="/community" onClick={onClose}>Our Community</Nav.Link>
            {/* <Nav.Link as={Link} to="/discover" onClick={onClose}>Discover</Nav.Link> */}
            <Nav.Link as={Link} to="/blog-list" onClick={onClose}>Articles</Nav.Link>
            {/* <Nav.Link as={Link} to="/the-importance-of-preceptorship-guiding-the-next-generation-of-birth" onClick={onClose}>Preceptorship</Nav.Link> */}
            <Nav.Link as={Link} to="/support-families-through-surrogacy-and-adoption-donations" onClick={onClose}>Donations</Nav.Link>

            {/* <Nav.Link as={Link} to="/contact-birthbridge" onClick={onClose}>Contact</Nav.Link> */}
            {!isLoggedIn && (
                <>
                    <Nav.Link as={Link} to="/birth-professional-listing" onClick={onClose}>List Your Practice</Nav.Link>
                    <Nav.Link onClick={() => {
                        navigate('/');
                        dispatch(showAuthModal(true));
                        onClose();
                    }}>
                        Login
                    </Nav.Link>
                </>
            )}

            {(userData && userData.role !== 'buyer') &&
                (
                    <Nav.Link
                        as={Link}
                        onClick={(e) => {
                            e.preventDefault()
                            dispatch(switchAppView('seller'))

                        }}
                    >
                        Manage Your Practice
                    </Nav.Link>
                )}
        </Nav>
    );
};

export default HeaderSite;