import React from "react";
import { Navigate } from "react-router-dom";
/** --------------------- Components -------------------------- */
import HomeComponent from "../components/Home";
import NewBusinessListingPageComponent from "../components/NewBusinessListingPageComponent/index";
import SingleBusiness from "../components/SingleBusiness";
import BusinessListingPageComponent from "../components/BusinessListingPageComponent/BusinessListingPageComponent.js";
import ContactUs from "../components/contactus/contactUs.js";
import BlogPost from "../components/services/BlogPost.js";
import BlogList from "../components/services/BlogList.js";

import Faqs from "../components/faqs/faqs.js";
import PrivacyPolicyFamilies from "../components/privacypolicy/privacyPolicyFamilies.js";
import PrivacyPolicyBirthProviders from "../components/privacypolicy/privacyPolicyBirthProviders.js";
import TermsAndConditionsFamilies from "../components/termsandconditions/TermsAndConditionsFamilies.js";
import StripeBirthProviders from "../components/privacypolicy/stripeBirthProfessionals.js";
import TermsAndConditionsBirthProviders from "../components/termsandconditions/TermsAndConditionsBirthProviders.js";

import BookMarks from "../components/Buyer/BookMarks";
import Appointments from "../components/Buyer/Appointments";

import Dashboard from "../components/Seller/Dashboard";
import MyBlogsCreate from "../components/Seller/MyBlogs/CreateBlogManagement";
import ViewAllMyBlogs from "../components/Seller/MyBlogs/ViewAllMyBlogs";
import ViewBlog from "../components/Seller/MyBlogs/ViewBlog";
import EducationalContent from "../components/SingleBusiness/educationalContent/ViewEducationalContent";


import SellerProfile from "../components/Seller/Profile";
import MyProfile from "../components/Profile/ProfilePage";
import Reviews from "../components/Seller/Reviews";

import AddStaffContent from "../components/Seller/StaffManagement/AddStaffTab";
import AddUpdateService from "../components/Seller/ServiceManagement/AddUpdateService";
// import EditServicesContent from "../components/Seller/ServiceManagement/EditServices";
import ServicesContent from "../components/Seller/ServiceManagement/ServicesPage";
import StaffList from "../components/Seller/StaffManagement";
// import SuspendedStaff from "../components/Seller/StaffManagement//SuspendedStaff";
import StaffJoin from "../components/Authentication/SignIn/StaffJoin";
import BookService from "../components/SingleBusiness/Booking/BookService";

// import StripeAccountOnboard from '../components/Seller/GetPaid/StripeAccountOnboard';
import StripeSuccess from "../components/SingleBusiness/StripeSuccess";
import StripeCancel from "../components/SingleBusiness/StripeCancel";
import Orders from "../components/Seller/OrdersManagement";
import Wallet from "../components/Seller/Wallet/Wallet";

/** ------ Subscription Routes Starts ------ */
import PaySubscription from "../components/Subscription/PaySubscription.js";
import PaymentCancel from "../components/Subscription/PaymentCancel";
import PaymentSuccess from "../components/Subscription/PaymentSuccess.js";
/** ------ Subscription Routes Ends ------ */


import ServiceScheduler from "../components/Seller/ServiceScheduler";
import BookSuccess from "../components/BookingConfirmation/success";
import TransactionHistories from "../components/Seller/TransactionHistories";
import SearchComponent from "../components/SearchComponent";
// import DiscoverComponent from "../components/DiscoverComponent/index.js";
// import NewDiscoverComponent from "../components/NewDiscoverComponentOld/index.js";
import Messenger from "../components/Messenger";
import Refferals from "../components/Seller/Refferals";
// import DiscoverCompanies from "../components/DiscoverCompanies";
// import SellerSignUpModel from "../components/Authentication/UserSignUp/SellerSignUpModel";
import SignUpModel from "../components/Authentication/UserSignUp/SignUpModel";
import SellerSignUp from "../components/Authentication/UserSignUp/seller/index.js";
// import Subscription from "../components/Seller/Subscription/index.js";

import SignInPage from "../components/Authentication/SignIn/SignInModelPage.js";
import SavedSuggestedList from "../components/SavedSuggestedList/index.js";


// community forum stuff
import CommunityForum from "../components/communityForum/index.js";
import SinglePost from "../components/communityForum/SinglePost.js";
/** -------------------------------------------------------------*/

const notFound = [
    {
        type: 'other',
        name: 'Un Match',
        key: 'UN_MATCH',
        component: <Navigate to="/" />,
        route: "*",
    }
]

const buyerSignup = [
    {
        type: 'page',
        name: 'Customer Signup',
        key: 'CUSTOMER_SIGNUP',
        component: <SignUpModel />,
        route: '/family-signup',
    }
]

const sellerSignUp = [
    {
        type: 'page',
        name: 'Business SignUp',
        key: 'BUSINESS_SIGNUP',
        component: <SellerSignUp />,
        route: '/bridger-signup',
    },
    {
        type: 'page',
        name: 'Invite Business SignUp',
        key: 'INVITE_BUSINESS_SIGNUP',
        component: <SellerSignUp />,
        route: '/invite',
    },

]

/** commmon Router for buyer and seller  */

const commonRouter = [
    {
        type: 'page',
        name: 'home',
        key: 'HOME',
        component: <HomeComponent />,
        route: '/',
    },
    // {
    //     type: 'page',
    //     name: 'Discover',
    //     key: 'DISCOVER_LIST',
    //     component: <DiscoverComponent />,
    //     route: '/discover', // discover-birth-professionals
    // },
    {
        type: 'page',
        name: 'popular',
        key: 'POPULAR',
        component: <SingleBusiness />,
        route: '/business/:id', //birth-professional-business
    },
    {
        type: 'page',
        name: 'Search',
        key: 'SEARCH_COMPONENT',
        component: <SearchComponent />,
        route: '/search', //birth-professional-search
    },
    // {
    //     type: 'page',
    //     name: 'Login',
    //     key: 'LOGIN_COMPONENT',
    //     component: <SignInPage />,
    //     route: '/login',
    // },
    {
        type: 'page',
        name: 'BookService',
        key: 'BOOKSERVICE',
        component: <BookService />,
        route: '/book-appointments/:id',
    },
    {
        type: 'page',
        name: 'businesslisting',
        key: 'BUSINESS_LISTING_COMPONENT',
        component: <NewBusinessListingPageComponent />,
        route: '/birth-professional-listing',
    },
    {
        type: 'page',
        name: 'DynamicBlogPost',
        key: 'DYNAMIC_BLOG_POST_COMPONENT',
        component: <BlogPost />,
        route: '/:slug', 
    },
    {
        type: 'page',
        name: 'BlogList',
        key: 'BLOG_LIST_PAGE_COMPONENT',
        component: <BlogList />,
        route: '/blog-list',
    },
    {
        type: 'page',
        name: 'SignUpPage',
        key: 'SIGNUP_PAGE_COMPONENT',
        component: <BlogPost />,
        route: '/sign-up',
    },
    {
        type: 'page',
        name: 'contactus',
        key: 'CONTACT_US_COMPONENT',
        component: <ContactUs />,
        route: '/contact-birthbridge',
    },
    {
        type: 'page',
        name: 'faqs',
        key: 'FAQS_COMPONENT',
        component: <Faqs />,
        route: '/faqs',
    },
    {
        type: 'page',
        name: 'privacypolicy',
        key: 'PRIVACY_POLICY_FAMILIES_COMPONENT',
        component: <PrivacyPolicyFamilies />,
        route: '/privacypolicyfamilies',
    },
    {
        type: 'page',
        name: 'privacypolicy',
        key: 'PRIVACY_POLICY_BIRTH_PROVIDERS_COMPONENT',
        component: <PrivacyPolicyBirthProviders />,
        route: '/privacypolicybirthproviders',
    },
    {
        type: 'page',
        name: 'termsAndConditionsFamilies',
        key: 'TERMS_AND_CONDITIONS_FAMILIES_COMPONENT',
        component: <TermsAndConditionsFamilies />,
        route: '/familyterms',
    },
    {
        type: 'page',
        name: 'termsAndConditionsBirthProviders',
        key: 'TERMS_AND_CONDITIONS_BIRTH_PROVIDERS_COMPONENT',
        component: <TermsAndConditionsBirthProviders />,
        route: '/birthproviderterms',
    },
    {
        type: 'page',
        name: 'stripeBirthProviders',
        key: 'STRIPE_BIRTH_PROVIDERS_COMPONENT',
        component: <StripeBirthProviders />,
        route: '/stripebirthproviders',
    },

    {
        type: 'page',
        name: 'STAFF JOIN',
        key: 'STAFF JOIN',
        component: <StaffJoin />,
        route: '/join',
    },
    {
        type: 'page',
        name: 'communityForum',
        key: 'COMMUNITY_FORUM',
        component: <CommunityForum />,
        route: '/community',
    },
    {
        type: 'page',
        name: 'communityForum',
        key: 'COMMUNITY_FORUM_SINGLE_POST',
        component: <SinglePost />,
        route: '/community/posts/:postId',
    },
];

const chatMessanger = [
    {
        type: 'page',
        name: 'Messanger',
        key: 'Messager',
        component: <Messenger />,
        route: '/chat',
    },
    {
        type: 'page',
        name: 'Messanger',
        key: 'Messager_Single',
        component: <Messenger />,
        route: '/chat/:chatId',
    }
]

/**   public Routes when user logged out  */

const publicRoutes = [
    ...commonRouter,
    {
        type: 'page',
        name: 'Login',
        key: 'LOGIN_COMPONENT',
        component: <SignInPage />,
        route: '/login',
    },
]

/** ------- Seller Subscription Routes Used to Access for only Sellers in Dashboard to Upgrade ----- */
const sellerSubscriptionPlans = [
    {
        type: 'sub-page',
        name: 'Pay Subscription',
        key: 'PAY_SUBSCRIPTION',
        component: <PaySubscription />,
        route: '/pay/subscription',
    },
    {
        type: 'sub-page',
        name: 'Pay Subscription Success',
        key: 'PAY_SUBSCRIPTION_SUCCESS',
        component: <PaymentSuccess />,
        route: '/pay/subscription/success',
    },
    {
        type: 'sub-page',
        name: 'Pay Subscription Cancel',
        key: 'PAY_SUBSCRIPTION_CANCLE',
        component: <PaymentCancel />,
        route: '/pay/subscription/cancel',
    },
]

/** -------  Pay Subscriptions Routes Used to Purchase Plans on Very First Login & Restrict to access other routes  ----- */
const paySubscription = [
    {
        type: 'other',
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/pay/subscription" />,
        route: "/",
    },
    ...sellerSubscriptionPlans,
    ...notFound
]

/*******  using for Buyer ******/

const buyerRoutes = [
    ...commonRouter,
    {
        type: 'page',
        name: 'profile',
        key: 'PROFILE',
        component: <MyProfile />,
        route: '/profile',
    },
    {
        type: 'page',
        name: 'bookmarks',
        key: 'BOOKMARKS',
        component: <BookMarks />,
        route: '/bookmarks',
    },
    {
        type: 'page',
        name: 'appointments',
        key: 'APPOINTMENTS',
        component: <Appointments />,
        route: '/appointments',
    },
    {
        type: 'page',
        name: 'stripesuccess',
        key: 'STRIPESUCESS',
        component: <StripeSuccess />,
        route: '/stripe/success',
    },
    {
        type: 'page',
        name: 'stripeCancel',
        key: 'STRIPECANCEL',
        component: <StripeCancel />,
        route: '/stripe/cancel',
    },
    {
        type: 'page',
        name: 'BookSuccess',
        key: 'BOOKSUCCESS',
        component: <BookSuccess />,
        route: '/booking'
    },
    ...chatMessanger,
    ...notFound
]



/** -------  Seller Routes -------- */

const staffManagement = [
    {
        type: 'page',
        name: 'Add Staff',
        key: 'ADDSTAFF',
        component: <AddStaffContent />,
        route: '/add-staff',
    },
    {
        type: 'page',
        name: 'Staff',
        key: 'STAFF',
        component: <StaffList />,
        route: '/staff',
    },

    {
        type: 'page',
        name: 'Staff Service',
        key: 'STAFF_SERVICES',
        component: <ServicesContent />,
        route: '/products/staff/:staffId',
    }
]

const sellerCommonRoutes = [
    {
        type: "other",
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/dashboard" />,
        route: "/",
    },
    {
        type: 'page',
        name: 'Dashboard',
        key: 'DASHBOARD',
        component: <Dashboard />,
        route: '/dashboard',
    },
    {
        type: 'page',
        name: 'MyBlogs',
        key: 'MYBLOGS',
        component: <ViewAllMyBlogs />,
        route: '/my-blogs',
    },
    {
        type: 'page',
        name: 'ViewBlog',
        key: 'VIEW_BLOG',
        component: <ViewBlog />,
        route: '/my-blogs/:id',
    },
    {
        type: 'page',
        name: 'MyBlogsCreate',
        key: 'MYBLOGSCREATE',
        component: <MyBlogsCreate />,
        route: '/create-blogs',
    },
    {
        type: 'page',
        name: 'About',
        key: 'ABOUT',
        component: <SellerProfile />,
        route: '/profile',
    },
    {
        type: 'page',
        name: 'Add Product',
        key: 'ADD PRODUCT',
        component: <AddUpdateService />,
        route: '/add-service',
    },
    {
        type: 'page',
        name: 'Edit Product',
        key: 'EDITPRODUCT',
        component: <AddUpdateService />,
        route: '/edit-service/:id',
    },
    {
        type: 'page',
        name: 'Product',
        key: 'PRODUCT',
        component: <ServicesContent />,
        route: '/service',
    },
    {
        type: 'page',
        name: 'Orders',
        key: 'ORDERS',
        component: <Orders />,
        route: '/orders'
    },
    {
        type: 'page',
        name: 'Wallet',
        key: 'WALLET',
        component: <Wallet />,
        route: '/wallet'
    },
    {
        type: 'page',
        name: 'Scheduler',
        key: 'SCHEDULER',
        component: <ServiceScheduler />,
        route: '/scheduler'
    },
    {
        type: 'Page',
        name: 'Transactions',
        key: 'TRANSACTIONS',
        component: <TransactionHistories />,
        route: '/transactions'
    },
    {
        type: 'page',
        name: 'Reviews',
        key: 'REVIEWS',
        component: <Reviews />,
        route: '/reviews',
    },
    {
        type: 'page',
        name: 'Suggestions',
        key: 'SUGGESTIONS',
        component: <SavedSuggestedList />,
        route: '/suggestions',
    },
    // ...commonRouter,
    ...sellerSubscriptionPlans,
    ...chatMessanger,
]

/** ---- Used For Business Owner ----- */
const sellerRoutes = [
    ...sellerCommonRoutes,
    ...staffManagement,
];


/** ---- Used For Individual Seller Without Staff ----- */
const sellerIndividualRoutes = [
    ...sellerCommonRoutes,
]

const sellerInviteRoute = [
    {
        type: 'page',
        name: 'Refferals',
        key: 'Invite',
        component: <Refferals />,
        route: '/invite',
    },
]

const isSellerProfileNotCompleted = [
    {
        type: 'other',
        name: 'ROOT',
        key: 'ROOT',
        component: <Navigate to="/profile" />,
        route: "/",
    },
    {
        type: 'page',
        name: 'About',
        key: 'ABOUT',
        component: <SellerProfile />,
        route: '/profile',
    },
    ...notFound
]

export {
    commonRouter,
    publicRoutes,
    buyerSignup,
    sellerSignUp,
    sellerIndividualRoutes,
    sellerRoutes,
    buyerRoutes,
    paySubscription,
    sellerInviteRoute,
    isSellerProfileNotCompleted,
    notFound
};
