import React from 'react';
import { 
    TextField, 
    InputAdornment 
} from '@mui/material';
import { FiSearch } from 'react-icons/fi';

const SearchBar = ({ value, onChange }) => {
    return (
        <TextField
            fullWidth
            placeholder="Search posts..."
            value={value}
            onChange={(e) => onChange(e.target.value)}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <FiSearch />
                    </InputAdornment>
                ),
            }}
            sx={{ maxWidth: { xs: '100%', md: 300 } }}
        />
    );
};

export default SearchBar; 