import React from 'react';
import { Modal } from 'react-bootstrap';
import PdfViewers from '../PDFViewer';

const FileViewModal = (props) => {
    const renderFile = () => {
        let type = props.type
        let fileView = null

        switch (type.toLowerCase()){
            case 'pdf': 
                fileView = <PdfViewers pdfDataUrl={props.url} />
                break;
        }

        return fileView;
    }

    return (
        <Modal 
            show={props.show}
            onHide={props.onHide}
            backdrop='static'
            size='xl'
        >
            <Modal.Header closeButton style={{ textTransform: 'capitalize'}}>
                {props.title || null}
            </Modal.Header>
            <Modal.Body>
                {renderFile()}
            </Modal.Body>
            {props.description &&
                <Modal.Footer>
                    {props.description}
                </Modal.Footer>
            }
        </Modal>
    )
    
   
}

export default FileViewModal;