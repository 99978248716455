import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, NavItem } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Link } from "react-router-dom";
import { RxDashboard } from 'react-icons/rx';
import { GrCubes } from "react-icons/gr";
import { GoChecklist } from "react-icons/go";
// import { RiPriceTag2Line } from "react-icons/ri";
import { MdOutlineManageAccounts } from "react-icons/md";
import { BiMenuAltLeft, BiListCheck } from 'react-icons/bi';
// import { IoIosGitNetwork } from "react-icons/io";
import { PiUsersThree } from "react-icons/pi";
// import { AiOutlineUserAdd, AiOutlineAppstoreAdd } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import { CgProfile } from "react-icons/cg";
// import { CiMoneyCheck1 } from "react-icons/ci";
// import { FaUserAltSlash } from "react-icons/fa";
import {
    // BsFolderSymlink, 
    BsWallet2,
    BsChatLeftText,
    BsEnvelopeAt
} from "react-icons/bs";
import { GrTransaction } from "react-icons/gr";
import Swal from "sweetalert2";
import { VscCalendar } from "react-icons/vsc";
// import { useSelector } from "react-redux";
import { useSelector } from "react-redux";
import "../../../styles/editStyle.css"

function AdminSidebar() {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    const [tokenData, setTokenData] = useState('');
    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const [userMeta, setUserMeta] = useState(null)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [expanded, setExpanded] = useState(false);
    const [isReferralActive, setReferralActive] = useState(false);
    const [messageCount, setMessageCount] = useState(null);

    const metaState = useSelector(state => state.meta);

    const user = useSelector(state => state.user);

    const socketState = useSelector(state => state.socket)

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const handleClick = () => {
        setIsActive(current => !current);
    };



    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            setTokenData(jwt_decode(token));
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (metaState.platform && metaState.platform.success) {
            if (metaState.platform.referral) {
                setReferralActive(metaState.platform.referral.is_active);
            }
        }
    }, [metaState])

    useEffect(() => {
        if (!user.error && user.userMetaDetail) {
            setUserMeta(user.userMetaDetail);
        }
    }, [user]);

    useEffect(() => {
        if (socketState.unreadCount) {
            setMessageCount(socketState.unreadCount.unreadCount)
        }
    }, [socketState])

    const renderInCompleteProfile = (event) => {
        if (userMeta && userMeta.role !== 'buyer' && !userMeta.is_profile_completed) {
            event.preventDefault(); // Prevents the navigation
        }
    }

    const handleItemClick = (event) => {
        if (userMeta && userMeta.role !== 'buyer' && !userMeta.is_profile_completed) {
            if (event) {
                event.preventDefault()
            }
            Swal.fire({
                title: "Oops...",
                text: "Please finish completing your profile before moving to other tabs.",
                icon: "info"
            });
        }
        if (isMobile) {
            setExpanded(false);
        }
    };



    return (
        <>
            <button className={isActive ? 'button--Toggle' : 'btn--Click'} onClick={handleClick}><span><BiMenuAltLeft /></span></button>
            <div id="nav--sidebar" className={isActive ? 'collapse--Nav' : ''}
            >
                <Navbar expand="md" expanded={expanded} onToggle={() => setExpanded(!expanded)}>
                    {/* <Navbar.Brand href="javascript:;">MENU</Navbar.Brand> */}
                    <Navbar.Toggle className="ml-auto " aria-controls="sidebar-navbar-nav" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="sidebar-navbar-nav">
                        <Nav>
                            <NavItem onClick={() => handleItemClick()}>
                                <Link to="/dashboard" onClick={renderInCompleteProfile} className={` ${(location.pathname.includes('/dashboard')) ? 'nav-link-active' : ''}`}>
                                    <RxDashboard /> <span className="nav--title">Dashboard</span>
                                </Link>
                            </NavItem>
                            <NavItem onClick={() => handleItemClick()}>
                                <Link to="/transactions" onClick={renderInCompleteProfile} className={`nav-link-route ${location.pathname.includes('/transactions') ? 'nav-link-active' : ''}`}>
                                    <GrTransaction /> <span className="nav--title">Transactions</span>
                                </Link>
                            </NavItem>

                            <NavItem onClick={() => handleItemClick()}>
                                <Link to="/service" onClick={renderInCompleteProfile} className={`link-side-bar-full-width-sub ${location.pathname.includes('/service') ? 'nav-link-active' : ''}`}>
                                    <GrCubes /> <span className="nav--title">Specialties</span>
                                </Link>
                            </NavItem>

                            <NavItem onClick={() => handleItemClick()}>
                                <Link to="/scheduler" onClick={renderInCompleteProfile} className={`link-side-bar-full-width-sub ${location.pathname.includes('/scheduler') ? 'nav-link-active' : ''}`}>
                                    <VscCalendar /> <span className="nav--title">Schedule</span>
                                </Link>
                            </NavItem>

                            {/* <NavItem onClick={() => handleItemClick()}>
                                <Link to="/onboard-status" onClick={renderInCompleteProfile} className={`link-side-bar-full-width-sub ${location.pathname.includes( '/onboard-status') ? 'nav-link-active' : ''}`}>
                                    <BsFolderSymlink /> <span className="nav--title">Get Paid</span>
                                </Link>
                            </NavItem> */}

                            <NavItem onClick={() => handleItemClick()}>
                                <Link to="/orders" onClick={renderInCompleteProfile} className={`nav-link-route ${location.pathname.includes('/orders') ? 'nav-link-active' : ''}`} >
                                    <GoChecklist /> <span className="nav--title">Orders</span>
                                </Link>
                            </NavItem>

                            <NavItem onClick={() => handleItemClick()}>
                                <Link to="/wallet" onClick={renderInCompleteProfile} className={`nav-link-route ${location.pathname.includes('/wallet') ? 'nav-link-active' : ''}`}>
                                    <BsWallet2 /> <span className="nav--title">Wallet</span>
                                </Link>
                            </NavItem>
                            <NavItem onClick={() => handleItemClick()}>
                                <Link
                                    to={'/chat'}
                                    onClick={renderInCompleteProfile}
                                    className={`justify-content-between av-link-route ${location.pathname.includes('/chat') ? 'nav-link-active' : ''}`}

                                >
                                    <span>
                                        <BsChatLeftText /> <span className="nav--title">Messages</span>
                                    </span>
                                    {(messageCount > 0) && <span className="nav--count--badge">{messageCount}</span>}

                                </Link>

                            </NavItem>
                            {tokenData.role === "seller" &&
                                <>
                                    {/* <NavItem className="dropdown">
                                        <Link to="/staff" className={` ${(location.pathname.includes( '/staff')) ? 'nav-link-active' : ''}`}>
                                            <FiUsers /> <span className="nav--title">Staff</span>
                                        </Link>
                                    </NavItem> */}
                                    <NavDropdown title={<span><MdOutlineManageAccounts /> Staff Management</span>} id="basic-nav-dropdown">
                                        <Link to="/staff" className={`${(location.pathname.includes('/staff')) ? 'nav-link-active dropdown-item' : ''}`} onClick={(event) => handleItemClick(event)}>
                                            <FiUsers /> <span className="nav--title">Staff</span>
                                        </Link>
                                        <Link to="/staff?view=trash" className={`${(location.pathname.includes('/staff')) ? 'nav-link-active dropdown-item' : ''}`} onClick={(event) => handleItemClick(event)}>
                                            <BiListCheck /> <span className="nav--title">View Trash</span>
                                        </Link>
                                    </NavDropdown>
                                </>
                            }

                            {/* {(tokenData.role === "seller") &&
                                <>
                                    <NavItem onClick={() => handleItemClick()}>
                                        <Link to="/pay/subscription" className={` ${(location.pathname.includes( '/pay/subscription')) ? 'nav-link-active' : ''}`}>
                                            <RiPriceTag2Line /> <span className="nav--title">Subscriptions</span>
                                        </Link>
                                    </NavItem>
                                </>
                            } */}

                            <NavItem onClick={() => handleItemClick()}>
                                <Link to={'/suggestions'} className={`av-link-route ${location.pathname.includes('/suggestions') ? 'nav-link-active' : ''}`}>
                                    <PiUsersThree /> <span className="nav--title">Recommended Professionals</span>
                                </Link>
                            </NavItem>



                            {/* {window.location.origin !== 'https://mybirthbridge.com' &&
                                } */}
                                <NavDropdown title={<span><MdOutlineManageAccounts /> Content</span>} id="basic-nav-dropdown">
                                    <Link to="/my-blogs" className={`${(location.pathname.includes('/my-blogs')) ? 'nav-link-active dropdown-item' : ''}`} onClick={(event) => handleItemClick(event)}>
                                        <FiUsers /> <span className="nav--title">View</span>
                                    </Link>
                                    <Link to="/create-blogs" className={`${(location.pathname.includes('/create-blogs')) ? 'nav-link-active dropdown-item' : ''}`} onClick={(event) => handleItemClick(event)}>
                                        <BiListCheck /> <span className="nav--title">Create</span>
                                    </Link>
                                </NavDropdown>



                            <NavItem onClick={() => handleItemClick()}>
                                <Link to={'/profile'} className={`av-link-route ${location.pathname.includes('/profile') ? 'nav-link-active' : ''}`}>
                                    <CgProfile /> <span className="nav--title">Profile</span>
                                </Link>
                            </NavItem>

                            {
                                (tokenData.role == "seller" && isReferralActive) &&
                                <NavItem onClick={() => handleItemClick()}>
                                    <Link to="/invite" onClick={renderInCompleteProfile} className={`link-side-bar-full-width-sub ${location.pathname.includes('/invite') ? 'nav-link-active' : ''}`}>
                                        <BsEnvelopeAt /> <span className="nav--title">Invite</span>
                                    </Link>
                                </NavItem>
                            }

                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </>
    );
}

export default AdminSidebar;