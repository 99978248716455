import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingView from '../LoadingView';
import '../../App.css';
import API from '../../helpers/api';

function BookSuccess() {
    const navigate = useNavigate()
    const location = useLocation();
    const [loader, setLoader] = useState(true);
    // const [orderId, setOrderId] = useState('');
    const [ alert, setAlert ] = useState({ variant: 'danger', message: 'Invalid URL'})
    // const [ payment_mode, setPaymentMode ] = useState('');

    // const paymentMode = useSelector(state => state.paymentMode);


    useEffect(() => {
        if( location.state && location.state.order_id){
            // setOrderId(location.state.order_id)
            verifySuccessBookingOrder(location.state.order_id)
        }else{
            navigate('/');
        }
    }, []);

    const verifySuccessBookingOrder = async (order_id) => {
        try {
            setLoader(true)
            const result = await API.apiGet('orders', `/callbackBookingVerification/${order_id}`);
            
            if(result.data && result.data.success){
                setAlert({ variant: 'info', message: result.data.message})
            }else{
                setAlert({ variant: 'danger', message: result.data.message})
            }
            setLoader(false)
        } catch (error) {
            setAlert({ variant: 'danger', message: error.message || 'Oops something went wrong'})
            setLoader(false)
        }
    }

    // console.log( loader )

    return (    
        <div className='stripe-cancel-main-wraper'>
            <Container>
                <Row>
                    {loader
                    ?   <LoadingView />
                    :   <Col sm={12} className='mb-5'>
                            <h3>Booking Details</h3>
                            <Alert key="info" variant={alert.variant}>
                                {alert.message}
                            </Alert>
                            <Button variant="secondary" onClick={() => navigate("/appointments")}>My Appointments</Button>
                        </Col>
                    }
                </Row>
            </Container>
        </div>
    )
}

export default BookSuccess