import { get } from 'lodash';
import API from '../../helpers/api';
/**   import user types */
import {
    GET_USER_PROFILE_SUCCESS, 
    GET_USER_PROFILE_FAILED,
    PUT_USER_PROFILE_SUCCESS,
    PUT_USER_PROFILE_FAILED,
    GET_USER_META_SUCCESS,
    GET_USER_META_FAILED,
    DELETE_USER_FAILED,
    DELETE_USER_SUCCESS,
    UPDATE_PASSWORD_FAILED,
    UPDATE_PASSWORD_SUCCESS,
    GET_REFERRALS_LIST_SUCCESS,
    GET_REFERRALS_LIST_FAILED,
    POST_TIMEZONE_SUCCES,
    POST_TIMEZONE_FAILED
} from './types' ;

// const config = {
//     headers: {
//       Authorization: `${auth.getToken()}`
//     }
//   };

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} userDetila using for get user Details 
 * @returns 
 */
export const getUserProfile = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('user', `/details`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_USER_PROFILE_SUCCESS, payload: response.data.userDetails });
        } else {
            await dispatch({ type: GET_USER_PROFILE_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_USER_PROFILE_FAILED);
      }
    }
}

/**
 * 
 * @param {*} using for edit user profile  
 * @returns 
*/

export const updateProfile = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiUpdateUploadFile('user', `/edit/details`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: PUT_USER_PROFILE_SUCCESS, payload: response.data.userDetail });
            } else {
                await dispatch({ type: PUT_USER_PROFILE_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, PUT_USER_PROFILE_FAILED);
        }
    }
}

export const getMetaUser = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('usermeta');
            if (response.data && response.data.meta) {
                await dispatch({ type: GET_USER_META_SUCCESS, payload: response.data.meta});
            } else {
                await dispatch({ type: GET_USER_META_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_USER_META_FAILED);
        }
    }
  }



export const deleteUserAccount = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiDelete('user');
            if (response.data && response.data.meta) {
                await dispatch({ type: DELETE_USER_SUCCESS, payload: response.data});
            } else {
                await dispatch({ type: DELETE_USER_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, DELETE_USER_FAILED);
        }
    }
}

export const updateAccountPassword = (data) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPutUrl('user', `/update-password`, data);
            if (response.data && response.data.success) {
                await dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: response.data});
            } else {
                await dispatch({ type: UPDATE_PASSWORD_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, UPDATE_PASSWORD_FAILED)
        }
    }
}

/**------------------------- */

export const getAllReferrals = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('user', `/referrals`);
            if(response.data && response.data.success){
                await dispatch({ type: GET_REFERRALS_LIST_SUCCESS, payload: response.data});
            }else{
                await dispatch({ type: GET_REFERRALS_LIST_FAILED, payload: response.data});
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_REFERRALS_LIST_FAILED);
        }
    }
}


export const updateTimeZone = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('user', `/update-timezone`, payload);
            if(response.data && response.data.success){
                await dispatch({ type: POST_TIMEZONE_SUCCES, payload: response.data});
            }else{
                await dispatch({ type: POST_TIMEZONE_FAILED, payload: response.data});
            }
        } catch (error) {
            errorRequest(error, dispatch, POST_TIMEZONE_FAILED);
        }
    } 
}