import { get } from 'lodash';
import API from '../../helpers/api';
import { 
    GET_SUBSCRIPTION_PLANS_SUCCESS,
    GET_SUBSCRIPTION_PLANS_FAILED,
    PURCHASE_PLAN_SUCCESS,
    PURCHASE_PLAN_FAILED,
    UPGRADE_PLAN_SUCCESS,
    UPGRADE_PLAN_FAILED,
    CANCEL_PLAN_SUCCESS,
    CANCEL_PLAN_FAILED,
    CLEAR_SUBSCRIPTION_STATE, 
} from './types';

/**
 * @param {*} 
 * @returns 
*/


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

export const getSubscriptionPlans = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('subscription', `/plans`);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_SUBSCRIPTION_PLANS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_SUBSCRIPTION_PLANS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_SUBSCRIPTION_PLANS_FAILED);
        }
    }
}

export const purchasePlan = (payload) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('subscription', `/purchase`, payload);
            if( response.data && response.data.success){
                await dispatch({ type: PURCHASE_PLAN_SUCCESS, payload: response.data })
            }else{
                await dispatch({ type: PURCHASE_PLAN_FAILED, payload: response.data })
            }
        } catch (error) {
            errorRequest(error, dispatch, PURCHASE_PLAN_FAILED);
        }
    }
}

export const upgradePlan = (planId) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('subscription', `/update/${planId}`);
            if( response.data && response.data.success){
                await dispatch({ type: UPGRADE_PLAN_SUCCESS, payload: response.data })
            }else{
                await dispatch({ type: UPGRADE_PLAN_FAILED, payload: response.data })
            }
        } catch (error) {
            errorRequest(error, dispatch, UPGRADE_PLAN_FAILED);
        }
    }
}

export const cancelPlan = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('subscription', `/cancel`);
            if( response.data && response.data.success){
                await dispatch({ type: CANCEL_PLAN_SUCCESS, payload: response.data })
            }else{
                await dispatch({ type: CANCEL_PLAN_FAILED, payload: response.data })
            }
        } catch (error) {
            errorRequest(error, dispatch, CANCEL_PLAN_FAILED);
        }
    }
}

export const clearState = () => {
    return async (dispatch) => {
        await dispatch({ type: CLEAR_SUBSCRIPTION_STATE });
    }
}