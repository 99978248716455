import React from 'react';
import { Form, ListGroup } from 'react-bootstrap';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';

const CustomGoogleSignIn = ({ handleGoogleSignIn }) => {

    return (
        <Form.Group className="mb-3">
            <ListGroup className="mb-3">
                <ListGroup.Item
                    className="d-flex align-items-center justify-content-center py-2"
                    onClick={handleGoogleSignIn}
                    aria-label="Verify"
                    style={{ cursor: 'pointer' }}
                >
                    <FcGoogle size={24} className="mr-2" /> Continue with Google
                </ListGroup.Item>
            </ListGroup>
            <div className="text-center">
                <small className="text-muted">
                    * Google Signin is for expecting and new families only
                </small>
                <div className="mt-2">
                    <small>
                        Are you a Birth Professional? {' '}
                        <Link to="/professional-signup" style={{ color: '#3e829a' }}>
                            Create your account here
                        </Link>
                        {' '} or {' '}
                        <Link to="/login" style={{ color: '#3e829a' }}>
                            sign in
                        </Link>
                    </small>
                </div>
            </div>
        </Form.Group>
    )

}

export default CustomGoogleSignIn;