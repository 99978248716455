import React from 'react';
import {
    Box,
    Chip,
    Typography
} from '@mui/material';
import { CATEGORIES_FILTER } from './postsUtils';


const CategoryFilter = ({ selected, onSelect }) => {

 
    return (
        <Box>
            <Typography variant="subtitle2" gutterBottom>
                Categories
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {CATEGORIES_FILTER.map((category) => (
                    <Chip
                        key={category.value}
                        label={category.label}
                        onClick={() => onSelect(category.value)}
                        color={selected === category.value ? "primary" : "default"}
                        variant={selected === category.value ? "filled" : "outlined"}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default CategoryFilter; 