import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../../../helpers/api';
import { toast } from 'react-toastify';

const ViewBlog = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);

    // const mockBlogs = [
    //     {
    //         id: 1,
    //         title: 'Blog Title 1',
    //         slug: 'blog-title-1',
    //         metaDescription: 'A short description for Blog 1',
    //         tags: ['React', 'SEO'],
    //         author: 'John Doe',
    //         featuredImage: null, // Add an image file if needed
    //         content: "<p>This is the content of Blog 1</p>",
    //         publishDate: '2025-01-01',
    //     },
    //     {
    //         id: 2,
    //         title: 'Blog Title 2',
    //         slug: 'blog-title-2',
    //         metaDescription: 'A short description for Blog 2',
    //         tags: ['JavaScript', 'Web'],
    //         author: 'Jane Smith',
    //         featuredImage: null, // Add an image file if needed
    //         content: "<p>This is the content of Blog 2</p>",
    //         publishDate: '2025-01-02',
    //     },
    // ];


    useEffect(() => {
        fetchBlog();
    }, [id]);

    const fetchBlog = async () => {
        try {
            const response = await API.apiGetByKey('seller', `/blogs/${id}`);
            if (response.data && response.data.success) {
                setBlog(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching blog:', error);
            toast.error(error.response?.data?.message || 'Error fetching blog');
        } finally {
            setLoading(false);
        }
    };

    const handleBack = () => {
        navigate('/my-blogs');
    };

    if (loading) {
        return <Container>Loading...</Container>;
    }

    if (!blog) {
        return (
            <Container>
                <Button variant="secondary" onClick={handleBack} className="mb-3">
                    Back to All Blogs
                </Button>
                <h2>Blog not found</h2>
            </Container>
        );
    }

    const { title, slug, category, metaDescription, tags, author, featuredImage, content, publishDate } = blog;

    // Sample featured image for testing
    const sampleImage = "https://picsum.photos/800/400";

    return (
        <Container style={{ padding: '20px' }}>
            <Button
                variant="outline-secondary"
                onClick={handleBack}
                style={{
                    borderRadius: '20px',
                    padding: '8px 20px',
                    marginBottom: '2rem'
                }}
            >
                ← Back to All Blogs
            </Button>

            <article style={{ maxWidth: '800px', margin: '0 auto', position: 'relative' }}>
                {blog.status === 'draft' && (
                    <div style={{
                        background: '#6c757d',
                        color: 'white',
                        padding: '8px 16px',
                        borderRadius: '4px',
                        display: 'inline-block',
                        marginBottom: '1rem'
                    }}>
                        Draft - Not Published
                    </div>
                )}
                {/* For testing, use sampleImage if featuredImage is null */}
                {(featuredImage || sampleImage) && (
                    <div className="featured-image-container mb-4">
                        <Image
                            src={featuredImage || sampleImage}
                            alt={title}
                            style={{
                                width: '100%',
                                maxHeight: '400px',
                                objectFit: 'cover',
                                borderRadius: '12px',
                                boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
                            }}
                        />
                    </div>
                )}

                <h1 className="blog-title mb-4" style={{
                    fontSize: '2.5rem',
                    fontWeight: '700',
                    color: '#2c3e50'
                }}>
                    {title}
                </h1>

                <div className="blog-meta mb-4" style={{
                    padding: '15px',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    marginBottom: '2rem'
                }}>
                    <div className="">
                        <p className="mb-0">
                            <strong>Author:</strong>{' '}
                            <span style={{ color: '#666' }}>{author}</span>
                        </p>
                        <p className="mb-0">
                            <strong>Category:</strong>{' '}
                            <span style={{ color: '#666' }}>{category}</span>
                        </p>
                        <p className="mb-0">
                            <strong>Published:</strong>{' '}
                            <span style={{ color: '#666' }}>
                                {new Date(publishDate).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })}
                            </span>
                        </p>
                    </div>


                </div>

                <div className="meta-description mb-4" style={{
                    fontSize: '1.1rem',
                    fontStyle: 'italic',
                    color: '#666',
                    borderLeft: '4px solid #007bff',
                    paddingLeft: '1rem',
                }}>
                    {metaDescription}
                </div>

                <div
                    className="blog-content-body"
                    dangerouslySetInnerHTML={{ __html: content }}
                    style={{
                        backgroundColor: '#ffffff',
                        padding: '2rem',
                        borderRadius: '12px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                        lineHeight: '1.8',
                        fontSize: '1.1rem',
                        lineBreak: 'anywhere',
                    }}
                />

                {tags && tags.length > 0 && (
                    <div className="mt-3">
                        <strong>Tags:</strong>{' '}
                        {tags.map((tag, index) => (
                            <span
                                key={index}
                                style={{
                                    background: '#e9ecef',
                                    padding: '4px 12px',
                                    borderRadius: '15px',
                                    fontSize: '0.9rem',
                                    marginRight: '8px',
                                    color: '#495057',
                                    display: 'inline-block',
                                    marginBottom: '8px'
                                }}
                            >
                                #{tag}
                            </span>
                        ))}
                    </div>
                )}
            </article>
        </Container>
    );
};

export default ViewBlog;
