import React, { useState } from 'react';
import { ListGroup, Form } from 'react-bootstrap';

const ShowMoreList = ({ 
    items,                // List of items to display
    filter,               // The filter object
    filterKey,            // Key for the specific filter (e.g., 'race', 'service')
    handleFilter,         // Function to handle filter change
    initialVisibleCount = 5, // Number of items to show initially
    incrementCount = 5    // Number of items to show when "Show more" is clicked
}) => {
    const [visibleCount, setVisibleCount] = useState(initialVisibleCount);

    // Show more items
    const handleShowMore = () => {
        setVisibleCount(prevCount => prevCount + incrementCount);
    };

    return (
        <ListGroup className={`filter-list ${filterKey}--filter`}>
            {items.slice(0, visibleCount).map((item, index) => (
                <ListGroup.Item key={`${filterKey}-${index}`}>
                    <Form.Check
                        name={item.value || item.name}
                        type="checkbox"
                        id={`${filterKey}_${index}`}
                        label={item.label || item.name}
                        onChange={(e) => handleFilter(e.target.name, filterKey)}
                        checked={Array.isArray(filter?.[filterKey])
                            ? filter[filterKey].includes(item.value || item.name)
                            : filter?.[filterKey] === (item.value || item.name)}
                    />
                </ListGroup.Item>
            ))}

            {/* Show "+X more" label */}
            {visibleCount < items.length && (
                <ListGroup.Item onClick={handleShowMore} style={{ cursor: 'pointer', color: '#43a2b1' }}>
                    +{items.length - visibleCount} more
                </ListGroup.Item>
            )}
        </ListGroup>
    );
};

export default ShowMoreList;
