import {
    STRIPE_CONNECT_SUCCESS,
    STRIPE_CONNECT_FAILED,
    GET_STRIPE_ACCOUNT_SUCCESS,
    GET_STRIPE_ACCOUNT_FAILED,
} from "../actions/types";

const initialState = {
    connect:null,
    error: null,
    account: null,
};

const stripeReducer = (state = initialState, action) => {
    switch (action.type){ 
        case STRIPE_CONNECT_SUCCESS:
            return {
                connect: action.payload,
                error: null
            }
        
        case GET_STRIPE_ACCOUNT_SUCCESS:
            return {
                account:action.payload,
                error: null
            }

        case STRIPE_CONNECT_FAILED: case GET_STRIPE_ACCOUNT_FAILED:
            return {
                error: action.payload
            }

        default: return state;
    }
};

export default stripeReducer;