import React, { useState, useEffect, lazy, Suspense } from "react";
import { Container, Tabs, Tab, } from "react-bootstrap";

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { GoShare } from "react-icons/go";
import Tooltip from "../../commonComponents/Tooltip";
import { generateLetterSVG } from "../../utils/validations";
import { Alert, Snackbar } from '@mui/material';
import SEO from "../../seo";
import API from '../../helpers/api';
import { optimizedCloudinaryImage } from "../../utils/fileCompression";
import '../Home/style.css';
import LoadingView from "../LoadingView";
import { useSelector } from "react-redux";


const AboutTabContent = lazy(() => import("./About/AboutTab"));
const StaffTabContent = lazy(() => import("./Staff/StaffTab"));
const ServicesTabContent = lazy(() => import("./Service/ServicesTab"));
const SuggestionTab = lazy(() => import("./Suggestions"));
// const LoadingView = lazy(() => import('../LoadingView')); // Loading indicator
const EducationalContentTab = lazy(() => import("./educationalContent"));
const ViewEducationalContentTab = lazy(() => import("./educationalContent/ViewEducationalContent"));
const tabs = ['about', 'staff', 'services', 'suggestion', 'educationalContent'];

function SingleBusiness() {
    const navigate = useNavigate();
    const { id } = useParams();
    const businessId = id;

    const [searchParams] = useSearchParams();
    const queryTab = searchParams.get('tab')
    const blogId = searchParams.get('blogId');

    const [tab, setTab] = useState(tabs[0])
    const [loading, setLoading] = useState(true);

    const [copiedService, setCopiedService] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [businessAverageRating, setBusinessAverageRating] = useState(0);
    const [loadingStaff, setloadingStaff] = useState(true);
    const [loadingService, setloadingService] = useState(true);

    const [singleBusinessDetails, setSingleBusiness] = useState(null);
    const [singleBusinessstaff, setsingleBusinessstaff] = useState(null);
    const [singleBusinessService, setsingleBusinessService] = useState(null);
    const [userMetaData, setUserMetaData] = useState(null );

    const userMeta = useSelector(state => state.user);

    useEffect(() => {
        setLoading(true);
        getSingleBusiness();
    }, [businessId]);

    useEffect(() => {
        const validTab = tabs.includes(queryTab) ? queryTab : 'about';
        setTab(validTab);
    }, [queryTab]);

    useEffect(() => {
        if (!userMeta.error && userMeta.userMetaDetail) {
            setUserMetaData(userMeta.userMetaDetail);
        }
    }, [userMeta])

    const getSingleBusiness = async () => {
        try {
            const response = await API.apiGet('buisnesses', `/${businessId}`);
            if (response.data && response.data.success) {
                setSingleBusiness(response.data.business);
                setBusinessAverageRating(response.data.averageRating);
            }
        } catch (error) {
            console.error("Failed to fetch business details:", error);
        } finally {
            setLoading(false);
        }
    };

    const getStaff = async () => {
        try {
            const response = await API.apiGet('buisnesses', `/staff/${businessId}`);
            if (response.data && response.data.success) {
                setsingleBusinessstaff(response.data.staffs);
            }
        } catch (error) {
            console.error("Failed to fetch business staff details:", error);
        } finally {
            setloadingStaff(false);
        }
    }

    const getServices = async (type) => {
        try {

            let url = `/${businessId}`

            if (type === 'owner') {
                // First is taking as business  second is taking as service provider
                url = `/${businessId}/${businessId}`
            }

            let query = '/service' + url
            setloadingService(true)
            const response = await API.apiGet('buisnesses', query);
            if (response.data && response.data.success) {
                setsingleBusinessService(response.data.services);
            }
        } catch (error) {
            console.error("Failed to fetch business services", error);
        } finally {
            setloadingService(false)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    useEffect(() => {
        if (singleBusinessDetails) {
            if (singleBusinessDetails.is_seller_with_staff) {
                getStaff()
            }
        }
    }, [singleBusinessDetails])

    const handleSelectTab = (_tab) => {
        setTab(_tab);
        let searchParams = new URLSearchParams();
        searchParams.set('tab', _tab);
        
        if (_tab === 'educationalContent' && blogId) {
            searchParams.set('blogId', blogId);
        }
        
        navigate({
            pathname: `/business/${businessId}`,
            search: searchParams.toString()
        });
    }

    const handleStaffBook = (_staffId) => {
        let _tab = 'services'
        setTab(_tab);
        navigate({
            pathname: `/business/${businessId}`,
            search: `?tab=${_tab}&staff=${_staffId}`,
        });
    }

    const handleCopyLink = (link, name) => {
        navigator.clipboard.writeText(link);
        setCopiedService(name);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const renderMainContent = () => {
        return (
            <>
                <section className="page--banner">
                    {singleBusinessDetails &&
                        <>
                            <SEO
                                title={`${singleBusinessDetails.name} Profile`}
                                description={`Explore detailed profiles of certified ${singleBusinessDetails.name} on BirthBridge. Find the ${singleBusinessDetails?.seller_business_desc}`}
                                url={`/business/${singleBusinessDetails?._id}}`}
                                keywords={`${singleBusinessDetails.name}, BirthBridge, Bridge Directory, Doula Match, Hellomeela, Bornbir, birth, prenatal, postpartum, birth professionals, families, Pregnancy`}
                                canonical={`${window.location.origin}/business/${singleBusinessDetails._id}`}
                            />

                            <Snackbar
                                open={openSnackbar}
                                autoHideDuration={3000}
                                onClose={handleCloseSnackbar}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '500px', maxWidth: '100%' }}>
                                    You've copied the link to <strong>{copiedService}'s</strong> profile. Share it with others and/or display it on your website for easy access.
                                </Alert>
                            </Snackbar>
                            <Container>
                                {(singleBusinessDetails.associatedWithSeller && singleBusinessDetails.associatedWithSeller.length > 0) &&
                                    <Tooltip message={`Return to ${singleBusinessDetails.associatedWithSeller[0].name} profile`}>
                                        <div
                                            className="business--name"
                                            style={{
                                                float: 'left',
                                                position: 'absolute'
                                            }}
                                            onClick={() => navigate(`/business/${singleBusinessDetails.associatedWithSeller[0]._id}`)}
                                        >
                                            <IoMdArrowRoundBack color="white" size={26} />
                                            <h5 style={{ textTransform: 'capitalize' }}>{singleBusinessDetails.associatedWithSeller[0].name}</h5>
                                        </div>
                                    </Tooltip>
                                }
                                <img
                                    loading="lazy"
                                    src={singleBusinessDetails.profilePic ? singleBusinessDetails.profilePic.url : generateLetterSVG(singleBusinessDetails.name, 38)}
                                    srcSet={`${optimizedCloudinaryImage(singleBusinessDetails.profilePic.url, { width: 400 })} 400w, 
             ${optimizedCloudinaryImage(singleBusinessDetails.profilePic.url, { width: 800 })} 800w`}
                                    sizes="(max-width: 768px) 100vw, 50vw"
                                    alt={`${singleBusinessDetails.name} - Profile Photo`}
                                    width="400"
                                />

                                <h1 className="text-capitalize">{singleBusinessDetails.name} <GoShare size={30} onClick={() => handleCopyLink(`${window.location.host}/business/${singleBusinessDetails?._id}`, singleBusinessDetails?.name)} /></h1>
                                {(singleBusinessDetails?.services && singleBusinessDetails?.services.length) && (
                                    <h6 className="text-capitalise">
                                        {singleBusinessDetails?.services
                                            .map((services) => services?.category?.name)
                                            .filter((name) => name !== "Consultation") // Filter out "Consultation"
                                            .splice(0, 1)
                                            .join(', ')}
                                    </h6>
                                )}
                            </Container>
                        </>
                    }
                </section>
                <section className="popular--Wrapper">
                    <Container className="main-wraper-tabs">
                        {singleBusinessDetails ? (
                            <Tabs
                                defaultActiveKey="about"
                                id="tab--about"
                                mountOnEnter
                                onSelect={(v) => handleSelectTab(v)}
                                activeKey={tab}
                            >
                                <Tab eventKey="about" title="About">
                                    <Suspense fallback={<div className="hello" style={{flex:1}}><LoadingView /></div>}>
                                        <AboutTabContent
                                            singleBusinessDetails={singleBusinessDetails}
                                            loading={loading}
                                            businessAverageRating={businessAverageRating}
                                            userMeta={userMetaData}
                                        />
                                    </Suspense>
                                </Tab>

                                {singleBusinessDetails.is_seller_with_staff && (
                                    <Tab eventKey="staff" title="Members">
                                        <Suspense fallback={<div className="hello" style={{flex:1}}><LoadingView /></div>}>
                                            <StaffTabContent
                                                singleBusinessDetails={singleBusinessDetails}
                                                singleBusinessstaff={singleBusinessstaff}
                                                loadingStaff={loadingStaff}
                                                handleStaffBook={handleStaffBook}
                                                userMeta={userMetaData}
                                            />
                                        </Suspense>
                                    </Tab>
                                )}

                                <Tab eventKey="services" title="Specialties">
                                    <Suspense fallback={<div className="hello" style={{flex:1}}><LoadingView /></div>}>
                                        <ServicesTabContent
                                            singleBusinessstaff={singleBusinessstaff}
                                            singleBusinessService={singleBusinessService}
                                            singleBusinessDetails={singleBusinessDetails}
                                            loadingService={loadingService}
                                            getServices={(v) => getServices(v)}
                                        />
                                    </Suspense>
                                </Tab>

                                <Tab eventKey="suggestion" title="Recommended Professionals">
                                    <Suspense fallback={<div className="hello" style={{flex:1}}><LoadingView /></div>}>
                                        <SuggestionTab
                                            associatedWithUser={singleBusinessDetails._id}
                                            singleBusinessDetails={singleBusinessDetails}
                                            userMeta={userMetaData}
                                        />
                                    </Suspense>
                                </Tab>

                                <Tab eventKey="educationalContent" title="Content">
                                    <Suspense fallback={<div className="hello" style={{flex:1}}><LoadingView /></div>}>
                                        {blogId ? (
                                            <ViewEducationalContentTab blogId={blogId} />
                                        ) : (
                                            <EducationalContentTab />
                                        )}
                                    </Suspense>
                                </Tab>

                            </Tabs>
                        ) : (
                            <h5>No Record Available</h5>
                        )}
                    </Container>
                </section>
            </>
        );
    };

    return (
        loading ? (
            <div style={{flex:1}}><LoadingView /></div>
        ) : (
            <Suspense fallback={<div className="hello" style={{flex:1}}><LoadingView /></div>}>
                {renderMainContent()}
            </Suspense>
        )
    );
}

export default SingleBusiness;