export const CATEGORY_STYLES = {
    'General Discussion': {
        color: '#1976d2',
        backgroundColor: '#e3f2fd'
    },
    'Questions': {
        color: '#2e7d32',
        backgroundColor: '#e8f5e9'
    },
    'Discussion': {
        color: '#9c27b0',
        backgroundColor: '#f3e5f5'
    },
    'Announcement': {
        color: '#ed6c02',
        backgroundColor: '#fff3e0'
    },
    'Career and Training': {
        color: '#d32f2f',
        backgroundColor: '#ffebee'
    },
    'Business Tips and Tools': {
        color: '#0288d1',
        backgroundColor: '#e1f5fe'
    },
    'Networking and Collaboration': {
        color: '#5d4037',
        backgroundColor: '#efebe9'
    },
    'Education and Resources': {
        color: '#7b1fa2',
        backgroundColor: '#f3e5f5'
    },
    'Specialized Services': {
        color: '#c2185b',
        backgroundColor: '#fce4ec'
    },
    'Client Support': {
        color: '#00796b',
        backgroundColor: '#e0f2f1'
    },
    'Advocacy and Birth Rights': {
        color: '#f57c00',
        backgroundColor: '#fff3e0'
    },
    'Birth Stories and Experiences': {
        color: '#0097a7',
        backgroundColor: '#e0f7fa'
    },
    'Tools and Techniques': {
        color: '#388e3c',
        backgroundColor: '#e8f5e9'
    },
    'Regional Groups': {
        color: '#303f9f',
        backgroundColor: '#e8eaf6'
    },
    'FAQs for New Professionals': {
        color: '#455a64',
        backgroundColor: '#eceff1'
    },
    'Doula Tools and Tech': {
        color: '#6a1b9a',
        backgroundColor: '#f3e5f5'
    },
    'Community Engagement and Outreach': {
        color: '#283593',
        backgroundColor: '#e8eaf6'
    }
};

export const CATEGORIES = [
    { value: 'General Discussion', label: 'General Discussion' },
    { value: 'Questions', label: 'Questions' },
    { value: 'Discussion', label: 'Discussion' },
    { value: 'Announcement', label: 'Announcement' },
    { value: 'Career and Training', label: 'Career and Training' },
    { value: 'Business Tips and Tools', label: 'Business Tips and Tools' },
    { value: 'Networking and Collaboration', label: 'Networking and Collaboration' },
    { value: 'Education and Resources', label: 'Education and Resources' },
    { value: 'Specialized Services', label: 'Specialized Services' },
    { value: 'Client Support', label: 'Client Support' },
    { value: 'Advocacy and Birth Rights', label: 'Advocacy and Birth Rights' },
    { value: 'Birth Stories and Experiences', label: 'Birth Stories and Experiences' },
    { value: 'Tools and Techniques', label: 'Tools and Techniques' },
    { value: 'Regional Groups', label: 'Regional Groups' },
    { value: 'FAQs for New Professionals', label: 'FAQs for New Professionals' },
    { value: 'Doula Tools and Tech', label: 'Doula Tools and Tech' },
    { value: 'Community Engagement and Outreach', label: 'Community Engagement and Outreach' }
];
export const CATEGORIES_FILTER = [
    { value: 'all', label: 'All Posts' },
    ...CATEGORIES.sort((a, b) => a.label.localeCompare(b.label))
];