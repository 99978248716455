import { getCurrentView } from "../../helpers/auth";
import {
    GET_PLATFORM_META_SUCCESS,
    GET_PLATFORM_META_FAILED,
    SWITCH_APP_VIEW
} from "../actions/types";

const currentView = getCurrentView()
const initialState = {
    platform: null,
    error:null,
    appView:currentView
};


const metaReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_PLATFORM_META_SUCCESS:
            return {
                appView:currentView,
                platform: action.payload,
                error: null
            }
        case GET_PLATFORM_META_FAILED:
            return {
                appView:currentView,
                error: action.paylaod
            }
        case SWITCH_APP_VIEW:
            return {
                platform: state.platform,
                appView:getCurrentView(),
                error: state.error
            }
        default: return state;
    }
};

export default metaReducer;