import React, { useState, useEffect } from 'react'
import './ContactUs.css';
import { Card, Form } from 'react-bootstrap';
import {
    Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";

const contactUsHeader = require('../../assets/images/contact-us-header.webp');
const contactUsHeader1 = require('../../assets/images/contactusheader1.webp');


// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});


const Header = () => {

    return (

        <header className="header">
            <div className="contact-us-header-content">

                <div className="leftside-content">
                    <Card.Img
                        alt="birth professional contact us header image"
                        variant="top"
                        className="header-img"
                        src={contactUsHeader1} />
                </div>
                <div className="rightside-content">
                    <div className='rightside-wrapper'>
                        <h1 className="header-main-text">We’d Love to Hear from You!</h1>
                        
                        <p style={{ margin: '16px 0px' }} >Want to speak to us about an issue or request a new feature? Submit the form below or Email us at hello@mybirthbridge.com.</p>
                        
                    </div>
                </div>
            </div>
        </header>

    )

}

const ReachOut = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        type: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };



    const handleSubmitMsg = async () => {
        try {
            const response = await axios.post('https://your-api-endpoint.com/submit', formData);
            if (response.status === 200) {
                alert('Your message has been submitted successfully!');
            }
        } catch (error) {
            console.error('There was an error submitting your message!', error);
        }
    };
    return (
        <header className="why-container">

            <div className='section-header-center'>
                <h1 className="center-text">Fill out the info below to leave a comment!</h1>
            </div>

            <div className="contact-us-why-content-reverse bottom-spacing">
                <div className="leftside-content">
                    <Card.Img
                        alt="birth professional contact us body image"
                        variant="top"
                        className="header-img"
                        src={contactUsHeader} />
                </div>
                <div className="rightside-content">
                    <div className='rightside-wrapper-reverse'>
                        <Form>
                            <Form.Group controlId="formName">
                                <Form.Label>Name (optional)</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email (optional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formType">
                                <Form.Label>Type (required)</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="type"
                                    required
                                    value={formData.type}
                                    onChange={handleChange}
                                >
                                    <option value="">Select type</option>
                                    <option value="feature-suggestion">Feature suggestion</option>
                                    <option value="something-isnt-working">Something isn’t working</option>
                                    <option value="question">Question</option>
                                </Form.Control>
                            </Form.Group>

                            <Form.Group controlId="formMessage">
                                <Form.Label>Message (required)</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="message"
                                    required
                                    placeholder="Enter your message"
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Button style={{ backgroundColor: '#5b6e74', color: '#fff', padding: 6, width: 100, marginBottom: 16 }} onClick={handleSubmitMsg}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>


        </header>
    )
}


export default function ContactUs() {
    useEffect(() => {
        logEvent('Page View', 'Viewing Contact Us', 'Viewing Contact Us Page', 1);
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <SEO
                title="Contact Us - BirthBridge"
                description="Get in touch with BirthBridge for any questions or support. We're here to help you connect with top birth professionals and support your birth journey."
                url="/contact-birthbridge"
                keywords="contact us, BirthBridge, birth professionals, support, questions, connect, doulas, midwives, lactation consultants, prenatal care, postpartum care"
                author="BirthBridge"
            />
            <Header />
            <ReachOut />
        </ThemeProvider>
    )
}

