import React, { useEffect } from 'react'
import './BusinessListingPageComponent.css';
import { Card, Carousel } from 'react-bootstrap';
import {
    Button,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";
const babyimg = require('../../assets/images/top-header-baby.webp');

const streamLineOperationsimg = require('../../assets/images/scheduler.webp');
const vibrantCommunity = require('../../assets/images/vibrantCommunity.webp');
const encourageClientFeedback = require('../../assets/images/encourageClientFeedback.webp');
const defineServices = require('../../assets/images/defineServices.webp');
const organizeYourSchedule = require('../../assets/images/organizeYourSchedule.webp');
const busisnessProfile = require('../../assets/images/busisnessProfile.webp');
const amplifyVisability = require('../../assets/images/amplifyVisability.webp');
const amplifyVisability2 = require('../../assets/images/amplifyVisability2.webp');


// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});


const CarouselComponent = () => {

    const slides = [
        {
            img: defineServices,
            header: 'Define Your Services',
            description: 'Showcase certificates that you worked so hard to complete! BirthBridge connects you with expectant families looking for your specific qualifications.',
        },
        {
            img: busisnessProfile,
            header: 'Simplified Booking',
            description: 'Highlight your professional statement, patient reviews, and photos on BirthBridge to attract families. Our platform allows for easy viewing of your availability, enabling clients to book directly with you.',
        },
        {
            img: organizeYourSchedule,
            header: 'Organize Your Schedule',
            description: 'Access a streamlined calendar interface where you can manage your appointments efficiently. BirthBridge ensures that you and your clients are always on the same page with scheduling.',
        },
        {
            img: babyimg,
            header: 'Automated Appointment Reminders',
            description: 'BirthBridge takes care of the reminders so that patients are prepared for their visits. We help keep your office running smoothly by ensuring clients arrive informed and ready.',
        },
        {
            img: encourageClientFeedback,
            header: 'Encourage Client Feedback',
            description: 'Post-visit, we engage clients to share their experiences through reviews. BirthBridge values your reputation, helping you enhance your visibility and credibility to attract more clients.',
        },
    ];
    return (
        <div className='carousel-container-business'>
            <div className='carousel-wrapper'>
                <div className='section-header-center'>
                    <h1 className="center-text">How does BirthBridge Marketplace work?</h1>
                    <p style={{ margin: '16px 0px', textAlign: 'center' }}>
                        The BirthBridge Marketplace is a website where expectant families search for and book appointments with Birth Professionals.
                    </p>
                </div>

                <Carousel
                    nextIcon={<FaArrowAltCircleRight />}
                    prevIcon={<FaArrowAltCircleLeft />}
                    data-bs-theme="dark"
                    className="carousel-component">
                    {slides.map((slide, index) => (
                        <Carousel.Item key={`slide_${index}`}>
                            <div className='card-img-wrapper'>
                                <div className="text-img-wrapper">
                                    <img loading='lazy' className="carousel-img" src={slide.img} alt={`Slide ${index}`} />
                                    <div className='header-description-carousel-wrapper'>
                                        <h5 className="carousel-header">{slide.header}</h5>
                                        <p className="carousel-description">{slide.description}</p>
                                    </div>
                                </div>
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>

            </div >
        </div >
    )

}

const Header = () => {

    const navigate = useNavigate();


    return (

        <header className="header">
            <div className="birth-pro-listing-header-content">

                <div className="leftside-content">
                    <Card.Img
                        alt="birth professional business listing header image"
                        variant="top"
                        className="header-img"
                        style={{ width: '600px !important' }}
                        src={amplifyVisability}
                    />
                </div>
                <div className="rightside-content" >
                    <div className='rightside-wrapper'>
                        <h1 className="header-main-text">The easiest way to
                            <br /> <span className="header-span">
                                GROW YOUR PRACTICE
                            </span>
                        </h1>
                        <p style={{ margin: '16px 0px' }} >BirthBridge helps you bring in more new patients and keep them coming back - while saving your practice valuable time.</p>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                // alert('This will redirect to the new login page for businesses');
                                navigate('/bridger-signup');
                            }}
                        >
                            Get started
                        </Button>
                        <p style={{ margin: '16px 0px' }} className="contact-info">Want to speak to us or request a new feature? Email us at hello@mybirthbridge.com</p>
                    </div>
                </div>
            </div>
        </header>

    )

}
const Why = () => {
    return (

        <header className="why-container">
            <div className='section-header-center'>
                <h1 className="center-text">Why you’ll love BirthBridge</h1>
            </div>




            <div className="business-listing-content bottom-spacing">
                <div style={{ marginRight: 16 }}
                    className="leftside-content">
                    <Card.Img
                        alt={`Birth Professionals - Amplify Your Visibility`}
                        variant="top"
                        className="header-img"
                        src={amplifyVisability2} />
                </div>
                <div className="rightside-content">
                    <div className='rightside-wrapper'>
                        <h1 className="header-main-text">
                            Amplify Your Visibility
                        </h1>
                        <p style={{ margin: '16px 0px' }} > Enhance your professional presence by showcasing your unique skills, comprehensive qualifications, and valuable experience on BirthBridge. Our platform is tailored to highlight your offerings, allowing families seeking birth-related services to easily find and engage with your profile, facilitating a stronger online connection.</p>
                        {/* <Button
                            variant="outlined"
                            onClick={() => {
                                alert('This will redirect to the new login page for businesses')
                            }}
                        >
                            Get started
                        </Button>
                        <p style={{ margin: '16px 0px' }} className="contact-info">Want to speak to us or request a new feature? Email us at hello@mybirthbridge.com</p> */}
                    </div>
                </div>
            </div>




            <div className="business-listing-content-reverse bottom-spacing">
                <div className="leftside-content">
                    <Card.Img
                        alt={`Birth Professionals - Streamline Your Operations`}
                        variant="top"
                        className="header-img"
                        src={streamLineOperationsimg} />
                </div>
                <div className="rightside-content">
                    <div className='rightside-wrapper-reverse'>
                        <h1 className="header-main-text">
                            Streamline Your Operations
                        </h1>
                        <p style={{ margin: '16px 0px' }} >Streamline your business operations with our intuitive platform designed to manage your scheduling, bookings, and financial transactions with ease. BirthBridge's secure and user-friendly interface simplifies the administrative side of your services, freeing up more time for you to focus on providing exceptional care to families.
                        </p>
                        {/* <Button
                            variant="outlined"
                            onClick={() => {
                                alert('This will redirect to the new login page for businesses')
                            }}
                        >
                            Get started
                        </Button>
                        <p style={{ margin: '16px 0px' }} className="contact-info">Want to speak to us or request a new feature? Email us at hello@mybirthbridge.com</p> */}
                    </div>
                </div>
            </div>



            <div className="business-listing-content bottom-spacing">
                <div className="leftside-content">
                    {/* <Card.Img variant="top" className="header-img" src={classes} /> */}
                    <Card.Img
                        alt={`Birth Professionals - Grow Your Practice and Be a Part of BirthBridge's Vibrant Community!`}
                        variant="top"
                        className="header-img"
                        src={vibrantCommunity} />

                </div>
                <div className="rightside-content">
                    <div className='rightside-wrapper'>
                        <h1 className="header-main-text">

                            Grow Your Practice and Be a Part of BirthBridge's Vibrant Community!

                        </h1>
                        <p style={{ margin: '16px 0px' }} >Connect with BirthBridge and become part of an expanding network that empowers your practice to grow. Engage with more clients, foster meaningful relationships, and amplify the impact you have on expectant families' lives. With BirthBridge, watch your professional reach extend and your influence in the birth community flourish.

                            Immerse yourself in BirthBridge's supportive and vibrant community that values connection and mutual support. Here, you'll join forces with other birth professionals and play a pivotal role in providing empowering experiences for families embarking on their transformative journey into parenthood.</p>

                    </div>
                </div>


            </div>



        </header>

    )

}
export default function BusinessListingPageComponent() {

    useEffect(() => {
        logEvent('Page View', 'Viewing Business Listing Page', 'Viewing Birth Professional Business Listing Page', 1);
    }, [])

    return (
        <ThemeProvider theme={theme}>

            <SEO
                title="Business Listing"
                description="Explore and connect with certified birth professionals on BirthBridge. Discover services offered by doulas, midwives, lactation consultants, and more to support your birth journey."
                url="/birth-professional-listing"
                keywords="business listing, BirthBridge, certified birth professionals, doulas, midwives, lactation consultants, birth services, prenatal care, postpartum care, family support, pregnancy care"
            />
            <Header />
            <Why />
            {/* <ComparisonTable /> */}


            <div
                className={'dividerServices'}
                style={{
                    height: "400px",
                    width: "100%",
                }}
                alt="Birth professional holding hands - services"
            >
                <span />
            </div>



            <CarouselComponent />
        </ThemeProvider>
    )


}

