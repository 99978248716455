import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import { BsExclamationTriangle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import UpgradeSubscriptionBox from './content';

function UpgradeSubscriptionModal({ targetDivId }) {
    const navigate = useNavigate();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    // Add any additional logic if needed
  };

  const modalContent = (
    <Modal
        show={show}
        backdrop={'static'}
        centered
        size="lg"
    >
        <Modal.Body>
            {/* <div className="upgrade--subscription--plan text-center">
                <div className="mb-3">
                    <BsExclamationTriangle size={52} color="orange" className="mb-3"/>
                    <h4><strong>Upgrade Your Subscription Plan</strong></h4>
                </div>
                <p>
                    Your current plan staff limit reached the maximum, you need to upgrade your subscription plan.
                    Please visit subscriptions in sidebar to upgrade your plan.
                </p>
            </div> */}
            <UpgradeSubscriptionBox/>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-center'>
            <Button variant='danger' onClick={() => navigate('/staff')}>Cancel</Button>
            <Button onClick={() => navigate('/pay/subscription')}>Subscription Plans</Button>
        </Modal.Footer>
    </Modal>
  );

  // Create a portal to render the modal content into the specified target div
  return createPortal(modalContent, document.getElementById(targetDivId));
}

export default UpgradeSubscriptionModal;
