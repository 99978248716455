import { GET_REVIEW_SUCCESS, GET_REVIEW_FAILED } from "../actions/types";
const initialState = {
    error : null,
    reviews: null
}

const ratingReducer = ( state = initialState, action ) => {
    switch(action.type){
        case GET_REVIEW_SUCCESS:
            return {
                ...state,
                reviews: action.payload,
                error: null
            }
        case GET_REVIEW_FAILED:
            return {
                ...state,
                error: action.payload,
            }
        
        default: return state
    }
}

export default ratingReducer;