import React, { useState, useEffect } from "react";
import { AppBar, Tabs, Tab, Alert, Snackbar } from '@mui/material';
import {
  Typography,
  Box,
} from '@mui/material';

import { styled } from '@mui/system';
import { Form, Col, Button, Row, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import timeZones from '../../../utils/timeZones';
import { toast } from 'react-toastify';
import ReactSelect from 'react-select';
import Slider from 'rc-slider';
import API from '../../../helpers/api';
import Files from "./Files";
import FileViewModal from "../../../commonComponents/FileViewer";
import race from "../../../utils/race";
import ImageCropModal from "../../../commonComponents/CustomImageCropper";
import LoadingView from "../../LoadingView";
import { getSellerProfile } from "../../../redux/actions/seller.action";
import { isAuth } from "../../../helpers/auth";
import { emailValidation, formatPhoneNumber, validateNumber, isAddressBlank, notValid, validateDomain, validateUsername } from "../../../utils/validations";
import { getPlaces, getPlaceDetails } from "../../../redux/actions/places.action";
import { getAllBBServices } from "../../../redux/actions/services.action";
import { getMetaUser } from "../../../redux/actions/user.action";
import { getLanguages } from "../../../redux/actions/language.action";
import { uploadFilesToCloud } from "../../../redux/actions/cloudinary.action";
import { medicineApproachOptions } from "../../../utils/medicineApproach";
import { religionsOptions } from "../../../utils/religions";
import Swal from 'sweetalert2'
import { culturalExpertiseOptions } from "../../../utils/cultureExpertise";
// import '../../../App.css';
import StepForm from './steps/StepForm';

import 'rc-slider/assets/index.css';
import 'react-tagsinput/react-tagsinput.css';
import './style.css';
import '../../../styles/editStyle.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Helper function for accessibility props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledAppBar = styled(AppBar)({});

const StyledTabs = styled(Tabs)({
  overflowX: 'auto',
  backgroundColor: '#f5f5f5',
});


function SellerProfile() {
  // const api = useRef(null)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** ----- Copy Feature --- */
  const [copiedService, setCopiedService] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [loader, setloader] = useState(true);
  const [loading, setLoading] = useState({ basic: false });

  const [disableSocial, setDisableSocial] = useState(true);

  const [showAddressFields, setShowAddressFields] = useState(false)

  // const [timeZones, setTimeZones] = useState([]);
  const [showPlaces, setShowPlaces] = useState(false);

  const [locations, setLocations] = useState([]);
  const [searchLocation, setSearchLocation] = useState('');

  const [showFileViewer, setShowFileViewer] = useState(false);
  const [certificateToShow, setCertificateToShow] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [isSellerWithStaff, setSellerWithStaff] = useState(false);

  const [loggedUserBBServiceList, setLoggedUserBBServiceList] = useState([]);
  const [bbServiceList, setBBService] = useState([]);
  const [isLoggedUserService, setIsLoggedUserService] = useState(false);

  /** ------- Image Crop ------ */
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [profileImage, setProfileImage] = useState({ original: '', cropped: '' });

  const [uploadProgress, setUploadProgress] = useState({ profilePic: false, seller_business_photos: false, files: false });
  const [fileLoading, setFileLoading] = useState({ profilePic: false, seller_business_photos: false, files: false });

  const loggedUser = isAuth();

  const getSellerProfilestate = useSelector(state => state.seller.userDetail);
  const languageState = useSelector(state => state.language);
  // const timeZoneState = useSelector(state => state.slots)

  /** -------- Reducer State -------- */
  const bbServices = useSelector(state => state.services)

  /** ---- Places Reducer State ---- */
  const places = useSelector(state => state.places);

  const [SellerProfile, setSellerProfile] = useState({
    id: '',
    name: "",
    email: "",
    seller_business_desc: "",
    seller_business_photos: [],
    phoneNumber: '',
    showPhoneNumberToFamilies: false,
    website: '',
    hasInsurance: 'yes',
    takesHealthInsurance: 'no',
    preferredTimezone: 'Eastern Standard Time',
    cancellation_policy: 'At least 24 hour notice is required for cancellation.',
    seller_serve_distance: 50,
    language: [],
    files: [],
    chooseToMentorOfBirthProfessional: 'no',
    suggestMe: false,
    yearsOfExperience: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    seller_business_desc: '',
    language: [],
    address: {
      street: '',
      landmark: '',
      city: '',
      state: '',
      country: '',
      postalCode: ''
    },
    social_media: {},
    location: '',
    yearsOfExperience: '',
  });

  const sellerProfileData = useSelector(state => state.seller);

  useEffect(() => {
    dispatch(getLanguages());
    dispatch(getAllBBServices())

    dispatch(getSellerProfile())
  }, []);

  useEffect(() => {
    if (sellerProfileData && sellerProfileData.userDetail) {

      const userData = sellerProfileData.userDetail
      let data = {
        ...SellerProfile,
        id: userData?._id,
        name: userData?.name,
        email: userData?.email,
        seller_business_desc: userData?.seller_business_desc,
        profilePic: userData?.profilePic,
        yearsOfExperience: userData?.yearsOfExperience,
        timeZone: userData?.timeZone,
        files: userData?.files,
        is_profile_completed: userData?.is_profile_completed,
        address: userData?.address,
        language: userData?.language,
        phoneNumber: userData?.phoneNumber,
        showPhoneNumberToFamilies: userData?.showPhoneNumberToFamilies,
        seller_serve_distance: userData?.seller_serve_distance,
        race: userData?.race,
        is_stripe_fees_charge_by_customer: userData?.is_stripe_fees_charge_by_customer,
        hasInsurance: userData?.hasInsurance || 'yes',
        chooseToMentorOfBirthProfessional: userData?.chooseToMentorOfBirthProfessional || 'no',
        website: userData?.website,
        cancellation_policy: userData?.cancellation_policy,
        social_media: userData?.social_media,
        medicineApproach: userData?.medicineApproach,
        takesHealthInsurance: userData?.takesHealthInsurance,
        religious: userData?.religious,
        suggestMe: userData?.suggestMe,
        culturalExpertise: userData?.culturalExpertise,
        yearsOfExperience: userData?.yearsOfExperience,
      }

      if (userData.role === 'seller') {
        data.seller_personal_name = userData.seller_personal_name
        // data.seller_personal_email = userData.seller_personal_email       
        data.moneyFromStaff = userData.moneyFromStaff
      }

      if (userData.role === 'seller') {
        data.seller_business_photos = userData.seller_business_photos
      }

      setSellerWithStaff(userData.is_seller_with_staff)
      setSellerProfile(data);
    }
  }, [sellerProfileData])

  useEffect(() => {
    if (!isAddressBlank(SellerProfile.address)) {
      setShowAddressFields(true)
    } else {
      setShowAddressFields(false)
    }
  }, [SellerProfile.address])


  useEffect(() => {
    if (places.places && places.places.success && !places.error) {
      setLocations(places.places.suggestions)
    }

    if (places.placeDetail && places.placeDetail.success && !places.error) {
      setSellerProfile({ ...SellerProfile, address: places.placeDetail.details });
    }
  }, [places])

  useEffect(() => {
    if (!languageState.error) {
      if (languageState.get && languageState.get.success) {
        setLanguages(languageState.get.languages)
      }
    }
  }, [languageState]);


  useEffect(() => {
    if (bbServices && bbServices.BBService && !isLoggedUserService) {
      setBBService(bbServices.BBService)
    }
    if (bbServices && bbServices.BBService && isLoggedUserService) {
      setLoggedUserBBServiceList(bbServices.BBService)
    }
  }, [bbServices])

  /** ----- Copy Link ----- */
  const handleCopyLink = (link, name) => {
    navigator.clipboard.writeText(link);
    setCopiedService(name);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  /** --------------- Handle Input Field Change --------------- */

  // const handleChange = (e, parameter, nestedparam) => {
  //   let value = e.target.value;
  //   setShowPlaces(false);
  //   if ((parameter === 'phoneNumber' || parameter === 'seller_personal_phoneno') && value.trim() !== '') {
  //     value = formatPhoneNumber(value);
  //   }
  //   if (parameter === 'showPhoneNumberToFamilies') {
  //     value = e.target.checked; // Get the boolean value directly from the checkbox
  //   }

  //   if (parameter === 'address') {
  //     let address = SellerProfile.address
  //     address[nestedparam] = value
  //     setSellerProfile({ ...SellerProfile, [parameter]: address });
  //   } else if (parameter === 'moneyFromStaff') {
  //     let moneyFromStaff = SellerProfile.moneyFromStaff
  //     if (nestedparam === 'value') {
  //       value = validateNumber(value);
  //     }
  //     moneyFromStaff[nestedparam] = value
  //     setSellerProfile({ ...SellerProfile, [parameter]: moneyFromStaff });
  //   } else if (parameter === 'social_media') {
  //     let social_media = SellerProfile.social_media
  //     value = value.replace(/^https?:\/\/(www\.)?/, ''); // remove protocol and www
  //     social_media[nestedparam] = typeof value === 'string' ? value.toLowerCase() : value;
  //     setSellerProfile({ ...SellerProfile, [parameter]: social_media });
  //   } else if (parameter === 'website') {
  //     setSellerProfile({ ...SellerProfile, [parameter]: value });
  //   } else {
  //     setSellerProfile({ ...SellerProfile, [parameter]: value });
  //   }

  //   setErrors({ ...errors, [parameter]: '' });
  // };


  const handleChange = (e, parameter, nestedparam) => {
    let value = e.target.value;
    setShowPlaces(false);
  
    // Format phone numbers
    if ((parameter === 'phoneNumber' || parameter === 'seller_personal_phoneno') && value.trim() !== '') {
      value = formatPhoneNumber(value);
    }
  
    // Handle checkbox inputs
    if (parameter === 'showPhoneNumberToFamilies') {
      value = e.target.checked; // Get the boolean value directly from the checkbox
    }
  
    let updatedProfile = { ...SellerProfile }; // Create a copy of SellerProfile for updates
    let updatedErrors = { ...errors }; // Create a copy of errors for updates
  
    // Handle nested parameters
    if (parameter === 'address') {
      let address = updatedProfile.address || {};
      address[nestedparam] = value;
      updatedProfile[parameter] = address;
  
      // Clear errors for nested parameters in address
      if (updatedErrors[parameter] && updatedErrors[parameter][nestedparam]) {
        delete updatedErrors[parameter][nestedparam];
        if (Object.keys(updatedErrors[parameter]).length === 0) {
          delete updatedErrors[parameter]; // Remove the address error object if empty
        }
      }
    } else if (parameter === 'moneyFromStaff') {
      let moneyFromStaff = updatedProfile.moneyFromStaff || {};
      moneyFromStaff[nestedparam] = value;
      updatedProfile[parameter] = moneyFromStaff;
  
      // Clear errors for nested parameters in moneyFromStaff
      if (updatedErrors[parameter] && updatedErrors[parameter][nestedparam]) {
        delete updatedErrors[parameter][nestedparam];
        if (Object.keys(updatedErrors[parameter]).length === 0) {
          delete updatedErrors[parameter]; // Remove the moneyFromStaff error object if empty
        }
      }
    } else if (parameter === 'social_media') {
      let social_media = updatedProfile.social_media || {};
      value = value.replace(/^https?:\/\/(www\.)?/, ''); // Remove protocol and www
      social_media[nestedparam] = typeof value === 'string' ? value.toLowerCase() : value;
      updatedProfile[parameter] = social_media;
  
      // Clear errors for nested parameters in social_media
      if (updatedErrors[parameter] && updatedErrors[parameter][nestedparam]) {
        delete updatedErrors[parameter][nestedparam];
        if (Object.keys(updatedErrors[parameter]).length === 0) {
          delete updatedErrors[parameter]; // Remove the social_media error object if empty
        }
      }
    } else {
      // Handle all other cases
      updatedProfile[parameter] = value;
  
      // Clear errors for the parameter
      if (updatedErrors[parameter]) {
        delete updatedErrors[parameter];
      }
    }
  
    // Update states
    setSellerProfile(updatedProfile);
    setErrors(updatedErrors);
  };

  
  useEffect(() => {
    if (getSellerProfilestate) {
      setloader(false)
    }
  }, [getSellerProfilestate])


  function getProductErrors(data) {
    let productError = [];

    data.forEach((item, index) => {
      let errors = [];

      const product = item.product;

      if (!product || !Array.isArray(product) || product.length === 0) {
        errors.push(`Category is missing.`);
      } else {

        product.forEach((prodItem) => {
          const errorObj = {
            service: null,
            category: null
          };
          const { service, category } = prodItem;
          if (!service || service === '') {
            errorObj.service = `Category is missing.`;
          }
          if (!Array.isArray(category) || category.length === 0) {
            errorObj.category = `Specialty is missing.`;
          }

          errors.push(errorObj);
        });
      }

      if (errors.length > 0) {
        productError[index] = errors;
      }
    });

    return productError;
  }

  function validateYear(data) {
    let yearErrors = [];

    data.forEach((item, index) => {
      const { last_updated } = item;
      let errorObj = {
        year: null
      };

      if (!last_updated || last_updated.year === null) {
        errorObj.year = `Please choose the year you received your certificate.`;
      }

      yearErrors.push(errorObj);
    });

    return yearErrors;
  }

  function hasAnyValue(arr) {
    for (let i = 0; i < arr.length; i++) {
      const innerArr = arr[i];
      for (let j = 0; j < innerArr.length; j++) {
        const obj = innerArr[j];
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value !== null && value !== undefined && value !== '') {
              if (Array.isArray(value) && value.length !== 0) {
                return true; // Found a non-blank, non-null, non-empty value
              } else {
                return true; // Found a non-blank, non-null, non-empty value
              }
            }
          }
        }
      }
    }
    return false; // No non-blank, non-null, non-empty value found
  }


  function hasNonNullValue(data) {
    for (const item of data) {
      for (const key in item) {
        if (item.hasOwnProperty(key) && item[key] !== null) {
          return true; // Found a non-null value, return true
        }
      }
    }
    return false; // No non-null value found in any index
  }
  /** --------------------- Validations ---------------------- */

  const handleSectionValidation = (section) => {
    let fields = SellerProfile;
    let formIsValid = true;

    if (section === 'basic') {
      if (!fields.profilePic) {
        formIsValid = false
        errors.profilePic = "Profile Picture is required"
      }

      if (!fields.name || notValid(fields.name)) {
        formIsValid = false;
        errors.name = "Business name is required";
      }

      if (!fields.email || notValid(fields.email)) {
        formIsValid = false;
        errors.email = "Business email is required";
      } else if (!emailValidation(fields.email)) {
        formIsValid = false;
        errors.email = "Please enter a valid email address";
      }

      if (!fields.seller_business_desc || notValid(fields.seller_business_desc)) {
        formIsValid = false;
        errors.seller_business_desc = "Description is required";
      }

      if (isSellerWithStaff) {
        if (!fields.seller_personal_name || notValid(fields.seller_personal_name)) {
          formIsValid = false;
          errors.seller_personal_name = "Your name is required";
        }
      }
    }

    if (section === 'basic2') {
      if (!fields.address) {
        formIsValid = false;
        errors.location = "Address is required";
      }

      if (fields.address && typeof fields.address === 'object') {

        if (Object.values(fields.address).every(add => !add)) {
          formIsValid = false;
          errors.location = "Address is required";
        } else {

          // if (!fields.address.street) {
          //   formIsValid = false;
          //   errors.address.street = "Street is required";
          // }
          errors.address =  errors.address ? errors.address : {}
          if (!fields.address.city || notValid(fields.address.city)) {
            formIsValid = false;
            errors.address.city = "City is required";
          }
          if (!fields.address.state || notValid(fields.address.state)) {
            formIsValid = false;
            errors.address.state = "State is required";
          }
          if (!fields.address.country) {
            formIsValid = false;
            errors.address.country = "Country is required";
          }
          if (!fields.address.postalCode) {
            formIsValid = false;
            errors.address.postalCode = "Postal Code is required";
          }
        }
      }

    }

    if (section === "basic3") {
      // if (fields.hasInsurance === "no") {
      //   formIsValid = false
      //   errors.hasInsurance = "You Must Have Business Insurance"
      // }

      if (!fields.race || notValid(fields.race)) {
        formIsValid = false
        errors.race = "Please select race"
      }

      if (!fields.language || !Array.isArray(fields.language) || (fields.language && fields.language.length < 1)) {
        formIsValid = false
        errors.language = "Please select your spoken language"
      }

      if (!fields.timeZone) {
        formIsValid = false
        errors.timeZone = "Please Select Timezone"
      }

      if (!fields.cancellation_policy || notValid(fields.cancellation_policy)) {
        formIsValid = false
        errors.cancellation_policy = "Please describe cancellation policy"
      }
    }

    if (section === 'cert') {
      if (!fields.files.length) {
        formIsValid = false;
        errors.files = "Certificate is required";
      }

      if (fields.files.length) {
        errors.products = []
        errors.certificateTime = []
        const _errors = getProductErrors(fields.files);
        const _certificateTime = validateYear(fields.files);
        if (hasAnyValue(_errors)) {
          formIsValid = false
          errors.products = _errors
        }
        if (hasNonNullValue(_certificateTime)) {
          formIsValid = false
          errors.certificateTime = _certificateTime
        }
      }
    }



    if (section === 'social') {
      if (fields.social_media && typeof fields.social_media === 'object') {
        if (typeof errors.social_media === 'string') {
          errors.social_media = {};
        }

        if (fields.website === null && fields.social_media.facebook === null && fields.social_media.instagram === null) {
          formIsValid = false;
          errors.website = "Please enter website url";
          errors.social_media.facebook = "Please enter facebook url";
          errors.social_media.instagram = "Please enter instagram url";
        }

        if (fields.website && !validateDomain(fields.website)) {
          formIsValid = false
          errors.website = `Invalid domain name format. Please enter a valid domain name without protocol or "www".'`
        }

        const platforms = ['facebook', 'instagram'];
        for (const platform of platforms) {
          if (fields.social_media[platform]) {
            const username = fields.social_media[platform];
            if (username.includes('/') || username.includes(' ')) {
              formIsValid = false;
              errors.social_media[platform] = 'Please enter only your username, without any slashes or spaces.';
            } else if (!validateUsername(username)) {
              formIsValid = false;
              errors.social_media[platform] = "Invalid username format. Only letters, numbers, dots, underscores, and hyphens are allowed.";
            }
          }
        }

      }
    }

    setErrors({ ...errors });
    return formIsValid;
  }

  useEffect(() => {
    setDisableSocial(false);
    if (SellerProfile.website === null && (!SellerProfile.social_media || (SellerProfile.social_media && typeof SellerProfile.social_media !== 'object') || SellerProfile.social_media.facebook === null && SellerProfile.social_media.instagram === null)) {
      setDisableSocial(true);
    }

  }, [SellerProfile]);


  const checkProfileCompleted = () => {
    let fields = SellerProfile;
    let formIsValid = true;

    if (!fields.profilePic) {
      formIsValid = false
    }

    if (!fields.name || notValid(fields.name)) {
      formIsValid = false;
    }

    if (!fields.email || notValid(fields.email)) {
      formIsValid = false;
    } else if (!emailValidation(fields.email)) {
      formIsValid = false;
    }

    if (!fields.seller_business_desc || notValid(fields.seller_business_desc)) {
      formIsValid = false;
    }

    if (isSellerWithStaff) {
      if (!fields.seller_personal_name || notValid(fields.seller_personal_name)) {
        formIsValid = false;
      }
    }

    if (!fields.address) {
      formIsValid = false;
    }

    if (fields.address && typeof fields.address === 'object') {
      if (Object.values(fields.address).every(add => !add)) {

        formIsValid = false;
      } else {

        if (!fields.address.city || notValid(fields.address.city)) {
          formIsValid = false;
          // errors.address.city = "City is required";
        }
        if (!fields.address.state || notValid(fields.address.state)) {
          formIsValid = false;
          // errors.address.state = "State is required";
        }
        if (!fields.address.country) {
          formIsValid = false;
          // errors.address.country = "Country is required";
        }
        if (!fields.address.postalCode) {
          formIsValid = false;
          // errors.address.postalCode = "Postal Code is required";
        }
      }
    }

    // if (fields.hasInsurance === 'no') {
    //   formIsValid = false;
    // }

    if (!fields.race || notValid(fields.race)) {
      formIsValid = false
    }

    if (!fields.language || !Array.isArray(fields.language) || (fields.language && fields.language.length < 1)) {
      formIsValid = false
    }

    if (!fields.timeZone) {
      formIsValid = false
    }

    if (!fields.cancellation_policy || notValid(fields.cancellation_policy)) {
      formIsValid = false
    }

    if (!fields.files.length) {
      formIsValid = false;
    }

    if (fields.files.length) {
      const _errors = getProductErrors(fields.files);
      const _certificateTime = validateYear(fields.files);
      if (hasAnyValue(_errors)) {
        formIsValid = false
      }
      if (hasNonNullValue(_certificateTime)) {
        formIsValid = false
      }
    }

    // if (fields.social_media && typeof fields.social_media === 'object') {
    //   if (fields.social_media.facebook) {
    //     if (fields.social_media.facebook.includes('/') || fields.social_media.facebook.includes(' ')){
    //       formIsValid = false;
    //     }

    //     if(!usernamePattern.test(fields.social_media.facebook)){
    //       formIsValid = false;
    //     }
    //   }

    //   if (fields.social_media.instagram) {
    //     if (fields.social_media.instagram.includes('/') || fields.social_media.instagram.includes(' ')){
    //       formIsValid = false;
    //     }

    //     if(!usernamePattern.test(fields.social_media.instagram)){
    //       formIsValid = false;
    //     }
    //   }
    // }

    // if(fields.website && !validateDomain(fields.website)){
    //   formIsValid = false
    // }

    // if (fields.social_media && typeof fields.social_media === 'object') {
    //   const platforms = ['facebook', 'instagram'];
    //   for (const platform of platforms) {
    //     if (fields.social_media[platform]) {
    //       const username = fields.social_media[platform];
    //       if (username.includes('/') || username.includes(' ')) {
    //         formIsValid = false;
    //       } else if (!validateUsername(username)) {
    //         formIsValid = false;
    //       }
    //     }
    //   }
    // }

    return formIsValid;
  }

  const renderToast = (message, variant) => {
    toast[variant](message, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })
  }

  const onSubmit = async (e, section) => {
    e.preventDefault();

    if (handleSectionValidation(section)) {
      setLoading({ ...loading, [section]: true })
      try {

        let data = {}

        let callMeta = false
        if (section === 'basic') {
          data.profilePic = SellerProfile.profilePic
          data.name = SellerProfile.name
          data.phoneNumber = SellerProfile.phoneNumber
          data.showPhoneNumberToFamilies = SellerProfile.showPhoneNumberToFamilies || false
          data.seller_business_desc = SellerProfile.seller_business_desc
          data.seller_personal_name = SellerProfile.seller_personal_name
          // if (isSellerWithStaff) {
          // }
            // data.seller_personal_email = SellerProfile.seller_personal_email
        }

        if (section === 'businessPhotos' && loggedUser && loggedUser.role === 'seller') {
          data.seller_business_photos = SellerProfile.seller_business_photos
        }

        if (section === 'basic2') {
          data.address = SellerProfile.address
          data.seller_serve_distance = SellerProfile.seller_serve_distance
        }

        if (section === 'cert') {
          data.files = SellerProfile.files
        }

        if (section === 'basic3') {
          data.language = SellerProfile.language
          data.race = SellerProfile.race
          data.hasInsurance = SellerProfile.hasInsurance || 'no'
          data.religious = SellerProfile.religious
          data.medicineApproach = SellerProfile.medicineApproach || 'no'
          data.culturalExpertise = SellerProfile.culturalExpertise
          data.yearsOfExperience = SellerProfile.yearsOfExperience
          data.chooseToMentorOfBirthProfessional = SellerProfile.chooseToMentorOfBirthProfessional || 'no'
          data.is_stripe_fees_charge_by_customer = SellerProfile.is_stripe_fees_charge_by_customer
          if (loggedUser && loggedUser.role === 'seller' && isSellerWithStaff) {
            data.moneyFromStaff = SellerProfile.moneyFromStaff
          }
          data.timeZone = SellerProfile.timeZone
          data.takesHealthInsurance = SellerProfile.takesHealthInsurance
          data.cancellation_policy = SellerProfile.cancellation_policy
          data.suggestMe = SellerProfile.suggestMe
        }

        if (section === 'social') {

          data.website = SellerProfile.website === '' ? null : SellerProfile.website
          data.social_media = SellerProfile.social_media
          if (SellerProfile.social_media && SellerProfile.social_media.instagram === '') {
            data.social_media.instagram = null
          }

          if (SellerProfile.social_media && SellerProfile.social_media.facebook === '') {
            data.social_media.facebook = null
          }
        }

        if (checkProfileCompleted() && !SellerProfile.is_profile_completed) {
          data.is_profile_completed = true
          callMeta = true
        }

        const response = await API.apiPostUrl('seller', `/edit/details?section=${section}`, data);
        if (response.data && response.data.success) {
          if (!SellerProfile.is_profile_completed && section === 'basic' && !data.is_profile_completed) {
            Swal.fire({
              title: 'Great Job! '.concat(response.data.message),
              text: 'Now, let’s finish setting up your profile. Please complete the remaining profile sections before being able to add your specialties!',
              icon: 'success',
              confirmButtonText: 'Ok',
              customClass: {
                title: 'custom-swal-title',
                htmlContainer: 'custom-swal-text',
                confirmButton: 'btn btn-primary',
              },
            })
          } else if (data.is_profile_completed) {
            Swal.fire({
              title: 'Profile Completed!',
              html: '<p>Now you can create your specialties or set up your wallet to start receiving payments.</p> <p><b>How would you like to proceed?</b></p>',
              icon: 'success',
              confirmButtonText: 'Setup Wallet',
              showCancelButton: true,
              cancelButtonText: 'Stay Here',
              showDenyButton: true,
              denyButtonText: `Setup Specialties`,

              customClass: {
                title: 'custom-swal-title',
                htmlContainer: 'custom-swal-text',
                confirmButton: 'btn btn-primary',
              },
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/wallet');
              } else if (result.isDenied) {
                navigate('/service');
              }
            });
          } else {
            renderToast(response.data.message, 'success');
          }
          dispatch(getSellerProfile())
          /** ------- Call Updated Meta Records of Logged in User once profile completed to access other pages routes -------- */
          if (callMeta) {
            dispatch(getMetaUser());
          }

          setLoading({ ...loading, [section]: false });
        }
      } catch (error) {
        // console.log(error);
        let msg = error?.response?.data?.message || 'Oops something goes wrong'
        renderToast(msg, 'error')
        setLoading({ ...loading, [section]: false });
      }
    }
  }


  const handleFileChange = async (event, field) => {
    setErrors({ ...errors, [field]: '' });

    const files = event.target.files;
    const formData = new FormData();
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (field === 'files' && !['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'].includes(file.type)) {
        setErrors({ ...errors, [field]: file.name + ' file type is not supported' });
        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      if (
        field === 'seller_business_photos' && 
        !['image/jpeg', 'image/png', 'image/jpg', 'video/mp4', 'video/avi', 'video/mov'].includes(file.type)
      ) {
        setErrors({ ...errors, [field]: file.name + ' file type is not supported' });
        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      if ((field === 'profilePic') && !['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        setErrors({ ...errors, [field]: file.name + ' file type is not supported' });
        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      if (file.size > (1024 * 1024) * 10) {
        setErrors({ ...errors, [field]: file.name + ' size should be less than 10mb' });
        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      formData.append('files', file);
    }

    if (!hasError && formData.has('files')) { // Proceed with upload only if there are no errors
      const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading, field);
      if (response && response.data && response.data.success) {
        if (field === 'profilePic') {
          setSellerProfile((prevProfile) => ({
            ...prevProfile,
            [field]: response.data.files[0]
          }));
        } else {
          setSellerProfile((prevProfile) => ({
            ...prevProfile,
            [field]: prevProfile[field]
              ? [...prevProfile[field], ...response.data.files] : response.data.files,
          }));
        }
      }
    }
  };

  const handleProfilePicChange = async (event, field) => {
    setErrors({ ...errors, profilePic: '' });
    const file = event.target.files[0];
    let hasError = false;

    if (!file) return;

    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      setErrors({ ...errors, profilePic: file.name + ' file type is not supported' });
      hasError = true; // Set hasError to true if there's an error
      return; // Exit the loop early if there's an error
    }

    if (file.size > (1024 * 1024) * 10) {
      setErrors({ ...errors, profilePic: file.name + ' size should be less than 10mb' });
      hasError = true; // Set hasError to true if there's an error
      return; // Exit the loop early if there's an error
    }

    if (!hasError) { // Proceed with image cropper if there are no errors
      setShowImageCrop(true)
      setProfileImage({ ...profileImage, original: file });
    }
  }

  const handleRemoveFile = (index, field) => {
    const updatedPhotos = [...SellerProfile[field]];
    updatedPhotos.splice(index, 1);
    setSellerProfile((prevProfile) => ({
      ...prevProfile,
      [field]: updatedPhotos,
    }));
  };


  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchLocation) {
        setShowPlaces(true)

        dispatch(getPlaces(searchLocation));
      } else {
        setShowPlaces(false)
      }
    }, 500);

    return () => clearTimeout(timer);

  }, [searchLocation]);

  const handleSelectPlace = (place) => {
    setShowPlaces(false)
    setSearchLocation('');
    setErrors({ ...errors, location: '' })
    dispatch(getPlaceDetails(place.place_id))
  }
  /** ------------------ Get Places on Search -------------- */

  const renderError = (field) => {
    if (errors && errors[field]) {
      return (<span className="error-msg">{errors[field]}</span>)
    }
    return null
  }

  const handleShowFileView = (file) => {
    setCertificateToShow(showFileViewer ? null : file);
    setShowFileViewer(!showFileViewer);
  }

  const handleFileInputChange = (event, index, field) => {
    const { name, value } = event.target;
    let files = SellerProfile.files
    let file = files[index];
    if (field === 'last_updated') {
      if (file[field]) {
        file[field][name] = value
      } else {
        file.last_updated = { month: null, year: null }
        file.last_updated[name] = value
      }
    } else {
      file[name] = value
    }

    files[index] = file
    setSellerProfile({ ...SellerProfile, files: files });
  }


  const handleProductError = (fileIndex, productIndex, field) => {
    let copyErrors = { ...errors };
    if (copyErrors.product) {
      let error = copyErrors.product[fileIndex]
      if (field) {
        error[productIndex][field] = ''
      } else {
        error[productIndex] = ['']
      }
      copyErrors.product[fileIndex] = error
      setErrors({ ...copyErrors })
    }
  }

  const handleCloseImageCropper = () => {
    setShowImageCrop(false);
  }

  const handleSaveCropImage = async (croppedImage, imageName) => {
    setShowImageCrop(false);
    const formData = new FormData();
    formData.append('files', croppedImage, imageName);
    const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading, 'profilePic');
    if (response && response.data && response.data.success) {
      setSellerProfile({
        ...SellerProfile,
        profilePic: response.data.files[0],
      });
    }
  }

  const handleMedicineApproach = (tags) => {
    if (tags && medicineApproachOptions && medicineApproachOptions.length) {
      const sp = medicineApproachOptions.filter(suggestion => tags.includes(suggestion.value)).map(v => { return { label: v.label, value: v.value } });
      return sp
    }
    return tags
  }

  const handleCulturalExpertise = (tags) => {
    if (tags && culturalExpertiseOptions && culturalExpertiseOptions.length) {
      const sp = culturalExpertiseOptions.filter(suggestion => tags.includes(suggestion.value)).map(v => { return { label: v.label, value: v.value } });
      return sp
    }
    return tags
  }

  const handleReligious = (tags) => {
    if (tags && religionsOptions && religionsOptions.length) {
      const sp = religionsOptions.filter(suggestion => tags.includes(suggestion.value)).map(v => { return { label: v.label, value: v.value } });
      return sp
    }

    return tags
  }

  const handleLanguage = (values, field) => {
    setErrors({ ...errors, [field]: '' });
    setSellerProfile({ ...SellerProfile, language: values });
  }


  const [value, setValue] = useState(0);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const showProfileCompleteAlert = () => {
    if (showWelcomeModal && !SellerProfile.is_profile_completed) {
      setShowWelcomeModal(false)
      Swal.fire({
        title: 'Welcome to BirthBridge! ',
        text: `It's time to create your profile! Please start by filling out your business information. This is where you'll begin configuring your business settings and preferences and what you choose to display to the families!`,
        icon: 'info',
        confirmButtonText: 'Yes, lets do it!',
        showCancelButton: false,
        // cancelButtonText: 'Later',
        customClass: {
          title: 'custom-swal-title',
          htmlContainer: 'custom-swal-text',
          confirmButton: 'btn btn-primary',
        },
      });
    }
    return (
      <div className="col-sm-12 text-center">
        {!SellerProfile.is_profile_completed && <Alert variant="warning">Please complete all profile tabs and fill in the required fields marked with a red star (<span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>) to proceed.</Alert>}
      </div>
    )
  }

  const handleIsProfileVideo = (e, index) => {
    const { checked } = e.target;
    let files = SellerProfile.seller_business_photos
    files[index].isProfileVideo = checked;

    // files[index] = file
    setSellerProfile({ ...SellerProfile, seller_business_photos:files  });
  }

  return (
    <>

      {loader
        ? <LoadingView loader="ThreeDots" />
        :
        <>
          {!SellerProfile.is_profile_completed
            ? <div>
              <StepForm
                dispatch={dispatch}

                sellerProfile={SellerProfile}
                handleProfilePicChange={handleProfilePicChange}
                uploadProgress={uploadProgress}
                fileLoading={fileLoading}
                languages={languages}
                isSellerWithStaff={isSellerWithStaff}
                /** ---------  */
                handleProductError={handleProductError}
                handleFileInputChange={handleFileInputChange}
                bbServiceList={bbServiceList}
                loggedUserBBServiceList={loggedUserBBServiceList}
                setSellerProfile={setSellerProfile}
                profileErrors={errors}
                handleFileChange={handleFileChange}
                handleShowFileView={handleShowFileView}
                handleRemoveFile={handleRemoveFile}
                loggedUser={loggedUser}
                getAllBBServices={getAllBBServices}
                setIsLoggedUserService={setIsLoggedUserService}

                /** ----- Certificate Error Handler ------ */
                // getProductErrors={getProductErrors}
                // validateYear={validateYear}
                // hasAnyValue={hasAnyValue}
                // hasNonNullValue={hasNonNullValue}
                /** ----- Fetch Latest Data of Logged User -----  */
                getMetaUser={getMetaUser}
              />
            </div>
            :
            <Form className="p-4 bg-white">
              <div>
                {/* <TabsIndex /> */}
                <div className="seller_profile">
                  <StyledAppBar position="static">
                    <StyledTabs
                      value={value}
                      onChange={handleChangeTabs}
                      aria-label="profile tabs"
                      variant="scrollable"
                      scrollButtons="auto"
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: '#73a5b5', // Change underline color
                        },
                      }}
                    >
                      <Tab
                        label="Information"
                        {...a11yProps(0)}
                        sx={{
                          '&.Mui-selected': { color: '#73a5b5', }
                        }}
                      />
                      <Tab
                        label="Media"
                        {...a11yProps(1)}
                        sx={{
                          '&.Mui-selected': { color: '#73a5b5', }
                        }}
                      />
                      <Tab
                        label="Location"
                        {...a11yProps(2)}
                        sx={{
                          '&.Mui-selected': { color: '#73a5b5', }
                        }}
                      />
                      <Tab
                        label="Certificates"
                        {...a11yProps(3)}
                        sx={{
                          '&.Mui-selected': { color: '#73a5b5', }
                        }}
                      />
                      <Tab
                        label="Preferences"
                        {...a11yProps(4)}
                        sx={{
                          '&.Mui-selected': { color: '#73a5b5', }
                        }}
                      />
                      <Tab
                        label="Social Media"
                        {...a11yProps(5)}
                        sx={{
                          '&.Mui-selected': { color: '#73a5b5', }
                        }}
                      />
                    </StyledTabs>
                  </StyledAppBar>
                  <TabPanel value={value} index={0}>
                    {showProfileCompleteAlert()}
                    <Row className="bg-color section">
                      <Form.Group className="col-sm-12 text-left">
                        <Form.Label>
                          {(loggedUser && loggedUser.role === 'seller') ? 'Profile Picture' : 'Profile Picture'}
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <div className={`image-upload-p`}>
                          <label htmlFor="profile__upload" className="text-center">
                            {fileLoading.profilePic
                              ? <LoadingView size={48} />
                              : <img className="profile_picture" loading="lazy" src={(SellerProfile.profilePic && SellerProfile.profilePic.url) ? SellerProfile?.profilePic.url : "/images/profile.png"} />
                            }
                          </label>
                          <Form.Control
                            type="file"
                            id="profile__upload"
                            title="upload profile"
                            hidden
                            style={{ height: '40px', overflow: 'visible', width: '100%' }}
                            onChange={(e) => fileLoading.profilePic ? null : handleProfilePicChange(e, 'profilePic')}
                          />
                          {renderError('profilePic')}
                        </div>
                        {uploadProgress.profilePic > 0 && <p className="upload-progress-status">Uploaded {uploadProgress.profilePic}%</p>}
                      </Form.Group>

                      <Form.Group className="col-sm-12 col-md-12 col-lg-4">
                        <Form.Label>Your Business Name
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <Form.Control type="text"
                          value={SellerProfile?.name || ""}
                          onChange={(e) => handleChange(e, 'name')}
                          placeholder="Business Name"
                        />
                        {renderError('name')}
                      </Form.Group>

                      <Form.Group className="col-sm-12 col-md-12 col-lg-4">
                        <Form.Label>
                          Contact Email
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={SellerProfile?.email || ""}
                          // onChange={(e) => handleChange(e, 'email')} 
                          placeholder="Contact Email"
                          disabled
                        />
                        {renderError('email')}
                      </Form.Group>

                      <Form.Group className="col-sm-12 col-md-12 col-lg-4">
                        <Form.Label>Contact Phone Number
                        </Form.Label>
                        <Form.Control type="text" value={SellerProfile?.phoneNumber || ""} onChange={(e) => handleChange(e, 'phoneNumber')} placeholder="Phone" />
                        {renderError('phoneNumber')}
                      </Form.Group>

                      {/* Add Checkbox */}
                      <Form.Group className="col-sm-12 col-md-12 col-lg-4">
                        <Form.Check
                          type="checkbox"
                          label="Show phone number to families"
                          checked={SellerProfile?.showPhoneNumberToFamilies || false}
                          onChange={(e) => handleChange(e, 'showPhoneNumberToFamilies')}
                          className="mt-2"
                        />
                      </Form.Group>


                      {isSellerWithStaff &&
                        <>
                          <Form.Group className="col-sm-6 col-lg-4">
                            <Form.Label>
                              Your Name
                              <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                            </Form.Label>
                            <Form.Control type="text"
                              value={SellerProfile?.seller_personal_name || ""}
                              onChange={(e) => handleChange(e, 'seller_personal_name')}
                              placeholder="Your Name"
                            />
                            {renderError('seller_personal_name')}
                          </Form.Group>
                          {/* 
                                      <Form.Group className="col-sm-6 col-lg-4">
                                        <Form.Label>Your Email</Form.Label>
                                        <Form.Control type="text" value={SellerProfile?.seller_personal_email || ""} onChange={(e) => handleChange(e, 'seller_personal_email')} placeholder="Your Email" />
                                        {renderError('seller_personal_email')}
                                      </Form.Group> 
                                  */}
                        </>
                      }


                      <Form.Group className="col-sm-12">
                        <Form.Label>About Your Business<span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span></Form.Label>

                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={SellerProfile?.seller_business_desc || ""}
                          onChange={(e) => handleChange(e, 'seller_business_desc')}
                          placeholder="Tell the families who you are, what you do, and why you do it"
                        />
                        {renderError('seller_business_desc')}
                      </Form.Group>

                      <Col sm={12} className="text-left mt-2">
                        <Button
                          variant="primary"
                          disabled={loading['basic']}
                          onClick={(e) => onSubmit(e, 'basic')}
                        >
                          {loading['basic'] ? 'Updating...' : 'Save Changes'}
                        </Button>
                      </Col>
                    </Row>

                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {/* <h3 className="text-left mb-3">Media</h3> */}
                    {(loggedUser && loggedUser.role === 'seller') &&
                      <Row className="bg-color section">
                        <Form.Group className="col-sm-12">
                          <Form.Label>Add Media (Optional)</Form.Label>
                          <div className="photo--grid">
                            {SellerProfile.seller_business_photos
                              ?
                              <ul className="mb-3">
                                {
                                  SellerProfile.seller_business_photos.map((file, fileIndex) => {
                                    return (
                                      <li className="image-upload-p" key={fileIndex}>
                                        {['mp4', 'avi', 'mov'].includes(file.file_type)

                                        ? <div >
                                            <video src={file.url} controls autoPlay={false} style={{ width: 250, height:180, margin: '10px'}}/>
                                            <Form.Check 
                                              checked={file.isProfileVideo} 
                                              label='Do you want to make this your profile video?'
                                              style={{ display:'flex', alignItems:'center'}}
                                              onChange={(e) => handleIsProfileVideo(e, fileIndex)}
                                            />
                                          </div>
                                        : <img
                                            src={file.url}
                                            alt="Our Business photos"
                                            style={{ margin:'10px', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open(file.url, '_target')}
                                          />
                                        }

                                        <span onClick={() => handleRemoveFile(fileIndex, 'seller_business_photos')} className="delete--img"><BsTrash /></span>
                                      </li>
                                    )
                                  })
                                }
                              </ul>
                              : null
                            }
                            <input
                              type="file"
                              id="upload--file1"
                              hidden
                              onChange={(e) => fileLoading.seller_business_photos ? null : handleFileChange(e, 'seller_business_photos')}
                              disabled={fileLoading.seller_business_photos}
                              multiple
                              accept={['image/jpeg', 'image/png', 'image/jpg', 'video/mp4', 'video/avi', 'video/mov']}
                            />
                            <label htmlFor="upload--file1" style={{ marginTop: '70px'}}>
                              {fileLoading.seller_business_photos
                                ? <>{uploadProgress.seller_business_photos > 0 && `Uploaded ${uploadProgress.seller_business_photos}%`} Please Wait....</>
                                : <> <AiOutlinePlus /> Upload Media (jpg | png | mp4 | avi | mov with in size of Max 10MB)</>
                              }
                            </label>

                          </div>
                        </Form.Group>

                        <Col sm={12} className="text-left mt-2">
                          <Button
                            variant="primary"
                            disabled={loading['businessPhotos']}
                            onClick={(e) => onSubmit(e, 'businessPhotos')}
                          >
                            {loading['businessPhotos'] ? 'Updating...' : 'Save Photos'}
                          </Button>
                        </Col>
                      </Row>
                    }
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {/* <h3 className="text-left mb-3">Location</h3> */}
                    <Form.Group className="col-sm-12">
                      <Form.Label>
                        {(loggedUser && loggedUser.role === 'seller') ? 'Location' : 'Your Location'}
                        <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        // size="sm"
                        value={searchLocation}
                        onChange={(e) => setSearchLocation(e.target.value)}
                        placeholder="Begin Typing Your Address..."
                      />
                      {(errors && errors.location) && <span className="error-msg">{errors.location}</span>}
                      {(showPlaces && locations.length > 0) &&
                        <div className="places--list">
                          {locations.map((suggestion, pIdx) => (
                            <span
                              key={pIdx}
                              onClick={() => handleSelectPlace(suggestion)}
                            >
                              {suggestion.description}
                            </span>
                          ))}
                        </div>
                      }
                    </Form.Group>

                    {showAddressFields &&
                      <Form.Group className="col-sm-12 col-md-12 mt-2 mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Street"
                          value={SellerProfile?.address?.street || ""}
                          name="address.street"
                          onChange={(e) => handleChange(e, 'address', 'street')}
                        />
                        {/* {(errors && errors?.address?.street) && <span className="error error-message">{errors?.address?.street}</span>} */}

                        <Form.Control
                          type="text"
                          placeholder="Landmark ( Optional )"
                          name="address.landmark"
                          value={SellerProfile?.address.landmark || ""}
                          onChange={(e) => handleChange(e, 'address', 'landmark')}
                          className="mt-2"
                        />

                        <Form.Control
                          type="text"
                          placeholder="City"
                          name="address.city"
                          value={SellerProfile?.address.city || ""}
                          onChange={(e) => handleChange(e, 'address', 'city')}
                          className="mt-2"
                        />
                        {(errors && errors?.address?.city) && <span className="error error-message">{errors.address?.city}</span>}

                        <Form.Control
                          type="text"
                          placeholder="State / Province"
                          name="address.state"
                          value={SellerProfile?.address.state || ""}
                          onChange={(e) => handleChange(e, 'address', 'state')}
                          className="mt-2"
                        />
                        {(errors && errors?.address?.state) && <span className="error error-message">{errors.address.state}</span>}

                        <Form.Control
                          type="text"
                          placeholder="Country"
                          name="address.country"
                          value={SellerProfile?.address.country || ""}
                          onChange={(e) => handleChange(e, 'address', 'country')}
                          className="mt-2"
                        />
                        {(errors && errors?.address?.country) && <span className="error error-message">{errors.address.country}</span>}

                        <Form.Control
                          type="text"
                          placeholder="Zip Code"
                          name="address.postalCode"
                          value={SellerProfile?.address.postalCode || ""}
                          onChange={(e) => handleChange(e, 'address', 'postalCode')}
                          className="mt-2"
                        />
                        {(errors && errors?.address?.postalCode) && <span className="error error-message">{errors.address.postalCode}</span>}
                      </Form.Group>
                    }

                    <Form.Group className="col-sm-12">
                      <Form.Label>Area of service ( {SellerProfile.seller_serve_distance} Miles )</Form.Label>
                      <Slider
                        min={0}
                        max={250}
                        value={SellerProfile.seller_serve_distance}
                        onChange={(value) => setSellerProfile({ ...SellerProfile, seller_serve_distance: value })}
                        tooltip={true}
                      />
                    </Form.Group>

                    <Col sm={12} className="text-left mt-2">
                      <Button
                        variant="primary"
                        disabled={loading['basic2']}
                        onClick={(e) => onSubmit(e, 'basic2')}
                      >
                        {loading['basic2'] ? 'Updating...' : 'Save Changes'}
                      </Button>
                    </Col>


                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    {/* <h3 className="text-left mb-3">Certificates</h3> */}

                    <Row className="bg-color section">
                      <Form.Group className="col-sm-12 b--certificates">
                        <Form.Label>
                          Certificates
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>

                        </Form.Label>
                        <Form.Group className="col-sm-12">
                          <input
                            type="file"
                            id="upload--file"
                            hidden
                            multiple
                            onChange={(e) => fileLoading.files ? null : handleFileChange(e, 'files')}
                            accept={['image/jpeg', 'application/pdf', 'image/png', 'image/jpg']}
                            disabled={fileLoading.files}
                          />
                          <label htmlFor="upload--file">
                            {fileLoading.files
                              ? <>{uploadProgress.files > 0 && `Uploaded ${uploadProgress.files}%`} Please Wait....</>
                              : <> <AiOutlinePlus /> Upload Certificate (JPG, PNG, or PDF, Max 10MB)</>
                            }
                          </label>
                          {renderError('files')}

                          {/* Note about certificate deletion */}
                          <small style={{ display: 'block', marginTop: '10px', color: '#6c757d' }}>
                            Note: Certificates cannot be deleted through the website. If you'd like to remove it, please contact us at <a href="mailto:hello@mybirthbridge.com">hello@mybirthbridge.com</a> for assistance.
                          </small>
                        </Form.Group>
                        {(SellerProfile.files && SellerProfile.files.length > 0)
                          &&
                          <div
                            className="certificate-files mt-2 mb-2"
                            id="certificate-files"
                          >
                            {SellerProfile.files.map((file, fileIndex) => {
                              return (
                                <Files
                                  key={fileIndex}
                                  file={file}
                                  SellerProfile={SellerProfile}
                                  setSellerProfile={setSellerProfile}
                                  filesIndex={fileIndex}
                                  handleShowFileView={handleShowFileView}
                                  handleRemoveFile={handleRemoveFile}
                                  handleFileInputChange={handleFileInputChange}
                                  bbServices={bbServiceList}
                                  productError={errors?.products?.[fileIndex]}
                                  certificateTimeError={errors?.certificateTime?.[fileIndex]}
                                  setProductErrors={(productIndex, field) => handleProductError(fileIndex, productIndex, field)}
                                />
                              )
                            })}
                          </div>
                        }
                      </Form.Group>

                      {/* {showFileViewer &&
                                <FileViewModal
                                  show={showFileViewer}
                                  onHide={handleShowFileView}
                                  type={certificateToShow.file_type}
                                  url={certificateToShow.url}
                                  title={certificateToShow.name}
                                />
                              } */}
                      <Col sm={12} className="text-left mt-2">
                        <Button
                          variant="primary"
                          disabled={loading['cert']}
                          onClick={(e) => onSubmit(e, 'cert')}
                        >
                          {loading['cert'] ? 'Updating...' : 'Save Changes'}
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>

                  {/* ----- Preferences ----- */}
                  <TabPanel value={value} index={4}>
                    <Row className="bg-color section">

                      <Form.Group className="col-sm-12">
                        <Form.Label>
                          Years of Experience
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => handleChange(e, 'yearsOfExperience')}
                          value={SellerProfile.yearsOfExperience}
                          name="yearsOfExperience"
                        >
                          <option value={""}>Choose Your Experience Level</option>
                          {
                            [
                              { value: '0-2 years', label: '0-2 years' },
                              { value: '3-5 years', label: '3-5 years' },
                              { value: '6-10 years', label: '6-10 years' },
                              { value: '10+ years', label: '10+ years' },
                            ].map((_yearsOfExperience, _yearsOfExperienceIndex) => (
                              <option value={_yearsOfExperience.value} key={_yearsOfExperienceIndex}>{_yearsOfExperience.label}</option>
                            ))
                          }

                        </Form.Control>
                        {(errors && errors.yearsOfExperience) && <span className="error-msg">{errors.yearsOfExperience}</span>}
                      </Form.Group>


                      <Form.Group className="col-sm-12 mb-3">
                        <Form.Label>
                          Spoken Languages
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <ReactSelect
                          isMulti
                          value={SellerProfile.language}
                          onChange={e => handleLanguage(e, 'language')}
                          options={languages}
                          placeholder="Select your spoken language(s)"
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />

                        {(errors && errors.language) && <span className="error-msg">{errors.language}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>
                          Race
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => handleChange(e, 'race')}
                          value={SellerProfile.race}
                          name="race"
                        >
                          <option value={""}>Select your race</option>
                          {
                            race.map((_race, _raceIndex) => (
                              <option value={_race.value} key={_raceIndex}>{_race.name}</option>
                            ))
                          }

                        </Form.Control>
                        {(errors && errors.race) && <span className="error-msg">{errors.race}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>Religion</Form.Label>
                        <ReactSelect
                          isMulti
                          value={handleReligious(SellerProfile?.religious)}
                          onChange={e => handleChange({ target: { value: e.map(ma => ma.value) } }, 'religious')}
                          options={religionsOptions}
                          placeholder="Select options..."
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>Cultural background</Form.Label>
                        <ReactSelect
                          isMulti
                          value={handleCulturalExpertise(SellerProfile.culturalExpertise)}
                          onChange={e => handleChange({ target: { value: e.map(ma => ma.value) } }, 'culturalExpertise')}
                          options={culturalExpertiseOptions}
                          placeholder="Select options..."
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>Do you have Business Insurance?
                          {/* <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span> */}
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="hasInsurance"
                            value="yes"
                            checked={SellerProfile.hasInsurance === 'yes'}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: 'yes' } }, 'hasInsurance')}
                            id="yesInsurance"
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="hasInsurance"
                            value="no"
                            checked={SellerProfile.hasInsurance === 'no'}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: 'no' } }, 'hasInsurance')}
                            id="noInsurance"
                          />
                        </div>
                        {(errors && errors.hasInsurance) && <span className="error-msg">{errors.hasInsurance}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>What type of medical approach do you practice?</Form.Label>
                        <ReactSelect
                          isMulti
                          value={handleMedicineApproach(SellerProfile.medicineApproach)}
                          onChange={e => handleChange({ target: { value: e.map(ma => ma.value) } }, 'medicineApproach')}
                          options={medicineApproachOptions}
                          placeholder="Select options..."
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>
                          Do you accept Health Insurance?
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="takesHealthInsurance"
                            value="yes"
                            checked={SellerProfile.takesHealthInsurance === 'yes'}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: 'yes' } }, 'takesHealthInsurance')}
                            id="yesHealthInsurance"
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="takesHealthInsurance"
                            value="no"
                            checked={SellerProfile.takesHealthInsurance === 'no'}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: 'no' } }, 'takesHealthInsurance')}
                            id="noHealthInsurance"
                          />
                        </div>

                        <Form.Text muted style={{
                          fontStyle: 'italic',
                          color: '#555',
                          maxWidth: '100%',
                          width: '600px',
                        }}>
                          Please note: While some professionals may accept health insurance, **BirthBridge does not process insurance claims through the platform.** However, you are welcome to discuss insurance directly with your professional.
                        </Form.Text>
                        {(errors && errors.takesHealthInsurance) && <span className="error-msg">{errors.takesHealthInsurance}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>
                          Are you willing to mentor or precept other birth professionals?
                          {/* <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span> */}
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="chooseToMentorOfBirthProfessional"
                            value="yes"
                            checked={SellerProfile.chooseToMentorOfBirthProfessional === 'yes'}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: 'yes' } }, 'chooseToMentorOfBirthProfessional')}
                            id="yesChooseToMentorOfBirthProfessional"
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="chooseToMentorOfBirthProfessional"
                            value="no"
                            checked={SellerProfile.chooseToMentorOfBirthProfessional === 'no'}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: 'no' } }, 'chooseToMentorOfBirthProfessional')}
                            id="noChooseToMentorOfBirthProfessional"
                          />
                        </div>
                        {(errors && errors.hasInsurance) && <span className="error-msg">{errors.hasInsurance}</span>}
                      </Form.Group>

                      {/* <Form.Group className="col-sm-12">
                        <Form.Label>Would you like customers to cover Stripe transaction fees?
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>
                        </Form.Label>
                        <div>
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="yes"
                            value="customer"
                            checked={SellerProfile.is_stripe_fees_charge_by_customer === true}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: true } }, 'is_stripe_fees_charge_by_customer')}
                            id="hasInsurance"
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="no"
                            value="no"
                            checked={SellerProfile.is_stripe_fees_charge_by_customer === false}
                            onChange={(e) => handleChange({ target: { name: e.target.name, value: false } }, 'is_stripe_fees_charge_by_customer')}
                            id="noInsurance"
                          />
                        </div>
                      </Form.Group> */}

                      {(loggedUser && loggedUser.role === 'seller' && isSellerWithStaff)
                        &&
                        <>
                          <Form.Group className="col-sm-12">
                            <Form.Label>
                              Do you wish to deduct a percentage from your staff's earnings?
                            </Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(e) => {

                                handleChange(e, 'moneyFromStaff', 'status')
                              }}
                              value={SellerProfile?.moneyFromStaff?.status}
                              name="moneyFromStaff.status"
                            >
                              <option value={true}>Yes</option>
                              <option value={false}>No</option>
                            </Form.Control>
                          </Form.Group>
                          {SellerProfile?.moneyFromStaff.status == 'true'
                            &&
                            <Form.Group className="col-sm-12">
                              <Form.Label>
                                What percentage of earnings would you like to retain from staff?
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="moneyFromStatus.value"
                                value={SellerProfile?.moneyFromStaff.value}
                                onChange={(e) => handleChange(e, 'moneyFromStaff', 'value')}
                              />
                            </Form.Group>}
                        </>
                      }

                      <Form.Group className="col-sm-12">
                        <Form.Label>Preferred Timezone for Emails
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>

                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => handleChange(e, 'timeZone')}
                          value={SellerProfile?.timeZone}
                          name="timeZone"
                        >
                          <option value="">Select your preferred timezone</option>
                          {
                            timeZones.map((timezone, index) => (
                              <option key={index} value={timezone.Description}>
                                {timezone.Description.concat(' ' + timezone.Location)}
                              </option>
                            ))
                          }
                        </Form.Control>
                        {(errors && errors.timeZone) && <span className="error-msg">{errors.timeZone}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>Cancellation Policy
                          <span style={{ marginLeft: 4, color: 'red', fontSize: 16 }}>*</span>

                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          value={SellerProfile?.cancellation_policy || ""}
                          onChange={(e) => handleChange(e, 'cancellation_policy')}
                          placeholder="Tell the families what your Cancellation Policy is"
                        />
                        {(errors && errors.cancellation_policy) && <span className="error-msg">{errors.cancellation_policy}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>
                          Would you like your account to be suggested by other birth professionals?
                        </Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => handleChange(e, 'suggestMe')}
                          value={SellerProfile?.suggestMe}
                          name="suggestMe"
                        >
                          <option value={true}>Yes</option>
                          <option value={false}>No</option>
                        </Form.Control>
                      </Form.Group>

                      <Col sm={12} className="text-left mt-2">
                        <Button
                          variant="primary"
                          disabled={loading['basic3']}
                          onClick={(e) => onSubmit(e, 'basic3')}
                        >
                          {loading['basic3'] ? 'Updating...' : 'Save Changes'}
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>

                  {/* ------ Social Media ------- */}
                  <TabPanel value={value} index={5}>
                    <Row className="bg-color section">
                      <Form.Group className="col-sm-12">
                        <Form.Label>Website {`(Optional)`}</Form.Label>

                        <InputGroup>
                          <InputGroup.Text>www.</InputGroup.Text>
                          <Form.Control
                            style={{ minWidth: '100px' }}
                            aria-label="URL"
                            type="text"
                            name="website"
                            value={SellerProfile?.website || ''}
                            placeholder="mybirthbridge.com"
                            onChange={(e) => handleChange(e, 'website')}
                          />
                        </InputGroup>
                        {(SellerProfile && SellerProfile.website) && <small>Verify Your Website Link <a className="verify-url" href={`//${SellerProfile?.website}`} target="_blank">www.{SellerProfile?.website}</a></small>}
                        {(errors && errors.website) && <span className="error error-message">{errors.website}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>Facebook {`(Optional)`}</Form.Label>

                        <InputGroup>
                          <InputGroup.Text>https://www.facebook.com/</InputGroup.Text>
                          <Form.Control
                            style={{ minWidth: '100px' }}
                            aria-label="URL"
                            type="text"
                            name="social_media.facebook"
                            value={SellerProfile?.social_media?.facebook || ''}
                            placeholder="username"
                            onChange={(e) => handleChange(e, 'social_media', 'facebook')}
                          />
                        </InputGroup>
                        {(SellerProfile && SellerProfile.social_media && SellerProfile.social_media.facebook) && <small>Verify Your Facebook Profile Link <a className="verify-url" href={`https://www.facebook.com/${SellerProfile?.social_media.facebook}`} target="_blank">https://www.facebook.com/{SellerProfile?.social_media.facebook}</a></small>}
                        {(errors && errors?.social_media?.facebook) && <span className="error error-message">{errors?.social_media?.facebook}</span>}
                      </Form.Group>

                      <Form.Group className="col-sm-12">
                        <Form.Label>Instagram {`(Optional)`}</Form.Label>

                        <InputGroup>
                          <InputGroup.Text>https://www.instagram.com/</InputGroup.Text>
                          <Form.Control
                            style={{ minWidth: '100px' }}
                            aria-label="URL"
                            type="text"
                            name="social_media.instagram"
                            value={SellerProfile?.social_media?.instagram || ''}
                            placeholder="username"
                            onChange={(e) => handleChange(e, 'social_media', 'instagram')}
                          />
                        </InputGroup>
                        {(SellerProfile && SellerProfile.social_media && SellerProfile.social_media.instagram) && <small>Verify Your Instagram Profile Link <a className="verify-url" href={`https://www.instagram.com/${SellerProfile?.social_media.instagram}`}>https://www.instagram.com/{SellerProfile?.social_media.instagram}</a></small>}
                        {(errors && errors?.social_media?.instagram) && <span className="error error-message">{errors?.social_media?.instagram}</span>}
                      </Form.Group>



                      <Row className="mt-2 d-flex justify-content-between align-items-center button-row">
                        <Button
                          variant="primary"
                          disabled={loading['social'] || disableSocial}
                          onClick={(e) => onSubmit(e, 'social')}
                        >
                          {loading['social'] ? 'Updating...' : 'Save Changes'}
                        </Button>
                        <Button
                          variant="primary"
                          onClick={() => handleCopyLink(`${window.location.host}/business/${SellerProfile.id}`, SellerProfile?.name)}
                        >
                          Share Your Profile Link
                        </Button>
                      </Row>

                    </Row>

                  </TabPanel>
                </div>
              </div>
              <hr />
            </Form >
          }
        </>
      }

      {
        showImageCrop &&
        <ImageCropModal
          show={showImageCrop}
          handleClose={handleCloseImageCropper}
          image={profileImage.original}
          handleSave={handleSaveCropImage}
        />
      }

      {openSnackbar &&
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '500px', maxWidth: '100%' }}>
            You've copied the link to <strong>{copiedService}'s</strong> profile. Please share on your website/social media.
          </Alert>
        </Snackbar>
      }

      {showFileViewer &&
        <FileViewModal
          show={showFileViewer}
          onHide={handleShowFileView}
          type={certificateToShow.file_type}
          url={certificateToShow.url}
          title={certificateToShow.name}
        />
      }
    </>
  );
}

export default SellerProfile;