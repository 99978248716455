import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createClient } from 'contentful';
import { Card, CardContent, CardMedia, Typography, Chip, Box, Pagination } from '@mui/material';
import './styles/BlogList.css';
import SEO from "../../seo";
import { logEvent } from "../../googleAnalyticWrapper/analytic";
import { optimizedCloudinaryImage } from '../../utils/fileCompression';
import { FaThumbtack } from 'react-icons/fa';
import AdUnit from '../communityForum/AdUnit';

const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
});

const BlogList = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const postsPerPage = 5;

    useEffect(() => {
        logEvent('Page View', 'viewing_blog_list', 'Viewing List of Blogs', 1);
    }, []);


    useEffect(() => {
        client.getEntries({ content_type: 'blogPost' })
            .then((response) => {
                // Separate pinned and regular posts
                const pinnedPosts = response.items.filter(post => post.fields.pinBlog);
                const regularPosts = response.items.filter(post => !post.fields.pinBlog);

                // Concatenate pinned posts at the start of the regular posts
                const orderedPosts = [...pinnedPosts, ...regularPosts];

                setPosts(orderedPosts);
            })
            .catch(console.error);
    }, []);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const displayedPosts = posts
        .filter(post => post.fields.displayInLIst)
        .slice((currentPage - 1) * postsPerPage, currentPage * postsPerPage);

    return (
        <div className="blog-list">
            <SEO
                title="Blogs"
                description="Explore and Learn more about our certified birth professionals on BirthBridge"
                url={`/blog-list`}
                keywords="blogs, birth professionals"
            />
            <Typography variant="h1" className="title" gutterBottom>
                Our Birthing Services and How it Helps with Your Birthing Journey
            </Typography>


            {displayedPosts.map((post) => (
                <Card key={post.sys.id} style={{ height: 'auto' }} className="blog-card">
                    <div className="card-row">
                        {post.fields.heroImage && (
                            <div className="image-container">
                                <CardMedia
                                    component="img"
                                    image={post.fields.heroImage.fields.file.url.includes('cloudinary')
                                        ? optimizedCloudinaryImage(post.fields.heroImage.fields.file.url)
                                        : post.fields.heroImage.fields.file.url
                                    }
                                    alt={post.fields.title}
                                    className="blog-image"
                                    loading="lazy"
                                />
                            </div>
                        )}
                        <div className="content-container">
                            <CardContent>
                                <Typography variant="h5" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                                    {post.fields.pinBlog && (
                                        <FaThumbtack style={{ color: 'red', marginRight: '8px' }} />
                                    )}
                                    <Link to={`/${post.fields.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                        {post.fields.title}
                                    </Link>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {post.fields.description.content[0].content[0].value}
                                </Typography>
                                {post.fields.tags && post.fields.tags.length > 0 && (
                                    <Box mt={2}>
                                        <Typography variant="subtitle1">Tags:</Typography>
                                        <Box display="flex" flexWrap="wrap" mt={1}>
                                            {post.fields.tags.map((tag, index) => (
                                                <Chip key={index} label={tag} color="primary" style={{ margin: '4px' }} />
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </CardContent>
                        </div>
                    </div>
                </Card>
            ))}

            <Pagination
                count={Math.ceil(posts.filter(post => post.fields.displayInLIst).length / postsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
            <div style={{ marginTop: '16px' }}>

                <AdUnit slot={'4626134585'} />
            </div>
        </div>
    );
};

export default BlogList;

