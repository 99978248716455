export const religionsOptions = [
    { "label": "Christianity", "value": "christianity" },
    { "label": "Islam", "value": "islam" },
    { "label": "Judaism", "value": "judaism" },
    { "label": "Hinduism", "value": "hinduism" },
    { "label": "Buddhism", "value": "buddhism" },
    { "label": "Sikhism", "value": "sikhism" },
    { "label": "Baha'i Faith", "value": "baha'i faith" },
    { "label": "Jainism", "value": "jainism" },
    { "label": "Taoism", "value": "taoism" },
    { "label": "Confucianism", "value": "confucianism" },
    { "label": "Zoroastrianism", "value": "zoroastrianism" },
    { "label": "Shinto", "value": "shinto" },
    { "label": "Paganism", "value": "paganism" },
    { "label": "Atheism", "value": "atheism" },
    { "label": "Agnosticism", "value": "agnosticism" }
]
