import { get } from 'lodash';
import API from '../../helpers/api';
import { 
    GET_PLATFORM_META_SUCCESS,
    GET_PLATFORM_META_FAILED,
    SWITCH_APP_VIEW,
} from './types';
import { saveCurrentView } from '../../helpers/auth';


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

/**
 * Get Referral Status
 */
export const getPlatformMeta = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('meta', '/platform');
            if (response.data && response.data.success) {
                await dispatch({ type: GET_PLATFORM_META_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_PLATFORM_META_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_PLATFORM_META_FAILED);
        }
    }
}

export const switchAppView = (view) => {
    return async dispatch => {
        saveCurrentView(view);
        dispatch({ type:SWITCH_APP_VIEW });
    }
  }