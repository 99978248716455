import React, { useEffect, useState } from "react";
import { Col, Container, Row, Navbar, Nav, Modal, Form, Button, ListGroup , Alert } from "react-bootstrap";
import { clearReduxState, requestOtp, showRequestOTP, showVerifyOtp } from "../../../redux/actions/auth.actions";
import { useSelector, useDispatch } from "react-redux";
import { notValid , emailValidation, deepClone } from "../../../utils/validations";

function RequestOtp({email , setEmail, showOtpModel , requestOtpModel}) {
    const intialValue = {email: ''};
    const alertValue = { message: '', show: false, variant: ''};
    const[alert , setAlert] = useState(deepClone(alertValue));
    const [user , setUser]= useState(intialValue); 
    const [loading , setLoading] = useState(false);
    const [error , setError] = useState(false);
    const dispatch = useDispatch();
    const authState = useSelector(state => state.auth);

    const handleChangeInput = (e) => {
        setAlert(deepClone(alertValue));
        const{name , value} = e.target ; 
        setUser({ ...user, [name]: value })
        setError({ ...error, [name]: '' })
    }

    useEffect(() => {
        return () => {
            dispatch(clearReduxState());
        }
    }, [])

    const isValid = () => {
        let isValid = true
        // let userNameErrorMessage = "Please provide Email";
        let email = "Please enter your email";
        let validEmail = "Please enter a valid email address";
        // let otpErrorMessage = "please enter your verification code "
        let errorMessages = {};
        if (notValid(user.email)) {
            errorMessages['email'] = email;
            isValid = false;
        } else if (!emailValidation(user.email)) {
            errorMessages['email'] = validEmail;
            isValid = false;
        }
        if (!isValid) {
            setError({ ...error, ...errorMessages });
        }
        return isValid;
    }

    const onSubmit = async(e) => {
        e.preventDefault();
        if (isValid()) {
            setLoading(true)
            setEmail(user.email)
            dispatch(requestOtp(user))
        }
    }

    useEffect(() => {
        if(authState.error){
            alert['show'] = true
            alert['message'] = authState.error
            alert['variant'] = "danger"
            setAlert(alert)
            setLoading(false)
        }

        if( !authState.error && authState.requestMessage){
            alert['show'] = true
            alert['message'] = authState.requestMessage.message
            alert['variant'] = "success"
            setAlert(alert)
            setTimeout(() => {
                setLoading(false)
                dispatch(showVerifyOtp(true));  // here true value is used for "Request to Verify email" for reset pass
            }, 1200)
        }
    }, [authState])

    const showAlertMessage = () => {
        return (
            <Alert variant={alert.variant}> {alert['message']} </Alert>
        )
    }
    return (
        <>
            <Container>
                <Row>
                    <Col sm={12} lg={6} className="pr-lg-0">
                        <a className="company--brand">
                            <img src="/images/logo.webp" loading='lazy' alt="BirthBridge Logo" />
                            <span>BirthBridge</span>
                        </a>
                    </Col>
                    <Col sm={12} lg={6} className="pl-lg-0">
                        <Form className="mt-3">
                            {alert['show'] && showAlertMessage()}
                        
                            <h3>Verify Email</h3>
                            {/* <p className="mb-3 mt-2"><span> Enter Your Email To Verify </span></p> */}
                            <Form.Group className="form-group">
                                <Form.Control 
                                    type="email" 
                                    placeholder="Email Address"  
                                    required
                                    name="email"
                                    value={user.email}
                                    onChange={handleChangeInput}
                                
                                />
                                {error.email && <span className="error error-message">{error.email}</span>}    
                            </Form.Group>
                            <Button 
                                variant="primary" 
                                type="submit" 
                                onClick={(e) => loading ? null : onSubmit(e)}
                                disabled={loading}
                            >
                                {loading ? 'Please Wait...' : 'Verify'}
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>    

    )
}

export default RequestOtp