import { get } from 'lodash';
import API from '../../helpers/api';
import { 
  GET_STRIPE_ACCOUNT_FAILED, 
  GET_STRIPE_ACCOUNT_SUCCESS, 
  STRIPE_CONNECT_FAILED, 
  STRIPE_CONNECT_SUCCESS, 
  GET_PAYMENT_MODE_SUCCESS, 
  GET_PAYMENT_MODE_FAILED, 
  PUT_PAYMENT_MODE_SUCCESS, 
  PUT_PAYMENT_MODE_FAILED 
} from './types';


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
      dispatch({
          type: type,
          payload: { ...data.error, message: data.error.message },
      });
    } else {
      dispatch({
          type: type,
          payload: { ...data, message: data.message },
      });
    }
}

export const connectStripe = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiPostUrl('stripe', '/connect');
        if (response.data && response.data.success) {
          await dispatch({ type: STRIPE_CONNECT_SUCCESS, payload: response.data })
        } else {
          await dispatch({ type: STRIPE_CONNECT_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, STRIPE_CONNECT_FAILED);
      }
    }
}

export const getStripeAccount = () => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('stripe');
      if (response.data && response.data.success) {
        await dispatch({ type: GET_STRIPE_ACCOUNT_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_STRIPE_ACCOUNT_FAILED, payload: response.data });
      }
    } catch (error) {
        errorRequest(error, dispatch, GET_STRIPE_ACCOUNT_FAILED)
    }
  }
}

export const getPaymentMode = () => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('paymentMode');
      if (response.data && response.data.success) {
        await dispatch({ type: GET_PAYMENT_MODE_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: GET_PAYMENT_MODE_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, GET_PAYMENT_MODE_FAILED);
    }
  }
}

export const updatePaymentMode = (data) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('paymentMode', "/createOrUpdate", data);
      if (response.data && response.data.success) {
        await dispatch({ type: PUT_PAYMENT_MODE_SUCCESS, payload: response.data })
      } else {
        await dispatch({ type: PUT_PAYMENT_MODE_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, PUT_PAYMENT_MODE_FAILED);
    }
  }
}

// export const onboardStatus = (accountId) => {
//   return async (dispatch) => {
//     try {
//       const response = await API.apiPostUrl('stripe', `/onboarding?accountId=${accountId}`);
//       if(response.data && response.data.success){
//         await dispatch({ type: GET_ONBOARDING_SUCCESS, payload: response.data });
//       }else{
//         await dispatch({ type: GET_ONBOARDING_FAILED, payload: response.data });
//       }
//     } catch (error) {
//       errorRequest(error, dispatch, GET_ONBOARDING_FAILED)
//     }
//   }
// }