import React, { useEffect, useState } from 'react';
import { Stepper, Step, Button, StepLabel, Typography, TextField, MenuItem, Grid, Box, Radio, FormControlLabel, FormControl, InputLabel, Select, InputAdornment, Chip, Alert } from '@mui/material';
import ImageCropModal from '../../../../commonComponents/CustomImageCropper';
import { uploadFilesToCloud } from '../../../../redux/actions/cloudinary.action';
import { deepCopy, deepEqual, numericValidation } from '../../../../utils/validations';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import FileViewModal from '../../../../commonComponents/FileViewer';
import './stepper.css'
import Files from '../../../Seller/Profile/Files';
import StepAddress from './stepAddress';
import race from '../../../../utils/race';
import API from '../../../../helpers/api';
import { FaInfoCircle } from 'react-icons/fa';
// import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { clearReduxState } from '../../../../redux/actions/auth.actions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  saveUserSignupFields,
  saveSignupUserId,
  getUserSignupFields,
  getSignupUserId,
  removeSignupUserId,
  removeUserSignupFields
} from '../../../../helpers/auth';
import LoadingView from '../../../LoadingView';



const SignUpSteps = ({
  languages,
  bbServiceList,
  selectedBBService,
}) => {
  const specialtyFields = { 
    bbservice: '', 
    service_type: '', 
    category: '', 
    amount_type: '', 
    amount: '', 
    desc: '' 
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);

  const [selectOpen, setSelectOpen] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(-1);
  const [isStepLoading, setIsStepLoading] = useState(false);

  /** ------- Image Crop ------ */
  const [showImageCrop, setShowImageCrop] = useState(false);
  const [profileImage, setProfileImage] = useState({ original: '', cropped: '' });

  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});

  /** ----------- File Viewer State ----------- */
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [certificateToShow, setCertificateToShow] = useState(null);

  /** ---------Input Files Handle Loading ------- */
  const [fileLoading, setFileLoading] = useState({
    profilePic: false,
    files: false,
    // disclaimer_policy_document: false
  });
  const [uploadProgress, setUploadProgress] = useState({
    profilePic: false,
    files: false,
    // disclaimer_policy_document: false
  });

  /** -------- Specialty ( category ) ----------- */
  const [specialtyLoading, setSpecialtyLoading] = useState(false);
  const [specialties, setSpecialty] = useState([]);


  const [alert, setAlert] = useState({ variant: '', message: '', show: false });

  const [prevFieldData, setPrevFieldData] = useState(); // Initialize the previous form data

  const [isNextDisabled, setIsNextDisabled] = useState(true);

  const _initialSteps = [
    {
      label: 'Welcome to BirthBridge!',
      description: `Creating your profile takes about 5-10 minutes, and your progress is automatically saved with every step. This means you can pause anytime and pick up right where you left off, without missing a beat.
      
      Let’s get started and create a profile that highlights your unique skills while joining a supportive community of birth professionals. Together, we’re building something special!`,
      key: 'welcome'
    },
    {
      label: 'Business Details',
      description: 'Fill in details about you and your business to help others get to know you!',
      key: 'business',
      bypass: true,
      subSteps: [
        {
          fields: [
            {
              key: 'email',
              label: 'Business Email',
              type: 'email',
              disabled: false,
              required: true,
              description: `Fill in details about you and your business to help others get to know you! 
              
              Please provide your email address for account access and communication.`
            },

            {
              key: 'password',
              label: 'Password',
              type: 'password',
              disabled: false,
              required: true
            },
            {
              key: 'terms',
              label: <>
                I agree to BirthBridge's <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="ml-2">
                  <FaInfoCircle />
                </a>
              </>,
              type: 'checkbox',
              disabled: false,
              required: true
            }
          ]
        },
        {
          fields: [
            {
              key: 'profilePic',
              label: 'Upload Business Profile Picture',
              type: 'file',
              description: 'A professional photo helps build trust and makes your profile stand out to families looking for support.',
              required: true,
              disabled: false,
            },
          ]
        },
        {
          fields: [
            {
              key: 'name',
              label: 'Business Name',
              type: 'text',
              required: true,
              description: 'Enter your name & business name to help families easily find and connect with you.',
              disabled: false,
            },
            {
              key: 'seller_personal_name',
              label: 'Your Name',
              type: 'text',
              required: true,
              description: '',
              disabled: false,
            },
          ]
        },
        {
          fields: [
            {
              key: 'address',
              label: 'Business Address',
              type: 'component',
              required: true,
              description: 'Add your business address so families can easily find and connect with you.',
              disabled: false,
            },
          ]
        },
        {
          fields: [
            {
              key: 'seller_business_desc',
              label: 'Business Description',
              type: 'textarea',
              required: true,
              description: 'Share a brief description of your business, highlighting your services, values, and mission to help families connect with what makes you unique.',
              disabled: false,
            },
          ]
        }
      ],
    },
    {
      label: 'Certifications',
      description: 'Showcase your certifications to highlight your expertise and build trust with the families you support.',
      key: 'certificates',
      bypass: true,
      subSteps: [
        {
          fields: [
            {
              key: 'files',
              label: 'Upload Certificate',
              type: 'file',
              fileType: 'pdf',
              required: true,
              description: `Showcase your qualifications by uploading relevant certifications. This helps families build trust in your expertise and demonstrates your commitment to professional growth.
              
              We welcome all certifications that reflect your unique contributions to supporting families.`,
              disabled: false,
            },
          ]
        },

      ],

    },

    {
      label: 'Specialties Offered',
      description: 'Highlight the specialties you offer to showcase your services, set pricing, and guide families to the support they’re looking for.',
      key: 'services',
      bypass: true,
      subSteps: [
        {
          fields: [
            {
              type: 'rows',
              description: `This section reflects the certifications you’ve chosen earlier. It ensures that your services align with your qualifications and areas of expertise. To make changes, revisit the Certifications tab to update your selections.`,
              required: true
            },
          ]
        },
      ]
    },
    {
      label: 'Referral',
      description: 'Some Text here',
      key: 'referral',
      bypass: true,
      subSteps: [
        {
          fields: [
            {
              key: 'referralSource',
              label: 'Where did you hear about us?',
              type: 'select',
              isMulti: false,
              options: [
                { label: 'Google Search', value: 'Google Search' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Pinterest', value: 'Pinterest' },
                { label: 'TikTok', value: 'TikTok' },
                { label: 'Reddit', value: 'Reddit' },
                // { label: 'Twitter', value: 'Twitter'},
                { label: 'Outdoor Ads', value: 'Outdoor Ads' },
                { label: 'Referred By', value: 'Referred by a Birth Professional' }
              ],
              required: false,
              disabled: false,
              description: `Where did you hear about us?`
            },

          ]
        }
      ]
    }
  ];


  const [steps, setSteps] = useState(_initialSteps);

  const handleNext = async () => {

    const isFormDataChanged = hasFormDataChanged();

    if (isFormDataChanged) {
      /** ----- Saving Filled Steps  */
      // saveCurrentFilledSteps(activeStep, activeSubStep);
      saveUserSignupFields(fields); // update to local storage on every click
    }

    if (isFormDataChanged && activeStep === 1 && activeSubStep === 0 && !user) {
      // Wait for the result of the API call
      const emailCheckResult = await checkEmailExistAndRegister(fields['business']);

      if (!emailCheckResult) {
        return; // Prevent moving to the next step
      }

      setAlert({ variant: '', show: false, message: '' }); // Reset if Success
    }

    if (isFormDataChanged && activeSubStep !== -1 && !(activeStep === 1 && activeSubStep === 0)) {
      // API call for other conditions
      await saveFormDataOnDB(steps[activeStep].key, steps[activeStep].subSteps)
    }

    // Handle substeps
    if (
      steps[activeStep].subSteps &&
      activeSubStep < steps[activeStep].subSteps.length - 1
    ) {
      setActiveSubStep((prevSubStep) => prevSubStep + 1);
    } else {
      // Move to the next step
      let nextStep = activeStep + 1;

      // Find the next step and determine if it should bypass to its first substep
      const nextStepData = steps[nextStep];
      const nextSubStep = nextStepData?.bypass && nextStepData?.subSteps ? 0 : -1;

      setActiveStep(nextStep);
      setActiveSubStep(nextSubStep);
    }
  };




  const handleBack = () => {
    if (activeSubStep > 0) {
      // Navigate to the previous substep
      setActiveSubStep((prevSubStep) => prevSubStep - 1);
    } else if (activeSubStep === 0 && steps[activeStep]?.bypass) {
      // If on the first substep of a bypassed step, go back to the previous step
      let previousStep = activeStep - 1;
      const prevStepData = steps[previousStep];

      // Determine if the previous step has substeps
      const prevSubStep = prevStepData?.subSteps?.length
        ? prevStepData.subSteps.length - 1
        : -1;

      setActiveStep(previousStep);
      setActiveSubStep(prevSubStep);
    } else if (activeStep > 0) {
      // Navigate to the previous step normally
      let previousStep = activeStep - 1;
      const prevStepData = steps[previousStep];

      // Determine if the previous step has substeps
      const prevSubStep = prevStepData?.subSteps?.length
        ? prevStepData.subSteps.length - 1
        : -1;

      setActiveStep(previousStep);
      setActiveSubStep(prevSubStep);
    }
  };





  // Create formData dynamically
  const initializeFieldsData = () => {
    return _initialSteps.reduce((acc, step) => {
      if (step.subSteps) {
        // Process only steps with subSteps
        acc[step.key] = step.subSteps.reduce((fields, subStep) => {
          if (subStep.fields) {
            // Process fields inside each subStep
            subStep.fields.forEach(field => {
              fields[field.key] = field.isMulti ? [] : '';
            });
          }
          return fields;
        }, {});
      }
      return acc;
    }, {});
  };

  useEffect(() => {

    const _savedFields = getUserSignupFields();
    const _uid = getSignupUserId();
    if (_uid) {
      setUser({ _id: _uid });
    }


    if (_savedFields) {
      setFields(_savedFields);
    } else {
      setFields({ ...initializeFieldsData() });
    }

    setErrors({ ...initializeFieldsData() });
  }, []);


  useEffect(() => {
    const referralSource = fields?.referral?.referralSource;

    if (referralSource && referralSource.value === 'Referred by a Birth Professional') {
      setSteps((prevSteps) =>
        prevSteps.map((step) => {
          if (step.key === 'referral') {
            return {
              ...step,
              subSteps: step.subSteps.map((subStep) => {
                const fieldExists = subStep.fields.some((field) => field.key === 'referralCode');
                if (!fieldExists) {
                  return {
                    ...subStep,
                    fields: [
                      ...subStep.fields,
                      {
                        key: 'referralCode',
                        label: 'Please enter referral code of person who referred you. ( Optional )',
                        type: 'text',
                        required: false,
                        disabled: false,
                      },
                    ],
                  };
                }
                return subStep; // No change if the field already exists
              }),
            };
          }
          return step; // No change for other steps
        })
      );
    } else {
      setSteps((prevSteps) =>
        prevSteps.map((step) => {
          if (step.key === 'referral') {
            return {
              ...step,
              subSteps: step.subSteps.map((subStep) => {
                return {
                  ...subStep,
                  fields: subStep.fields.filter((field) => field.key !== 'referralCode'),
                };
              }),
            };
          }
          return step; // No change for other steps
        })
      );
    }


  }, [
    fields?.referral?.referralSource,
  ]); // Watch for changes in service_type

  useEffect(() => {
    if (activeSubStep == -1) {
      setIsNextDisabled(false);
    } else {
      const isValid = isStepValid();
      setIsNextDisabled(!isValid);
    }
  }, [fields, activeSubStep, steps[activeStep].key]);


  /** ---- Handle Selected BB services of user ----- */
  useEffect(() => {
    (async () => {

      if (steps[activeStep].key === 'services' && activeSubStep === 0 && !specialties.length) {
        await fetchSpecialties()
      }
    })()
  }, [activeStep, activeSubStep]);

  useEffect(() => {
    if (!specialties.length) return; // Exit early if specialties are empty

    // Normalize fields.services to an array if it's invalid
    const normalizedServices =
      Array.isArray(fields.services) ? fields.services : [];

    // Helper function to generate new rows
    const generateRows = () => {
      const newRows = [];
      specialties.forEach((service) => {
        service.categories.forEach((category) => {
          newRows.push({
            ...specialtyFields,
            bbservice: service.bbservice._id, // bbservice ID
            service_type: service.bbservice.service_type, // service type
            amount_type: service.bbservice.service_type === 'service' ? 'fixed' : '',
            category: category._id, // category ID
            desc: category.name, // category name
          });
        });
      });
      return newRows;
    };

    // Compare existing services with the generated rows
    const newRows = generateRows();
    const updatedServices = [...normalizedServices]; // Copy existing services

    // Add only rows that are not already present in `fields.services` unless it's manually added
    newRows.forEach((newRow) => {
      const exists = updatedServices.some(
        (existingRow) =>
          existingRow.bbservice === newRow.bbservice &&
          existingRow.category === newRow.category &&
          !existingRow.manual // Skip prefilled rows (without the manual flag)
      );
      if (!exists) {
        updatedServices.push({ ...newRow, manual: false }); // Add with manual flag set to false
      }
    });

    // If no changes, don't update `fields.services`
    if (updatedServices.length !== normalizedServices.length) {
      const _fields = { ...fields, services: updatedServices };
      setFields(_fields);
      saveUserSignupFields(_fields);
    }
  }, [specialties]);



  const hasFormDataChanged = () => {
    // Compare the current form data with the previous state
    const hasChanged = !deepEqual(fields, prevFieldData);

    if (hasChanged) {
      // Update the previous form data state
      // setPrevFieldData({ ...fields });
      setPrevFieldData(deepCopy(fields));
    }

    return hasChanged;
  };

  const saveFormDataOnDB = async (currentSectionKey, subStepFields, toSubmit) => {
    try {
      setIsStepLoading(true)
      const payload = {};
      payload[currentSectionKey] = {}; // Initialize the section in the payload

      // Iterate over the fields in the current sub-step and add their values to the payload
      subStepFields[activeSubStep].fields.forEach((field) => {
        const fieldKey = field.key;
        if (currentSectionKey === 'services') {
          payload[currentSectionKey] = fields[currentSectionKey]
        } else {
          const fieldValue = fields[currentSectionKey]?.[fieldKey];
          payload[currentSectionKey][fieldKey] = fieldValue;
        }
      });


      if (toSubmit) {
        payload[currentSectionKey]['finalSubmit'] = true;
      }

      const response = await API.apiPostUrl('seller', `/setup-profile?section=${currentSectionKey}&userId=${user._id}`, payload);
      setIsStepLoading(false);
      if (response.data && response.data.success) {
        if (currentSectionKey === 'certificates') {
          await fetchSpecialties()
        }

        if (response.data.certificates) {
          let _fields = { ...fields };
          _fields['certificates']['files'] = response.data.certificates
          setFields(_fields);
          saveUserSignupFields(_fields); // save updated response from server to local storage
        }

        if (response.data.services) {
          let _fields = { ...fields };
          _fields['services'] = response.data.services.map(s => { return { ...s, update: false } });
          setFields(_fields);
          saveUserSignupFields(_fields); // save updated response from server to local storage
        }

        if (response.data.emailSent) {
          removeSignupUserId();
          removeUserSignupFields();

          Swal.fire({
            position: "center",
            icon: "success",
            title: "Success! Please Verify Your Email",
            html: "<p>Your information has been submitted successfully.</p> <br> <p>Please check your email to verify your account.</p>",
            // title: "You’ve24 successfully submitted your information. Please go to your email and verify your account.",
            showConfirmButton: false,
            timer: 5000
          }).then((e) => {
            if (e.isDismissed) {
              navigate('/login')
              dispatch(clearReduxState());
            }
          });
        }
      }
    } catch (error) {
      setIsStepLoading(false);

    }
  };

  /** ----------- Step Vaidate ------------- */

  const isStepValid = () => {
    const currentSectionKey = steps[activeStep].key;
    const subStepFields = steps[activeStep].subSteps;

    if (!subStepFields) return true;

    // Iterate through each field in the current sub-step
    const fieldsToValidate = subStepFields[activeSubStep]?.fields || [];
    for (const question of fieldsToValidate) {
      const fieldKey = question.key;
      const value = fields[currentSectionKey]?.[fieldKey];
      // Check if the field is required and validate its value
      if (question.required) {
        // Specific validation for certificates
        if (currentSectionKey === 'certificates' && fieldKey === 'files') {
          if (!Array.isArray(value)) return false;

          for (const file of value) {
            if (!file.url || typeof file.url !== 'string' || file.url.trim() === '') return false;
            if (!file.file_type || typeof file.file_type !== 'string' || file.file_type.trim() === '') return false;
            if (!file.name || typeof file.name !== 'string' || file.name.trim() === '') return false;
            if (!file.title || typeof file.title !== 'string' || file.title.trim() === '') return false;
            // if (file.cert_type && (typeof file.cert_type !== 'string' || file.cert_type.trim() === '')) return false;
            if (!file.cert_type || typeof file.cert_type !== 'string' || file.cert_type.trim() === '') return false;

            if (file.last_updated === undefined) return false;
            if (file.last_updated) {
              const { year } = file.last_updated;
              // if (!month) return false; // Check MM format
              if (!year) return false; // Check YYYY format
              if (year === 'Year') return false; // Check YYYY format
            }

            if (!Array.isArray(file.product) || file.product.length === 0) return false;

            for (const product of file.product) {
              if (!product.service) return false;
              if (!Array.isArray(product.category) || product.category.length === 0) return false;
            }
          }
        }

        // Specific validation for terms in business
        if (currentSectionKey === 'business' && fieldKey === 'terms' && value === false) {
          return false;
        }

        if (currentSectionKey === 'business' && fieldKey === 'address') {
          if (typeof value === 'object') {
            if (value.city.toString().trim() === '') return false;
            if (value.state.toString().trim() === '') return false;
            return true
          }
        }

        if (currentSectionKey === 'services') {
          if (!Array.isArray(fields.services)) return false;

          for (const service of fields.services) {
            if (!service.bbservice || typeof service.bbservice !== 'string' || service.bbservice.trim() === '') return false;
            if (!service.category || typeof service.category !== 'string' || service.category.trim() === '') return false;
            if (service.amount_type) {
              if (typeof service.amount_type !== 'string' || service.amount_type.trim() === '') return false;
            }

            if (!service.desc || typeof service.desc !== 'string' || service.desc.trim() === '') return false;
            

            if (!service.amount || (service.amount && (!numericValidation(service.amount) || Number(service.amount) < 0))) {

              return false;
            }

            if (service.service_type === 'class' && (!service.max_capacity || !numericValidation(service.max_capacity) || Number(service.max_capacity) < 0)) {
              return false;
            }
          }

          return true
        }

        // Generic validation for required fields
        if (typeof value === 'string' && value.trim() === '') return false;
        if (Array.isArray(value) && value.length === 0) return false;
        if (value === null || value === undefined) return false;

        // Validate objects
        if (typeof value === 'object' && !Array.isArray(value)) {
          if (
            !Object.values(value).every(
              (subValue) => subValue !== null && subValue !== undefined && subValue.toString().trim() !== ''
            )
          ) {
            return false;
          }
        }
      }
    }

    // If all validations pass
    return true;
  };



  const fetchSpecialties = async () => {
    try {
      setSpecialtyLoading(true);
      const response = await API.apiGetByKey('category', '/selected-specialties', {
        userId: user ? user._id : null // userId 
      });

      if (response.data && response.data.success) {
        setSpecialty(response.data.services); // Assuming the API returns an array of suggestions
      }

    } catch (error) {
    } finally {
      setSpecialtyLoading(false);
    }
  };

  /** ---------- Profile Pic Input Handler ----------- */
  const handleProfilePicChange = async (event, activeStepKey) => {
    // setErrors({ ...errors, profilePic: '' });
    setErrors((prevErrors) => {
      const newError = {
        ...prevErrors,
        [activeStepKey]: {
          ...prevErrors[activeStepKey],
          profilePic: '',
        },
      };

      return newError;
    })
    const file = event.target.files[0];
    let hasError = false;

    if (!file) return;

    if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
      setErrors((prevErrors) => {
        const newError = {
          ...prevErrors,
          [activeStepKey]: {
            ...prevErrors[activeStepKey],
            profilePic: file.name + ' file type is not supported',
          },
        };

        return newError;
      })
      hasError = true; // Set hasError to true if there's an error
      return; // Exit the loop early if there's an error
    }

    if (file.size > (1024 * 1024) * 10) {
      setErrors((prevErrors) => {
        const newError = {
          ...prevErrors,
          [activeStepKey]: {
            ...prevErrors[activeStepKey],
            profilePic: file.name + ' size should be less than 10mb',
          },
        };

        return newError;
      })
      hasError = true; // Set hasError to true if there's an error
      return; // Exit the loop early if there's an error
    }

    if (!hasError) { // Proceed with image cropper if there are no errors
      setShowImageCrop(true)
      setProfileImage({ ...profileImage, original: file });
    }
  }

  /** ------------ Upload Cropped Profile Pic Image to Cloud ------------- */
  const handleSaveCropImage = async (croppedImage, imageName) => {
    setShowImageCrop(false);
    const formData = new FormData();
    formData.append('files', croppedImage, imageName);
    const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading, 'profilePic');
    if (response && response.data && response.data.success) {
      setFields((prevData) => {
        return {
          ...prevData,
          ['business']: {
            ...prevData['business'],
            ['profilePic']: response.data.files[0]
          },
        };
      });
    }
  }

  /** --------- Handle Certificate Upload ----------- */
  const handleCertificateUpload = async (event, activeStepKey, field) => {

    setErrors((prevErrors) => {
      const newError = {
        ...prevErrors,
        [activeStepKey]: {
          ...prevErrors[activeStepKey],
          [field]: '',
        },
      };

      return newError;
    })

    const files = event.target.files;
    const formData = new FormData();
    let hasError = false;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!['image/jpeg', 'application/pdf', 'image/png', 'image/jpg'].includes(file.type)) {
        setErrors((prevErrors) => {
          const newError = {
            ...prevErrors,
            [activeStepKey]: {
              ...prevErrors[activeStepKey],
              [field]: file.name + ' file type is not supported',
            },
          };

          return newError;
        });

        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }


      if (file.size > (1024 * 1024) * 10) {
        setErrors((prevErrors) => {
          const newError = {
            ...prevErrors,
            [activeStepKey]: {
              ...prevErrors[activeStepKey],
              [field]: file.name + ' size should be less than 10mb',
            },
          };

          return newError;
        });

        hasError = true; // Set hasError to true if there's an error
        break; // Exit the loop early if there's an error
      }

      formData.append('files', file);
    }

    if (!hasError && formData.has('files')) { // Proceed with upload only if there are no errors
      const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading, field);
      if (response && response.data && response.data.success) {
        setFields((prevData) => {
          return {
            ...prevData,
            [activeStepKey]: {
              ...prevData[activeStepKey],
              ['files']: prevData[activeStepKey]['files'] ? [...prevData[activeStepKey]['files'], ...response.data.files] : response.data.files
            },
          };
        });

      }
    }
  };

  /** -------- Handle Certificate Remove ------------ */
  const handleRemoveCertificate = (index, field) => {
    const updatedCertificates = [...fields[steps[activeStep].key][field]];
    updatedCertificates.splice(index, 1);
    setFields((prevData) => {
      return {
        ...prevData,
        ['certificates']: {
          ...prevData['certificates'],
          ['files']: updatedCertificates
        },
      };
    });
  };


  /** -------- Handle Certificate Fields Input  ------------ */
  const handleCertificatesInputChange = (event, index, field) => {
    const { name, value } = event.target;
    let files = fields[steps[activeStep].key]['files']
    let file = files[index];
    if (field === 'last_updated') {
      if (file[field]) {
        file[field][name] = value
      } else {
        file.last_updated = { month: null, year: null }
        file.last_updated[name] = value
      }
    } else {
      file[name] = value
    }

    files[index] = file
    setFields((prevData) => {
      return {
        ...prevData,
        ['certificates']: {
          ...prevData['certificates'],
          ['files']: files
        },
      };
    });
  }


  /** ------- File Viewer ------ */
  const handleShowFileView = (file) => {
    setCertificateToShow(showFileViewer ? null : file);
    setShowFileViewer(!showFileViewer);
  }


  /** ------- Input Handler ------- */
  const handleInputChange = async (e, activeStepKey, selectedOption) => {
    // console.log( e, activeStepKey, selectedOption)
    const { name, value, checked } = e.target;
    // console.log( name, value );
    setFields((prevData) => {
      const newState = {
        ...prevData,
        [activeStepKey]: {
          ...prevData[activeStepKey],
          [name]: ['terms'].includes(name)
            ? checked
            : ['disclaimer_policy', 'is_virtual'].includes(name)
              ? JSON.parse(value)
              : value,
        },
      };

      // Conditionally set `service_type` if the name is `bbservice`
      if (name === "bbservice" && selectedOption?.service_type) {
        newState[activeStepKey].service_type = selectedOption.service_type;
        newState[activeStepKey]['category'] = '';
        // If the `service_type` is 'service', set `amount_type` to 'fixed'
        if (selectedOption.service_type === 'service') {
          newState[activeStepKey].amount_type = 'fixed';
        }
      } else if (activeStepKey === 'preferences' && name === 'language') {
        newState[activeStepKey]['language'] = value.map(v => languages.find(lang => lang.value === v));
      } else if (activeStepKey === 'business' && (name.startsWith('address.'))) {
        const addressKey = name.split('.')[1]; // Extract the specific address key (e.g., 'street', 'city')
        newState[activeStepKey]['address'] = {
          ...newState[activeStepKey]['address'],
          [addressKey]: value, // Update the specific address property
        };

        // Remove `address.<key>` from top-level fields if present
        delete newState[activeStepKey][name];
        // if(name === 'address'){
        //   newState[activeStepKey]['address'] = value;
        // }else{
        // }
      }

      return newState;
    });
  }

  const renderMainHeadingAndDescription = (label, description) => {
    if (activeSubStep > -1) return null; // Do not render if on a substep
    return (
      <div style={{ flex: 1 }}>
        <p style={{ fontSize: '28px', color: 'black' }}>
          {label}
        </p>
        {description &&
          description.split('\n').map((line, index) => (
            <p key={index} style={{ fontSize: '18px', color: 'black', margin: '10px 0 0 0' }}>
              {line.trim() === '' ? <br /> : line}
            </p>
          ))}
      </div>
    );
  };

  /** ------- Render Fields Description -------- */
  const renderSubStepDescription = (label, description) => {
    if (!description) return null;
    return (
      <div className='mt-2 mb-3'>
        {description.split('\n').map((line, index) => (
          <p
            key={index}
            style={{ fontSize: '16px', margin: 0 }}
          // className="text-center"
          >
            {line.trim() === '' ? <br /> : line}
          </p>
        ))}
      </div>
    );
  };



  const displayError = (activeStepKey, field) => {
    const _error = errors[activeStepKey]?.[field] || null
    if (!_error) return null;

    return <Typography variant="body2" color="error" className='mt-1'>{_error}</Typography>
  }


  const handleUpdateCertificates = (data) => {
    setFields((prevData) => {
      const newState = {
        ...prevData,
        ['certificates']: {
          ...prevData['certificates'],
          ['files']: data['files']
        },
      };

      return newState;
    })
  }


  const renderDynamicSelectOptions = (question, option, optionIndex) => {
    var label = option.label
    var value = option

    if (['race'].includes(question.key)) {
      label = option.name
    }

    if (['amount_type', 'race', 'language'].includes(question.key)) {
      value = option.value
    }


    if (['bbservice', 'category'].includes(question.key)) {
      value = option._id
      label = option.name
    }

    return (
      <MenuItem
        key={optionIndex}
        value={value}
        {...(question.key === 'bbservice' ? { 'data-service-type': option.service_type } : {})}
      >
        {label}
      </MenuItem>
    )
  }

  const renderDynamicSelectedValue = (question, selected) => {
    let selectedChip = (label, index) => <Chip key={index} label={label} />

    if (question.isMulti) {
      return (
        <div
          style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
        >
          {selected.map((item, itemIndex) => {
            var label = item
            if (['language'].includes(question.key)) {
              label = languages.find(lang => lang.value === item)?.label
            } else {
              label = typeof item === 'string' ? item : item.label
            }

            return selectedChip(label, itemIndex)
          })}
        </div>
      )
    } else {
      // Single
      if (['race'].includes(question.key)) {
        return race.find((r) => r.value === selected)?.name
      } else if (['bbservice'].includes(question.key)) {
        return selectedBBService.find(bbs => bbs._id === selected)?.name
      } else if (['category'].includes(question.key)) {
        return specialties.find(s => s._id === selected)?.name
      } else if (['amount_type'].includes(question.key)) {
        return question.options.find(at => at.value === selected)?.label
      } else {
        return selected?.label || selected
      }
    }
  }

  /** -------- Select Field Input Value Handler --------- */
  const getSelectInputValue = (sectionData, question) => {
    if (question.key === 'language') {
      return sectionData['language']?.map(la => la.value);
    }

    return sectionData[question.key] || (question.isMulti ? [] : '')
  }


  const checkEmailExistAndRegister = async (data) => {
    // console.log( data);
    try {
      setIsStepLoading(true);
      const response = await API.apiPostUrl('auth', '/seller/register', data);
      setIsStepLoading(false);
      if (response.data && response.data.success) {
        setUser(response.data.data);
        saveSignupUserId(response.data.data._id)
        return true
      } else {
        setAlert({ show: true, variant: 'error', message: response.data.message });
        setActiveStep(1)
        setActiveSubStep(0)
        return false
      }
    } catch (error) {
      setAlert({ show: true, variant: 'error', message: error?.response?.data?.message || `Unable to proceed your request, try again later` })
      setActiveStep(1)
      setActiveSubStep(0)
      setIsStepLoading(false);
      return false
    }
  }

  // console.log('Fields', fields);



  const handleInputChangeSpecialtyField = (index, field, value) => {
    const updatedRows = [...fields.services];

    if (['max_capacity', 'amount'].includes(field) && value !== '') {
      if (!numericValidation(value)) {
        return;
      }
    }

    if (field === 'bbservice') {
      const __service = specialties.find(sp => sp.bbservice._id.toString() === value.toString())
      // console.log(__service);

      updatedRows[index]['service_type'] = __service.bbservice.service_type
      updatedRows[index]['amount_type'] = __service.bbservice.service_type === 'service' ? 'fixed' : '';
      updatedRows[index]['amount'] = ''
      updatedRows[index]['category'] = ''
    }


    updatedRows[index][field] = value;

    updatedRows[index]['updated'] = true

    setFields((prevData) => {
      const newState = {
        ...prevData,
        ['services']: [...updatedRows],
      };

      return newState;
    })
  };


  // const isLastRowIncomplete = () => {
  //   const lastRow = fields[rows.length - 1];
  //   return Object.values(lastRow).some((field) => field === '');
  // };



  const handleAddRow = () => {
    let _specialtyfields = { ...specialtyFields }
    _specialtyfields.manual = true
    setFields((prevData) => {
      const newState = {
        ...prevData,
        ['services']: [...prevData.services, { ..._specialtyfields }],
      };

      return newState;
    })
  };



  const handleRemoveRow = (index) => {
    const updatedRows = fields.services.filter((_, i) => i !== index);
    setFields((prevData) => {
      const newState = {
        ...prevData,
        ['services']: [...updatedRows],
      };

      return newState;
    })

  };


  const renderSpecialties = (_row) => {
    const _categories = specialties.find(s => s.bbservice._id.toString() === _row.bbservice.toString());

    if (!_categories) {
      return null; // If no matching specialties are found, return nothing
    }

    return (
      <>
        <option>Select</option>
        {
          _categories.categories.map((_category) => (
            <option key={_category._id} value={_category._id}>
              {_category.name}
            </option>
          ))
        }
      </>
    );
  }

  const renderAmountType = (row) => {
    const _options = [
      { label: 'Flat', value: 'fixed' },
      { label: 'Hourly', value: 'hour' }
    ]

    return (
      <>
        <option value={''}>Select</option>

        {_options.map((op) => (
          <option key={op.value} value={op.value} disabled={row.service_type === 'service'}>
            {op.label}
          </option>
        ))}
      </>
    );
  }

  return (
    <Box sx={{
      maxWidth: '100%',
      width: '100%',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      // justifyContent: 'space-between'
    }}>
      <Stepper style={{ width: '100%', flexWrap: 'wrap' }} activeStep={activeStep} alternativeLabel>
        {steps.map((step, stepIdx) => (
          <Step key={step.label}>
            <StepLabel key={stepIdx}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {renderMainHeadingAndDescription(steps[activeStep].label, steps[activeStep].description)}
      {(activeSubStep > -1 && steps[activeStep].subSteps && steps[activeStep].subSteps.length > 1) && (

        <div style={{ display: 'block', }}>
          <div style={{ marginTop: '16px' }}>
            {/* { true&& ( */}
            <Box sx={{ marginY: 2 }} >
              <Stepper activeStep={activeSubStep} alternativeLabel>
                {steps[activeStep].subSteps.map((subStep, subStepIdx) => (
                  <Step
                    key={subStepIdx}
                  >
                    <StepLabel key={`${subStep.key}-label-${subStepIdx}`}></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {/* // )} */}
          </div>
          {(alert && alert.show) && <Alert className='mt-2 mb-2' variant="filled" severity={alert.variant} >{alert.message}</Alert>}
        </div>
      )}

      {activeSubStep > -1 &&
        <div style={{ minHeight: '200px', flex: 1, marginTop: '2%' }}>
          {steps[activeStep].subSteps && (
            <Grid container spacing={2}>
              {steps[activeStep].subSteps[activeSubStep].fields.map((field) => (
                <Grid
                  item
                  xs={12}

                  sm={field.type === 'textarea' ? 12 : 12}
                  key={field.key}
                >
                  {renderSubStepDescription(field?.label, field?.description || null)}

                  {['email', 'password'].includes(field.type) && (
                    <TextField
                      fullWidth
                      id={field.key}
                      type={field.type}
                      label={field.label}
                      name={field.key}
                      value={fields[steps[activeStep].key][field.key]}
                      onChange={(e) => user ? null : handleInputChange(e, steps[activeStep].key)}
                      disabled={field.disabled || user} // disable if user is saved on DB
                    />
                  )}

                  {field.type === 'text' && (
                    <TextField
                      fullWidth
                      id={field.key}
                      label={field.label}
                      name={field.key}
                      value={fields[steps[activeStep].key][field.key]}
                      onChange={(e) => handleInputChange(e, steps[activeStep].key)}
                      disabled={field.disabled}
                    />
                  )}

                  {field.type === 'textarea' && (
                    <TextField
                      fullWidth
                      id={field.key}
                      multiline
                      rows={4}
                      label={field.label}
                      name={field.key}
                      value={fields[steps[activeStep].key][field.key]}
                      onChange={(e) => handleInputChange(e, steps[activeStep].key)}
                      disabled={field.disabled}
                    />
                  )}

                  {field.type === 'select' && (
                    <FormControl fullWidth margin="normal" style={{ width: '100%' }}>
                      <InputLabel id={field.key}>{field.label}</InputLabel>
                      <Select
                        labelId={field.key}
                        label={field.label}
                        onChange={(e) => {
                          var selectedOption = null;

                          // Apply selectedOption logic only for `bbservice`
                          if (field.key === 'bbservice') {
                            selectedOption = field.options.find((opt) => opt._id === e.target.value);
                          }

                          handleInputChange(e, steps[activeStep].key, selectedOption)
                          // Force close the dropdown after selection
                          setSelectOpen(false);
                        }}

                        name={field.key}
                        value={
                          getSelectInputValue(fields[steps[activeStep].key], field)
                        }

                        multiple={!!field.isMulti} // Ensure `multiple` is always a boolean

                        renderValue={(selected) => renderDynamicSelectedValue(field, selected)}
                        // {...(question.key === 'amount_type' && sectionData['service_type'] === 'service' ? { disabled: true } : {})}
                        open={selectOpen} // Controlled open state
                        onOpen={() => setSelectOpen(true)} // Open the dropdown
                        onClose={() => setSelectOpen(false)} // Close the dropdown
                        disabled={field.disabled}
                      >
                        {/* {field.options.map((option, optionIndex) => (
                        <MenuItem key={optionIndex} value={option.value}>{option.label}</MenuItem>
                      ))} */}

                        {field.options.map((option, optionIndex) => (
                          renderDynamicSelectOptions(field, option, optionIndex)
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {field.type === 'radio' && (
                    <div className=''>
                      <label>{field.label}</label>
                      <div  >
                        {field.options.map((option) => (
                          <FormControlLabel
                            key={option}
                            // labelPlacement={field.label}
                            disabled={field.disabled}
                            control={
                              <Radio
                                name={field.key}
                                onChange={(e) => handleInputChange(e, steps[activeStep].key)}
                                checked={
                                  String(fields[steps[activeStep].key][field.key]) === String(option)
                                }
                                value={option}

                              />
                            }
                            label={
                              ['true', true, 'yes', 'Yes'].includes(option) ? 'Yes' : 'No'
                            }
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {(field.type === 'rows' && Array.isArray(fields[steps[activeStep].key])) &&
                    <>
                      {specialtyLoading
                        ? <LoadingView />
                        : (
                          <div style={{ textAlign: 'center' }}>
                            <label>{field.label}</label>

                            <Row>
                              {fields[steps[activeStep].key]?.map((row, index) => (
                                <React.Fragment key={index}>
                                  <Row style={{ width: '100%' }}>
                                    {/* BB Service */}
                                    <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Category</Form.Label>
                                        <Form.Control
                                          disabled={!row.manual || row._id}
                                          as="select"
                                          value={row.bbservice || ''}
                                          onChange={(e) =>
                                            handleInputChangeSpecialtyField(index, 'bbservice', e.target.value)
                                          }
                                          placeholder="Category"
                                        >
                                          <option>Select</option>
                                          {specialties.map((service) => (
                                            <option key={service.bbservice._id} value={service.bbservice._id}>
                                              {service.bbservice.name}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>

                                    {/* Specialty */}
                                    <Col xs={12} sm={12} md={3} lg={3} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Specialty</Form.Label>
                                        <Form.Control
                                          disabled={!row.manual || row._id}
                                          as="select"
                                          value={row.category || ''}
                                          onChange={(e) =>
                                            handleInputChangeSpecialtyField(index, 'category', e.target.value)
                                          }
                                          placeholder="Specialty"
                                        >
                                          {renderSpecialties(row)}
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>

                                    {/* Pricing */}
                                    <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Pricing</Form.Label>
                                        <Form.Control
                                          as="select"
                                          value={row.amount_type}
                                          onChange={(e) =>
                                            handleInputChangeSpecialtyField(index, 'amount_type', e.target.value)
                                          }
                                          placeholder="Pricing"
                                          disabled={row.service_type === 'service'}
                                        >
                                          {renderAmountType(row)}
                                        </Form.Control>
                                      </Form.Group>
                                    </Col>

                                    {/* Amount */}
                                    <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <InputGroup>
                                          <InputGroup.Prepend>
                                            <InputGroup.Text>$</InputGroup.Text>
                                          </InputGroup.Prepend>
                                          <Form.Control
                                            type="text"
                                            value={row.amount}
                                            onChange={(e) =>
                                              handleInputChangeSpecialtyField(index, 'amount', e.target.value)
                                            }
                                            placeholder="Amount"
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>

                                    {/* Capacity */}
                                    <Col xs={12} sm={12} md={2} lg={2} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Capacity</Form.Label>
                                        <Form.Control
                                          type="text"
                                          value={row.max_capacity}
                                          onChange={(e) =>
                                            handleInputChangeSpecialtyField(index, 'max_capacity', e.target.value)
                                          }
                                          placeholder="Capacity"
                                        />
                                      </Form.Group>
                                    </Col>

                                    {/* Virtual Class */}
                                    <Col xs={12} sm={12} md={1} lg={1} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Virtual</Form.Label>
                                        <Form.Check
                                          type="checkbox"
                                          checked={row.is_virtual}
                                          onChange={(e) =>
                                            handleInputChangeSpecialtyField(index, 'is_virtual', e.target.checked)
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  {/* Description */}
                                  <Row style={{ width: '100%' }}>
                                    <Col xs={12} style={{ padding: '5px' }}>
                                      <Form.Group>
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={4}
                                          value={row.desc}
                                          onChange={(e) =>
                                            handleInputChangeSpecialtyField(index, 'desc', e.target.value)
                                          }
                                          placeholder="Describe Specialty"
                                          style={{ border: '1px solid #ced4da', borderRadius: '.25rem' }} // Ensure the border is visible
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  {/* Separator */}
                                  <Row style={{ width: '100%' }}>
                                    <Col xs={12} style={{ padding: '5px' }}>
                                      <div style={{ height: 10, width: '100%', backgroundColor: '#d1eff9', margin: '20px 0' }} />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              ))}
                            </Row>


                            {/* Add Row Button */}
                            {/* <Button
                              variant="contained"
                              color="lightBlue"
                              onClick={handleAddRow}
                              // disabled={isLastRowIncomplete()}
                              className="mt-3">
                              Add Row
                            </Button> */}
                          </div>
                        )}
                    </>
                  }

                  {field.key === 'profilePic' && (
                    <div style={{ textAlign: 'center' }}>
                      <label>{field.label}</label>
                      <div style={{ marginBottom: '20px' }}>
                        <input
                          // hidden
                          id="profile__upload"
                          type={field.type}
                          name={field.key}
                          accept={'image/*'}
                          disabled={fileLoading.profilePic}
                          onChange={(e) =>
                            fileLoading.profilePic
                              ? null
                              : handleProfilePicChange(e, steps[activeStep].key)
                          }
                        />
                      </div>
                      <div className='signup--step--profile--picture'>
                        <label htmlFor="profile__upload" className="text-center">
                          {fileLoading.profilePic
                            ? <p>Loading...</p>
                            : <img
                              className="profile_picture"
                              loading="lazy"
                              style={{ height: '250px', width: '100%' }}
                              src={
                                (fields[steps[activeStep].key][field.key] && fields[steps[activeStep].key][field.key].url)
                                  ? fields[steps[activeStep].key][field.key].url
                                  : "/images/profile.png"
                              }
                            />
                          }
                        </label>
                        <input
                          hidden
                          id="profile__upload"
                          type={field.type}
                          name={field.key}
                          accept={'image/*'}
                          disabled={fileLoading.profilePic}
                          onChange={(e) =>
                            fileLoading.profilePic
                              ? null
                              : handleProfilePicChange(e, steps[activeStep].key)
                          }
                        />
                        {uploadProgress[field.key] > 0 && <p className="upload-progress-status">Uploaded {uploadProgress[field.key]}%</p>}
                      </div>
                      {displayError(steps[activeStep].key, field.key)}
                    </div>
                  )}

                  {/* {(field.key === 'disclaimer_policy' && ['true', true, 'yes', 'Yes'].includes(fields[steps[activeStep].key][field.key])) && (
                    <Form.Group className="col-sm-12 text-center waiver-policy-document" >
                      <Form.Label>Waiver Policy Document</Form.Label>
                      {fields[steps[activeStep].key]['disclaimer_policy_document'] ? (
                        <p className="cert__title mb-3 d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
                          <span className="mr-2">
                            {fields[steps[activeStep].key]['disclaimer_policy_document'].name}{' '}
                            {fields[steps[activeStep].key]['disclaimer_policy_document'].last_updated && (
                              <strong>
                                {`(Last Updated: ${momentFormat.dateTimeFormat(
                                  fields[steps[activeStep].key]['disclaimer_policy_document'].last_updated
                                )})`}
                              </strong>
                            )}
                          </span>

                          <span
                            className="ml-auto badge-success px-2"
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleShowFileView(fields[steps[activeStep].key]['disclaimer_policy_document'])}
                          >
                            View
                          </span>
                          <span
                            className="cerificate--files--close badge-danger ml-1 px-1"
                            onClick={() => handleWaiverDocumentRemove(steps[activeStep].key)}
                            style={{ cursor: 'pointer' }}
                          >
                            <IoClose />
                          </span>
                        </p>
                      ) : (
                        <>
                          <input
                            type="file"
                            id="upload--file"
                            hidden
                            name={'disclaimer_policy_document'}
                            onChange={(e) =>
                              fileLoading.disclaimer_policy_document
                                ? null
                                : handleWaiverDocumentChange(e, steps[activeStep].key)
                            }
                            accept={['application/pdf']}
                            disabled={fileLoading.disclaimer_policy_document}
                          />
                          <label htmlFor="upload--file" className='waiver--upload--file'>
                            {fileLoading.disclaimer_policy_document ? (
                              <>
                                {' '}
                                {uploadProgress.disclaimer_policy_document > 0 &&
                                  `Uploaded ${uploadProgress.disclaimer_policy_document}%`}{' '}
                                Please Wait...{' '}
                              </>
                            ) : (
                              <>
                                {' '}
                                <AiOutlinePlus /> Upload Waiver Policy Document in ( pdf ),
                                must be less than 10mb in size
                              </>
                            )}
                          </label>
                          {displayError(steps[activeStep].key, field.key)}
                        </>
                      )}
                    </Form.Group>
                  )} */}

                  {(field.key === 'files') &&
                    (
                      <div>
                        <input
                          type="file"
                          id="upload--file"
                          hidden
                          multiple
                          onChange={(e) => fileLoading.files ? null : handleCertificateUpload(e, steps[activeStep].key, 'files')}
                          accept={['image/jpeg', 'application/pdf', 'image/png', 'image/jpg']}
                          disabled={fileLoading.files}
                        />
                        <label
                          className='signup--step--upload--certificate'
                          htmlFor="upload--file"
                        >
                          {fileLoading.files
                            ? <>{uploadProgress.files > 0 && `Uploaded ${uploadProgress.files}%`} Please Wait....</>
                            : <> <AiOutlinePlus /> Upload Certificate (JPG, PNG, or PDF, Max 10MB)</>
                          }
                        </label>
                        {displayError(steps[activeStep].key, field.key)}
                        {/* Note about certificate deletion */}
                        <small style={{ display: 'block', marginTop: '10px', color: '#6c757d' }}>
                          Note: Certificates cannot be deleted through the website. If you'd like to remove it, please contact us at <a href="mailto:hello@mybirthbridge.com">hello@mybirthbridge.com</a> for assistance.
                        </small>

                        {/* ------- Certificate Input fields -------- */}
                        {(fields[steps[activeStep].key][field.key] && fields[steps[activeStep].key][field.key].length > 0) &&
                          <div
                            className="certificate-files mt-2 mb-2"
                            id="certificate-files"
                          >
                            {fields[steps[activeStep].key][field.key].map((file, fileIndex) => {
                              return (
                                <Files
                                  key={fileIndex}
                                  file={file}
                                  SellerProfile={fields[steps[activeStep].key]}
                                  setSellerProfile={(data) => handleUpdateCertificates(data)}
                                  filesIndex={fileIndex}
                                  handleShowFileView={handleShowFileView}
                                  handleRemoveFile={handleRemoveCertificate}
                                  handleFileInputChange={handleCertificatesInputChange}
                                  bbServices={bbServiceList || []}
                                  productError={errors?.products?.[fileIndex]}
                                  certificateTimeError={errors?.certificateTime?.[fileIndex]}
                                  setProductErrors={(productIndex, field) => console.log('Error On Certificates', productIndex)}
                                />
                              )
                            })}
                          </div>
                        }
                      </div>
                    )
                  }

                  {(field.key === 'address') &&
                    <StepAddress
                      handleChangeInput={handleInputChange}
                      seller={fields[steps[activeStep].key]}
                      error={{}}
                      setError={(data) => console.log('error', data)}
                    />
                  }

                  {field.type === 'checkbox' && (

                    <Form.Group className="form--checkbox mb-0">
                      <Form.Check
                        type="checkbox"
                        name='terms'
                        label={field.label}
                        checked={fields[steps[activeStep].key][field.key]}
                        onChange={(e) => user ? null : handleInputChange(e, steps[activeStep].key)}
                        disabled={user}
                      />
                    </Form.Group>
                  )}

                </Grid>
              ))}
            </Grid>
          )}
        </div>}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>

        <Button variant="lightText" color='greyTxt' disabled={activeStep === 0 && activeSubStep === 0} onClick={handleBack}>
          Back
        </Button>

        {/* {(activeStep === steps.length - 1 && activeSubStep === 0) &&
          <Button
            variant='contained'
            color='lightBlue'
            onClick={() => handleAddNewSpecialty()}
          >
            Add Another Specialty
          </Button>} */}

        {activeStep === steps.length - 1 && activeSubStep === steps[activeStep].subSteps.length - 1
          ? (
            <Button
              variant="contained"
              color="lightBlue"
              onClick={() => saveFormDataOnDB(steps[activeStep].key, steps[activeStep].subSteps, true)}>
              {isStepLoading ? 'Loading...' : 'Submit'}
            </Button>
          ) : (

            <Button
              variant="contained"
              color="lightBlue"
              onClick={isStepLoading ? null : handleNext}
              disabled={isNextDisabled || isStepLoading}
            >
              {isStepLoading ? 'Loading...' : 'Next'}
            </Button>
          )}
      </Box>

      {
        showImageCrop &&
        <ImageCropModal
          show={showImageCrop}
          handleClose={() => setShowImageCrop(false)}
          image={profileImage.original}
          handleSave={handleSaveCropImage}
        />
      }

      {showFileViewer &&
        <FileViewModal
          show={showFileViewer}
          onHide={handleShowFileView}
          type={certificateToShow.file_type}
          url={certificateToShow.url}
          title={certificateToShow.name}
        />
      }
    </Box>
  );
};

export default SignUpSteps;



// {fields[steps[activeStep].key]?.map((row, index) => (
//   <div key={index} style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center' }}>
//     {/* BB Service */}
//     <div style={{ margin: 3 }}>
//       <Form.Group>
//         <Form.Label>Category</Form.Label>
//         <Form.Control
//           disabled={!row.manual || row._id}
//           as="select"
//           value={row.bbservice || ''} // Make sure a default value is provided in case it's undefined
//           onChange={(e) =>
//             handleInputChangeSpecialtyField(index, 'bbservice', e.target.value)
//           }
//           placeholder="Category"
//         >
//           {/* Add options dynamically or statically, if needed */}
//           <option>Select</option>
//           {specialties.map((service) => (
//             <option key={service.bbservice._id} value={service.bbservice._id}>
//               {service.bbservice.name}
//             </option>
//           ))}
//         </Form.Control>
//       </Form.Group>
//     </div>

//     {/* Category */}
//     <div style={{ margin: 3 }}>
//       <Form.Group>
//         <Form.Label>Specialty</Form.Label>
//         <Form.Control
//           disabled={!row.manual || row._id}
//           as="select"
//           value={row.category || ''} // Default value if specialty is not selected
//           onChange={(e) =>
//             handleInputChangeSpecialtyField(index, 'category', e.target.value)
//           }
//           placeholder="Specialty"
//         >
//           {/* Add options dynamically or statically, if needed */}
//           {renderSpecialties(row)}
//         </Form.Control>
//       </Form.Group>
//     </div>

//     {/* Pricing */}
//     <div style={{ width: '100px', margin: 3 }}>
//       <Form.Group>
//         <Form.Label>Pricing</Form.Label>
//         <Form.Control
//           as={'select'}
//           value={row.amount_type}
//           onChange={(e) =>
//             handleInputChangeSpecialtyField(index, 'amount_type', e.target.value)
//           }
//           placeholder="Pricing"
//           disabled={row.service_type === 'service'}
//         >
//           {renderAmountType(row)}
//         </Form.Control>
//       </Form.Group>
//     </div>
//     {/* Amount */}
//     <div style={{ width: '100px', margin: 3 }}>
//       <Form.Group>
//         <Form.Label>Amount ($) </Form.Label>
//         <Form.Control
//           type="text"
//           value={row.amount}
//           onChange={(e) =>
//             handleInputChangeSpecialtyField(index, 'amount', e.target.value)
//           }
//           placeholder="Amount"
//         />
//       </Form.Group>
//     </div>

//     {row.service_type === 'class' &&
//       <>

//         <div style={{ width: '100px', margin: 3 }}>
//           <Form.Group>
//             <Form.Label>Capacity</Form.Label>
//             <Form.Control
//               type="text"
//               value={row.max_capacity}
//               onChange={(e) =>
//                 handleInputChangeSpecialtyField(index, 'max_capacity', e.target.value)
//               }
//               placeholder="Capacity"
//             />
//           </Form.Group>
//         </div>

//         <div>
//           <Form.Group>
//             <Form.Label>Virtual Class</Form.Label>
//             <Form.Check
//               type="checkbox"
//               checked={row.is_virtual}
//               onChange={(e) =>
//                 handleInputChangeSpecialtyField(index, 'is_virtual', e.target.checked)
//               }
//             // label="Virtual"
//             />
//           </Form.Group>
//         </div>
//       </>
//     }

//     <div style={{ width: '100px', margin: 3 }}>
//       <Form.Group>
//         <Form.Label> Description </Form.Label>
//         <Form.Control 
//           as={'textarea'}
//           // fullWidth
//           // multiline
//           rows={4}
//           cols={15}
//           value={row.desc}
//           onChange={(e) =>
//             handleInputChangeSpecialtyField(index, 'desc', e.target.value)
//           }
//           placeholder="Describe Specialty"
//         />
//       </Form.Group>
//     </div>  

//     {/* Remove Row Button */}
//     {/* <div xs="auto" className="d-flex align-items-end">
//       <Button
//         style={{ fontSize: 15 }}
//         // disabled={!isLastRowIncomplete()}
//         variant="danger"
//         onClick={() => handleRemoveRow(index)}
//       >
//         X
//       </Button>
//     </div> */}
//     <div style={{ height: 10, width: '100%', backgroundColor: '#d1eff9', margin: '20px 0px 20px 0px' }} />
//   </div>
// ))}
