import { useEffect, useState } from "react";
import { generateLetterSVG } from "../../utils/validations";

const PDFThumbnail = ({ pdfBase64, pdfUrl, title }) => {
    const [thumbnails, setThumbnails] = useState([]);

    useEffect(() => {
        if (![undefined, 'undefined', null, 'null', ''].includes(pdfBase64)) {
            generateThumbnails(pdfBase64);
        } else if (pdfUrl) {
            generateThumbnailsFromUrl(pdfUrl);
        }
    }, [pdfBase64, pdfUrl]);

    const generateThumbnails = (base64) => {
        const pdfBlob = base64ToBlob(base64);
        const fileReader = new FileReader();

        fileReader.onload = () => {
        const arrayBuffer = fileReader.result;
        // Dynamically load pdf.js script
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js';
        script.onload = () => {
            // Initialize pdf.js
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${window.pdfjsLib.version}/pdf.worker.min.js`;
            window.pdfjsLib.getDocument(arrayBuffer).promise.then((pdf) => {
            const pagesPromises = [];
            pagesPromises.push(pdf.getPage(1))

            Promise.all(pagesPromises).then((pages) => {
                const thumbnails = pages.map((page) => {
                const viewport = page.getViewport({ scale: 0.2 });
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };

                return page.render(renderContext).promise.then(() => {
                    return canvas.toDataURL('image/png');
                });
                });

                Promise.all(thumbnails).then((thumbnailUrls) => {
                setThumbnails(thumbnailUrls);
                });
            });
            });
        };
        document.body.appendChild(script);
        };

        fileReader.readAsArrayBuffer(pdfBlob);
    };

    // const generateThumbnailsFromUrl = (url) => {
    //     // Fetch PDF from URL and generate thumbnails
    //     fetch(url)
    //         .then(response => response.blob())
    //         .then(blob => {
    //             const fileReader = new FileReader();
    //             fileReader.onload = () => {
    //                 generateThumbnails(fileReader.result);
    //             };
    //             fileReader.readAsArrayBuffer(blob);
    //         })
    //         .catch(error => {
    //             console.error('Error fetching PDF:', error);
    //         });
    // };

    const generateThumbnailsFromUrl = (url) => {
        // Fetch PDF from URL and generate thumbnails
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    const arrayBuffer = fileReader.result;
                    const uint8Array = new Uint8Array(arrayBuffer);
                    const base64 = btoa(String.fromCharCode.apply(null, uint8Array));
                    generateThumbnails(base64);
                };
                fileReader.readAsArrayBuffer(blob);
            })
            .catch(error => {
                console.error('Error fetching PDF:', error);
            });
    };
    

    // const base64ToBlob = (base64) => {
    //     const byteString = atob(base64.split(',')[1]);
    //     const arrayBuffer = new ArrayBuffer(byteString.length);
    //     const byteArray = new Uint8Array(arrayBuffer);

    //     for (let i = 0; i < byteString.length; i++) {
    //     byteArray[i] = byteString.charCodeAt(i);
    //     }

    //     return new Blob([arrayBuffer], { type: 'application/pdf' });
    // };

    const base64ToBlob = (base64) => {
        if (typeof base64 !== 'string' || base64 === '') {
            throw new Error('Invalid base64 data');
        }
    
        const byteString = atob(base64);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const byteArray = new Uint8Array(arrayBuffer);
    
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([arrayBuffer], { type: 'application/pdf' });
    };
    

    return (
        <img
            loading='lazy'
            src={(thumbnails && thumbnails[0]) ? thumbnails[0] : generateLetterSVG(title)}
            alt={`Thumbnail ${1}`}
            style={{ marginRight: '10px', marginBottom: '10px' }}
        />
    );
};

export default PDFThumbnail;

