import React, { useState } from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import CommunityHeader from './CommunityHeader';
import PostsList from './PostsList';
import CreatePostModal from './CreatePostModal';
import CategoryFilter from './CategoryFilter';
import SearchBar from './SearchBar';
import SEO from "../../seo";

const CommunityContainer = () => {
    const theme = useTheme();
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [searchQuery, setSearchQuery] = useState('');
    const [posts, setPosts] = useState([]);

    const handlePostCreated = async (newPost) => {
        console.log('1. handlePostCreated triggered with new post:', newPost);
        setCreateModalOpen(false);
        
        const updatedPost = {
            ...newPost,
            likes: [],
            views: 0,
            replies: [],
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        };
        
        setPosts(prevPosts => [updatedPost, ...prevPosts]);
    };

    return (
        <Box
            sx={{
                flex: 1,
                backgroundColor: theme.palette.background.default,
                minHeight: '100vh',
                py: 4
            }}
        >
            <SEO
                title="Community Forum"
                description="Join our community forum to connect with birth professionals, share experiences, and engage in meaningful discussions about birth and postpartum care."
                url="/community"
                keywords="birth community, birth professionals forum, doula discussions, birth support community, pregnancy forum, postpartum discussions"
            />
            <Container maxWidth="lg">
                <CommunityHeader
                    onCreatePost={() => setCreateModalOpen(true)}
                />

                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    mb: 4,
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                    <CategoryFilter
                        selected={selectedCategory}
                        onSelect={setSelectedCategory}
                    />
                    <SearchBar
                        value={searchQuery}
                        onChange={setSearchQuery}
                    />
                </Box>

                <PostsList
                    category={selectedCategory}
                    searchQuery={searchQuery}
                    posts={posts}
                    setPosts={setPosts}
                />

                <CreatePostModal
                    open={isCreateModalOpen}
                    onClose={() => setCreateModalOpen(false)}
                    onPostCreated={handlePostCreated}
                />
            </Container>
        </Box>
    );
};

export default CommunityContainer;