import {
    GET_STATISTICS_SUCCESS,
    GET_STATISTICS_FAILED
} from "../actions/types";

const initialState = {
  stats: null,
  error: null
};

const statisticsReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_STATISTICS_SUCCESS :
            return {
                stats : action.payload,
                error: null
            }
        case GET_STATISTICS_FAILED : 
            return {
                error : action.payload ,
            }
       
        default: return state;
    }
};

export default statisticsReducer;