import React from 'react';
import { FaRegCopy } from "react-icons/fa";
import { Flip, toast } from 'react-toastify';

const ClipboardCopy = ({ textToDisplay, textToCopy }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(textToCopy);
        toast.success('Invite link copied to clipboard', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            theme: "colored",
            transition: Flip,
        });
    }
    
    return (
        <span>
            {textToDisplay && <strong style={{ color: '#b7b7b7'}}>{textToDisplay}</strong>}
            {' '}
            <FaRegCopy 
                style={{ cursor: 'pointer'}} 
                size={16} 
                onClick={handleCopy} 
                title='click to copy'
            />
        </span>
    );
};

export default ClipboardCopy;
