import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getSchedules } from '../../../redux/actions/schedule.action';
import { momentFormat } from '../../../utils/validations';
import BookedUsersModal from './bookedUsers';
import LoadingView from '../../LoadingView';

const Schedules = ({ startDate,  endDate }) => {
    const dispatch = useDispatch();
    const [ events, setEvents] = useState([]);
    const [ event, setEvent] = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const [loading, setLoading] = useState({ init: true, loadMore: false })
    /** ----------------------------[ Redux State ]------------------------------- */
    const scheduleState = useSelector(state => state.schedule);
    useEffect(() => {
        handleGetSchedules()
    }, [])

    const handleGetSchedules = () => {
        let payload = {}

        payload.service_type = 'class'

        if(startDate){
            payload.startDate = startDate
        }

        if(endDate){
            payload.endDate = endDate
        }

        dispatch(getSchedules(payload));
    }

   
    useEffect(() => { 
        if( scheduleState.error ){
          setLoading({ init: false, loadMore: false})
        }

        if( !scheduleState.error){
          if( scheduleState.get && scheduleState.get.success){
            setEvents([ ...scheduleState.get.schedules ]);
            setLoading({ init: false, loadMore: false})
          }
        } 
    }, [scheduleState]);

    const handleShowModal = (event) => {
        setShowModal(true);
        setEvent(event)
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setEvent(null);
    }

    const renderTable = () => {
        return (    
            <>
                <Table striped responsive>
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date/Time</th>
                            <th scope="col">Max Capacity</th>
                            <th scope='col'>Seats Booked</th>
                            <th scope="col">Families</th>
                        </tr>
                    </thead>
                    <tbody>
                        {events.map((event, eventIndex) => {
                            return (
                                <tr key={eventIndex}>
                                    <td>{event.title}</td>
                                    <td>
                                        <p>{momentFormat.formatWeekDate(event.start)}</p>
                                        <p>{momentFormat.timeFormat(event.start)}-{momentFormat.timeFormat(event.end)}</p>
                                    </td>
                                    <td>{event.max_capacity}</td>
                                    <td>{(event.bookedUsers && event.bookedUsers.length) ? event.bookedUsers.length : 0}</td>
                                    <td>
                                        {(event.bookedUsers && event.bookedUsers.length)
                                            ?   
                                                <Button 
                                                    onClick={() => handleShowModal(event)}
                                                >
                                                    View {event.bookedUsers.length}  
                                                </Button>
                                            :   'Not Available'
                                        }
                                       
                                    </td>
                                </tr>
                            )  
                        })}
                    </tbody>
                </Table>
            </>
        )
    }  

    return (
       <div className='schedules'>
            {loading.init
            ? <LoadingView/>
            : (events.length > 0) 
                ?   renderTable()
                :  <h5>No Record Available</h5>
            }
            {showModal && 
                <BookedUsersModal 
                    onHide={handleCloseModal}
                    event={event}
                    users={event.bookedUsers}
                    show={showModal}
                />
            }
       </div>
    )
} 

export default Schedules;