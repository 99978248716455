import React, { useEffect, useState } from "react";
import { getAllServices, resetReduxState } from "../../../redux/actions/services.action";
import {
    Container,
    Row,
    Table,
    Button,
    Accordion,
    Tooltip,
    OverlayTrigger,
    Form
} from "react-bootstrap";
import FileViewModal from "../../../commonComponents/FileViewer";
import LoadingView from "../../LoadingView";
import { formatPrice, momentFormat } from "../../../utils/validations";
import { Snackbar, Alert } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { BiEdit } from "react-icons/bi";
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/editStyle.css';
// import '../../../App.css';
import API from "../../../helpers/api";
import Swal from "sweetalert2";

function ServicesContent() {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ServicesData, setServicesData] = useState([]);
    const [hideOnStaff, setHideOnStaff] = useState(true);
    const [showFileViewer, setShowFileViewer] = useState(false);
    const [certificateToShow, setCertificateToShow] = useState(null);
    const [copiedService, setCopiedService] = useState('');
    /** ---- Status Update Loader ---- */
    const [statusLoader, setStatusLoader] = useState(false);


    const serviceState = useSelector(state => state.services);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { staffId } = useParams();

    const fetchServices = () => {
        dispatch(getAllServices(staffId ? staffId : null));
    };

    useEffect(() => {
        fetchServices();
        setHideOnStaff(staffId ? false : true);
        return () => {
            dispatch(resetReduxState());
        };
    }, [staffId]);

    useEffect(() => {
        if (!serviceState.error && serviceState.servicesDetails) {
            setServicesData(serviceState.servicesDetails);
            setLoading(false);
        }

        if (serviceState.error) {
            setLoading(false);
        }
    }, [serviceState]);

    const handleCopyLink = (link, serviceName) => {
        navigator.clipboard.writeText(link);
        setCopiedService(serviceName);
        setOpenSnackbar(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleShowFileView = (file) => {
        setCertificateToShow(showFileViewer ? null : file);
        setShowFileViewer(!showFileViewer);
    };

    const handleUpdateStatus = async (e, serviceId) => {
        Swal.fire({
            title: `Are you sure you want's to update status?`,
            // text: `Congratulations on setting up your services for the first time! You’re almost done! Next, please head over to your scheduler to complete your availability for the families!`,
            icon: 'warning',
            confirmButtonText: 'Yes',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            customClass: {
                confirmButton: 'btn btn-primary',
            },
            preConfirm: () => {
                setStatusLoader(serviceId);
                return API.apiPutUrl('services', `/status/${serviceId}`, { isActive: !e.target.checked}).then(res => {
                    fetchServices();
                    if(res.data && res.data.success){
                        Swal.fire(res.data.message,'','success')
                    }else{
                        Swal.fire(res.data.message,'','error')
                    }
                }).catch(err => {
                    Swal.fire(err?.response?.data?.message,'','error');
                    
                }).finally(() => {
                    setStatusLoader('');
                })
            }
        });
    }

    const renderTable = () => {
        return (
            <div style={{ width: '100%', overflowX: 'auto' }}>
                <Table striped responsive="sm" style={{ minWidth: '1000px' }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Category Name</th>
                            <th>Specialty Name</th>
                            <th>Copy Link to Schedule</th>
                            <th>Specialty Description</th>
                            <th>Amount</th>
                            <th>Virtual Appointment</th>
                            <th>Waiver Disclaimer Policy</th>
                            <th>Max Capacity</th>
                            <th>Is Active</th>
                            <th>Created</th>
                            {hideOnStaff && <th className="edit-service">Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {ServicesData.map((item, ind) => (
                            <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td style={{ minWidth: '300px' }}>{item?.bbservice?.name}</td>
                                <td style={{ minWidth: '400px' }}>{item?.category?.name || 'N/A'}</td>
                                <td style={{ minWidth: '300px' }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id={`tooltip-${ind}`}>Copy Link to Schedule</Tooltip>}
                                    >
                                        <Button
                                            variant="primary"
                                            onClick={() => handleCopyLink(`${window.location.host}/book-appointments/${item._id}`, item?.bbservice?.name)}
                                        >
                                            Copy Link
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                                <td style={{ minWidth: '400px' }}>{item.desc ? item.desc : item?.bbservice?.description}</td>
                                <td>${formatPrice(item.amount)}</td>
                                <td>{item.is_virtual ? 'Virtual' : 'In Person'}</td>
                                <td>{item.disclaimer_policy ? <Button onClick={() => handleShowFileView(item.disclaimer_policy_document)}>View</Button> : 'No'}</td>
                                <td>{item.max_capacity || 'N/A'}</td>
                                <td>
                                    <Form.Check 
                                        className="cursor-pointer"
                                        label={item.is_active ? 'Active': 'Inactive'} 
                                        checked={item.is_active} 
                                        onChange={(e) => handleUpdateStatus(e, item._id)}
                                        onLoadStart={''}
                                    /> 
                                </td>
                                <td style={{ minWidth: '200px' }}>{momentFormat.formatWeekDate(item?.createdAt)}</td>
                                {hideOnStaff && (
                                    <td className="edit-service">
                                        <span onClick={() => navigate(`/edit-service/${item._id}`)}>
                                            <BiEdit />
                                        </span>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </Table>

                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '500px', maxWidth:'100%' }}>
                        {`The link to your ${copiedService} schedule has been copied. You can share this with your customers or display it on your website.`}
                    </Alert>
                </Snackbar>



                {/* <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        <p>{`You copied the link to your ${copiedService} schedule to give to your customers or put on your website`}</p>
                        <p>{`Feel free to put this link on your own website as well 😊`}</p>
                    </Alert>
                </Snackbar> */}
            </div>
        );
    };

    return (
        <Container fluid>
            <Row>
                <div className="all-servicess-Wrap p-0">
                    <h2>{hideOnStaff ? 'Specialties' : 'Staff Specialties'}</h2>
                    {hideOnStaff && <Button variant="primary" onClick={() => navigate('/add-service')}>Add Specialty</Button>}
                    {!hideOnStaff && <Button className="mr-2" onClick={() => navigate('/staff')}>View Staff</Button>}
                </div>
                {loading
                    ? <LoadingView />
                    : <Accordion defaultActiveKey="0" className="services-main-wraper">
                        {ServicesData && ServicesData.length > 0
                            ? renderTable()
                            : <h4>No Specialties Found</h4>
                        }
                    </Accordion>
                }
                {showFileViewer &&
                    <FileViewModal
                        show={showFileViewer}
                        onHide={handleShowFileView}
                        type={certificateToShow.file_type}
                        url={certificateToShow.url}
                        title={certificateToShow.name}
                    />
                }
            </Row>
        </Container>
    );
}

export default ServicesContent;
