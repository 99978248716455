import React, { useEffect, useState,  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scheduler from './scheduler';
import { isAuth } from '../../../helpers/auth';
import { getAllServices } from '../../../redux/actions/services.action';
import { getPaymentMode, getStripeAccount } from '../../../redux/actions/stripe.action';

const ServiceScheduler = () => {
    const dispatch = useDispatch();
    const [ services, setServices ] = useState([]);
    const [ payment_mode, setPaymentMode ] = useState(null); 
    const [ isStripeConnected, setConnected ] = useState(false);
    const loggedUser = isAuth();
    /** --- Redux State --- */
    const serviceState = useSelector(state => state.services);
    const paymentMode = useSelector(state => state.paymentMode);
    const stripe = useSelector(state => state.stripe);
    const userMeta = useSelector((state) => state.user.userMetaDetail);


    useEffect(() => {
        dispatch(getAllServices());
        dispatch(getPaymentMode());
        dispatch(getStripeAccount());
    }, [])


    useEffect(() => {
        if(!serviceState.error && serviceState.servicesDetails){
            setServices( serviceState.servicesDetails)
        }
    }, [serviceState]);

    useEffect(( ) => {
        if( !paymentMode.error){
            if(paymentMode.get && paymentMode.get.success){
                if(paymentMode.get.paymentMode){
                    setPaymentMode(paymentMode.get.paymentMode.type);
                }
            }
        }
    }, [paymentMode]);

    useEffect(() => {
        if(!stripe.error){
            if( stripe.account && stripe.account.success){
                if(stripe.account.account){
                    setConnected(stripe.account.account.status);
                }       
            }
        }
    }, [stripe])

    return (
        <Scheduler 
            services={services.filter(s => s.is_active === true )}
            loggedUser={loggedUser} 
            paymentMode={payment_mode}
            isStripeConnected={isStripeConnected}
            userMeta={userMeta}
        /> 
    )   
}

export default ServiceScheduler;
