import { get } from 'lodash';
import API from '../../helpers/api';
import { GET_TIME_SLOTS_SUCCESS, GET_TIME_SLOTS_FAILED, GET_AVAILABLE_DATES_SUCCESS, GET_AVAILABLE_DATES_FAILED, REFRESH_TIMESLOT, GET_TIMEZONE_SUCCESS, GET_TIMEZONE_FAILED } from './types';

/**
 * @param {*} 
 * @returns 
*/


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

export const getAvailableDates = (serviceId, sellerOrStaffId, currentDate) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('timeSlots', `/available-dates`, { serviceId, sellerOrStaffId, currentDate});
            if (response.data && response.data.success) {
                await dispatch({ type: GET_AVAILABLE_DATES_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_AVAILABLE_DATES_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_AVAILABLE_DATES_FAILED);
        }
    }
}

export const getTimeSlots = (data) => {
    return async (dispatch) => {
        try {
            const response = await API.apiPostUrl('timeSlots', `/time-slots`, data);
            if (response.data && response.data.success) {
                await dispatch({ type: GET_TIME_SLOTS_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_TIME_SLOTS_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_TIME_SLOTS_FAILED);
        }
    }
}

export const getTimeZones = () => {
    return async (dispatch) => {
        try {
            const response = await API.apiGet('timeSlots', `/time-zones`);

            if (response.data && response.data.success) {
                await dispatch({ type: GET_TIMEZONE_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: GET_TIMEZONE_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, GET_TIMEZONE_FAILED);
        }
    }
}

export const clearTimeSlotReduxState = () => {
    return async ( dispatch ) => {
       await dispatch({ type: REFRESH_TIMESLOT });
    }
}