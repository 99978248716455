import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import { getBookmarks, deleteBookmark } from '../../redux/actions/bookmark.actions';
import LoadingView from '../LoadingView';
import SEO from "../../seo";
import CustomBookmarkCard from '../../commonComponents/CustomBookmarkCard';
import "./Buyer.css";

function BookMarks() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [bookmarks, setBookmarks] = useState([]);
    const [loader, setLoader] = useState({ list: true, delete: false });


    const bookmarkState = useSelector(state => state.bookmark);

    useEffect(() => {
        dispatch(getBookmarks())
    }, []);

    useEffect(() => {
        if (bookmarkState.error) {
            renderToast('error', bookmarkState.error.message);
            setLoader({ list: false, delete: false });
        }

        if (!bookmarkState.error) {
            if (bookmarkState.list && bookmarkState.list.success) {
                setBookmarks(bookmarkState.list.bookMarks);
                setLoader({ list: false });
            }

            if (bookmarkState.deleted && bookmarkState.deleted.success) {
                // renderToast('success', bookmarkState.deleted.message);
                setLoader({ delete: false });
                dispatch(getBookmarks())
            }

            // if( bookmarkState.added && bookmarkState.added.success){
            //     // renderToast('success', bookmarkState.added.message);
            //     setLoader({ added: false });
            // }
        }
    }, [bookmarkState])

    const renderToast = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }

    const handleRemove = (bookmarkId) => {
        setLoader({ delete: true })
        dispatch(deleteBookmark(bookmarkId));
    }

    return (
        <>

            <SEO
                title="Bookmarks"
                description="View and manage your saved birth professionals on BirthBridge. Keep track of your favorite doulas, midwives, and other birth professionals for easy access."
                url="/bookmarks"
                keywords="bookmarks, saved birth professionals, BirthBridge, doulas, midwives, lactation consultants, birth support, prenatal care, postpartum care, family support"
            />

            <section className="page--banner">
                <Container>
                    <h1>Bookmarks</h1>
                </Container>
            </section>
            <section className="profile--Wrapper bookmark-main">
                <Container>
                    <Row className='bookmark-list'>
                        {loader['list']
                            ? <LoadingView />
                            : (bookmarks.length > 0)
                                && bookmarks.map((user, userIndex) => {
                                    return (
                                        <Col xs={12} sm={6} md={4} lg={4} key={userIndex} className="mb-4">

                                            <CustomBookmarkCard
                                                item={user.business}
                                                handleRemove={handleRemove}
                                                navigate={navigate}
                                                bookmarkId={user._id}
                                                loading={loader.delete}
                                            />
                                        </Col>
                                        // <CustomBookmarkCard 
                                        //     item={user.business}
                                        //     key={userIndex}
                                        //     handleRemove={handleRemove}
                                        //     navigate={navigate}
                                        // />
                                    )
                                })                               
                        }
                    </Row>
                    {(!bookmarks.length && !loader['list']) && 
                    <div className='text-center'>
                        <h6>Looks like you haven't saved any Birth Providers. Add your favorite Doula to keep track of them easily.</h6>
                    </div>}
                </Container>
            </section>
        </>
    )
}

export default BookMarks