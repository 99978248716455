import React, { useEffect, useState } from "react";
import { Row, Col, Table, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllReferrals } from "../../../redux/actions/user.action";
import LoadingView from "../../LoadingView";
import { momentFormat } from "../../../utils/validations";
import ClipboardCopy from "../../ClipboardCopy";
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/editStyle.css';
// import '../../../App.css';

const Refferals = () => {
    const disptach = useDispatch();
    const [loading, setLoading] = useState(false);
    const [referrals, setReferrals] = useState({});
    const [earnings, setTotalEarnings] = useState(0);
    const [ filter, setFilter ] = useState('seller');
    const [referralCode, setReferralCode] = useState(null);
    const referralState = useSelector(state => state.user);

    function organizeUsersByRole(users) {
        return users.reduce((acc, user) => {
          const role = user.associatedWithUser.role;
          if (!acc[role]) {
            acc[role] = [];
          }
          acc[role].push(user);
          return acc;
        }, {});
    }


    useEffect(() => {
        disptach( getAllReferrals() );
    }, [])


    useEffect(() => {
        if(referralState.error){
            setLoading(false);
        }

        if(!referralState.error){

            if( referralState.referrals && referralState.referrals.success){
                setReferrals(organizeUsersByRole(referralState.referrals.referrals));
                setTotalEarnings(referralState.referrals.totalReferralEarnings);
                setReferralCode(referralState.referrals.referralCode);
                setLoading(false);
            }
        }
    }, [referralState]);

    const renderTable = () => {
        return (
            <>
            <Table striped>
                <thead>
                    <tr>
                        <th>Referral Date</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    
                </tbody>
                    {referrals[filter].map((_referral, _referralIndex) => (
                        <tr key={_referralIndex}>
                            <td>{momentFormat.fullDate(_referral.createdAt)}</td>
                            <td>
                                {_referral.associatedWithUser?.name }
                            </td>
                            <td>{_referral.associatedWithUser?.email}</td>
                            <td className="text-capitalize">{_referral.status}</td>
                        </tr>  
                    ))}
            </Table>
            </>
        )
    }

    const renderReferralCode = () => {
        return (
            <h6>
                {/* Invite and earn by sharing your referral code <ClipboardCopy textToDisplay={referralCode} textToCopy={`${process.env.REACT_APP_URL}/invite?mode=bridger signup&inviter=${referralCode}`}/> to your friends */}
                Invite your friends and earn rewards! Share your referral code <ClipboardCopy textToDisplay={referralCode} textToCopy={`${process.env.REACT_APP_URL}/invite?mode=bridger signup&inviter=${referralCode}`}/>
            </h6>
        )
    }

    return (
        <section className="refferal__page">
            {loading
            ? <LoadingView /> 
            :   <Row>
                    <Col sm={12}>
                        <div className="d-flex justify-content-between align-items-center">
                            <h4>Your Referrals</h4>
                            <Form.Group>
                                <Form.Control as="select" onChange={e => setFilter(e.target.value)} value={filter} >
                                    <option value={'seller'}>Birth Professionals</option>
                                    <option value={'buyer'}>Families</option>
                                </Form.Control>
                            </Form.Group>
                        </div>
                    </Col>
                    <Col sm={12}>
                        <Card className="affiliate__Card mb-3 w-100">
                            <Card.Body className="d-flex justify-content-between align-items-center">
                                <Card.Title>Referral Earnings</Card.Title>
                                <Card.Text>${earnings}</Card.Text>
                                {/* <Button variant="primary">Request Withdrawal</Button> */}
                            </Card.Body>
                            <Col sm={12} md={12}>
                                {renderReferralCode()}
                            </Col>
                        </Card>
                    </Col>
                    <Col sm={12} md={12}>
                        {(Object.keys(referrals).length > 0 && referrals[filter]) 
                        && renderTable()
                        }
                    </Col>
                </Row>
            }
        </section>
    );
}

export default Refferals;