import React, { useEffect, useState, createRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Row } from 'react-bootstrap';
import Cropper from "react-cropper";
// import { useGesture } from 'react-use-gesture';
import "cropperjs/dist/cropper.css";
import './imageCropperStyle.css'; // Include your custom styles

const ImageCropModal = ({ show=false, handleClose, handleSave, image }) => {
    const [imageName, setImageName] = useState('');
    const [src, setSrc] = useState(null);
    // const [cropData, setCropData] = useState('#');
  

    // const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = createRef(null);
    


    useEffect(() => {
        if (!image) return;
        if (typeof image === 'object') {
            setImageName(image.name);
            setSrc(URL.createObjectURL(image));
        } else {
            setSrc(image);
        }
    }, [image]);


    // const handleSaveCrop = () => {
    //     // if (!imgRef.current || !crop || !previewCanvasRef.current) return;
    //     if (!imgRef.current || !crop ) return;

    //     const canvas = document.createElement('canvas');
    //     const image = imgRef.current;
    //     const scaleX = image.naturalWidth / image.width;
    //     const scaleY = image.naturalHeight / image.height;
    //     canvas.width = crop.width;
    //     canvas.height = crop.height;
    //     const ctx = canvas.getContext('2d');

    //     // Calculate the position and size of the image within the crop box
    //     const imgX = (crop.x * scaleX - position.x) / zoom;
    //     const imgY = (crop.y * scaleY - position.y) / zoom;
    //     const imgWidth = crop.width * scaleX / zoom;
    //     const imgHeight = crop.height * scaleY / zoom;

    //     // Adjust the canvas drawing to include any blank space
    //     ctx.drawImage(
    //         image,
    //         imgX < 0 ? 0 : imgX,   // If the image position is negative, start drawing from 0
    //         imgY < 0 ? 0 : imgY,   // If the image position is negative, start drawing from 0
    //         imgWidth,
    //         imgHeight,
    //         0,
    //         0,
    //         crop.width,
    //         crop.height
    //     );

    //     canvas.toBlob(blob => {
    //         if (blob) {
    //             handleSave(blob, imageName);
    //         }
    //     });
    // };

    // const getCropData = () => {
    //     if (typeof imgRef.current?.cropper !== "undefined") {
    //       setCropData(imgRef.current?.cropper.getCroppedCanvas().toDataURL());
    //     }
    // };

    const getCropData = () => {
        if (typeof imgRef.current?.cropper !== "undefined") {
            imgRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
                if (blob) {
                    // setCropData(URL.createObjectURL(blob)); 
                    handleSave(blob, imageName); // Call handleSave with the blob and image name
                }
            }, 'image/jpeg'); // You can specify the MIME type (e.g., 'image/png' or 'image/jpeg')
        }
    };

    // const handleZoom = (e) => {
    //     // Prevent zooming out more than 80%
    //     if (e.detail.ratio < 0.8) {
    //         e.preventDefault();
    //         imgRef.current?.cropper.zoomTo(0.8); // Zoom to 80% if zoom goes below it
    //     }
    // };

    // const fitImageToCropArea = () => {
    //     if (imgRef.current && imgRef.current.cropper) {
    //         const cropper = imgRef.current.cropper;
    //         const imageData = cropper.getImageData(); // Get the image dimensions
    
    //         // Calculate the zoom ratio to fit the entire image into the crop box
    //         const minZoom = Math.min(
    //             imageData.width / cropper.getCropBoxData().width,
    //             imageData.height / cropper.getCropBoxData().height
    //         );
            
    //         cropper.zoomTo(minZoom); // Set the zoom to fit the entire image
    //     }
    // };

    return (
            <Modal 
                show={show} 
                onHide={handleClose} 
                backdrop="static" 
                centered
            >
                <Modal.Header closeButton className='pb-0'>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {src && (
                            <Cropper
                                ref={imgRef}
                                style={{ height: 400, width: "100%" }}
                                initialAspectRatio={1} // Maintain 1:1 aspect ratio for circular crop
                                preview=".img-preview"
                                src={src}
                                viewMode={1}
                                // cropBoxHeight={10}
                                // cropBoxWidth={10}
                                
                                minCropBoxHeight={300} // Fixed height for crop box
                                minCropBoxWidth={300}  // Fixed width for crop box

                                cropBoxResizable={false}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                // crop={getCropData}
                                guides={false} // Hides crop guides                                
                                // movable={false} // Disable crop box movement
                                scalable={false} // Disable crop box scaling
                                rotatable={false} // Disable crop box rotation
                                aspectRatio={1} // Maintain 1:1 aspect ratio for circular crop

                                zoomOnTouch={true}
                                zoomable={true} // Allow zoom
                                // minZoomRatio={0.1} // Set minimum zoom level (0.1 = 10% zoom)
                                maxZoomRatio={10} // Set maximum zoom level (10x zoom)
                                zoomTo={0.1} // Initially zoom out
                                // ready={fitImageToCropArea} // Fit image to the crop area initially
                                
                            />
                        )}
                    </Row>
                </Modal.Body>
                <Modal.Footer className='justify-content-end p-3 pt-0'>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" 
                    // onClick={handleSaveCrop}
                    onClick={getCropData}
                >
                    Save Changes
                </Button>
                </Modal.Footer>
            </Modal>
    );
};

export default ImageCropModal;



