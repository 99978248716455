import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import sellerReducer from "./seller.reducer";
import staffReducer from "./staff.reducer";
import servicesReducer from "./services.reducer";
import businessReducer from "./business.reducer";
import stripeReducer from "./stripe.reducer";
import placesReducer from "./places.reducer";
import searchReducer from "./search.reducer";
import timeSlotsReducer from "./timeSlots.reducer";
import bookmarkReducer from "./bookmark.reducer";
import ordersReducer from "./orders.reducer";
import walletReducer from "./wallet.reducer";
import ratingReducer from "./rating.reducer";
import authModalReducer from "./authModal.reducer";
import scheduleReducer from "./schedule.reducer";
import paymentModeReducer from "./paymentMode.reducer";
import subscriptionReducer from "./subscription.reducer";
import transactionReducer from "./transaction.reducer";
import categoriesReducer from "./categories.reducer";
import socketReducer from "./socket.reducer";
import statisticsReducer from "./statistics.reducer";
import languageReducer from "./language.reducer";
import metaReducer from "./meta.reducer";

export default combineReducers({
  auth: authReducer,
  user: userReducer,
  seller:sellerReducer,
  staff:staffReducer,
  services:servicesReducer,
  business:businessReducer,
  stripe: stripeReducer,
  places: placesReducer,
  search: searchReducer,
  slots: timeSlotsReducer,
  bookmark: bookmarkReducer,
  orders: ordersReducer,
  wallet: walletReducer,
  rating: ratingReducer,
  showModal: authModalReducer,
  schedule: scheduleReducer,
  paymentMode: paymentModeReducer,
  subscription: subscriptionReducer,
  transactions: transactionReducer,
  categories: categoriesReducer,
  socket: socketReducer,
  statistics: statisticsReducer,
  language: languageReducer,
  meta: metaReducer
});
