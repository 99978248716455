import React, { useState, useEffect } from "react";
import { Col, Container, Row, Navbar, Nav, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BiLogOutCircle } from 'react-icons/bi';
import { Link, useNavigate } from "react-router-dom";
import { CgProfile } from 'react-icons/cg';
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { ThreeDots } from 'react-loader-spinner';
import { logout } from "../../redux/actions/auth.actions";
import { getUserProfile } from "../../redux/actions/user.action";
import { switchAppView } from "../../redux/actions/meta.action";
import { optimizedCloudinaryImage } from "../../utils/fileCompression";
import { generateLetterSVG } from "../../utils/validations";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './headerStyle.css';
import './loginStyle.css';
// import '../../App.css';



function SellerHeader() {
    const [userData, setUserData] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.user);

    useEffect(() => {
        if (auth.loggedIn) {
            dispatch(getUserProfile())
        }
    }, [])


    useEffect(() => {
        if (user.userDetail) {
            setUserData(user.userDetail)
        }
    }, [user])

    const handleSwitchToClientView = () => {
        dispatch(switchAppView('client'))
        setTimeout(() => {
            navigate(`/business/${userData._id}`);
        }, 0)
    }
    const handleToCommunity = () => {
        dispatch(switchAppView('client'))
        setTimeout(() => {
            navigate(`/community`);
        }, 0)
    }

    if (!userData) {
        return (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '64px' // Adjust this value to match your header height
            }}>
                <ThreeDots
                    height="50"
                    width="50"
                    radius="9"
                    color="#AED9E0"
                    ariaLabel="three-dots-loading"
                    visible={true}
                />
            </div>
        );
    }

    return (
        <>
            <header className="App-header">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <Navbar expand="lg" className="p-0">
                                <Navbar.Brand href="/">
                                    <img loading='lazy' src="/images/logo.webp" alt="BirthBridge Logo" />
                                    <span>BirthBridge</span>
                                </Navbar.Brand>
                                <Dropdown className="ml-auto">
                                    <div className="d-flex app-header-seller">
                                        <Nav.Link as={Link} className="d-none d-lg-block" onClick={handleSwitchToClientView}>View as a Family</Nav.Link>
                                        <Nav.Link as={Link} className="d-none d-lg-block" onClick={handleToCommunity}>Community</Nav.Link>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                            {(userData && userData.profilePic)
                                                ? <img
                                                    loading='lazy'
                                                    src={optimizedCloudinaryImage(userData.profilePic.url)}
                                                    alt="profile image" />
                                                : generateLetterSVG(userData?.name, 16)
                                            }
                                        </Dropdown.Toggle>
                                    </div>
                                    <Dropdown.Menu>
                                        {(userData && userData.name) ? <Dropdown.Item ><Link to="/profile"><CgProfile /> {userData.name} </Link></Dropdown.Item> : null}
                                        <Dropdown.Item
                                            className="d-lg-none"
                                            onClick={() => handleToCommunity()}
                                        >
                                            <HiOutlineSwitchHorizontal /> Community
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="d-lg-none"
                                            onClick={() => handleSwitchToClientView()}
                                        >
                                            <HiOutlineSwitchHorizontal /> View as a Family
                                        </Dropdown.Item>


                                        <Dropdown.Item onClick={() => logout()}><BiLogOutCircle /> Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Navbar>
                        </Col>
                    </Row>
                </Container>
            </header>
        </>
    );
}

export default SellerHeader;