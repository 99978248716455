import { 
    GET_BUYER_ORDERS_SUCCESS,
    GET_BUYER_ORDERS_FAILED,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_FAILED,
    GET_BUSINESS_ORDERS_SUCCESS,
    GET_BUSINESS_ORDERS_FAILED,
    APPROVE_ORDER_FAILED,
    APPROVE_ORDER_SUCCESS,
    COMPLETE_ORDER_SUCCESS,
    COMPLETE_ORDER_FAILED,
    POST_RATING_SUCCESS,
    POST_RATING_FAILED,
    RESET_ORDER_STATE,
 } from "../actions/types";

const initialState = {
    buyerOrders: null,
    sellerOrders: null,
    approve: null,
    cancelOrder: null,
    error: null,
    complete: null,
    rating: null
};

const ordersReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_BUYER_ORDERS_SUCCESS:
            return {
                // ...state,
                buyerOrders: action.payload,
                error: null
            }
        case GET_BUYER_ORDERS_FAILED:
            return {
                // ...state,
                error:action.payload
            }
        case CANCEL_ORDER_SUCCESS:
            return {
                // ...state,
                cancelOrder: action.payload,
                error: null
            }
        case CANCEL_ORDER_FAILED:
            return {
                // ...state,
                error: action.payload
            }
        case GET_BUSINESS_ORDERS_SUCCESS:
            return {
                // ...state,
                sellerOrders: action.payload,
                error: null
            }
        case GET_BUSINESS_ORDERS_FAILED:
            return {
                // ...state,
                error: action.payload
            }
        case APPROVE_ORDER_SUCCESS:
            return {
                // ...state,
                approve: action.payload,
                error: null
            }
        case APPROVE_ORDER_FAILED:
            return {
                // ...state,
                error: action.payload
            }
        case COMPLETE_ORDER_SUCCESS:
            return {
                complete:  action.payload,
                error: null
            }
        case COMPLETE_ORDER_FAILED:
            return {
                error: action.payload
            }
        case POST_RATING_SUCCESS:
            return{
                rating: action.payload,
                error: null
            }
        case POST_RATING_FAILED:
            return {
                error: action.payload
            }
        case RESET_ORDER_STATE: 
            return {
             resetState: null
            } 
        default: return state;
    }
};

export default ordersReducer;