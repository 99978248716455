import { get } from 'lodash';
import API from '../../helpers/api';
import { 
  GET_WALLET_SUCCESS,
  GET_WALLET_FAILED,
  WITHDRAW_BAL_FAILED,
  WITHDRAW_BAL_SUCCESS,
} from './types';


function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: { message: data.error.message },
        });
    } else {
        dispatch({
            type: type,
            payload: { message: data.message },
        });
    }
}

export const getWalletTx = () => {
    return async (dispatch) => {
        try{
            const response = await API.apiGet('wallet');
            if (response.data && response.data.success) {
              await dispatch({ type: GET_WALLET_SUCCESS, payload: response.data })
            } else {
              await dispatch({ type: GET_WALLET_FAILED, payload: response.data });
            }
        }catch (error ){
            errorRequest( error , dispatch, GET_WALLET_FAILED);
        }
    }
}

export const withdrawWalletAmount = (amount) => {
    return async (dispatch) => {
        try {
            const response = await API.apiGetByKey('withdrawal', `/${amount}`);
            if (response.data && response.data.success) {
                await dispatch({ type: WITHDRAW_BAL_SUCCESS, payload: response.data })
            } else {
                await dispatch({ type: WITHDRAW_BAL_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, WITHDRAW_BAL_FAILED)
        }
    }
}