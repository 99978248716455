
import React, { useState, useEffect } from "react";
import {
    Modal,
    Box,
    Typography,
    CircularProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    List,
    ListItem,
    ListItemText,
    Button,
} from "@mui/material";
import { FaChevronDown, FaExternalLinkAlt } from "react-icons/fa"; // Icon from react-icons
import axios from "axios";

const BrowseSpecialtiesModal = ({ show, onHide }) => {
    const [categories, setCategories] = useState([]); // Stores main categories
    const [subcategories, setSubcategories] = useState({}); // Stores subcategories for each category
    const [loading, setLoading] = useState(false); // Loading state for categories
    const [loadingSub, setLoadingSub] = useState({}); // Loading state for subcategories

    // Fetch categories when the modal opens
    useEffect(() => {
        if (show) {
            fetchCategories();
        }
    }, [show]);

    const fetchCategories = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/api/v1/user/services/birth-bridge`
            );
            if (response.data && response.data.bbService) {
                const sortedCategories = response.data.bbService.sort((a, b) => {
                    if (a.name === "Other Support Services") return 1;
                    if (b.name === "Other Support Services") return -1;
                    return a.name.localeCompare(b.name);
                });
                setCategories(sortedCategories);
                // console.log('response.data.bbService', response.data.bbService);
                // setCategories(response.data.bbService); // Access the 'categories' array in the API response
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
        setLoading(false);
    };

    const fetchSubcategories = async (categoryId) => {
        setLoadingSub((prev) => ({ ...prev, [categoryId]: true }));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_HOST}/api/v1/user/category?bbservice=${categoryId}`
            );
            if (response.data && response.data.categories) {
                const sortedSubcategories = response.data.categories.sort((a, b) =>
                    a.name.localeCompare(b.name)
                );
                setSubcategories((prev) => ({
                    ...prev,
                    [categoryId]: sortedSubcategories,
                }));

            }
        } catch (error) {
            console.error("Error fetching subcategories:", error);
        }
        setLoadingSub((prev) => ({ ...prev, [categoryId]: false }));
    };


    return (
        <Modal
            open={show}
            onClose={onHide}
            aria-labelledby="browse-specialties-title"
            aria-describedby="browse-specialties-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "95%", // Full width
                    height: "70%", // Full height
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden", // Prevent overflow
                    p: 0, // Remove padding from container box
                }}
            >
                {/* Close Button in Header */}
                <Box
                    sx={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        px: 3,
                        py: 2,
                        borderBottom: "1px solid #ddd",
                    }}
                >
                    <Typography
                        id="browse-specialties-title"
                        variant="h4"
                        component="h2"
                        gutterBottom
                    >
                        Browse Specialties
                    </Typography>
                    <Button
                        onClick={onHide}
                        sx={{
                            minWidth: 0,
                            padding: 0,
                            bgcolor: "transparent", // Transparent background
                            color: "black",
                            fontSize: 24,
                            lineHeight: 1,
                            borderRadius: "50%",
                            "&:hover": {
                                bgcolor: "rgba(0, 0, 0, 0.1)", // Add a subtle hover effect
                            },
                        }}
                    >
                        &times;
                    </Button>
                </Box>

                {/* Body */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: "auto", // Make the body scrollable
                        px: 3,
                        py: 2,
                    }}
                >
                    {loading ? (
                        <Box sx={{ textAlign: "center", mt: 4 }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box>
                            {categories.map((category) => (
                                <Accordion
                                    key={category._id}
                                    onChange={(event, isExpanded) => {
                                        if (isExpanded && !subcategories[category._id]) {
                                            fetchSubcategories(category._id);
                                        }
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<FaChevronDown />}
                                        aria-controls={`panel-${category._id}-content`}
                                        id={`panel-${category._id}-header`}
                                    >
                                        <Typography style={{ color: "black" }}>
                                            {category.name}
                                        </Typography>

                                        {/* Link Icon */}
                                        <a
                                            href={`/search?service=${encodeURIComponent(category.name)}`}
                                            style={{ color: "black", textDecoration: "none" }}
                                        >
                                            <FaExternalLinkAlt size={16} style={{ marginLeft: 12 }} />
                                        </a>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {loadingSub[category._id] ? (
                                            <CircularProgress size={24} />
                                        ) : subcategories[category._id] ? (
                                            <List>
                                                {subcategories[category._id].map(
                                                    (subcategory) => (
                                                        <ListItem key={subcategory._id}>
                                                            <a
                                                                href={`/search?category=${encodeURIComponent(
                                                                    subcategory.name
                                                                )}`}
                                                            >
                                                                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                                    <ListItemText
                                                                        style={{
                                                                            color: "black",
                                                                        }}
                                                                        primary={
                                                                            subcategory.name
                                                                        }
                                                                    />
                                                                    <FaExternalLinkAlt size={16} style={{ marginLeft: 12 }} />
                                                                </div>
                                                            </a>
                                                        </ListItem>
                                                    )
                                                )}
                                            </List>
                                        ) : (
                                            <Typography>
                                                No subcategories available
                                            </Typography>
                                        )}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>
                    )}
                </Box>

                {/* Footer */}
                <Box
                    sx={{
                        borderTop: "1px solid #ddd",
                        px: 3,
                        py: 2,
                        textAlign: "right",
                    }}
                >
                    <Button variant="contained" onClick={onHide}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>


    );
};

export default BrowseSpecialtiesModal;