import {
    GET_PAYMENT_MODE_SUCCESS,
    GET_PAYMENT_MODE_FAILED,
    PUT_PAYMENT_MODE_SUCCESS,
    PUT_PAYMENT_MODE_FAILED,
} from "../actions/types";

const initialState = {
    get: null,
    update: null,
    error: null,
};

const paymentModeReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_PAYMENT_MODE_SUCCESS :
            return {
                get: action.payload,
                error: null
            }
        case GET_PAYMENT_MODE_FAILED:
            return {
                error: action.payload
            }
        case PUT_PAYMENT_MODE_SUCCESS:
            return {
                update: action.payload,
                error: null
            }
        case PUT_PAYMENT_MODE_FAILED:
            return {
                error:action.payload
            }
        
        default: return state;
    }
};

export default paymentModeReducer;