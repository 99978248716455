import React from 'react';
import { 
    RotatingLines, 
    ThreeDots,
 } from 'react-loader-spinner';
 import './loading.css'

 const loaderComponents = {
    RotatingLines: RotatingLines,
    ThreeDots:ThreeDots,
};
  
const LoadingView = ({ height, size, color, thickness, message, loader='ThreeDots', appLoader }) => {
    const LoaderComponent = loaderComponents[loader];
    return (
        <div className={appLoader ? "app--loader":"loader-main"}>
            <LoaderComponent
                strokeColor={color ? color : "grey"}
                strokeWidth={thickness ? thickness : "5"}
                animationDuration="0.75"
                width={size ? size : 80}
                height={height || 80}
                visible={true}
            />
            {message && <p className=''>{message}</p>}
        </div>
    )
}

export default LoadingView;