import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORIES_FAILED,
} from "../actions/types";

const initialState = {
    all: null,
    error: null,
};

const categoriesReducer = (state = initialState, action) => {
    switch (action.type){ 
        case GET_CATEGORIES_SUCCESS:
            return {
                all: action.payload,
                error: null
            }
        case GET_CATEGORIES_FAILED:
            return {
                error: action.payload
            }
        default: return state;
    }
};

export default categoriesReducer;