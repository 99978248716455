module.exports = [
    {
        "id": 1,
        "name": "White",
        "value": "white"
    },
    {
        "id": 2,
        "name": "Black or African American",
        "value": "black_or_african_american"
    },
    {
        "id": 3,
        "name": "American Indian or Alaska Native",
        "value": "american_indian_or_alaska_native"
    },
    {
        "id": 4,
        "name": "Asian",
        "value":"asian"
    },
    {
        "id": 5,
        "name": "Native Hawaiian or Other Pacific Islander",
        "value":"native_hawaiian_or_other_pacific_islander"
    },
    {
        "id": 6,
        "name": "Middle Eastern or North African",
        "value": "middle_eastern_or_north_african"
    },
    {
        "id": 7,
        "name": "Hispanic or Latino",
        "value":'hispanic_or_latino'
    },
    {
        "id": 8,
        "name": "Other",
        "value":"other"
    }
]