import moment from 'moment';
// import momentTz from 'moment-timezone';

const Email = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
const Dateformat = new RegExp(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i);
const Alphanumeric = new RegExp(/^[A-Za-z\d\s]+$/);
const Aphabeticals = new RegExp(/^[a-zA-Z ]*$/);
const Numeric = new RegExp(/^[0-9]+$/);
const Amount = new RegExp(/^\$?[\d,]+(\.\d*)?$/);

// Regex to detect if the domain contains http, https, or www.
const unwantedPattern = /^(?:https?:\/\/)?(?:www\.)/;

// Original domain validation pattern
// const domainPattern = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

// Regex for validating a website domain (with optional paths)
const domainPattern = /^(?!.*[-_.]{2})[a-zA-Z0-9]([a-zA-Z0-9_.-]{1,38}[a-zA-Z0-9])?\.[a-zA-Z]{2,}(\/[\w-./?%&=]*)?$/;

// Block domains related to Facebook or Instagram
const domainBlockPattern = /(facebook\.com|fb\.com|instagram\.com|insta\.com)/i;

// Validate username with standard rules
const usernamePattern = /^[a-zA-Z0-9][a-zA-Z0-9_.-]{1,38}[a-zA-Z0-9]$/;

export function cleanDomain(domain) {
    // Remove http, https, and www.
    return domain.replace(/^(?:https?:\/\/)?(?:www\.)?/, '');
}

export const removeExtraSpaces = (string) => {
    return string.replace(/\s{2,}/g, ' ').trim()
}

export const notValid = string => {
    return [null, undefined, 'null', 'undefined', ''].includes(removeExtraSpaces(string))
}

export const emailValidation = email => Email.test(email);

export const capitalizeFirstLetterEachWord = (str) => {
    if (!str) return '';
    str = str.split(" ");
    for (var i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(" ");
}

export const dateFormatValidation = date => Dateformat.exec(date);
export const alphanumeicValidation = text => Alphanumeric.test(text);
export const aphabeticalsValidation = text => Aphabeticals.test(text);
export const numericValidation = number => Numeric.test(number);
export const amountValidation = amount => Amount.test(amount);
export const removeCommaInDigit = (value) => {
    if (!value) return;
    return parseFloat(value.split(',').join(''))
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const extractDate = (date) => {
    if (!(date instanceof Date)) return date;
    return date.toISOString().split('T')[0];
}
export const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const formatPrice = (value) => {
    // If value is falsy (null, undefined, 0) or less than/equal to 1000, return as-is
    if (!value || value <= 1000) return value;

    // Convert to a number if it's a string with commas
    const numericValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, "")) : value;

    // Format the number with commas as thousand separators, including decimals
    return new Intl.NumberFormat('en-US').format(numericValue);
}

// export const formatPrice = (value) => {
//     if (!value) return value;
//     if (value <= 1000) return value;
//     var nStr = value + '';
//     nStr = nStr.replace(/\,/g, "");
//     let x = nStr.split('.');
//     let x1 = x[0];
//     let x2 = x.length > 1 ? '.' + x[1] : '';
//     var rgx = /(\d+)(\d{3})/;
//     while (rgx.test(x1)) {
//         x1 = x1.replace(rgx, '$1' + ',' + '$2');
//     }
//     return x1 + x2;
// }

export const formatPriceinK = (value) => {
    if (value === undefined || value === null) return value;

    const numericValue = parseFloat(value);
    if (isNaN(numericValue)) return value;

    // Handle numbers below 1000 with up to 2 decimal places
    if (numericValue < 1000) return numericValue.toFixed(2).replace(/(\.00|(?<=\.\d)0+)$/, '');

    // Determine the appropriate unit (K, M, B)
    const absValue = Math.abs(numericValue);
    let formattedValue;

    if (absValue >= 1_000_000_000) {
        // Billion
        formattedValue = (numericValue / 1_000_000_000).toFixed(2) + 'B';
    } else if (absValue >= 1_000_000) {
        // Million
        formattedValue = (numericValue / 1_000_000).toFixed(2) + 'M';
    } else if (absValue >= 1_000) {
        // Thousand
        formattedValue = (numericValue / 1_000).toFixed(2) + 'K';
    }

    // Remove trailing '.00' or unnecessary trailing zeros
    return formattedValue.replace(/(\.00|(?<=\.\d)0+)$/, '');
}


export const commission = (value) => {
    const number = value.replace(/[^0-9.]/g, '');

    if (number <= 100) return number;
    return number.slice(0, 2)
}

export const replaceTime = (dateString, newTime) => {
    // Convert the input date string to a Date object
    const date = new Date(dateString);

    // Extract the date components (year, month, day) from the input date
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();

    // Extract the hours and minutes from the new time string
    const [hours, minutes] = newTime.split(':').map(part => parseInt(part, 10));

    // Create a new Date object with the same date components and the new time
    const newDate = new Date(year, month, day, hours, minutes);

    // Return the new Date object as an ISO string
    return newDate.toISOString();
}

/** ---- Completed Order Button Disable Or Enable ---- */
export const isAppointmentOverToCompleteOrder = (_order) => {
    const now_time_stamp = new Date();
    const order_time_stamp = _order.timeSlot.end_time ? _order.timeSlot.end_time : _order.timeSlot.date
  
    if (moment(order_time_stamp).isBefore(now_time_stamp, 'minutes')) {
        /** ---- Make Button Disable ---- */
        return true;
    }
    /** ----- Make Button Enable ---- */
    return false;
}


export const momentFormat = {

    now:(format)=>{
        return moment().format(format)
    },
    timIn12Format:(dateTime) => {
        return moment(dateTime).format('hh:mm A');
    },
    timeZone:(dateTime) => {
        const tz = moment().format('z')
        return tz
    },
    timeInHHmm: (dateTime) => {
        return moment(dateTime).format('HH:mm')
    },
    dateTimeFormat: (dateTime) => {
        return moment(dateTime).format('lll')
    },
    timeFormat: (time) => {
        return moment(time).format('LT')
    },
    dateFormat: (date) => {
        return moment(date).format('YYYY-MM-DD');
    },
    fullDate: (date) => {
        return moment(date).format('LL');
    },
    formatReactCalendarDate: (date) => {
        date = new Date(date)
        let year = date.getYear();
        let month = date.getMonth();
        let dt = date.getDate();
        return new Date(year, month, dt);
    },
    formatReactCalendarTime: (date) => {
        date = new Date(date)
        let hr = date.getHours();
        let min = date.getMinutes();

        hr = hr > 9 ? hr : `0${hr}`;
        min = min > 9 ? min : `0${min}`;

        return `${hr}:${min}`
    },
    correctDate: (start, end) => {
        let isSameDate = moment(start).isSame(moment(end), 'day');
        if(isSameDate)  return end

        end.setDate(end.getDate() - 1);
        return end;
    },
    combineDateTime: (date, time) => {
        date = typeof date === 'string' ? new Date(date) : date
        if (time) {
            let _time = time.split(':');
            date.setHours(_time[0]);
            date.setMinutes(_time[1]);
            date.setSeconds(0);
        }
        return date;
    },
    formatWeekDate: (date) => {
        // return moment(date).format('dddd, DD MMMM, YY');
        return moment(date).format('dddd, MM/DD/YY');
    },

    increaseDateByOne: (date) => {
        date.setDate(date.getDate() + 1);
        return date;
    },
    convertTimeSlots: (data) => {
        let times = []
        data.forEach(d => {
            let start = moment(d.start).format('HH:mm')
            let end = moment(d.end).format('HH:mm')

            times.push({ start, end })
        })

        return times;
    },
    utcDateTime: (date) => {
        return moment(date).utc().toDate()
    }
}

export function displayAddress(address) {
    let formattedAddress = 'N/A';

    function cleanString(str) {
        return str ? str.replace(/,\s*$/, '') : '';
    }

    // Initialize formattedAddress to an empty string
    formattedAddress = '';

    // Check each key in the address object, clean it, and add non-blank values to the formatted address
    // if (address.street) {
    //     formattedAddress = `${cleanString(address.street)}, `;
    // }

    // if (address.landmark) {
    //     formattedAddress += `${cleanString(address.landmark)}, `;
    // }

    if (address.city) {
        formattedAddress += `${cleanString(address.city)}, `;
    }

    if (address.state) {
        formattedAddress += `${cleanString(address.state)}, `;
    }

    // if (address.country) {
    //     formattedAddress += `${cleanString(address.country)}, `;
    // }

    // if (address.postalCode) {
    //     formattedAddress += `${cleanString(address.postalCode)}`;
    // }

    // Trim any trailing commas and spaces from the final formatted address
    formattedAddress = formattedAddress.replace(/,\s*$/, '');

    // If formattedAddress is empty, set it to 'N/A'
    if (!formattedAddress) {
        formattedAddress = 'N/A';
    }

    return formattedAddress;
}

// export const generateTimeOptions = (events, selectedDate, startTime, startDate) => {
//     const timeOptions = [];
//     const currentTime = new Date();
//     const areSameDate = moment(selectedDate).isSame(moment(currentTime), 'day');
//     const areStartAndSelectedDateSame = moment(startDate).isSame(moment(selectedDate), 'day');

//     // Filter events for the selected date
//     const eventsOnSelectedDate = events.filter(evt =>
//         moment(selectedDate).isSame(moment(evt.start).startOf('day'))
//     );

//     // Get the end time of the last disabled event after the selected start time
//     let lastDisabledEndTimeAfterStartTime = null;
//     eventsOnSelectedDate.forEach(event => {
//         const eventEndTime = moment(event.end).format('HH:mm');
//         if (eventEndTime > startTime && (!lastDisabledEndTimeAfterStartTime || eventEndTime < lastDisabledEndTimeAfterStartTime)) {
//             lastDisabledEndTimeAfterStartTime = eventEndTime;
//         }
//     });

//     for (let hour = 0; hour < 24; hour++) {
//         for (let minute = 0; minute < 60; minute += 15) {
//             const formattedHour = hour.toString().padStart(2, '0');
//             const formattedMinute = minute.toString().padStart(2, '0');
//             const time = `${formattedHour}:${formattedMinute}`;
            
//              // Convert to 12-hour format with AM/PM
//              const time12 = moment(`${formattedHour}:${formattedMinute}`, 'HH:mm').format('hh:mm A');


//             // Create a Date object for the current option time
//             const optionTime = new Date(selectedDate);
//             optionTime.setHours(hour, minute, 0, 0);

//             // If generating end time options and a start time is provided
//             if (startTime) {
//                 // Disable time options only if startDate and selectedDate are the same
//                 if (areStartAndSelectedDateSame) {
//                     if ((lastDisabledEndTimeAfterStartTime) && (time >= lastDisabledEndTimeAfterStartTime) || (time <= startTime)) {
//                         timeOptions.push({ time, time12, disabled: true });
//                         continue;
//                     }
//                 } else {
//                     // If startDate and selectedDate are not the same, only disable time options after last disabled end time
//                     if ((lastDisabledEndTimeAfterStartTime) && (time >= lastDisabledEndTimeAfterStartTime)) {
//                         timeOptions.push({ time, time12, disabled: true });
//                         continue;
//                     }
//                 }
//             }

//             // Check if the option time is in the past relative to the current time
//             if (areSameDate && optionTime < currentTime) {
//                 timeOptions.push({ time, time12, disabled: true });
//                 continue;
//             }

//             // Check if the option time falls within any event's time range
//             const isTimeWithinEvent = eventsOnSelectedDate.some(event => {
//                 const eventStart = new Date(event.start);
//                 const eventEnd = new Date(event.end);
//                 return optionTime >= eventStart && optionTime <= eventEnd;
//             });

//             if (isTimeWithinEvent) {
//                 timeOptions.push({ time, time12, disabled: true });
//             } else {
//                 timeOptions.push({ time, time12 });
//             }
//         }
//     }
//     return timeOptions;
// };

export const generateTimeOptions = (events, selectedDate, startTime, startDate) => {
    const timeOptions = [];
    const currentTime = new Date();
    
    // Get the start of the selected date and check if it matches the current date
    const selectedMoment = moment(selectedDate).startOf('day');
    const areSameDate = selectedMoment.isSame(moment(currentTime), 'day');
    const areStartAndSelectedDateSame = selectedMoment.isSame(moment(startDate).startOf('day'));

    // // Filter events for the selected date
    // const eventsOnSelectedDate = events.filter(evt =>
    //     selectedMoment.isSame(moment(evt.start).startOf('day'))
    // );

    // // Determine the last disabled end time after the specified start time
    // const lastDisabledEndTimeAfterStartTime = eventsOnSelectedDate
    //     .map(event => moment(event.end).format('HH:mm'))
    //     .filter(eventEndTime => eventEndTime > startTime)
    //     .sort()[0] || null;

    // Generate time options in 15-minute intervals
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const formattedHour = hour.toString().padStart(2, '0');
            const formattedMinute = minute.toString().padStart(2, '0');
            const time = `${formattedHour}:${formattedMinute}`;
            const time12 = moment(time, 'HH:mm').format('hh:mm A');

            // Create a Date object for the current option time
            const optionTime = new Date(selectedDate);
            optionTime.setHours(hour, minute, 0, 0);

            // // Determine if the time option should be disabled
            // const shouldDisable = (
            //     (startTime && areStartAndSelectedDateSame && (time <= startTime || (lastDisabledEndTimeAfterStartTime && time >= lastDisabledEndTimeAfterStartTime))) ||
            //     (startTime && !areStartAndSelectedDateSame && lastDisabledEndTimeAfterStartTime && time >= lastDisabledEndTimeAfterStartTime) ||
            //     (areSameDate && optionTime < currentTime) ||
            //     eventsOnSelectedDate.some(event => {
            //         const eventStart = new Date(event.start);
            //         const eventEnd = new Date(event.end);
            //         return optionTime >= eventStart && optionTime <= eventEnd;
            //     })
            // );

            // Determine if the time option should be disabled
             // Determine if the time option should be disabled
             const shouldDisable = (
                (areSameDate && optionTime < currentTime) || // Current day: Disable past times
                (areStartAndSelectedDateSame && startTime && time <= startTime) // Same day: Ensure startTime is before endTime
            );

            // Push the time option to the array with disabled status
            timeOptions.push({ 
                time, 
                time12, 
                disabled: shouldDisable 
            });
        }
    }

    return timeOptions;
};



/**
 * Checks if a new time slot overlaps with existing events for a date range.
 * 
 * @param {string} newStartTime - The start time of the new time slot (in HH:mm format).
 * @param {string} newEndTime - The end time of the new time slot (in HH:mm format).
 * @param {Array} events - Array of existing events, where each event has `start`, `end`, and the date (e.g., `start` includes the date and time in ISO format).
 * @param {string} startDate - The start date for the new time slot (in YYYY-MM-DD format).
 * @param {string} endDate - The end date for the new time slot (in YYYY-MM-DD format).
 * @returns {boolean} - Returns true if there is an overlap, otherwise false.
 */
export const isOverlapping = (newStartDate, newEndDate, events, newEvent) => {
    // Combine new event's start and end dates with their respective times
    const newStartDateTime = moment(newStartDate).set({
        hour: parseInt(newEvent.startTime.split(':')[0], 10),
        minute: parseInt(newEvent.startTime.split(':')[1], 10),
    });

    const newEndDateTime = moment(newEndDate).set({
        hour: parseInt(newEvent.endTime.split(':')[0], 10),
        minute: parseInt(newEvent.endTime.split(':')[1], 10),
    });

    // // Check for overlap with existing events
                    // .some
    // return events.filter(event => {
    //     const eventStartDateTime = moment(event.start);
    //     const eventEndDateTime = moment(event.end);

    //     // Overlap conditions
    //     return newStartDateTime.isBetween(eventStartDateTime, eventEndDateTime, null, '[)') || // New start overlaps
    //            newEndDateTime.isBetween(eventStartDateTime, eventEndDateTime, null, '(]') || // New end overlaps
    //            eventStartDateTime.isBetween(newStartDateTime, newEndDateTime, null, '[)'); // Existing event starts within the new event
    // });

    // Find the first overlapping event
    return events.find(event => {
        const eventStartDateTime = moment(event.start);
        const eventEndDateTime = moment(event.end);

        // Overlap conditions
        return newStartDateTime.isBetween(eventStartDateTime, eventEndDateTime, null, '[)') || // New start overlaps
               newEndDateTime.isBetween(eventStartDateTime, eventEndDateTime, null, '(]') || // New end overlaps
               eventStartDateTime.isBetween(newStartDateTime, newEndDateTime, null, '[)'); // Existing event starts within the new event
    }) || null;
};

export const validateNumber = (value) => {
    if (!value) return value;
    const number = value.replace(/[^\d]/g, '');
    return number;
}

export const serviceTypeName = (type) => {
    if (type === 'service') {
        return 'Doula'
    }

    return 'Class'
}

export const isImage = (file) => {
    return file.type.startsWith('image/');
}

export const convertMetersToMiles = (value) => {
    const oneMiles = 1609.34  // meter
    let miles = (value / oneMiles);
    miles = miles > 0 ? miles.toFixed(1) : miles
    return miles > 1 ? `${miles} Miles` : `${miles} Mile`
}

export const showOverview = (paragraph, maxWords = 10) => {
    if (!paragraph) return;
    const words = paragraph.split(' '); // Split the paragraph into an array of words
    const numWords = words.length;

    // Check if the number of words is less than or equal to the maximum number of words
    if (numWords <= maxWords) {
        return paragraph; // Return the entire paragraph
    } else {
        // Extract the first few words up to the maximum number of words
        const overview = words.slice(0, maxWords).join(' ');

        return overview + '...'; // Append '...' to indicate that the overview has been truncated
    }
}

export function showOverviewByChar(text, charLimit) {
    // If the text is shorter than or equal to the limit, return it as is
    if (text.length <= charLimit) {
      return text;
    }
    
    // Otherwise, truncate the text and add '...' to indicate it's truncated
    return text.substring(0, charLimit) + '...';
}


function extractLetters(inputString) {
    let result = "";

    // Split the input string into words
    const words = inputString.split(/\s+/);

    // Define a set of words to exclude
    const excludeWords = new Set(["and", "or", "for", "not", "nor"]);

    // Loop through each word to find the first valid letter
    let count = 0;
    for (let word of words) {
        // Remove special characters and numbers from the word
        const cleanedWord = word.replace(/[^a-zA-Z]/g, "");
        // Check if the cleaned word is not empty and not in the exclude list
        if (cleanedWord.length > 0 && !excludeWords.has(cleanedWord.toLowerCase())) {
            // Take the first letter and append it to the result
            result += cleanedWord[0].toUpperCase();
            count++;
            // If we have found the first two valid words, exit the loop
            if (count === 2) {
                break;
            }
        }
    }

    return result;
}

/**
 * 
 * @param {String} words to get initials from a name
 * @returns string
 */

export function generateLetterSVG(words, size) {
    const letters = extractLetters(words);

    return(
        <span 
            className="initials"
            style={{
                fontSize:size
            }} 
        >
            {letters}
        </span>
    )  
}

export function deepClone(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    const cloned = Array.isArray(obj) ? [] : {};

    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            cloned[key] = deepClone(obj[key]);
        }
    }

    return cloned;
}


export const objectToSearchParams = (obj, prefix = '') => {
    const searchParams = new URLSearchParams();

    // Loop through the object's keys and values
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];

            // Construct the key for this iteration, including the prefix for nested objects
            const currentKey = prefix ? `${prefix}[${key}]` : key;

            if (Array.isArray(value)) {
                // If the value is an array, append each element as a separate search parameter
                value.forEach(val => {
                    if (val) { // Check if value is truthy
                        searchParams.append(currentKey, val);
                    }
                });
            } else if (typeof value === 'object' && value !== null) {
                // If the value is a nested object, recursively call objectToSearchParams with the nested object and updated prefix
                objectToSearchParams(value, currentKey).forEach((val, index) => {
                    if (val) { // Check if value is truthy
                        searchParams.append(index === 0 ? currentKey : '', val);
                    }
                });
            } else {
                if (value) { // Check if value is truthy
                    // Otherwise, append the value as a single search parameter
                    searchParams.append(currentKey, value);
                }
            }
        }
    }

    // Return the search parameter string
    return searchParams.toString();
};


// export const generateYears = () => {
//     let currentDate = new Date().getFullYear();
//     let endYear = currentDate + 10; // Generate up to the next 10 years

//     const years = [];
//     for (let year = currentDate; year <= endYear; year++) {
//         years.push(year);
//     }
//     return years;
// }
export const generateYears = () => {
    let currentDate = new Date().getFullYear();
    let endYear = currentDate + 10; // Generate up to the next 10 years

    const years = ["No Expiration Date"]; // Add "No Expiration Date" as the first option
    for (let year = currentDate; year <= endYear; year++) {
        years.push(year);
    }
    return years;
};
export const generateMonths = () => {
    const months = [
        { number: 1, name: "January" },
        { number: 2, name: "February" },
        { number: 3, name: "March" },
        { number: 4, name: "April" },
        { number: 5, name: "May" },
        { number: 6, name: "June" },
        { number: 7, name: "July" },
        { number: 8, name: "August" },
        { number: 9, name: "September" },
        { number: 10, name: "October" },
        { number: 11, name: "November" },
        { number: 12, name: "December" }
    ];

    return months;
}

export function validateTwitterUrl(url) {
    const twitterRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/([a-zA-Z0-9_]{1,15})(\/)?$/;
    return twitterRegex.test(url);
}

export function validateFacebookUrl(url) {
    const facebookRegex = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.]{1,}$/;
    return facebookRegex.test(url);
}

export function validateInstagramUrl(url) {
    const instagramRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9._]{1,30}(\/)?$/;
    return instagramRegex.test(url);
}

export const handleWebsiteClick = (url) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      window.open(url, '_blank');
    } else {
      window.open(`//${url}`, '_blank');
    }
};

export const isAddressBlank = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null && obj[key].trim() !== '') {
          return false; // Return false if any value is not null or blank
        }
      }
    }
    return true; // Return true if all values are null or blank
}

export const removeProtocol = (url) => {
    return url.replace(/^https?:\/\//, '//');
}

export const validateDomain = (domain) => {
     // First check if the domain contains unwanted patterns (http, https, www)
     if (unwantedPattern.test(domain)) {
        return false;  // Reject domains containing http, https, or www
    }

    // If no unwanted patterns, proceed with actual domain validation
    return domainPattern.test(domain);
}

export function validateUsername(input) {
    // Check if the input contains any blocked domains
    if (domainBlockPattern.test(input)) {
        return false;  // Reject input containing any of the blocked domains
    }

    // If no domains are found, validate the username pattern
    return usernamePattern.test(input);
}

// Used for existing saved website and social media url
export function cleanExistingURL(input) {
    if(!input) return '';

   // Regex to match unwanted domains and capture the path
   const unwantedPattern = /^(https?:\/\/)?(www\.)?(facebook\.com|fb\.com|instagram\.com)(\/(.+))?$/i;

   // Check if the input matches the unwanted pattern
   const match = input.match(unwantedPattern);
   if (match) {
       // Return the captured path if it exists, removing any leading slashes
       return match[5] ? match[5].replace(/^\/+/, '') : ''; // Return path without leading slashes
   }

   // Remove unwanted prefixes (http, https, www) and return cleaned input
   return input
       .replace(/^(https?:\/\/)?(www\.)?/, '') // Remove http, https, www
       .trim(); // Trim any leading or trailing spaces
}

export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  export const deepCopy = (obj) => {
    if (obj === null || typeof obj !== "object") return obj;
  
    if (Array.isArray(obj)) {
      return obj.map(deepCopy);
    }
  
    const copy = {};
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }
    return copy;
  };
  
  