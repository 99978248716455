import { 
    GET_WALLET_SUCCESS, 
    GET_WALLET_FAILED, 
    WITHDRAW_BAL_SUCCESS, 
    WITHDRAW_BAL_FAILED 
} from "../actions/types";

// Initial state of the wallet reducer
const initialState = {
    withdraw: null,
    walletTx: null,
    error: null,
};

// Wallet reducer function
const walletReducer = (state = initialState, action) => {
    switch (action.type) { 
        case GET_WALLET_SUCCESS:
            return {
                walletTx: action.payload, // Update wallet transactions
                error: null, // Clear error on successful fetch
            };
        case GET_WALLET_FAILED:
            return {
                walletTx: null, // Reset wallet transactions on failure
                error: action.payload, // Set error payload
            };
        case WITHDRAW_BAL_SUCCESS:
            return {
                withdraw: action.payload, // Update withdrawal information
                error: null, // Clear error on successful withdrawal
            };
        case WITHDRAW_BAL_FAILED:
            return {
                withdraw: null, // Reset withdrawal info on failure
                error: action.payload, // Set error payload
            };
        default:
            return state; // Return unchanged state for unrecognized actions
    }
};

// Export the wallet reducer as the default export
export default walletReducer;
