import React from 'react';
import './PrivacyPolicy.css';
import { Card } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});

const PrivacyPolicyFamilies = () => (
    <div className="privacy-policy-container">
        <h1 className="center-text">Privacy Policy for Families</h1>
        <div className='privacy-policy-wrapper'>
            <p className="privacy-policy-content">
                <strong>1. Introduction</strong><br />
                This Privacy Policy explains how BirthBridge collects, uses, and discloses your personal information as a Family.
            </p>
            <p className="privacy-policy-content">
                <strong>2. Information We Collect</strong><br />
                - Personal details (name, contact information).<br />
                - Booking information (services booked, payment details).<br />
                - Feedback and reviews.
            </p>
            <p className="privacy-policy-content">
                <strong>3. How We Use Your Information</strong><br />
                - To facilitate bookings and payments.<br />
                - To communicate with you about your account and bookings.<br />
                - To improve our platform and services.
            </p>
            <p className="privacy-policy-content">
                <strong>4. Information Sharing</strong><br />
                - With birth professionals you book services from.<br />
                - With third-party service providers (e.g., payment processors).<br />
                - As required by law or to protect our rights.
            </p>
            <p className="privacy-policy-content">
                <strong>5. Data Security</strong><br />
                - We implement reasonable security measures to protect your personal information.<br />
                - You are responsible for maintaining the security of your account credentials.
            </p>
            <p className="privacy-policy-content">
                <strong>6. Your Rights</strong><br />
                - Access, update, or delete your personal information.<br />
                - Object to or restrict our use of your information.<br />
                - Contact us with any privacy-related questions or concerns.
            </p>
        </div>
    </div>
);

export default function PrivacyPolicyFamiliesWrapper() {
    return (
        <ThemeProvider theme={theme}>
            <PrivacyPolicyFamilies />
        </ThemeProvider>
    );
}
