export const GA_TRACKING_ID = 'G-E1190C55F9';

// Initialize gtag function
export const initGA = () => {
  if(process.env.REACT_APP_MODE === 'production'){
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', GA_TRACKING_ID);
  }
};

// Log page views
export const logPageView = (url) => {
  if(process.env.REACT_APP_MODE === 'production'){
    window.gtag('config', GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// Log specific events
export const logEvent = (action, category, label, value) => {
  if(process.env.REACT_APP_MODE === 'production'){
    window.gtag('event', 
      action, 
      {
        event_category: category,
        event_label: label,
        value: value,
      }
    );
  }
};