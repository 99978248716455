import React from 'react';
import './Maintainance.css';

function App() {
  return (
    <div className="mApp">
      <header className="mApp-header">
        <h1>We'll be back soon!</h1>
        <p>
          Sorry for the inconvenience but we're performing some maintenance at the moment.
          If you need to, you can always <a href="mailto:hello@mybirthbridge.com">contact us</a>,
          otherwise we'll be back online shortly!
        </p>
        <p>&mdash; The BrithBridge team</p>
      </header>
    </div>
  );
}

export default App;