import React from 'react';
import CustomReviewCard from '../CustomReviewCard';
import LoadingView from '../../components/LoadingView';
import { Dropdown } from 'react-bootstrap';
import { CiFilter } from 'react-icons/ci';
import { BsSortDown } from 'react-icons/bs';

const Reviews = ({ reviews, loading, showTitle= true, singleBusinessDetails }) => {

    return (
        <div className="reviews--List">
           {showTitle && <div className="title--group">
                {/* <h5>Feedback</h5> */}
                {/* <Dropdown>
                    <Dropdown.Toggle id="filter--dropdown">
                        <CiFilter />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#" >All Reviews</Dropdown.Item>
                        <Dropdown.Item href="#" >Owner Reviews</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                {/* <Dropdown>
                    <Dropdown.Toggle id="sort--dropdown">
                        <BsSortDown />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="#" >Newest</Dropdown.Item>
                        <Dropdown.Item href="#" >Oldest</Dropdown.Item>
                        <Dropdown.Item href="#" >Highest Rated</Dropdown.Item>
                        <Dropdown.Item href="#" >Lowest Rated</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
            </div>}
            {loading
            ? <LoadingView />
            : (reviews.length > 0 ) 
                ?   reviews.map((review, reviewIndex) => (
                        <CustomReviewCard 
                            key={reviewIndex}
                            review={review}
                            reviews={reviews}
                            singleBusinessDetails={singleBusinessDetails}
                        />
                    ))
                :<p>No reviews have been shared yet. Be the first to leave your feedback and help others discover their amazing care!</p>
            }
        </div>
    )
}

export default Reviews;