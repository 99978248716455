import React from "react";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './popularStyle.css';
// import '../../App.css';

// const tooltip = ['No Ratings', 'Terrible', 'Bad', 'Average', 'Great', 'Perfect']
const color = ['', '#f17a45', '#f19745', '#f1a545', '#f1b345', '#f1d045']

function RatingReadOnly({ value, classNames, onClick, reviews, reviewsCount=false }) {

    const starWidth = `${(value % 1) * 100}%`;
    let classes = `item__rating`
    if (classNames) {
        classes += ' '
        classes += classNames
    }
    return (
        <p className={classes}>
            {Array.from({ length: 5 }).map((star, starIndex) => {
                let isRated = starIndex < Math.floor(value);
                let isHalfRated = starIndex === Math.floor(value) && value % 1 !== 0;
                return (
                    <span
                        key={starIndex}
                        // className={starIndex < value ? 'rated' : 'not__rated'}
                        className={`${(isRated || isHalfRated) ? 'rated' : 'not__rated'}`}
                        style={{ width: starWidth, cursor: onClick ? 'pointer' : 'default' }}
                        onClick={() => onClick ? onClick(starIndex + 1) : null}
                    >
                        {isHalfRated
                            ? <FaStarHalfAlt />
                            : isRated
                                ? <FaStar />
                                : <FaRegStar />}
                    </span>

                )
            })}

            {(reviews && value > 0 && reviewsCount ) && 
                <span 
                    className="ml-1 mb-0 review-count" 
                    style={{ 
                        // backgroundColor: color[value],
                        // color: value > 0 ? '#fff' : '#000', 
                        // padding: '5px', 
                        marginTop:'1px',
                        // borderRadius: '5px', 
                        fontSize:14 
                    }}>
                        {reviews ? reviews.length === 1 ? `${reviews.length} Review` : `${reviews.length} Reviews` : 'No Reviews Yet'}
                </span>
            }
        </p>
    )
}

export default RatingReadOnly;
