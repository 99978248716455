import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Container, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
// import {AiOutlineCloseCircle} from "react-icons/ai";
import '../../App.css';
// import { toast } from 'react-toastify';
import API from '../../helpers/api';
import LoadingView from '../LoadingView';

function StripeSuccess() {
    const navigate = useNavigate()
    const searchQuery = new URLSearchParams(useLocation().search)
    const orderId = searchQuery.get('order_id');
    const [ loader, setLoader] = useState(true)
    const [ alert, setAlert ] = useState({ variant: 'danger', message: 'Invalid URL'})


    useEffect(() => {
        verifySuccessBookingOrder()
    }, [orderId])


    const verifySuccessBookingOrder = async () => {
        try {
            const result = await API.apiGet('orders', `/callbackBookingVerification/${orderId}`);
            setLoader(false)
            if(result.data && result.data.success){
                setAlert({ variant: 'info', message: result.data.message})
            }else{
                setAlert({ variant: 'danger', message: result.data.message})
                // toast.error(result.data.message)
                // navigate('/');
            }
        } catch (error) {
            setLoader(false)
            setAlert({ variant: 'danger', message: error.message || 'Oops something went wrong'})
            // navigate('/')
        }
    }

    return (
        <div className='stripe-cancel-main-wraper'>
            <Container>
                {loader
                ?  <LoadingView />
                : <Row>
                    <Col sm={12} className='mb-5'>
                        <h3>Booking Details</h3>
                        <Alert key="info" variant={alert.variant}>
                           {alert.message}
                        </Alert>
                        <Button variant="secondary" onClick={() => navigate("/appointments")}>My Appointments</Button>
                    </Col>
                </Row>}
            </Container>
        </div>
    )
}

export default StripeSuccess