import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { BsClockHistory, BsHandbag, BsCheckCircle, BsBook, BsCurrencyDollar, BsPersonPlus, BsPersonPlusFill, BsQuestionCircle } from 'react-icons/bs';
// import { FaUsers } from 'react-icons/fa';
// import { CiBoxes } from 'react-icons/ci';
// import { AiOutlineDollarCircle } from 'react-icons/ai';
import { MdOutlineReviews, MdFavoriteBorder } from "react-icons/md";
import { IoIosInformationCircleOutline, IoMdMailUnread } from "react-icons/io";
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DateRange } from 'react-date-range';
import moment from "moment";
import { getStats } from "../../../redux/actions/statistics.action";
import LoadingView from "../../LoadingView";
import Schedules from "../Schedules";
import { formatPriceinK, momentFormat } from "../../../utils/validations";
import RatingReadOnly from "../../RatingReadOnly";
import Tooltip from "../../../commonComponents/Tooltip";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/editStyle.css';
// import '../../../App.css';

const filterOptions = [
    { label: "Total", value: 'totalCount' },
    { label: "Today's", value: 'today' },
    { label: "Yesterday's", value: 'yesterday' },
    { label: "Current Week", value: 'currentWeek' },
    { label: "Last Week", value: 'lastWeek' },
    { label: "Current Month", value: 'currentMonth' },
    { label: 'Last Month', value: 'lastMonth' },
    { label: 'Since 10 Days', value: 'since10Days' },
    { label: 'Since 15 Days', value: 'since15Days' },
    { label: 'Since 6 Months', value: 'sinceSixMonths' },
    { label: 'Since 1 Year', value: 'sinceOneYear' },
    { label: 'Custom Range', value: 'customRange' }
]

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [stats, setStats] = useState(null);
    const todayDate = momentFormat.dateFormat(moment());
    const [loader, setLoader] = useState(true);
    const [showScheduleRange, setShowScheduleRange] = useState(false);
    const [showCustomRange, setShowCustomRange] = useState(false);

    const [range, setRange] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'range'
    }]);

    const [scheduleRange, setScheduleRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'scheduleRange'
        }
    ])

    const [enableCalendar, setEnableCalendar] = useState(false);

    const [filterInput, setFilterInput] = useState(filterOptions[0]);

    const statsState = useSelector(state => state.statistics)

    useEffect(() => {
        (async () => {
            setLoader(true);
            await handleGetStats();
        })()
    }, [filterInput, range, dispatch])

    const handleGetStats = async () => {
        let payload = {}
        if (filterInput.value === 'customRange') {
            if (range[0] && range[0].startDate && range[0].endDate)
                payload = {
                    [filterInput['value']]: true,
                    startDate: moment(range[0].startDate).format('YYYY-MM-DD'),
                    endDate: moment(range[0].endDate).format('YYYY-MM-DD')
                }
        } else {
            payload = { [filterInput['value']]: true }
        }

        if (Object.keys(payload).length > 0) {
            dispatch(getStats(payload))
        }
    }

    useEffect(() => {

        if (!statsState.error) {
            if (statsState && statsState.stats && statsState.stats.success) {
                setStats(statsState.stats.result[filterInput.value]);
                setLoader(false)
            }
        }

        if (statsState.error) {
            setLoader(false)
        }
    }, [statsState])


    const handleSelectFilter = (val) => {
        setEnableCalendar(false)
        if (val.value === 'customRange') {
            setEnableCalendar(true)
        }
        setFilterInput(val)
    }

    /** -- Select Date Range -- */
    const handleSelectDates = (v) => {
        setRange([v.range])
    }

    const getSchedulesDate = () => {
        let date = null
        if (scheduleRange[0]) {

            const isSame = moment(scheduleRange[0].startDate).isSame(scheduleRange[0].endDate);
            if (isSame) {
                date = momentFormat.fullDate(scheduleRange[0].startDate)
            } else {
                date = `${momentFormat.fullDate(scheduleRange[0].startDate)} to ${momentFormat.fullDate(scheduleRange[0].endDate)}`
            }
        }

        return date;
    }

    const getCustomRangeDate = () => {
        let date = null
        if (range[0]) {

            const isSame = moment(range[0].startDate).isSame(range[0].endDate);
            if (isSame) {
                date = momentFormat.fullDate(range[0].startDate)
            } else {
                date = `${momentFormat.fullDate(range[0].startDate)} to ${momentFormat.fullDate(range[0].endDate)}`
            }
        }

        return date;
    }

    const getIconByKey = (key) => {
        switch (key) {
            case 'orders': case 'orderPending': case 'orderApprovalPending':
                return <BsClockHistory />;
            case 'orderCompleted':
                return <BsCheckCircle />;
            case 'service':
                return <BsHandbag />;
            case 'classes':
                return <BsBook />;
            case 'earnings':
                return <BsCurrencyDollar />;
            case 'sellerReferrals':
                return <BsPersonPlus />;
            case 'buyerReferrals':
                return <BsPersonPlusFill />;
            case 'averageRating':
                return <MdOutlineReviews />;
            case 'unreadMessage':
                return <IoMdMailUnread />;
            case 'mostBookedSpeciality':
                return <MdFavoriteBorder />
            default:
                return <BsQuestionCircle />;
        }
    };

    // const handleOnClick = (key) => {
    //     switch (key) {
    //         case 'averageRating':
    //             navigate('/reviews'); 
    //             break;
    //         default: return null;
    //     }
    // }
    const handleOnClick = (key) => {
        const urlMapping = {
            orders: '/orders',
            orderPending: '/orders',
            orderApprovalPending: '/orders',
            orderCompleted: '/orders',
            earnings: '/orders',
            sellerReferrals: '/invite',
            buyerReferrals: '/invite',
            averageRating: '/reviews',
            unreadMessage: '/chat',
            mostBookedSpeciality: '/service',
        };

        if (urlMapping[key]) {
            navigate(urlMapping[key]);
        }
    };

    const renderValue = (key, value) => {
        switch (key) {
            case 'earnings':
                return `${formatPriceinK(value)}`
            case 'averageRating':
                return <span style={{ fontSize: 16 }}><RatingReadOnly value={value} /></span>

            default: return value
        }
    }

    return (
        <>
            <Row>
                <Col sm={12} className="d-md-flex justify-content-between">
                    <h5>Dashboard</h5>
                    <div className="filter__options d-flex align-items-center mb-3">
                        <h5 className="mb-0 mr-3">Filter By: </h5>
                        <Select
                            options={filterOptions}
                            value={filterInput}
                            onChange={(v) => handleSelectFilter(v)}
                            className="mr-3"
                        />
                        {enableCalendar &&
                            <h5>
                                {getCustomRangeDate() &&
                                    <span
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setShowCustomRange(!showCustomRange)}
                                    >
                                        {getCustomRangeDate()}
                                    </span>
                                }
                                {' '}
                                <Tooltip message={'Click on date to select date range'}>
                                    <IoIosInformationCircleOutline className="mb-3" size={17} />
                                </Tooltip>
                            </h5>
                        }
                    </div>
                </Col>
                {(enableCalendar && showCustomRange) &&
                    <Col className="mb-3">
                        <DateRange
                            // editableDateInputs={true}
                            onChange={item => handleSelectDates(item)}
                            // moveRangeOnFirstSelection={false}
                            showSelectionPreview={true}
                            ranges={range}
                            direction="horizontal"
                            className="date-selector"
                        />
                    </Col>
                }
            </Row>
            <Row className="mb-3">
                {loader
                    ? <LoadingView />
                    : stats
                        ? Object.entries(stats).map(([key, value], index) =>
                            <>
                                {[
                                    'orders',
                                    'orderPending',
                                    'orderCompleted',
                                    'orderApprovalPending',
                                    'averageRating',
                                    'earnings',
                                    'sellerReferrals',
                                    'buyerReferrals',
                                    'unreadMessage',
                                ].includes(key) &&
                                    <Col sm={6} lg={4} xl={3} className="mb-3" key={index}>
                                        <Card
                                            onClick={() => handleOnClick(key)}
                                        >
                                            <span className="card-img">{getIconByKey(key)}</span>
                                            <Card.Body>
                                                {key === 'orderPending' && <Card.Title>Orders Pending</Card.Title>}
                                                {key === 'orderCompleted' && <Card.Title>Orders Completed</Card.Title>}
                                                {key === 'sellerReferrals' && <Card.Title>Invited Birth Professional</Card.Title>}
                                                {key === 'buyerReferrals' && <Card.Title>Invited Families</Card.Title>}
                                                {key === 'orderApprovalPending' && <Card.Title>Awaiting Approval</Card.Title>}
                                                {key === 'unreadMessage' && <Card.Title>Unread Messages</Card.Title>}
                                                {!['orderPending', 'orderApprovalPending', 'unreadMessage', 'orderCompleted', 'sellerReferrals', 'buyerReferrals', 'doulas', 'averageRating'].includes(key) &&
                                                    <Card.Title>{key}</Card.Title>
                                                }
                                                {key === 'averageRating' && <Card.Title>Average Rating</Card.Title>}
                                                <Card.Text>
                                                    {renderValue(key, value)}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                        {/* <Card 
                                            onClick={() => handleOnClick(key)}
                                        >
                                            <span className="card-img">{getIconByKey(key)}</span>
                                            <Card.Body>

                                                {key === 'orderPending' && <Card.Title>Orders Pending</Card.Title>}
                                                
                                                {key === 'orderCompleted' && <Card.Title>Orders Completed</Card.Title>}
                                                {key === 'sellerReferrals' && <Card.Title>Invited Birth Professional</Card.Title>}
                                                {key === 'buyerReferrals' && <Card.Title>Invited Families</Card.Title>}
                                              
                                                {key === 'orderApprovalPending' && <Card.Title>Awaiting Approval</Card.Title>}
                                                {key === 'unreadMessage' && <Card.Title>Unread Messages</Card.Title>}
                                                {!['orderPending', 'orderApprovalPending', 'unreadMessage', 'orderCompleted', 'sellerReferrals', 'buyerReferrals', 'doulas', 'averageRating'].includes(key) &&
                                                    <Card.Title>{key}</Card.Title>
                                                }

                                                {key === 'averageRating' && <Card.Title>Average Rating</Card.Title>}
                                                <Card.Text>
                                                    {renderValue(key, value)}
                                                   
                                                </Card.Text>
                                            </Card.Body>
                                        </Card> */}
                                    </Col>
                                }
                            </>
                        )
                        : null
                }

                {(stats && stats.mostBookedSpeciality) &&
                    <Col sm={12} lg={6} xl={6} className="mb-3">
                        <Card
                        // onClick={() => handleOnClick('mostBookedSpeciality')}
                        >
                            <span className="card-img">{getIconByKey('mostBookedSpeciality')}</span>
                            <Card.Body>

                                <Card.Title>
                                    Most Booked Speciality
                                </Card.Title>
                                <span style={{ fontSize: '22px', marginTop: '10px', fontWeight: '700' }}>
                                    {stats.mostBookedSpeciality?.service?.category?.name || 'Not Available'}
                                </span>
                            </Card.Body>
                        </Card>
                    </Col>
                }
            </Row>
            <Row className="mb-3">
                <Col sm={12}>
                    <h5>
                        Schedules {' '}
                        {getSchedulesDate() &&
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={() => setShowScheduleRange(!showScheduleRange)}
                            >   of {getSchedulesDate()}
                            </span>
                        }
                        {' '}
                        <Tooltip message={'Click on date to select date range'}>
                            <IoIosInformationCircleOutline className="mb-3" size={17} />
                        </Tooltip>
                    </h5>
                    {showScheduleRange &&
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setScheduleRange([item.scheduleRange])}
                            moveRangeOnFirstSelection={false}
                            ranges={scheduleRange}
                        />}
                </Col>
                <Col sm={12}>
                    <Schedules
                        startDate={todayDate}
                        endDate={todayDate}
                    />
                </Col>
            </Row>
        </>
    );
}

export default Dashboard;