import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {AddStaff, getAllStaff} from "../../../redux/actions/staff.action";
import { emailValidation, formatPhoneNumber } from "../../../utils/validations";
import {  useNavigate } from 'react-router-dom';
import { uploadFilesToCloud } from "../../../redux/actions/cloudinary.action";
import LoadingView from "../../LoadingView";
import UpgradeSubscriptionModal from "../../../commonComponents/UpgradePlanErrorDialogue";
import ImageCropModal from "../../../commonComponents/CustomImageCropper";
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/editStyle.css';
// import '../../../App.css';

function AddStaffContent() {
    const dispatch = useDispatch();
    const navigate = useNavigate()

      /** ------- Image Crop ------ */
    const [showImageCrop, setShowImageCrop] = useState(false);
    const [profileImage, setProfileImage] = useState({ original: '', cropped: ''});

    const [ upgradeSubscription, setUpgradeSubscription ] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const [Active, setActive] = useState(false); //// use for onSubmit click then active 

    const [ staffs, setStaffs] = useState([]);
    const [staff, setstaff] = useState({
        name:'',
        email: '',
        profilePic: '',
        phoneNumber:'',
        files: [],
        // seller_business_desc: ''
    })
    const [errors, setErrors] = useState({
        name:'',
        email: '',
        phoneNumber:'',
        files: '',
        profilePic:'',
    });

    const [ uploadProgress, setUploadProgress] = useState(0);
    const [ fileLoading , setFileLoading ] = useState(false);

    // const AddStaffdetals = useSelector(state => state.staff.StaffAdd)
    // const errorApi = useSelector(state => state.staff.errorStaffPost)
    const staffState = useSelector(state => state.staff );
    const userMeta = useSelector((state) => state.user.userMetaDetail);


    useEffect(() => {
        dispatch(getAllStaff())
    }, [])

    useEffect(() => {
        if(!staffState.error ){
            if (staffState.list) {
                setStaffs(staffState.list)
            }
        }

    }, [staffState])

    useEffect(() => {
        if(userMeta && userMeta.subscription && userMeta.subscription.associatedWithSubscriptionPlan){
            const maxStaffLimit = userMeta.subscription.associatedWithSubscriptionPlan.maxStaffLimit;
            const staffCount = staffs.length + 1;
    
            if (maxStaffLimit && staffCount >= maxStaffLimit) {
                setUpgradeSubscription(true)
            }

        }
    }, [userMeta, staffs]);

    const handleChange = ({ target: { name, value } }) => {
        if((name === 'phoneNumber' ) && value.trim() !== ''){
            value = formatPhoneNumber(value)
        }
        setstaff({ ...staff, [name]: value })
        setErrors({ ...errors, [name]: '' });
    }

    const handleValidation = () => {
        // let fields = staff;
        let formIsValid = true;

        if (!staff.name) {
            formIsValid = false;
            errors.name = "Name is required";
        }

        if(!staff.profilePic){
            formIsValid = false
            errors.profilePic = 'Profile Picture Is Required'
        }

        if(!staff.phoneNumber){
            formIsValid = false;
            errors.phoneNumber = "Phone Number is required"
        }

        // if (!staff.seller_business_desc) {
        //     formIsValid = false;
        //     errors.seller_business_desc = "Write Something about staff";
        // }

        if (!staff.email) {
            formIsValid = false;
            errors.email = "Email is required";
        } else if (!emailValidation(staff.email)) {
            formIsValid = false;
            errors.email = "Please enter a valid email address";
        }
        setErrors({ ...errors });
        return formIsValid;
    }

    const onSubmit = e => {
        e.preventDefault();
        if (handleValidation()) {
            setActive(true)
            setLoading(true)
            dispatch(AddStaff(staff))
        }
    }

    // const handleFileChange = async (event, field) => {
    //     setErrors({ ...errors, [field]: '' });
    
    //     const files = event.target.files;
    //     const formData = new FormData();
    //     let hasError = false;
    
    //     for (let i = 0; i < files.length; i++) {
    //         const file = files[i];
  
    //         if((field === 'profilePic') && !['image/jpeg' , 'image/png' , 'image/jpg'].includes(file.type)){
    //           setErrors({ ...errors, [field]: file.name + ' file type is not supported' });
    //           hasError = true; // Set hasError to true if there's an error
    //           break; // Exit the loop early if there's an error
    //         }
  
  
    //         if (file.size > (1024 * 1024) * 10) {
    //             setErrors({ ...errors, [field]: file.name + ' size should be less than 10mb' });
    //             hasError = true; // Set hasError to true if there's an error
    //             break; // Exit the loop early if there's an error
    //         }
            
    //         formData.append('files', file);
    
    //     }
    
    //     if (!hasError && formData.has('files') ) { // Proceed with upload only if there are no errors
  
    //         const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading);
    //         if (response && response.data && response.data.success) {
  
    //             setstaff({
    //                 ...staff,
    //                 profilePic: response.data.files[0],
    //             });
               
    //         }
    //     }
    //   };

      useEffect(() => {
        if(staffState.errorStaffPost){
            renderToast('error', staffState.errorStaffPost.message);
            setLoading(false);
        }

        if( staffState.StaffAdd ){
            renderToast('success', staffState.StaffAdd );
            setLoading(false);
            navigate('/staff')
        }
      }, [staffState])


      const renderToast = (variant, message) => {
        toast[variant](message , {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
      }

    
    const handleProfilePicChange = async (event, field) => {
        setErrors({ ...errors, profilePic: '' });

        const file = event.target.files[0];
        let hasError = false;
    
        if(!file) return ;
    
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
          setErrors({ ...errors, profilePic: file.name + ' file type is not supported' });
          hasError = true; // Set hasError to true if there's an error
          return; // Exit the loop early if there's an error
        }
    
        if (file.size > (1024 * 1024) * 10) {
          setErrors({ ...errors, profilePic: file.name + ' size should be less than 10mb' });
          hasError = true; // Set hasError to true if there's an error
          return; // Exit the loop early if there's an error
        }
    
        if (!hasError) { // Proceed with image cropper if there are no errors
            setShowImageCrop(true)
            setProfileImage({ ...profileImage, original: file});
        }
    } 
    
    const handleCloseImageCropper = () => {
        setShowImageCrop(false);
    }
    
    const handleSaveCropImage = async (croppedImage, imageName) => {
        setShowImageCrop(false);
        const formData = new FormData();
        formData.append('files', croppedImage, imageName);
        const response = await uploadFilesToCloud(formData, setUploadProgress, setFileLoading);
        if (response && response.data && response.data.success) {
            setstaff({
                ...staff,
                profilePic: response.data.files[0],
            });
        }
    }

    return (
       <div id="add-staff-wrapper">
            <h2 className="mb-4">Add Staff</h2>
            <Row>
                <Col sm={12}>
                    <Row className="px-2 py-4 bg-white m-0">
                        <Col sm={6} md={4}>
                            <Form.Group className="mb-0 add-pic-staff">
                                <div className="image-upload-p">
                                    <label htmlFor="profile__upload">
                                        {fileLoading
                                            ?  <LoadingView size={48} />
                                            :  <img loading="lazy" className="profile_picture" src={(staff.profilePic && staff.profilePic.url) ? staff.profilePic.url : "/images/profile.png"} />
                                        }
                                        
                                    </label>
                                    <Form.Control
                                        type="file"
                                        id="profile__upload"
                                        title="upload profile"
                                        hidden
                                        style={{ height: '40px' , overflow:'visible', width:'100%'}}
                                        onChange={(e) => upgradeSubscription ? null :handleProfilePicChange(e, 'profilePic')}
                                        disabled={fileLoading || upgradeSubscription}
                                    />
                                </div>
                                {uploadProgress > 0 && <p className="upload-progress-status">Uploaded {uploadProgress}%</p>}
                                {(errors && errors.profilePic) &&  <p className="error-msg" style={{ color: 'red'}}>{errors.profilePic}</p>}
                            </Form.Group>
                        </Col>
                        <Col sm={6} md={8}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Name"
                                    value={staff.name}
                                    onChange={handleChange}
                                />
                            {(errors && errors.name) &&  <span className="error-msg">{errors.name}</span>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control type="email"
                                    placeholder="Email"
                                    name="email"
                                    value={staff.email}
                                    onChange={handleChange}
                                />
                                {(errors && errors.email) &&  <span className="error-msg">{errors.email}</span>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Contact Number" 
                                    name= "phoneNumber" 
                                    value={staff.phoneNumber} 
                                    onChange={handleChange}
                                />
                            {(errors && errors.phoneNumber) &&  <span className="error-msg">{errors.phoneNumber}</span>}
                            </Form.Group>
                            {/* <Form.Group>
                                <Form.Label>About</Form.Label>
                                <Form.Control as="textarea"
                                    rows={5} placeholder="Tell us about..."
                                    name="seller_business_desc"
                                    value={staff.seller_business_desc}
                                    onChange={handleChange}
                                />
                                <span className="error-msg">{errors && errors?.seller_business_desc}</span>
                            </Form.Group> */}
                            <Form.Group className="text-right mt-2">
                                <Button 
                                    disabled={loading || fileLoading} 
                                    variant="primary" 
                                    onClick={(e) => (fileLoading || loading) ? null : onSubmit(e)}
                                >
                                    {loading ? 'Please Wait...' : 'Add'}
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Col>
                {upgradeSubscription &&
                    <UpgradeSubscriptionModal showPopup={upgradeSubscription} targetDivId="add-staff-wrapper"/>
                }
                {showImageCrop &&
                    <ImageCropModal 
                        show={showImageCrop}
                        handleClose={handleCloseImageCropper}
                        image={profileImage.original}
                        handleSave={handleSaveCropImage}
                    />
                }
            </Row>
       </div>
    );
}

export default AddStaffContent;