import React from 'react';
import { OverlayTrigger, Tooltip as BootTooltip } from 'react-bootstrap';

const Tooltip = ({ show=true, children, message, placement  }) => {


    return (
         message && show
         ?   <OverlayTrigger 
                placement={placement ? placement : "auto" }
                overlay={
                    <BootTooltip id={`tooltip`}>
                    {message}
                </BootTooltip>
                }
            >
                {children}
            </OverlayTrigger>
        :   children
    )
}

export default Tooltip;