import React from "react";
import { Col, Container, Row, ListGroup, Button } from "react-bootstrap";
import './footerStyle.css';
import ReviewUsOnGoogle from "../../commonComponents/CustomReviewOnGoogle";
import { FaFacebook } from "react-icons/fa";

import { FaInstagramSquare } from "react-icons/fa";

function FooterSite() {
    return (
        <footer className="App-footer">
            <Container>
                <Row>
                    <Col sm={8} lg={6}>
                        <div className="brand--Name">
                            <a href="/">
                                <img loading="lazy" src="/images/logo.webp" alt="BirthBridge Logo" />
                                <span>BirthBridge</span>
                            </a>
                            <p>
                                BirthBridge is a marketplace designed to empower small businesses, independent professionals, and the families they serve. Whether you’re a certified doula, midwife, lactation consultant, childbirth educator, or perinatal fitness expert, our platform helps you grow, connect, and thrive.
                            </p>
                            <p>
                                BirthBridge simplifies the journey for families to find and book trusted professionals while providing tools and a supportive community for professionals to succeed. Together, we’re redefining how families and professionals come together for exceptional care and support.
                            </p>
                        </div>

                        <ReviewUsOnGoogle link="https://g.page/r/CTNZxrqLThRJEAI/review" />
                        <div>

                            <FaFacebook
                                size={38}
                                color="#3b5998"
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                            <FaInstagramSquare
                                size={38}
                                color="#C13584"
                                style={{ cursor: 'pointer', marginRight: '10px' }}
                            />
                        </div>
                    </Col>

                    <Col>
                        <div className="footer--list">
                            <h3>Community</h3>
                            <ListGroup>
                                <ListGroup.Item href="/building-stronger-birth-teams-the-power-of-trusted-connections" action>Build Your Team</ListGroup.Item>
                                <ListGroup.Item href="/innovative-birth-marketplace-what-sets-birthbridge-apart" action>What Sets Us Apart</ListGroup.Item>
                                <ListGroup.Item href="/support-families-through-surrogacy-and-adoption-donations" action>Giving Back</ListGroup.Item>
                                <ListGroup.Item href="/birth-professional-listing" action>Join Our Community of Birth Providers</ListGroup.Item>
                                <ListGroup.Item href="/blog-list" action>Our Blogs</ListGroup.Item>
                                <ListGroup.Item href="/search" action>Search Birth Professionals</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Col>
                    <Col>
                        <div className="footer--list">
                            <h3>Discover</h3>
                            <ListGroup>
                                <ListGroup.Item href="/faqs" action> FAQs</ListGroup.Item>
                                <ListGroup.Item href="/contact-birthbridge" action>Contact Us</ListGroup.Item>
                                <ListGroup.Item href="/terms-and-conditions" action>Terms & Conditions</ListGroup.Item>
                                <ListGroup.Item href="/privacy-policy" action>Privacy Policy</ListGroup.Item>
                                <ListGroup.Item href="/hipaa-policy" action>HIPAA Policy</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Col>
                    <Col>
                        <div className="footer--list">
                            <h3>Articles</h3>
                            <ListGroup>

                                <ListGroup.Item href="/doula-service" action>Doulas</ListGroup.Item>
                                <ListGroup.Item href="/midwives-service" action>Midwives</ListGroup.Item>
                                <ListGroup.Item href="/newborn-care-workshop-page" action>Newborn Care Workshops</ListGroup.Item>
                                <ListGroup.Item href="/the-role-of-lactation-consultants" action>Lactation Consultants</ListGroup.Item>
                                <ListGroup.Item href="/the-benefits-of-prenatal-and-postpartum-fitness" action>Prenatal & Postpartum Fitness:</ListGroup.Item>
                                <ListGroup.Item href="/the-importance-of-massage-therapy-during-pregnancy-and-postpartum" action>Massage Therapy</ListGroup.Item>
                                <ListGroup.Item href="/the-importance-of-nutritional-counseling-during-pregnancy-and-postpartum" action>Nutritional Counseling</ListGroup.Item>
                                <ListGroup.Item href="/birth-photography-services" action>Maternity, Newborn and Family Photography</ListGroup.Item>
                                <ListGroup.Item href="/the-value-of-pregnancy-support-groups" action>Pregnancy Support Groups</ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="copyright--Text">
                            <p>&copy; 2025 BirthBridge. All Rights Reserved</p>
                            <ListGroup className="justify-content-end">
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>

    );
}

export default FooterSite;