import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const CancelOrderReason = ({loading,appointment,  showCancelModal, handleCloseCancelModal, handleChangeReason, cancelReason, handleSubmitCancelRequest, order}) => {
    return (
        <Modal
            show={showCancelModal}
            onHide={() => handleCloseCancelModal()}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Cancel Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='wallet-popup-text'>
                    <p>Are you sure you’d like to cancel this order?</p>
                    <h6>Reason for Cancellation</h6>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Control 
                        placeholder={`Please let ${appointment?.associatedWithUser?.name} know the reason for cancelling this order`}
                            as="textarea" 
                            rows={3}
                            onChange={(e) => handleChangeReason(e)}
                            value={cancelReason} 
                        />
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="secondary" 
                    onClick={() => handleCloseCancelModal()}
                >
                    Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={() => (loading || !cancelReason) ? null : handleSubmitCancelRequest(order ? order._id : null)}
                    disabled={loading || !cancelReason}
                >
                    {loading ? 'Sending...' : 'Submit'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CancelOrderReason;