import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Dropdown, Table, Tabs, Tab, Alert } from "react-bootstrap";
import { getAllStaff, SuspendedStaffSingle, InviteStaffVerification, getDeletedStaff, updateStaff } from "../../../redux/actions/staff.action";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from 'react-icons/md';
import { AiOutlineEye, AiOutlineSend } from "react-icons/ai";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from "react-router-dom";
import RatingReadOnly from "../../RatingReadOnly";
import LoadingView from "../../LoadingView";
import { displayAddress, generateLetterSVG, momentFormat } from "../../../utils/validations";
import CustomCertificatesCard from "../../../commonComponents/CustomCertificatesCard";
import Reviews from "../../../commonComponents/Reviews";
import API from "../../../helpers/api";
import UpgradeSubscriptionBox from "../../../commonComponents/UpgradePlanErrorDialogue/content";
import { optimizedCloudinaryImage } from "../../../utils/fileCompression";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './staff.css';
// import '../../../App.css';

function StaffList() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const defaultTab = 'about'

    const searchParams = new URLSearchParams(location.search)

    const [loading, setLoading] = useState(true);
    const [staffDetails, setstaffDetails] = useState([]);
    const [staff, setStaff] = useState(null);

    const [modalLoading, setModalLoading] = useState({ reviews: false })

    const [reviews, setReviews] = useState([]);
    const [tab, setTab] = useState(defaultTab)

    const [isTrashPage, setTrashPage] = useState(false);

    const [upgradeSubscription, setUpgradeSubscription] = useState(false);

    const [showStaffModel, setshowStaffModel] = useState(false); ////  Model open staff details

    const staffState = useSelector(state => state.staff);
    const userMeta = useSelector((state) => state.user.userMetaDetail);

    const handleCloseStaffModel = () => {
        setshowStaffModel(false)
        setStaff(null);
        setReviews([])
    }

    const handleShowStaffModel = (staff) => {
        setStaff(staff)
        setshowStaffModel(true)
    };

    useEffect(() => {
        setLoading(true)
        getStaffList()

    }, [location.search])

    useEffect(() => {
        if (staff) {
            fetchReviews()
        }
    }, [staff])

    const getStaffList = () => {
        if (searchParams.get('view') === 'trash') {
            setTrashPage(true)
            dispatch(getDeletedStaff())
        } else {
            setTrashPage(false)
            dispatch(getAllStaff())
        }
    }

    useEffect(() => {
        if (!staffState.error) {
            if (staffState.list) {
                setstaffDetails(staffState.list)
                setLoading(false)
            }

            if (staffState.invite) {
                renderToast('success', staffState.invite);
                getStaffList()
            }

            if (staffState.deleted) {
                renderToast('success', staffState.deleted);
                getStaffList()
            }

            if (staffState.deletedList) {
                setstaffDetails(staffState.deletedList);
                setLoading(false)
            }

            if (staffState.update && staffState.update.success) {
                renderToast('success', staffState.update.message)
                getStaffList()

            }
        }

        if (staffState.error) {
            setLoading(false);
            renderToast('error', staffState.error.message)
        }

    }, [staffState])

    useEffect(() => {
        if (userMeta && userMeta.subscription && userMeta.subscription.associatedWithSubscriptionPlan) {
            const maxStaffLimit = userMeta.subscription.associatedWithSubscriptionPlan.maxStaffLimit;
            const staffCount = staffDetails.length + 1;

            if (maxStaffLimit && staffCount >= maxStaffLimit) {
                setUpgradeSubscription(true)
            }
        }
    }, [userMeta, staffDetails]);

    const handleDeleteStaff = (id) => {
        dispatch(SuspendedStaffSingle(id))
        getStaffList()
    }

    const handleInviteStaff = (id) => {
        dispatch(InviteStaffVerification(id))
    }

    const renderToast = (variant, message) => {
        toast[variant](message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        })
    }

    const handleRestore = (staffId) => {
        dispatch(updateStaff(staffId, { is_deleted: false, is_active: true }))
    }

    const handleLoading = (key, value) => {
        setModalLoading({ ...modalLoading, [key]: value })
    }

    const fetchReviews = async () => {
        let payload = {
            userId: staff._id
        }
        try {
            handleLoading('reviews', true)
            const response = await API.apiGet('rating', payload);
            handleLoading('reviews', false)
            if (response.data && response.data.success) {
                setReviews(response.data.reviews);
            }
        } catch (error) {
            handleLoading('reviews', false)
        } finally {
            handleLoading('reviews', false)
        }
    }

    const renderTable = () => {
        return (
            <>
                <Col sm={12} className="tb-staff">
                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th scope="col">S.No.</th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                {/* <th scope="Col">Email Status</th> */}
                                <th scope='col'>Phone</th>
                                <th scope="col">Account Status</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {staffDetails.map((_staff, staffIndex) => {
                                return (
                                    <tr key={staffIndex}>
                                        <td aria-describedby="Sno">{staffIndex + 1}</td>
                                        <td aria-describedby="Name">{_staff.name}</td>
                                        <td aria-describedby="Email">
                                            {_staff.email}  <span className={`status-services status--${_staff.emailVerification}`}>{_staff.emailVerification}</span></td>
                                        {/* <td aria-describedby="Email Status">
                                        
                                        </td> */}
                                        <td aria-describedby="phone">{_staff.phoneNumber}</td>
                                        <td aria-describedby="account status">
                                            {_staff.is_active ? 'Active' : 'Inactive'}
                                        </td>
                                        <td aria-describedby="joining date">
                                            {momentFormat.fullDate(_staff.createdAt)}
                                        </td>

                                        <td aria-describedby="action">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <AiOutlineEye />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleShowStaffModel(_staff)}>
                                                        <AiOutlineEye /> View
                                                    </Dropdown.Item>

                                                    {isTrashPage
                                                        ?
                                                        <Dropdown.Item onClick={() => handleRestore(_staff._id)}>
                                                            <MdDelete /> Restore
                                                        </Dropdown.Item>
                                                        : <Dropdown.Item onClick={() => handleDeleteStaff(_staff._id)}>
                                                            <MdDelete /> Delete
                                                        </Dropdown.Item>}

                                                    {(!isTrashPage && _staff.emailVerification == "pending")
                                                        &&
                                                        <Dropdown.Item onClick={() => handleInviteStaff(_staff._id)}>
                                                            <AiOutlineSend /> Re-Invite
                                                        </Dropdown.Item>
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Col>
            </>
        )
    }

    const viewStaffModal = () => {
        return (
            <Modal
                size="lg"
                show={showStaffModel}
                onHide={handleCloseStaffModel}
                backdrop={true}
                keyboard={true}
            >
                <Modal.Header closeButton />
                <Modal.Body>
                    <Container className="main-wraper-tabs staff--modal">
                        <Tabs
                            defaultActiveKey={defaultTab}
                            id="tab--about"
                            mountOnEnter
                            unmountOnExit
                            onSelect={(v) => setTab(v)}
                            activeKey={tab}
                        >
                            <Tab
                                eventKey="about"
                                title="About"
                            >
                                <div className="staff--profile d-flex align-items-center justify-content-between">
                                    <img
                                        loading="lazy"
                                        alt={`Business photos for ${staff.name}`}
                                        src={staff.profilePic ? optimizedCloudinaryImage(staff.profilePic.url) : generateLetterSVG(staff.name)} />
                                    <Button
                                        variant="primary"
                                        onClick={() => navigate(`/products/staff/${staff._id}`)}
                                    >
                                        View Products
                                    </Button>
                                </div>
                                <h6><strong> Personal Details </strong></h6>
                                <div className='table-model-popup'>
                                    <table className='striped table'>
                                        <tr>
                                            <td>Name</td>
                                            <td>{staff.name}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>
                                                <span className="mr-2">{staff.email}</span>
                                                <span className={`status-services status--${staff.emailVerification}`}>
                                                    {staff.emailVerification}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Phone Number</td>
                                            <td>{staff.phoneNumber}</td>
                                        </tr>
                                        <tr>
                                            <td>Address</td>
                                            <td>{displayAddress(staff.address)}</td>
                                        </tr>

                                    </table>
                                </div>
                                <h6><strong> Other Details </strong></h6>
                                <div className='table-model-popup'>
                                    <table className='striped table'>
                                        <tr>
                                            <td>About</td>
                                            <td>{staff.seller_business_desc || "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <td>Average Ratings</td>
                                            <td><RatingReadOnly reviews={reviews} value={staff.averageRating} /></td>
                                        </tr>
                                        <tr>
                                            <td>Service Range</td>
                                            <td>{staff.seller_serve_distance} miles</td>
                                        </tr>
                                        <tr>
                                            <td>Account Status</td>
                                            <td>{staff.is_active ? 'Active' : 'Inactive'}</td>
                                        </tr>
                                        <tr>
                                            <td>Language</td>
                                            <td>{staff.language?.map(item => item.label)?.join(', ') || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td>Race</td>
                                            <td>{staff.race || "N/A"}</td>
                                        </tr>
                                    </table>
                                </div>
                            </Tab>
                            <Tab
                                eventKey="certificate"
                                title="Certificate"
                            >
                                <div className="staff--modal--tab--content">
                                    {(staff.files && staff.files.length > 0)
                                        ?
                                        <Row>
                                            {staff.files.map((item, itemIndex) => (
                                                <Col sm={12} md={6} xl={6} className="mb-4" key={itemIndex}>
                                                    <CustomCertificatesCard
                                                        item={item}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                        : <h6 style={{ paddingRight: '20px', paddingLeft: '20px' }}>No Record Available</h6>
                                    }
                                </div>
                            </Tab>
                            <Tab
                                eventKey="reviews"
                                title="Reviews"
                            >
                                <div className="staff--modal--tab--content">
                                    <Reviews
                                        loading={modalLoading.reviews}
                                        reviews={reviews}
                                        showTitle={false}
                                    />
                                </div>
                            </Tab>
                        </Tabs>
                    </Container>
                </Modal.Body>
            </Modal>
        )
    }

    const renderUpgradeDialogue = () => {
        return (
            <Col sm={12} className="text-center">
                <UpgradeSubscriptionBox />
                {/* <Button className="mt-3" onClick={() => navigate('/pay/subscription')}>Subscription Plans</Button> */}
            </Col>
        )
    }


    return (
        <>
            <Container fluid>
                <Row className="staff--wrapper">
                    <Col sm={12}>
                        <div className="our-staff-main-add">

                            <h1 className="our-staff-heading">
                                {isTrashPage ? 'Staff Trash List' : 'Staff'}
                                {isTrashPage && <Button className="ml-3" onClick={() => navigate('/staff')}>Back</Button>}
                            </h1>
                            {!isTrashPage &&
                                <div className="staff-actions">
                                    <Button
                                        className="mr-1"
                                        variant="primary"
                                        disabled={(upgradeSubscription && staffDetails && !staffDetails.length)}
                                        onClick={() => (upgradeSubscription && staffDetails && !staffDetails.length) ? null : navigate('/add-staff')}
                                    >
                                        Add
                                    </Button>
                                    {/* {
                                <Button variant="primary" onClick={() => navigate('/staff?view=trash')}>
                                    View Trash
                                </Button>} */}
                                </div>}
                        </div>
                    </Col>

                    {(!loading && upgradeSubscription && staffDetails && !staffDetails.length) && renderUpgradeDialogue()}

                    {loading
                        ? <LoadingView loader="ThreeDots"/>
                        : (staffDetails && staffDetails.length > 0)
                            ? renderTable()
                            : upgradeSubscription
                                ? null
                                : <h4 style={{ textAlign: 'center' }}>No Record Available</h4>
                    }

                    {/* {(upgradeSubscription && staffDetails && staffDetails.length > 0) && renderUpgradeDialogue()} */}

                </Row>
            </Container>

            {showStaffModel && viewStaffModal()}

        </>
    );
}

export default StaffList;