import React from 'react';
import './termsAndConditions.css';
// import { Card } from 'react-bootstrap';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#3e829a',
        },
    },
});

const TermsAndConditionsFamilies = () => (
    <div className="terms-conditions-container">
        <h1 className="center-text">Terms and Conditions for Families</h1>
        <div className='terms-conditions-wrapper'>
            <p className="terms-conditions-content">
                <strong>1. Introduction</strong><br />
                Welcome to BirthBridge! These Terms and Conditions govern your use of our platform and services as a Family. By signing up and using BirthBridge, you agree to comply with these terms.
            </p>
            <p className="terms-conditions-content">
                <strong>2. Account Registration</strong><br />
                - Provide accurate, complete, and current information during registration.<br />
                - Maintain the security and confidentiality of your account credentials.<br />
                - Notify us immediately of any unauthorized use of your account.
            </p>
            <p className="terms-conditions-content">
                <strong>3. Booking Services</strong><br />
                - Use the platform to search for and book services from certified birth professionals.<br />
                - Provide accurate information when booking services.<br />
                - Comply with the cancellation and refund policies of the birth professionals.
            </p>
            <p className="terms-conditions-content">
                <strong>4. Payments</strong><br />
                - Payments for services will be processed through Stripe.<br />
                - Ensure you have sufficient funds for the booked services.
            </p>
            <p className="terms-conditions-content">
                <strong>5. Reviews and Ratings</strong><br />
                - Provide honest and constructive reviews of birth professionals.<br />
                - Do not engage in any activity to manipulate reviews.
            </p>
            <p className="terms-conditions-content">
                <strong>6. Content and Intellectual Property</strong><br />
                - You retain ownership of any content you create and share on BirthBridge.<br />
                - Grant BirthBridge a non-exclusive license to use, display, and distribute your content on the platform.
            </p>
            <p className="terms-conditions-content">
                <strong>7. Liability and Indemnity</strong><br />
                - BirthBridge is not liable for any disputes or issues arising from services provided by birth professionals.<br />
                - Indemnify BirthBridge against any claims, damages, or expenses arising from your use of the platform.
            </p>
            <p className="terms-conditions-content">
                <strong>8. Termination</strong><br />
                - BirthBridge may terminate or suspend your account if you violate these terms.<br />
                - You may terminate your account at any time by contacting BirthBridge support.
            </p>
            <p className="terms-conditions-content">
                <strong>9. Amendments</strong><br />
                - BirthBridge reserves the right to modify these terms at any time.<br />
                - We will notify you of any significant changes.
            </p>
        </div>
    </div>
);

export default function TermsAndConditionsFamiliesWrapper() {
    return (
        <ThemeProvider theme={theme}>
            <TermsAndConditionsFamilies />
        </ThemeProvider>
    );
}
