import React, { useState } from 'react';
import {
    Box,
    Typography,
    Button,
    useTheme,
    useMediaQuery,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Divider
} from '@mui/material';
import { FiPlus } from 'react-icons/fi';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';

const CommunityHeader = ({ onCreatePost }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [openAuthModal, setOpenAuthModal] = useState(false);

    const userProfileData = useSelector(state => state.user);

    const isAuthenticated = userProfileData?.userDetail?._id;

    const handleCreatePost = () => {
        if (!isAuthenticated) {
            setOpenAuthModal(true);
        } else {
            onCreatePost();
        }
    };

    const handleLogin = () => {
        navigate('/login');
        setOpenAuthModal(false);
    };

    const handleProfessionalSignup = () => {
        navigate('/bridger-signup');
        setOpenAuthModal(false);
    };

    const handleFamilySignup = () => {
        navigate('/family-signup');
        setOpenAuthModal(false);
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 4,
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? 2 : 0
                }}
            >
                <Box>
                    <Typography
                        variant="h4"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            mb: 1
                        }}
                    >
                        BirthBridge Community
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                    >
                        A space to connect, share experiences, and grow together.
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                    >
                        Join the conversation and be part of the BirthBridge community!
                    </Typography>
                </Box>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FiPlus />}
                    onClick={handleCreatePost}
                    sx={{
                        backgroundColor: '#73a5b5',
                        height: 'fit-content',
                        minWidth: isMobile ? '100%' : 'auto',
                        '&:hover': {
                            backgroundColor: '#5d8a99'
                        }
                    }}
                >
                    Create Post
                </Button>
            </Box>

            {/* Authentication Modal */}
            <Dialog
                open={openAuthModal}
                onClose={() => setOpenAuthModal(false)}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: theme.palette.primary.main
                }}>
                    Join the Community
                </DialogTitle>
                <DialogContent>
                    <Typography align="center" sx={{ mb: 2 }}>
                        To create a post, you'll need to log in or create an account first.
                    </Typography>

                    {/* Login Button */}
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 2
                    }}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleLogin}
                            sx={{
                                backgroundColor: '#73a5b5',
                                '&:hover': {
                                    backgroundColor: '#5d8a99'
                                }
                            }}
                        >
                            Log In
                        </Button>

                        <Divider sx={{ my: 2 }}>
                            <Typography variant="body2" color="text.secondary">
                                Or Create Account As
                            </Typography>
                        </Divider>

                        {/* Professional Registration Button */}
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={handleProfessionalSignup}
                            sx={{
                                borderColor: '#73a5b5',
                                color: '#73a5b5',
                                '&:hover': {
                                    borderColor: '#5d8a99',
                                    backgroundColor: 'rgba(115, 165, 181, 0.04)'
                                }
                            }}
                        >
                            Birth Professional
                        </Button>

                        {/* Family Registration Button */}
                        <Button
                            variant="outlined"
                            fullWidth
                            onClick={handleFamilySignup}
                            sx={{
                                borderColor: '#73a5b5',
                                color: '#73a5b5',
                                '&:hover': {
                                    borderColor: '#5d8a99',
                                    backgroundColor: 'rgba(115, 165, 181, 0.04)'
                                }
                            }}
                        >
                            Family Member
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
                    <Button
                        onClick={() => setOpenAuthModal(false)}
                        sx={{
                            color: 'text.secondary',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)'
                            }
                        }}
                    >
                        Maybe Later
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CommunityHeader;
