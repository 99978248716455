import moment from "moment";
import { AiFillPlusSquare } from "react-icons/ai";

const EventWrapper = (props) => {
    // Check if the date of the cell matches today's date
    const isToday = moment(props.value).isSame(moment(), 'day');
    const isPassed = moment().isAfter(props?.value, 'day')
    const  dateCell = {
        borderRight: "0.5px solid #DDDDDD",
        flex: "1 1 0%",
        minWidth:100
        // display: "flex",
        // justifyContent: "flex-end",
        // bottom: 0,
    }
    const positon = {
        position: "absolute",
        top: 0,
        // left: 10,
        bottom: 0,
        padding: "1px",
        // zIndex: 9999999,
      } 
    
    const todayStyle = {
        backgroundColor: "#f9f9d0", // Change to your desired highlight color
    };

    return (
        
        <div style={{ ...dateCell, ...(isToday && todayStyle) }}>
            {!isPassed && <span style={{ ...positon}}>
                <AiFillPlusSquare size={16} />
            </span>}
        </div>
    );
  };


export default EventWrapper;