import React, { useRef, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import './PDFViewer.css'

const PdfViewer = ({ pdfDataUrl }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
      const loadingTask = pdfjsLib.getDocument(pdfDataUrl);
      loadingTask.promise.then(pdf => {
        pdf.getPage(1).then(page => {
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = canvasRef.current;
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
  
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
        });
      });
    }, [pdfDataUrl]);


    return (
      <div 
        className='agreement-pdf-viewer'
        // style={{ height:440, width:'100%', border: '1px solid rgba(0, 0, 0, 0.3)', }}
      >
        {/* <embed src={pdfDataUrl} width="100%"  /> */}
        
        {/* <object 
          data={pdfDataUrl} 
          type="application/pdf" 
          style={{ width: '100%', minHeight: '440px'}}
        >
          <p>
            Your web browser doesn't have a PDF plugin. Instead you can <a href="filename.pdf">click here to download the PDF file.</a>
          </p>
        </object> */}

          <canvas 
            className='pdf-canvas' 
            height="100%" 
            width="100%" 
            ref={canvasRef}
          />
      </div>
    );
  };

  export default PdfViewer;
  