export const checkScrolledToBottom = () => {
    const { scrollTop, offsetHeight, scrollHeight } = document.documentElement;

    const heightDifference = Math.abs(scrollHeight - Math.round(scrollTop) - offsetHeight)

    if (heightDifference <= 10) {
       return true;
    }

    return false;
}

export const scrollToBottom = (elementId) => {
    var messageContainer = document.getElementById(elementId);
    if( messageContainer){
        messageContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
}

export const checkScrolledToTop = (elementId) => {
    const { scrollTop } = document.getElementById(elementId);
    // const { innerHeight } = window;
    
    // console.log( Math.round(scrollTop), innerHeight, offsetHeight, clientHeight, scrollHeight)
    /** --- Detect Bottom Window --- */
    // const bottomOfWindow = scrollHeight - Math.round(scrollTop)  === offsetHeight;

    /** --- Detect Top Of Window */
    // const topOfWindow = Math.round(scrollTop) - innerHeight === offsetHeight ;

    const heightDifference = Math.round(scrollTop)
    return heightDifference;
}

