import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({
  title,
  description,
  url,
  canonical, // Add this prop
  image,
  keywords,
  author,
  lang = 'en',
  streetAddress = "Default Street",
  addressLocality = "Default City",
  addressRegion = "Default State",
  postalCode = "00000",
  country = "US",
  latitude = 0,
  longitude = 0,
}) => {
  const defaultTitle = "BirthBridge";
  const defaultDescription = "From prenatal to postpartum, providing a marketplace to connect expectant families with experienced birth professionals.";
  const defaultUrl = process.env.REACT_APP_URL;
  const defaultImage = "https://res.cloudinary.com/fureverhomed/image/upload/f_auto,q_auto/v1693793640/v69e1ql5z5pebjugkzts.png";
  const defaultKeywords = "BirthBridge, Bridge Directory, Doula Match, Hellomeela, Bornbir, birth, prenatal, postpartum, birth professionals, families, Pregnancy";
  const defaultAuthor = "BirthBridge";
  url = defaultUrl.concat(`${url}`);

  return (
    <Helmet htmlAttributes={{ lang }}>
      {/* Page Metadata */}
      <title>{title ? `${title} | ${defaultTitle}` : defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta name="keywords" content={keywords || defaultKeywords} />
      <meta name="author" content={author || defaultAuthor} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="UTF-8" />
      <meta name="robots" content="index, follow" />
      <meta httpEquiv="Content-Type" content="text/html; charset=UTF-8" />

      {/* Open Graph Tags */}
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:url" content={url || defaultUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={image || defaultImage} />
      <meta property="og:site_name" content="BirthBridge" />
      <meta property="og:locale" content="en_US" />

      {/* Canonical Link */}
      <link rel="canonical" href={canonical || url || defaultUrl} />

      {/* Favicon */}
      <link rel="icon" href="/path/to/favicon.ico" /> {/* Replace with your favicon path */}

      {/* Alternate Languages */}
      <link rel="alternate" href="https://mybirthbridge.com" hrefLang="en" />

      {/* Preload Resources */}
      <link rel="preload" href="/path/to/resource" as="script" />

      {/* LocalBusiness Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          "name": "Birth Professionals Near You",
          "url": url || defaultUrl,
          "logo": image || defaultImage,
          "address": {
            "@type": "PostalAddress",
            "streetAddress": streetAddress,
            "addressLocality": addressLocality,
            "addressRegion": addressRegion,
            "postalCode": postalCode,
            "addressCountry": country
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": latitude,
            "longitude": longitude
          },
          "openingHours": "Mo-Fr 09:00-17:00",
          "telephone": "+1-800-BIRTH"
        })}
      </script>

      {/* Organization Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Birth Bridge LLC",
          "url": "https://www.mybirthbridge.com",
          "logo": defaultImage,
          "sameAs": [
            "https://www.facebook.com/mybirthbridge",
            "https://www.instagram.com/mybirthbridge",
            "https://www.reddit.com/user/mybirthbridge",
            "https://www.google.com/search?q=mybirthbridge" // Google Business page link
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "hello@mybirthbridge.com",
            "contactType": "Customer Service",
            "areaServed": "US",
            "availableLanguage": "English"
          },
          "description": description
            ? `${description} - From prenatal to postpartum, providing a marketplace to connect expectant families with experienced birth professionals.`
            : defaultDescription,
          "founder": {
            "@type": "Person",
            "name": "BirthBridge"
          },
          "foundingDate": "2024",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": streetAddress,
            "addressLocality": addressLocality,
            "addressRegion": addressRegion,
            "postalCode": postalCode,
            "addressCountry": country
          }
        })}
      </script>
    </Helmet>
  );
};

export default SEO;