import { get } from 'lodash';
import API from '../../helpers/api';

/**   import Seller types */
import {
    GET_STAFF_SUCCESS,
    GET_STAFF_FAILED,
    POST_STAFF_SUCCESS,
    POST_STAFF_FAILED,
    GET_STAFF_META_SUCCESS,
    GET_STAFF_META_FAILED,
    DELETE_STAFF_SUCCESS,
    DELETE_STAFF_FAILED,
    GET_DELETED_STAFF_SUCCESS,
    GET_DELETED_STAFF_FAILED,
    INVITE_STAFF_VERIFICATION_SUCCESS,
    INVITE_STAFF_VERIFICATION_FAILED,
    UPDATE_STAFF_PASSWORD_SUCCESS,
    UPDATE_STAFF_PASSWORD_FAILED,
    GET_SINGLE_STAFF_SUCCESS,
    GET_SINGLE_STAFF_FAILED,
    UPDATE_ONE_STAFF_SUCCESS,
    UPDATE_ONE_STAFF_FAILED,
} from './types' ;

// const config = {
//     headers: {
//       Authorization: `${auth.getToken()}`
//     }
//   };

function errorRequest(err, dispatch, type) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    if (data.error) {
        dispatch({
            type: type,
            payload: {message: data.error.message},
        });
    } else {
        dispatch({
            type: type,
            payload: {message: data.message},
        });
    }
}

/**
 * 
 * @param {*} using for staff
 * @returns 
*/


export const getAllStaff = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('staffGet');
        if (response.data && response.data.success) {
          await dispatch({ type: GET_STAFF_SUCCESS, payload: response.data.StaffsDetail })
        } else {
          await dispatch({ type: GET_STAFF_FAILED, payload: response.data.message });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_STAFF_FAILED);
      }
    }
  }

/**
 * 
 * @param {*} using for staff  
 * @returns 
*/

export const AddStaff = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPostUrl('staff', `/add`, payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: POST_STAFF_SUCCESS, payload: response.data.message });
            } else {
                await dispatch({ type: POST_STAFF_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, POST_STAFF_FAILED);
        }
    }
}

/**--------------- staffMeta---------- */


export const staffMetaDetails = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('staffMeta');
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_STAFF_META_SUCCESS, payload: response.data.meta
            });
        } else {
            await dispatch({ type: GET_STAFF_META_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_STAFF_META_FAILED);
      }
    }
}


export const getDeletedStaff = () => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('staffSuspended');
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: GET_DELETED_STAFF_SUCCESS, payload: response.data.StaffsDetail
            });
        } else {
            await dispatch({ type: GET_DELETED_STAFF_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, GET_DELETED_STAFF_FAILED);
      }
    }
}

export const SuspendedStaffSingle = (id) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('staffSuspentOne', `/${id}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: DELETE_STAFF_SUCCESS, payload: response.data.message
            });
        } else {
            await dispatch({ type: DELETE_STAFF_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, DELETE_STAFF_FAILED);
      }
    }
}

export const InviteStaffVerification = (id) => {
    return async (dispatch) => {
      try {
        const response = await API.apiGet('InviteverificationLink', `/${id}`);
        /** -- If Response Success -- */
        if (response.data && response.data.success) {
            await dispatch({ type: INVITE_STAFF_VERIFICATION_SUCCESS, payload: response.data.message
            });
        } else {
            await dispatch({ type: INVITE_STAFF_VERIFICATION_FAILED, payload: response.data });
        }
      } catch (error) {
        errorRequest(error, dispatch, INVITE_STAFF_VERIFICATION_FAILED);
      }
    }
}

export const updateStaffPass = (payload) => {
    return async (dispatch) => {
        try{
            const response = await API.apiPost('staffUpdatePass', payload);
            /** -- If Response Success -- */
            if (response.data && response.data.success) {
                await dispatch({ type: UPDATE_STAFF_PASSWORD_SUCCESS, payload: response.data });
            } else {
                await dispatch({ type: UPDATE_STAFF_PASSWORD_FAILED, payload: response.data });
            }
        } catch (error) {
            errorRequest(error, dispatch, UPDATE_STAFF_PASSWORD_FAILED);
        }
    }
}

export const getSingleStaff = (id) => {
  return async (dispatch) => {
    try {
      const response = await API.apiGet('staffGet', `/${id}`);
      /** -- If Response Success -- */
      if (response.data && response.data.success) {
          await dispatch({ type: GET_SINGLE_STAFF_SUCCESS, payload: response.data.staffDetail
          });
      } else {
          await dispatch({ type: GET_SINGLE_STAFF_FAILED, payload: response.data });
      }
    } catch (error) {
      errorRequest(error, dispatch, GET_SINGLE_STAFF_FAILED);
    }
  }
}

/** ----- Update Staff ----- */
export const updateStaff = (staffId, payload) => {
  return async (dispatch) => {
    try {
      const response = await API.apiPostUrl('staff', `/edit/${staffId}`, payload);
      /** -- If Response Success -- */
      if (response.data && response.data.success) {
            await dispatch({ type: UPDATE_ONE_STAFF_SUCCESS, payload: response.data });
        } else {
            await dispatch({ type: UPDATE_ONE_STAFF_FAILED, payload: response.data });
        }
    } catch (error) {
      errorRequest(error, dispatch, UPDATE_ONE_STAFF_FAILED);
    }
  }
}