import React, { useEffect } from 'react';
import { Col, Row, Container, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import * as auth from '../../helpers/auth';
import { useSelector } from 'react-redux';
import '../../App.css';

function PaymentSuccess() {
    const navigate = useNavigate()
    const location = useLocation()
    const tnx_id  = new URLSearchParams(location.search).get('tnx_id')
    const userDetail = useSelector((state) => state.user.userMetaDetail);
    useEffect(() => {
        if( userDetail && userDetail.subscription && userDetail.subscription.subscribed){
            auth.removeSubscriptionSession();
        }

        setTimeout(() => {
            navigate('/pay/subscription')
        }, 5000);
    }, [userDetail])

    return (
        <div className='stripe-cancel-main-wraper'>
            <Container>
                <Row>
                    <Col sm={12} className='mb-5'>
                        <h3>Payment processed with Transaction Id {tnx_id || 'N/A'} </h3>
                        <Alert key="info" variant="info">
                           Your Payment is being processed. We will send you an email when your Payment succeeds. 
                        </Alert>
                        <p>Redirecting... Please do not refresh</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PaymentSuccess