import { get } from 'lodash';
import API from '../../helpers/api';
import { toast } from 'react-toastify';

function errorRequest(err) {
    let data = get(err, 'response.data', null);
    data = data || get(err, 'response');
    data = data || err;
    let error = ''
    if (data.error) {
        toast.error(data.error.message)
    } else {
        toast.error(data.message)
    }
}

export const uploadFilesToCloud = async (payload, setUploadProgress, setFileLoading, field) => {
    try {

        if( field){
            setFileLoading(prevState => ({ ...prevState, [field]: true })); 
        }else{
            setFileLoading(true)
        }
        const response = await API.apiUploadFile('cloudinary', payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if( field ){
                    setUploadProgress(prevState => ({ ...prevState, [field]: progress }));
                }else{
                    setUploadProgress(progress);
                }
            }
        });

        if( field ){
            setFileLoading(prevState => ({ ...prevState, [field]: false })); 
            setUploadProgress(prevState => ({ ...prevState, [field]: 0 }));
        }else{
            setFileLoading(false)
            setUploadProgress(0)
        }
       
        return response
    } catch (error) {
        errorRequest(error);
        if( field ){
            setFileLoading(prevState => ({ ...prevState, [field]: false })); 
            setUploadProgress(prevState => ({ ...prevState, [field]: 0 }));
        }else{
            setFileLoading(false)
            setUploadProgress(0)
        }
        return false
    }
}