// import React, { useState, useEffect, useRef } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { getMetaUser, updateTimeZone } from './redux/actions/user.action';
import { receivedDeletedMessage, receivedMessage, receivedRoomJoined, receiveReadStatus, registerUserSocket, socketConnection, socketDisconnected, socketReconnect, socketReconnectFailed, unreadMessageCount } from './redux/actions/socket.action';
// import ReactGA from 'react-ga';
import * as auth from './helpers/auth';
import HeaderSite from './components/Header/Header';
import FooterSite from './components/Footer/Footer';
import SellerHeader from './components/Header/SellerHeader';
import SellersModule from './components/Seller';
import {
  sellerIndividualRoutes,
  sellerRoutes,
  buyerRoutes,
  publicRoutes,
  paySubscription,
  isSellerProfileNotCompleted,
  sellerInviteRoute,
  notFound,
  // authRoutes, 
  buyerSignup,
  sellerSignUp,
  commonRouter
} from './routes'; // Import your route configurations
// import AppLoader from './components/LoadingView/AppLoader';
// import SocketEvents from './components/socket';
// import { getTimeZones } from './redux/actions/timeSlots.action';
import { updateUserProfile } from './utils/dst';
import {
  getPlatformMeta,
  // switchAppView 
} from './redux/actions/meta.action';

import usePageView from './customHooks/usePageView';
// import GoogleAnalyticTracker from './googleAnalyticWrapper';
// import 'react-toastify/dist/ReactToastify.css';
import LoadingView from './components/LoadingView';
// import StepAddress from './components/Authentication/UserSignUp/seller/stepAddress';
import AddressModal from './components/AddressModal';
import Swal from 'sweetalert2';
import { Alert } from 'react-bootstrap';
// const Swal = lazy(() => import('sweetalert2'));

function App() {
  usePageView();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get("email");


  const seller = auth.getSeller();
  const staff = auth.getStaff();

  const timeInMs = 15 * 60 * 1000; // 10 minutes 

  const [loader, setLoader] = useState(true);

  const [showFooter, setShowFooter] = useState(true);

  /** ---------- Save Timezones fetched from server --------- */
  const [timeZones, setTimeZones] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [ coordinates, setCoordinates] = useState([]);

  const [metaUserdata, setMetaUserdata] = useState(null);

  const [isReferralActive, setReferralActive] = useState(null);

  const [isAddressModalOpen, setAddressModal] = useState(false);

  const [currentView, setCurrentView] = useState(auth.getCurrentView());

  const [isSellerSignupActive, setSellerSignupActive] = useState(null);
  const [isBuyerSignupActive, setBuyerSignupActive] = useState(null);

  // Use the useSelector hook to access state properties

  // const openPaySubscription = useSelector((state) => state.auth ? state.auth.openPaySubscription : auth.getSubscriptionSession());
  const openPaySubscription = useSelector((state) => {
    if (state.auth && state.auth.openPaySubscription) {
      return state.auth.openPaySubscription
    }
    return auth.getSubscriptionSession();
  });

  const loggedIn = useSelector((state) => state.auth ? state.auth.loggedIn : false);
  // const _loggedIn = useSelector((state) => state.auth );

  const userDetail = useSelector((state) => state.user);
  const timeZoneState = useSelector(state => state.slots);

  const metaState = useSelector(state => state.meta);

  // const matchedRoute = commonRouter.filter(c => !['/', '/join', '/login'].includes(c.route)).find(route => {
  //   const routeRegex = new RegExp(`^${route.route.replace(/:\w+/g, '[^/]+')}$`);
  //   return routeRegex.test(location.pathname);
  // });

  const fetchData = async () => {
    try {
      /** ----- Before Joining Clear All LocalStorage ------- */
      if (['/join'].includes(location.pathname) && token && email) {
        localStorage.clear();
      }

      // Set loader to true before making the requests

      if (loggedIn) {
        // getMatchRoute();

        // Wait for both API calls to finish
        await Promise.all([
          dispatch(getMetaUser()),
          dispatch(getPlatformMeta())
        ]);

        // Once both calls are done, set the loader to false
      } else {
        // Even if not logged in, still call getPlatformMeta
        dispatch(getPlatformMeta());
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoader(false); // In case of error, hide the loader
    }
  };

  useEffect(() => {
    fetchData();

  }, []);


  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimeout();
    };

    const startInactivityTimeout = () => {
      resetInactivityTimeout();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    startInactivityTimeout(); // Start the initial timer

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(inactivityTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const currentUser = auth.isAuth();

      /** -----------  It Uses to Register Socket ----------- */
      registerUserSocket(currentUser.aud);

      socketConnection();
      socketDisconnected();
      socketReconnect();
      socketReconnectFailed();

      dispatch(receivedDeletedMessage());
      dispatch(receivedRoomJoined());
      dispatch(receivedMessage());
      dispatch(receiveReadStatus());
      dispatch(unreadMessageCount());
      // SocketEvents()
    }
  }, [loggedIn])

  useEffect(() => {
    // console.log( 'userDetail', userDetail )
    if(userDetail){
      if (userDetail.error) {
        setLoader(false);
        toast.error(userDetail.error.message)
        console.log('Error Code [188]')
        // auth.logout();
      }
  
      if (!userDetail.error && userDetail.userMetaDetail) {
        setMetaUserdata(userDetail.userMetaDetail);
        // setLoader(false);
      }
    }
  }, [userDetail]);

  useEffect(() => {
    if (metaUserdata) {
      if (['staff', 'seller'].includes(metaUserdata.role) && metaUserdata.is_first_login) {
        // navigate('/scheduler');
        navigate('/scheduler?redirect=profile', { state: { redirect: 'profile' } })
      }

      /** ---- Address Is Blank ---- */
      // checkAddressIsBlank();

      // Check if state and city have non-null and non-empty values && buyer
      if (['buyer'].includes(metaUserdata.role)) {
        const isCityStateAvailable = metaUserdata && metaUserdata.address.city && metaUserdata.address.state;

        setAddressModal(!isCityStateAvailable);
      }

      if(metaUserdata && !metaUserdata.is_active){
        auth.logout()
      }

    }
  }, [metaUserdata])


  /** --------- Fetch Time Zones from server --------- */
  useEffect(() => {
    if (!timeZoneState.error && timeZoneState.timeZones && timeZoneState.timeZones.success) {
      setTimeZones(timeZoneState.timeZones.timeZones);
    }
  }, [timeZoneState])


  useEffect(() => {
    if (timeZones.length && metaUserdata) {
      const dayLightTimeZone = updateUserProfile(metaUserdata.timeZone, timeZones)
      if ((dayLightTimeZone !== metaUserdata.isDayLightTimeZone)) {
        dispatch(updateTimeZone({ isDayLightTimeZone: dayLightTimeZone }))
      }
    }
  }, [timeZones, metaUserdata])


  useEffect(() => {
    setShowFooter(true);
    if (window.location.pathname.includes('chat')) {
      setShowFooter(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (metaState.error) {
      setLoader(false);
    }

    if (metaState.platform && metaState.platform.success) {
      if (metaState.platform.referral) {
        setReferralActive(metaState.platform.referral.is_active);
      }

      if (metaState.platform.sellerSignup) {
        setSellerSignupActive(metaState.platform.sellerSignup.is_active)
      }

      if (metaState.platform.buyerSignup) {
        setBuyerSignupActive(metaState.platform.buyerSignup.is_active)
      }
      setLoader(false);
    }

    if (metaState.appView) {
      setCurrentView(metaState.appView)
    }

    else {
      // getMatchRoute()
    }

  }, [metaState])

  useEffect(() => {
    if(!metaUserdata?.isOutOfCountry){
      showPopupForFamily();
    }
  }, [isAddressModalOpen, metaUserdata]);

  // const getMatchRoute = () => {
  //   if (matchedRoute && (seller || staff)) {
  //     auth.saveCurrentView('client');
  //     dispatch(switchAppView('client'));

  //     setTimeout(() => {
  //       navigate(location.pathname);
  //     }, 0);
  //   }
  // }

  const getRoutes = (routes) => {
    return routes.map((route) => {
      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });
  };


  const inactivityTimeoutRef = useRef(null);

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeoutRef.current);
    inactivityTimeoutRef.current = setTimeout(() => {
      toast.info(`No Activity for the last 10 minutes Reloading for latest updates`)
      window.location.reload();
    }, timeInMs);
  };



  const renderSellerClientRoutes = (_routes) => {
    return [..._routes, ...notFound]
  }

  const renderSellerRoutes = (_routes) => {
    if (isReferralActive) {
      return [..._routes, ...sellerInviteRoute, ...notFound]
    } else {
      return [..._routes, ...notFound]
    }
  }

  const renderPublicRoutes = (_route) => {
    let renderRoutes = [..._route, ...notFound]
    if (isSellerSignupActive && isBuyerSignupActive) {
      renderRoutes = [..._route, ...buyerSignup, ...sellerSignUp, ...notFound]
    }

    if (isSellerSignupActive && !isBuyerSignupActive) {
      renderRoutes = [..._route, ...sellerSignUp, ...notFound]
    }

    if (!isSellerSignupActive && isBuyerSignupActive) {
      renderRoutes = [..._route, ...buyerSignup, ...notFound]
    }

    return renderRoutes
  }



  const renderHeader = () => {
    if (currentView === 'client') {
      return <HeaderSite />
    }

    if (currentView === 'seller') {
      return <SellerHeader />
    }

    if (seller || staff) {
      return <SellerHeader />
    } else {
      return <HeaderSite />
    }
  }

  function WelcomePopup() {
    useEffect(() => {
      const handleUserInteraction = async () => {
        // const Swal = (await import('sweetalert2')).default;

        const hasVisited = localStorage.getItem('hasVisited');
        const skipPopupUrls = ['/terms-and-conditions', '/beta-testing-agreement'];
        const currentUrl = window.location.pathname;
        const shouldShowPopup = !skipPopupUrls.includes(currentUrl);

        if (!hasVisited && shouldShowPopup) {
          Swal.fire({
            title: 'Welcome to BirthBridge!',
            html: `
            <p>We’re currently in beta testing and excited to open up BirthBridge to everyone! Feel free to invite your clients to join, explore, and manage your business as usual. While we’re not marketing just yet, we’d love to hear your feedback if you encounter any issues!</p>
            <label for="terms-checkbox" style="display: block; margin-top: 15px;">
              <input type="checkbox" id="terms-checkbox" /> I agree to the 
              <a href="/beta-testing-agreement" target="_blank" style="color:#aed9e0;font-weight:700">Beta Testing Agreement</a>
            </label>
          `,
            icon: 'info',
            confirmButtonText: 'Got it!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            allowOutsideClick: false,
            backdrop: true,
            showConfirmButton: true,
            didRender: () => {
              const confirmBtn = Swal.getConfirmButton();
              confirmBtn.disabled = true;

              document.getElementById('terms-checkbox').addEventListener('change', function () {
                confirmBtn.disabled = !this.checked;
              });
            },
            preConfirm: () => {
              const termsCheckbox = document.getElementById('terms-checkbox');
              if (!termsCheckbox.checked) {
                Swal.showValidationMessage('You must agree to the terms and conditions to proceed');
                return false;
              }
              return true;
            },
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage.setItem('hasVisited', 'true');
            }
          });
        }

        // Remove event listeners after the popup is shown
        window.removeEventListener('click', handleUserInteraction);
      };

      // Attach event listeners for the first user interaction
      window.addEventListener('click', handleUserInteraction);

      // Cleanup function to remove listeners if component unmounts before interaction
      return () => {
        window.removeEventListener('click', handleUserInteraction);
      };
    }, []);

    return null;
  }


  // const checkAddressIsBlank = () => {
  //   if(metaUserdata) {

  //     // return isCityStateAvailable
  //   }
  // }

  // <p>Watch this quick video to learn more about us:</p>
  // <iframe 
  //     width="100%" 
  //     height="200" 
  //     src="https://www.youtube.com/embed/K4TOrB7at0Y" 
  //     frameborder="0" 
  //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
  //     allowfullscreen>
  // </iframe>

  const showPopupForFamily = async () => {
    // console.log(isAddressModalOpen, metaUserdata);
    if (!isAddressModalOpen && metaUserdata && metaUserdata.role === 'buyer' && metaUserdata.address && metaUserdata.address.city && metaUserdata.address.state) {
      if (metaUserdata.isStateAllowed) {
        // If the state is allowed, show a modal explaining who you are, with an optional video
        Swal.fire({
          title: "Welcome to BirthBridge!",
          html: `
                <p>We’re so excited to have you join our community! BirthBridge is all about connecting families with trusted, experienced birth professionals and creating a space where everyone feels supported and empowered.</p>
                
                <p>Whether you're here to find the perfect team for your journey or to connect with others in the birth community, we’re here to make the process seamless and meaningful.</p>
                
                <p>Let’s build connections, share knowledge, and grow together. Welcome to the BirthBridge family!</p>  
                `,

          //  <iframe 
          //      width="100%" 
          //      height="200" 
          //      src="https://www.youtube.com/embed/K4TOrB7at0Y" 
          //      frameborder="0" 
          //      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          //      allowfullscreen>
          //  </iframe>
          icon: "info",
          showCloseButton: true,
          confirmButtonText: "Got it!",
        });
      } else {
        // If the state is not allowed, show a modal with a message
        Swal.fire({
          title: "Coming Soon to Your State!",
          html: `
                  <p>We’re thrilled to have you join us! While we’re not live in your state yet, you’re welcome to explore the community.</p>
                  <p>Please check out our community by clicking the Our Community button at the top of the page.</p>
                  <p>Stay tuned—we’ll notify you as soon as we’re available in your area!</p>
                `,
          icon: "info",
          showCloseButton: true,
          confirmButtonText: "Got it!",
        });
      }
    }
  };

  // console.log( 'metaUserdata', metaUserdata );

  return (
    <div className="App">

      {(loader || (loggedIn && (seller || staff) && !metaUserdata))
        ? <LoadingView appLoader={true} size={120} />
        : <>
          <ToastContainer />

          {!loader && metaUserdata && !['buyer'].includes(metaUserdata?.role) && metaUserdata?.isOutOfCountry && 
            <Alert className="custom-alert" variant="danger">
              You’re outside our launch region, but you’re still on free promotion and can share your profile.
            </Alert>
          } 

          {renderHeader()}

          {/* Conditionally render WelcomePopup if the user hasn't visited before */}
          {!localStorage.getItem('hasVisited') && <WelcomePopup />}

          {(isAddressModalOpen && ['buyer'].includes(metaUserdata.role)) &&
            <AddressModal
              show={isAddressModalOpen}
              onHide={() => setAddressModal(false)}
              getMetaUser={getMetaUser}
            />
          }

          {/* {showPopupForFamily()} */}

          {/* {renderWelcomePopup()} */}
          {loggedIn || auth.isAuth()
            ? (seller || staff)
              ? ((metaUserdata && metaUserdata.subscription && !metaUserdata.subscription.subscribed) || openPaySubscription)
                ? (<Routes>{getRoutes(paySubscription)}</Routes>)
                : (currentView === 'client' || auth.getCurrentView() === 'client')
                  ? <Routes>{getRoutes(renderSellerClientRoutes(commonRouter))}</Routes>
                  : (<SellersModule>
                    {(metaUserdata && metaUserdata.is_profile_completed)
                      ? (metaUserdata && seller)
                        ? <Routes>{getRoutes(renderSellerRoutes(sellerRoutes))}</Routes>
                        : <Routes>{getRoutes(renderSellerRoutes(sellerIndividualRoutes))}</Routes>
                      : <Routes>{getRoutes(isSellerProfileNotCompleted)}</Routes>
                    }
                  </SellersModule>)
              : <Routes>{getRoutes(buyerRoutes)}</Routes>
            : <Routes>{getRoutes(renderPublicRoutes(publicRoutes))}</Routes>
          }
          {showFooter && <FooterSite />}
        </>
      }


    </div>
  );
}

export default App;