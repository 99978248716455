import { Button } from '@mui/material';
// import Link from 'next/link';
import './header.css';





export default function Header({ title, subTitle, btnDetails = true }) {
  return (
    <div style={{marginBottom:24}} className={'outsideContainerHeader'}>
      <div className={'insideContainerColumnHeader'}>
        <div style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          <h1 className={'subtitle'} style={{ textShadow: "3px 3px 1px #4d645a" }}>{title}</h1>

          <p className='introduction' style={{ textShadow: "1px 1px 1px #4d645a" }}>
            {subTitle}
          </p>

          {btnDetails.showBtn && (
            <a href={btnDetails.btnUrl} passHref>
              <Button style={{  padding: '10px 35px'}} className={'btnPrimaryHeader'}>{btnDetails.btnText}</Button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}